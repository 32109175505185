import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { CustomerGroup, ScopeResponse } from "@/interfaces/serverData";
import { PlusIcon } from "@radix-ui/react-icons";
import { Box } from "@radix-ui/themes";
import type React from "react";
import { useEffect, useState } from "react";
import CustomerGroupCard from "./CustomerGroupCard";
import CustomerGroupPopup from "./CustomerGroupPopup";

export interface CustomerGroupInfo {
    selectedSource: string;
    icon: React.ElementType | undefined;
    options: ScopeResponse[];
    selectedChannels: ScopeResponse[];
    filters: Map<string, Set<string>>;
}

const CustomerGroups: React.FC = () => {
    const [customerGroups, setCustomerGroups] = useState<CustomerGroup[]>([]);

    const api = useApi();

    useEffect(() => {
        api.get(`${URLS.serverUrl}${API.getCustomerGroups}`, {
            headers: { "Content-Type": "application/json" },
        })
            .then((generalRes) => {
                if (generalRes.status === 200) {
                    const data = generalRes.data.data;
                    setCustomerGroups(data ?? []);
                }
            })
            .catch((error) => {
                console.error(
                    "Error fetching general notification settings:",
                    error,
                );
            });
    }, [api]);

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <h2 className="text-2xl font-semibold">Customer Groups</h2>
                <p className=" text-sm text-gray11">
                    Manage your customer groups
                </p>
                <div className="flex flex-col items-end gap-2">
                    <CustomerGroupPopup
                        triggerElement={
                            <DialogTriggerButton>
                                Create Customer Group
                                <PlusIcon />
                            </DialogTriggerButton>
                        }
                        editing={false}
                        sourceFields={[]}
                        customerGroup={{
                            id: "",
                            created_at: "",
                            updated_at: "",
                            description: "",
                            group_name: "",
                            user_id: "",
                            metadata: "",
                            editable: true,
                            integration: "",
                        }}
                        setCustomerGroups={setCustomerGroups}
                    />
                </div>

                {customerGroups !== undefined && (
                    <div className="flex flex-col gap-3 mt-4">
                        {customerGroups?.map((customerGroup) => {
                            if (customerGroup === undefined) {
                                return <></>;
                            }
                            return (
                                <CustomerGroupCard
                                    key={customerGroup.id}
                                    customerGroup={customerGroup}
                                    setCustomerGroups={setCustomerGroups}
                                />
                            );
                        })}
                    </div>
                )}
            </Box>
        </div>
    );
};

export default CustomerGroups;
