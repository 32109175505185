import { Filter } from "@/IssuesTable/Filter";
import FilterDropdown from "@/IssuesTable/FilterDropdown";
import ShinyButton from "@/component/shadcn/magicui/shiny-button";
import { Button } from "@/component/shadcn/ui/button";
import { Calendar } from "@/component/shadcn/ui/calendar";
import { Card } from "@/component/shadcn/ui/card";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import { ScrollArea } from "@/component/shadcn/ui/scroll-area";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    GetTopicsResponse,
    GetUserResponse,
    Insight,
    InsightFilters,
    Topic,
    TopicInfo,
} from "@/interfaces/serverData";
import {
    CalendarIcon,
    CaretDownIcon,
    CaretUpIcon,
} from "@radix-ui/react-icons";
import * as Toggle from "@radix-ui/react-toggle";
import { useQuery } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import { format } from "date-fns";
import { PencilRulerIcon } from "lucide-react";
import { memo, useCallback, useEffect, useState } from "react";
import type { DateRange } from "react-day-picker";
import { FixedSizeList } from "react-window";
import { InsightDisplay } from "./InsightDisplay";
import InsightsListCard from "./InsightsListCard";
import { arraysAreEqual, getFilterOption } from "@/utilities/methods";
import { type FilterOption, FilterType } from "@/IssuesTable/constants";
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";

const areEqual = (
    prevProps: GenerateInsightsProps,
    nextProps: GenerateInsightsProps,
) => {
    return (
        arraysAreEqual(prevProps.savedInsights, nextProps.savedInsights) &&
        // Function comparison is causing unnecessary re renders
        // prevProps.handleSaveAIGeneratedInsight ===
        // nextProps.handleSaveAIGeneratedInsight &&
        prevProps.userID === nextProps.userID &&
        prevProps.topicsMap === nextProps.topicsMap &&
        arraysAreEqual(prevProps.topics, nextProps.topics) &&
        arraysAreEqual(prevProps.users, nextProps.users) &&
        arraysAreEqual(
            prevProps.generatedInsights,
            nextProps.generatedInsights,
        ) &&
        prevProps.setGeneratedInsights === nextProps.setGeneratedInsights &&
        prevProps.filters === nextProps.filters &&
        prevProps.setFilters === nextProps.setFilters &&
        prevProps.isOpen === nextProps.isOpen &&
        prevProps.setIsOpen === nextProps.setIsOpen &&
        prevProps.dateRange === nextProps.dateRange &&
        prevProps.setDateRange === nextProps.setDateRange &&
        prevProps.suggestedIsOpen === nextProps.suggestedIsOpen &&
        prevProps.teamID === nextProps.teamID
    );
};

interface GenerateInsightsProps {
    savedInsights: Insight[];
    handleSaveAIGeneratedInsight: (
        insight: Insight,
        teamID?: string,
    ) => Promise<void>;
    userID: string;
    topicsMap: Map<string, GetTopicsResponse>;
    topics: Topic[];
    users: GetUserResponse[];
    generatedInsights: Insight[];
    setGeneratedInsights: React.Dispatch<React.SetStateAction<Insight[]>>;
    filters: Map<string, Set<string>>;
    setFilters: React.Dispatch<React.SetStateAction<Map<string, Set<string>>>>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    dateRange: DateRange | undefined;
    setDateRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
    suggestedIsOpen: boolean;
    teamID?: string;
}

function GenerateInsights({
    savedInsights,
    handleSaveAIGeneratedInsight,
    userID,
    topicsMap,
    topics,
    users,
    generatedInsights,
    setGeneratedInsights,
    filters,
    setFilters,
    isOpen,
    setIsOpen,
    dateRange,
    setDateRange,
    suggestedIsOpen,
    teamID,
}: GenerateInsightsProps) {
    const itemSize = 65;
    const api = useApi();
    const [listItems, setListItems] = useState<
        { key: string; content: JSX.Element }[]
    >([]);
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const [popUpHeight, setPopUpHeight] = useState<number>(
        window.innerHeight * 0.9,
    );
    const [selectedInsight, setSelectedInsight] = useState<Insight | null>(
        null,
    );
    const handleInsightClick = (insight: Insight) => {
        setSelectedInsight(insight);
    };

    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    // TODO: check that "source" filter is still processed correctly in generate insights
    const filterOptions: FilterOption[] = [
        getFilterOption("Assignee", FilterType.MenuThenSelect, true),
        getFilterOption("Tag", FilterType.MenuThenSelect, true),
        getFilterOption("Source", FilterType.MenuThenSelect, true),
        getFilterOption("Status", FilterType.MenuThenSelect, true),
        getFilterOption("Topic", FilterType.MenuThenSelect, true),
    ];

    const categoryOptionsQuery = useQuery<Category[]>({
        queryKey: ["categories"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getCategories}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });

    // Update the pop up height when the window resizes
    useEffect(() => {
        const handleResize = () => setPopUpHeight(window.innerHeight * 0.9);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [dialogOpen, setDialogOpen] = useState(false);
    useEffect(() => {
        const newListItems: { key: string; content: JSX.Element }[] = [];

        for (const insight of generatedInsights) {
            newListItems.push({
                key: insight.id,
                content: (
                    <div>
                        <button
                            type="button"
                            className="w-full"
                            onClick={() => handleInsightClick(insight)}
                        >
                            <InsightsListCard
                                insight={insight}
                                userID={userID}
                                handleSaveAIGeneratedInsight={
                                    handleSaveAIGeneratedInsight
                                }
                                saved={false}
                                topicsMap={topicsMap}
                                teamID={teamID}
                            />
                        </button>
                    </div>
                ),
            });
        }
        setListItems(newListItems);
    }, [generatedInsights, userID]);

    const handleFilterSelect = useCallback(
        (
            type: string,
            option: {
                label: string;
                value: string;
                key: string;
                color: string;
            },
        ) =>
            () => {
                const newFilters = new Map(filters);
                if (newFilters.has(type)) {
                    const currValues = new Set(newFilters.get(type));
                    // Value is already in filter, so remove it
                    if (currValues?.has(option.value)) {
                        currValues.delete(option.value);
                        // Remove the whole filter if there are no more values
                        if (currValues.size === 0) {
                            newFilters.delete(type);
                        } else {
                            newFilters.set(type, currValues);
                        }
                    } else {
                        // Add the value since it's not in the filter yet
                        currValues.add(option.value);
                        newFilters.set(type, currValues);
                    }
                } else {
                    newFilters.set(type, new Set([option.value]));
                }
                setFilters(newFilters);
            },
        [filters],
    );

    const fetchProcessedResult = async (insight_filters: InsightFilters) => {
        try {
            const res = await api.post(
                URLS.serverUrl + API.determineTopInsights,
                {
                    filters: insight_filters,
                    saved_insights: savedInsights,
                },
            );
            if (res.status === 200) {
                const response: Insight[] = res.data.data;
                setGeneratedInsights(response);
                setIsGenerating(false);
            } else {
                setIsGenerating(false);
                setErrorMsg(res.data.data);
                console.error(
                    `Error fetching processed top questions response with filters ${insight_filters}`,
                );
            }
        } catch (error) {
            const axiosError = error as AxiosError<string>;
            const errorMsg = axiosError.response?.data || "An error occurred";
            setErrorMsg(errorMsg);
            setIsGenerating(false);
            console.error(
                `Error fetching processed top questions response with filters ${insight_filters}: `,
                error,
            );
        }
    };

    const generateFiltersOnClick = () => {
        setIsGenerating(true);
        setErrorMsg("");
        setGeneratedInsights([]);
        const insightFilters: InsightFilters = {};
        if (filters.has("Topic")) {
            const topicInfos: TopicInfo[] = [];
            for (const topicName of Array.from(filters.get("Topic") ?? [])) {
                topicInfos.push({
                    ID: topicsMap.get(topicName)?.id ?? "",
                    Name: topicName,
                });
            }
            insightFilters.topics = topicInfos;
        }
        if (filters.has("Tag")) {
            insightFilters.tags = Array.from(filters.get("Tag") ?? []);
        }
        if (filters.has("Status")) {
            insightFilters.statuses = Array.from(filters.get("Status") ?? []);
        }
        if (filters.has("Source")) {
            insightFilters.sources = Array.from(filters.get("Source") ?? []);
        }
        if (filters.has("Assignee")) {
            insightFilters.assignee_user_ids = Array.from(
                filters.get("Assignee") ?? [],
            );
        }
        if (dateRange?.from) {
            insightFilters.oldest = dateRange.from.toISOString();
        }
        if (dateRange?.to) {
            insightFilters.latest = dateRange.to.toISOString();
        }
        if (teamID) {
            insightFilters.team_id = teamID;
        }
        fetchProcessedResult(insightFilters);
    };

    const handleMenuClick = useCallback(
        (menu: string) => () => {
            // Reset activeMenu when dropdown is closed
            setActiveMenu(menu);
        },
        [],
    );

    return (
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <Card className="m-2 py-1 rounded-sm w-full flex flex-col relative">
                <div className="flex items-center justify-between">
                    <div className="flex flex-col gap-0.5 px-7 pt-2.5 pb-1.5">
                        <div className="flex items-center gap-1 text-[15px] font-semibold">
                            Insights Playground
                            <PencilRulerIcon className="h-6 w-6 p-1 text-[#5B5BD6]" />
                        </div>
                        <div className="text-xs text-muted-foreground">
                            Use custom filters to generate your own AI insights.
                            For the best results, add more filters.
                        </div>
                    </div>
                    {isOpen ? (
                        <Button
                            variant="ghost"
                            className="text-xs px-1 absolute top-2 right-2"
                            onClick={() => setIsOpen(false)}
                        >
                            <CaretUpIcon className="w-5 h-5" />
                        </Button>
                    ) : (
                        <Button
                            variant="ghost"
                            className="text-xs px-1 absolute top-2 right-2"
                            onClick={() => setIsOpen(true)}
                        >
                            <CaretDownIcon className="w-5 h-5" />
                        </Button>
                    )}
                </div>
                {isOpen && (
                    <>
                        <div className="flex items-center gap-1 mx-4 mt-0 px-2 mb-1">
                            <div className="flex items-center gap-1 text-xs px-1">
                                <div> Issues from</div>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            variant="outline"
                                            className="flex items-center gap-2 text-muted-foreground text-xs"
                                        >
                                            <CalendarIcon className="h-3 w-3" />
                                            {dateRange?.from ? (
                                                format(dateRange.from, "PP")
                                            ) : (
                                                <span>Oldest</span>
                                            )}
                                            <div>to</div>
                                            {dateRange?.to ? (
                                                format(dateRange.to, "PP")
                                            ) : (
                                                <span>Latest</span>
                                            )}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0">
                                        <Calendar
                                            mode="range"
                                            selected={dateRange}
                                            onSelect={setDateRange}
                                        />
                                    </PopoverContent>
                                </Popover>
                            </div>
                            {Array.from(filters.entries()).map(
                                ([type, values]) => (
                                    <div key={type}>
                                        <Filter
                                            type={type}
                                            values={values}
                                            filters={filters}
                                            setFilters={setFilters}
                                            topics={topics}
                                            users={users}
                                            customerGroups={[]}
                                            teams={[]}
                                            isSavedViewFilter={false}
                                            categories={
                                                categoryOptionsQuery.data ?? []
                                            }
                                            channels={new Map()}
                                            filterOptions={filterOptions}
                                        />
                                    </div>
                                ),
                            )}
                            <FilterDropdown
                                filters={filters}
                                filterOptions={filterOptions}
                                activeMenu={activeMenu}
                                setActiveMenu={setActiveMenu}
                                handleItemSelect={handleFilterSelect}
                                handleMenuClick={handleMenuClick}
                                topics={topics}
                                users={users}
                                customerGroups={[]}
                                categories={categoryOptionsQuery.data ?? []}
                                teams={[]}
                                channels={new Map()}
                            />
                        </div>
                        <div className="flex items-center px-6 pt-1 pb-3">
                            <Toggle.Root
                                aria-label="Toggle italic"
                                className="flex items-center justify-center rounded text-base leading-4"
                                onClick={generateFiltersOnClick}
                                asChild
                            >
                                <div>
                                    <ShinyButton
                                        text={
                                            <div className="flex flex-row gap-2 items-center text-xs">
                                                <p>Generate Insights</p>
                                            </div>
                                        }
                                        className="text-[#5e6ad2] pr-2 pl-2 text-xs outline outline-1 outline-iris8 flex py-1 flex-wrap justify-start data-[state=on]:bg-[#eceefb]"
                                    />
                                </div>
                            </Toggle.Root>
                        </div>
                    </>
                )}

                {isOpen && isGenerating && (
                    <div className="flex items-center gap-2 px-7 py-1 text-xs flex items-center rounded-lg w-full">
                        Generating AI Insights{" "}
                        <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                    </div>
                )}
                {isOpen && errorMsg && errorMsg !== "" && (
                    <div className="flex items-center gap-2 px-7 py-1 text-xs italic flex items-center rounded-lg w-full">
                        {`Please try again: ${errorMsg}`}
                    </div>
                )}

                {isOpen && generatedInsights.length > 0 && (
                    <ScrollArea className="h-full w-full">
                        <FixedSizeList
                            width={"100%"}
                            height={
                                suggestedIsOpen
                                    ? window.innerHeight * 0.2
                                    : window.innerHeight * 0.55
                            }
                            itemCount={listItems.length}
                            itemSize={itemSize}
                        >
                            {({ index, style }) => {
                                const item = listItems[index];
                                return (
                                    <DialogTrigger style={style} key={item.key}>
                                        {item.content}
                                    </DialogTrigger>
                                );
                            }}
                        </FixedSizeList>
                    </ScrollArea>
                )}
                <DialogContent className="max-w-[1200px] max-h-[750px] w-full h-full">
                    {selectedInsight && (
                        <InsightDisplay
                            insight={selectedInsight}
                            userID={userID}
                            topicsMap={topicsMap}
                            insightSelectedIsSaved={false}
                            handleSaveAIGeneratedInsight={
                                handleSaveAIGeneratedInsight
                            }
                            issueCounts={selectedInsight?.issue_counts}
                            trend={selectedInsight?.trend}
                            teamID={teamID}
                            setDialogClose={() => setDialogOpen(false)}
                        />
                    )}
                </DialogContent>
            </Card>
        </Dialog>
    );
}

export default memo(GenerateInsights, areEqual);
