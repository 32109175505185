import { Avatar } from "@/Ticket/Avatar";
import { MyUser } from "@/Ticket/User";
import Clock from "@/component/Timer";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    GetTopicsResponse,
    GetUserResponse,
    IconEntry,
    QueriesWithPaginationResponse,
    Query,
    Teams,
} from "@/interfaces/serverData";
import { IssueListType } from "@/pages/Admin/AdminQueriesPage";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    arraysAreEqual,
    cleanText,
    formatEmojis,
    getExternalIssueIcon,
    getHtmlStringFromReactContent,
    hasIssueChanged,
} from "@/utilities/methods";
import {
    AvatarIcon,
    ComponentBooleanIcon,
    DotFilledIcon,
} from "@radix-ui/react-icons";
import { Badge, Box } from "@radix-ui/themes";
import type {
    InfiniteData,
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import parse from "html-react-parser";
import React, {
    useMemo,
    useState,
    useCallback,
    memo,
    Suspense,
    useRef,
    useLayoutEffect,
    useEffect,
} from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkEmoji from "remark-emoji";
import remarkGfm from "remark-gfm";
import { toHTML } from "slack-markdown";

import { Checkbox } from "@/component/shadcn/ui/checkbox";

const areEqual = (
    prevProps: IssuesListCardProps,
    nextProps: IssuesListCardProps,
) => {
    return (
        hasIssueChanged(prevProps.issue, nextProps.issue) &&
        prevProps.topicsMap === nextProps.topicsMap &&
        arraysAreEqual(prevProps.users, nextProps.users) &&
        arraysAreEqual(prevProps.teams, nextProps.teams) &&
        prevProps.listType === nextProps.listType &&
        prevProps.refetch === nextProps.refetch &&
        prevProps.teamID === nextProps.teamID &&
        prevProps.isSelected === nextProps.isSelected &&
        prevProps.onSelect === nextProps.onSelect &&
        prevProps.isSelected === nextProps.isSelected &&
        prevProps.openContextMenuForSingleIssue ===
            nextProps.openContextMenuForSingleIssue
    );
};

interface IssuesListCardProps {
    issue: Query;
    categories: Category[];
    topicsMap: Map<string, GetTopicsResponse>;
    users: GetUserResponse[];
    teams: Teams[];
    listType: IssueListType;
    refetch: (
        options?: RefetchOptions,
    ) => Promise<
        QueryObserverResult<
            InfiniteData<QueriesWithPaginationResponse, unknown>,
            Error
        >
    >;
    teamID?: string;
    viewID?: string;
    onSelect: (
        checked: boolean,
        issueId: string,
        group: string,
        event?: React.MouseEvent,
    ) => void;
    isSelected: boolean;
    openContextMenuForSingleIssue: (issue: Query) => void;
}

function IssuesListCard({
    issue,
    categories,
    topicsMap,
    users,
    teams,
    refetch,
    listType,
    teamID,
    viewID,
    onSelect,
    isSelected,
    openContextMenuForSingleIssue,
}: IssuesListCardProps) {
    const navigate = useNavigate();
    const api = useApi();

    if (
        [1265, 1261].includes(issue.number) ||
        [1727, 1711].includes(issue.ticket_number)
    ) {
        console.log(
            "issue ",
            issue.ticket_number,
            " has status",
            issue.ticket_status,
        );
    }

    const [issueState, setIssueState] = useState<Query>(issue);
    useEffect(() => {
        setIssueState(issue);
    }, [issue]);

    useLayoutEffect(() => {
        updateDimensions();
    }, [issueState.topic, topicsMap, issueState.breaching]);

    const foundUser: GetUserResponse | undefined = useMemo(
        () => users.find((user) => user.id === issueState.assignee_user_id),
        [issueState.assignee_user_id, users],
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;

    const SourceSvgImage: React.ElementType | undefined = useMemo(
        () =>
            integrationBackEndDataMappingToSvg.get(
                issueState.source ?? "Unknown",
            ),
        [issueState.source],
    );

    const externalIssuesIcons = useMemo(() => {
        const icons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        issueState.external_issues?.forEach((url) => {
            icons.add({
                Component: getExternalIssueIcon(url),
                props: {
                    width: 20,
                    height: 20,
                    style: { marginLeft: "2", marginRight: "2" },
                },
            });
        });
        return icons;
    }, [issueState.external_issues]);

    const date: string = useMemo(() => {
        let updatedDate = new Date(issueState.ticket_updated_at);
        if (
            Number.isNaN(updatedDate.getTime()) ||
            !issueState.ticket_updated_at
        ) {
            updatedDate = new Date();
        }
        const today = new Date();

        const isToday =
            updatedDate.getDate() === today.getDate() &&
            updatedDate.getMonth() === today.getMonth() &&
            updatedDate.getFullYear() === today.getFullYear();

        if (isToday) {
            const userLocale = navigator.language || "en-US";
            return updatedDate.toLocaleTimeString(userLocale, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
        }

        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        return updatedDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }, [issueState.ticket_updated_at]);

    const [visibleTopics, setVisibleTopics] = useState<
        { label: string; value: string }[]
    >([]);
    const [overflowTopics, setOverflowTopics] = useState<Map<string, string[]>>(
        new Map(),
    );

    const getMaxVisibleTopics = (width: number): number => {
        if (width > 1520) {
            return 2;
        }
        if (width > 1280) {
            return 1;
        }
        return 0;
    };

    const updateDimensions = useCallback(() => {
        const topicsToShow: { value: string; label: string }[] = [];
        const overflow: Map<string, string[]> = new Map();
        const windowWidth = window.innerWidth;
        let MAX_VISIBLE_TOPICS = getMaxVisibleTopics(windowWidth);

        // If breach is on, reduce the max visible topics by 1
        if (
            issueState.breaching &&
            issueState.breaching != null &&
            issueState.breaching !== ""
        ) {
            MAX_VISIBLE_TOPICS = Math.max(MAX_VISIBLE_TOPICS - 1, 0);
        }

        for (let i = 0; i < issueState.topic.length; i++) {
            let topicColor =
                topicsMap.get(issueState.topic[i])?.color ?? "#9B9EF0";
            const value = issueState.topic[i];
            if (topicColor === "") {
                topicColor = "#9B9EF0";
            }
            if (i < MAX_VISIBLE_TOPICS) {
                topicsToShow.push({
                    value: value,
                    label: topicColor,
                });
            } else {
                if (overflow.has(topicColor)) {
                    const curr = overflow.get(topicColor) || [];
                    overflow.set(topicColor, [...curr, value]);
                } else {
                    overflow.set(topicColor, [value]);
                }
            }
        }
        setVisibleTopics(topicsToShow);
        setOverflowTopics(overflow);
    }, [issueState.topic, topicsMap]);

    useLayoutEffect(() => {
        // Update dimensions immediately on mount
        updateDimensions();

        // Add the resize listener to update dimensions on window resize
        const handleResize = () => {
            updateDimensions();
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleRowClick = useCallback(
        (id: string) => {
            let from: string;
            if (listType === IssueListType.Team && teamID) {
                from = "team";
                navigate(`/teams/${teamID}/issue/${id}`, { state: { from } });
            } else if (listType === IssueListType.View && viewID) {
                from = "view";
                navigate(`/views/${viewID}/issue/${id}`, { state: { from } });
            } else {
                from = listType === IssueListType.Inbox ? "inbox" : "issues";
                navigate(`/issue/${id}`, { state: { from } });
            }
        },
        [listType, teamID, navigate],
    );

    const timestampCutoff = "2024-09-30T23:50:00.000000Z";
    const parsedTimestampCutoff = new Date(timestampCutoff);
    const commentParsedTimestamp = new Date(issueState.created_at);

    const [truncatedText, setTruncatedText] = useState<string>(
        cleanText(formatEmojis(issueState.query ?? "")),
    );
    const containerRef = useRef<HTMLDivElement | null>(null);
    const truncateTextToFit = () => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;

            // Clean and format the text before truncating
            const formattedText = cleanText(
                formatEmojis(issueState.query ?? ""),
            );

            // Create a span to measure the width of the full text without rendering it in the DOM
            const tempSpan = document.createElement("span");
            tempSpan.style.visibility = "hidden";
            tempSpan.style.whiteSpace = "nowrap";
            document.body.appendChild(tempSpan);

            let currentText = "";
            const words = formattedText.split(" ");
            let index = 0;

            // Measure word by word until the text exceeds the container width
            while (index < words.length) {
                currentText += `${words[index]} `;
                tempSpan.textContent = currentText.trim();
                if (tempSpan.offsetWidth > containerWidth) {
                    break;
                }
                index++;
            }

            // Set the truncated text (with ellipsis if needed)
            const truncated = words.slice(0, index).join(" ");
            setTruncatedText(truncated);

            // Clean up the temporary span
            document.body.removeChild(tempSpan);

            // If the text is truncated and was cut off, add ellipsis
            if (words.length > index) {
                setTruncatedText((prevText) => `${prevText}...`);
            }
        }
    };

    useLayoutEffect(() => {
        // Truncate text initially
        truncateTextToFit();

        // Adjust truncation when the container resizes
        const handleResize = () => {
            truncateTextToFit();
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [issueState.query]);

    const handleCheckboxClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            onSelect?.(
                !isSelected,
                `${issueState.id}`,
                `${issueState.ticket_status}`,
                e,
            );
        },
        [isSelected, issueState.id, issueState.ticket_status, onSelect],
    );

    return (
        <Card
            className={`${isSelected ? "bg-[#eceefb]" : "hover:bg-muted bg-transparent"} lg:py-2.5 py-1 h-full flex items-center gap-1 lg:gap-0 lg:items-start justify-between pl-1 pr-2 lg:pr-6 border-[#fafafa] border-l-transparent border-r-transparent border-b-transparent  rounded w-full ${issueState.disabled && "bg-muted"}`}
            onClick={() =>
                handleRowClick(
                    `${issueState.ticket_identifier}-${issueState.ticket_number}`,
                )
            }
            onContextMenu={() => openContextMenuForSingleIssue(issueState)}
        >
            <CardContent className="p-0 w-full">
                <button
                    className="text-xs bg-transparent border-none p-0 cursor-pointer w-full"
                    type="button"
                >
                    <div
                        className={`flex items-center justify-between w-full ${issueState.user_info.id === "" && "pt-2"}`}
                    >
                        <div className="flex items-center gap-1 w-full max-w-60 lg:max-w-[21rem] xl:max-w-[27rem] 2xl:max-w-[36rem]">
                            <div
                                className="px-2 h-[100%]"
                                onClick={handleCheckboxClick}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" || e.key === " ") {
                                        e.stopPropagation();
                                    }
                                }}
                            >
                                <Checkbox
                                    checked={isSelected}
                                    className={`${
                                        isSelected ? "opacity-100" : "opacity-0"
                                    } hover:opacity-100 hover:shadow-[0_0_10px_4px] hover:shadow-iris5 lb-comment-details data-[state=checked]:bg-[#5e6ad2] data-[state=checked]:text-[#eceefb] outline-1 outline hover:outline-iris10 outline-[#eceefb] cursor-pointer`}
                                    data-checked={isSelected}
                                />
                            </div>
                            {issueState.user_info.id !== "" && (
                                <div className="lb-root pr-2">
                                    <div className="lb-comment-details">
                                        <Suspense
                                            fallback={
                                                <div className="relative aspect-square w-8 flex-none animate-pulse rounded-full bg-gray-100" />
                                            }
                                        >
                                            <Avatar
                                                user={issueState.user_info}
                                            />
                                        </Suspense>
                                    </div>
                                </div>
                            )}
                            <div className="flex flex-col gap-0.5 w-full">
                                <div className="flex items-center gap-2">
                                    {issueState.user_info.id !== "" ? (
                                        <div className="lb-comment-details-labels">
                                            <MyUser
                                                user={issueState.user_info}
                                                className="lb-comment-author text-sm font-semibold"
                                            />
                                        </div>
                                    ) : (
                                        <div className="text-sm font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                                            {issueState.title?.trim() ||
                                                issueState.query}
                                        </div>
                                    )}
                                    <div className="flex-shrink-0 text-xs text-muted-foreground">
                                        {`${issueState.ticket_identifier}-${issueState.ticket_number}`}
                                    </div>
                                </div>
                                {issueState.user_info.id !== "" && (
                                    <div className="text-xs overflow-hidden whitespace-nowrap text-ellipsis">
                                        {issueState.title?.trim() ||
                                            issueState.query}
                                    </div>
                                )}
                                {(issueState.source === "Slack" ||
                                    issueState.source === "CommunitySlack") &&
                                commentParsedTimestamp <
                                    parsedTimestampCutoff ? (
                                    <div className="text-xs text-muted-foreground">
                                        <ReactMarkdown
                                            remarkPlugins={[
                                                remarkGfm,
                                                remarkEmoji,
                                            ]}
                                            rehypePlugins={[rehypeRaw]}
                                            className="markdown-content"
                                        >
                                            {getHtmlStringFromReactContent(
                                                parse(
                                                    toHTML(
                                                        cleanText(
                                                            issueState.query ??
                                                                "",
                                                        ),
                                                    ),
                                                ),
                                            )}
                                        </ReactMarkdown>
                                    </div>
                                ) : (
                                    <div
                                        ref={containerRef}
                                        className="text-xs text-muted-foreground w-full flex-grow"
                                    >
                                        <ReactMarkdown
                                            remarkPlugins={[
                                                remarkGfm,
                                                remarkEmoji,
                                            ]}
                                            rehypePlugins={[rehypeRaw]}
                                            className="w-full"
                                            components={{
                                                a: ({ node, ...props }) => (
                                                    <a
                                                        {...props}
                                                        style={{
                                                            textDecoration:
                                                                "underline",
                                                            color: "#5B5BD6",
                                                        }}
                                                    />
                                                ),
                                            }}
                                        >
                                            {truncatedText}
                                        </ReactMarkdown>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex items-center max-h-16 overflow-hidden flex-wrap">
                            {issueState.breaching !== "" &&
                                issueState.breaching !== undefined &&
                                issueState.breaching !== null && (
                                    <Box className="lg:block hidden">
                                        <Clock
                                            targetTime={issueState.breaching}
                                            variant="soft"
                                            businessHoursID={
                                                issueState.business_hours_id
                                            }
                                            api={api}
                                        />
                                    </Box>
                                )}
                            {(visibleTopics.length ||
                                overflowTopics.size > 0) && (
                                <div className="relative flex-none">
                                    <div className="flex items-center gap-1 mx-1">
                                        {visibleTopics.map((topic) => (
                                            <Badge
                                                color="gray"
                                                size="2"
                                                radius="full"
                                                variant="outline"
                                                className="m-0.5"
                                                key={topic.value}
                                            >
                                                <div className="flex flex-row items-center">
                                                    <DotFilledIcon
                                                        color={
                                                            topic.label !== ""
                                                                ? topic.label
                                                                : "#9B9EF0"
                                                        }
                                                        style={{
                                                            transform:
                                                                "scale(1.8)",
                                                        }}
                                                    />
                                                    <p className="pl-0.3">
                                                        {topic.value}
                                                    </p>
                                                </div>
                                            </Badge>
                                        ))}
                                        {overflowTopics.size > 0 &&
                                            Array.from(
                                                overflowTopics.entries(),
                                            ).map(([label, topics]) => {
                                                return (
                                                    <Badge
                                                        color="gray"
                                                        size="2"
                                                        radius="full"
                                                        variant="outline"
                                                        className="m-0.5 lg:block hidden"
                                                        key={label}
                                                    >
                                                        <div className="flex flex-row items-center">
                                                            <DotFilledIcon
                                                                color={
                                                                    label ??
                                                                    "#9B9EF0"
                                                                }
                                                                style={{
                                                                    transform:
                                                                        "scale(1.8)",
                                                                }}
                                                            />
                                                            <div className="flex items-center pl-0.3">
                                                                +{topics.length}
                                                            </div>
                                                        </div>
                                                    </Badge>
                                                );
                                            })}
                                    </div>
                                </div>
                            )}
                            <Box className="md:block hidden">
                                <Badge
                                    color="gray"
                                    size="2"
                                    radius="full"
                                    variant="outline"
                                    className="m-0.5"
                                >
                                    <div className="flex flex-row items-center">
                                        <ComponentBooleanIcon
                                            color={
                                                categories.find(
                                                    (category) =>
                                                        category.name ===
                                                        issueState.bot_category,
                                                )?.color ?? "gray"
                                            }
                                        />
                                        <p className="pl-0.5">
                                            {issueState.bot_category}
                                        </p>
                                    </div>
                                </Badge>
                            </Box>
                            <div className="mx-1.5 text-xs w-15">{date}</div>
                            {SourceSvgImage && (
                                <SourceSvgImage className="w-5 h-5 mr-1" />
                            )}
                            {Array.from(externalIssuesIcons).map((icon) =>
                                React.createElement(icon.Component, icon.props),
                            )}
                            {issueState.assignee_user_id &&
                            issueState.assignee_user_id !== "noAssignee" ? (
                                <div className="lb-avatar rounded-lg w-6 h-6 mx-1">
                                    {pictureURL && (
                                        <img
                                            className="lb-avatar-image"
                                            src={pictureURL}
                                            alt={userName}
                                        />
                                    )}
                                    <span>{userName ?? ""}</span>
                                </div>
                            ) : (
                                <AvatarIcon className="w-6 h-6 mx-1" />
                            )}
                        </div>
                    </div>
                </button>
            </CardContent>
        </Card>
    );
}

export default memo(IssuesListCard, areEqual);
