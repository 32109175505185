import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import type {
    Announcement,
    GetUserResponse,
    IconEntry,
} from "@/interfaces/serverData";
import {
    AvatarIcon,
    Cross2Icon,
    DotsHorizontalIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Badge, Button } from "@radix-ui/themes";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnnouncementsState } from "./constants";

import React, { useCallback, useEffect, useRef } from "react";

import MarkdownIt from "markdown-it";

import ReactMarkdown from "react-markdown";

import rehypeRaw from "rehype-raw";

import truncateMarkdown from "markdown-truncate";

import debounce from "lodash/debounce";
import { integrationBackEndDataMappingToSvg } from "../Admin/Integrations/constant";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { useToast } from "@/component/shadcn/ui/use-toast";

interface AnnouncementsListCardProps {
    announcement: Announcement;
    userID: string;
    users: GetUserResponse[];
    forceUpdate: () => void;
}

export function AnnouncementsListCard({
    announcement,
    userID,
    users,
    forceUpdate,
}: AnnouncementsListCardProps) {
    const navigate = useNavigate();
    const { toast } = useToast();

    const [announcementState] = useState<Announcement>(announcement);

    const foundUser: GetUserResponse | undefined = useMemo(
        () => users.find((user) => user.id === announcementState.user_id),
        [announcementState.user_id, users],
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;

    const badgeLength = (announcement?.name?.length ?? 3) * 0.8;
    const md = useRef();
    const [limitCount, setLimitCount] = useState(
        window.innerWidth * 0.08 - badgeLength,
    );

    const [mrkdwnText, setMrkdwnText] = useState<string>(
        truncateMarkdown(announcementState.content, {
            limit: limitCount,
            ellipsis: false,
        }),
    );

    useEffect(() => {
        md.current = new MarkdownIt();
        setMrkdwnText(announcement.content);
    }, []);

    const output =
        md.current &&
        md.current.render(
            truncateMarkdown(announcement.content, {
                limit: limitCount,
                ellipsis: false,
            }),
        );

    const date: string = useMemo(() => {
        let updatedDate = new Date(announcementState.updated_at);

        if (
            Number.isNaN(updatedDate.getTime()) ||
            !announcementState.updated_at
        ) {
            updatedDate = new Date();
        }

        const today = new Date();

        const isToday =
            updatedDate.getDate() === today.getDate() &&
            updatedDate.getMonth() === today.getMonth() &&
            updatedDate.getFullYear() === today.getFullYear();

        if (isToday) {
            const userLocale = navigator.language || "en-US";
            return updatedDate.toLocaleTimeString(userLocale, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
        }

        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        return updatedDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }, [announcementState.updated_at]);

    const api = useApi();
    const handleDelete = async () => {
        const status = AnnouncementsState.delete;
        const requestData = {
            status: status,
            id: announcement.id,
        };

        api.post(URLS.serverUrl + API.announcement, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    toast({
                        title: "Announcement deleted!",
                        description:
                            "Your announcement has been deleted successfully.",
                    });
                    forceUpdate(); // forces an announcement update
                } else {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                    console.log("could not create a draft announcement");
                }
            })
            .catch((res) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            });
    };

    const [serviceNames, setServiceNames] = useState<string[]>([]);

    useEffect(() => {
        if (!announcementState.metadata) {
            return;
        }

        try {
            const data = JSON.parse(announcementState.metadata);
            const keys = Object.keys(data);
            setServiceNames(keys);
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    }, [announcementState.metadata]);

    const serviceIcons = useMemo(() => {
        const icons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        serviceNames.forEach((serviceName) => {
            const IconComponent =
                integrationBackEndDataMappingToSvg.get(serviceName);
            if (IconComponent) {
                icons.add({
                    Component: IconComponent,
                    props: {
                        width: 20,
                        height: 20,
                        style: { marginLeft: "2px", marginRight: "2px" },
                    },
                });
            }
        });
        return icons;
    }, [serviceNames]);

    const handleRowClick = (id: string, status: string) => {
        if (status === AnnouncementsState.draft) {
            navigate(`/announcements/new/${id}`);
        } else if (status === AnnouncementsState.sent) {
            navigate(`/announcements/${id}`);
        }
    };

    const [titleMaxWidth, setTitleMaxWidth] = useState(
        window.innerWidth * 0.75,
    );

    const updateDimensions = useCallback(() => {
        setTitleMaxWidth(window.innerWidth * 0.75);
        setLimitCount(window.innerWidth * 0.08 - badgeLength);
    }, []);

    const debouncedUpdateDimensions = useMemo(
        () => debounce(updateDimensions, 200),
        [updateDimensions],
    );

    // Directly update dimensions when issue or topics change
    useEffect(() => {
        updateDimensions();
    }, [announcementState]);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        // Handle window resize event with debounce
        debouncedUpdateDimensions();
        window.addEventListener("resize", debouncedUpdateDimensions);
        return () =>
            window.removeEventListener("resize", debouncedUpdateDimensions);
    }, [debouncedUpdateDimensions]);

    return (
        <Card
            className="py-2.5 pl-8 pr-6 border-l-transparent border-r-transparent border-b-transparent hover:bg-muted rounded w-full"
            onClick={() =>
                handleRowClick(
                    `${announcementState.id}`,
                    announcementState.status,
                )
            }
        >
            <CardContent className="p-0">
                <button
                    className="text-xs bg-transparent border-none p-0 cursor-pointer w-full"
                    type="button"
                >
                    <div className="flex items-center justify-between w-full gap-2">
                        <div
                            className="flex items-center gap-2"
                            style={{ maxWidth: titleMaxWidth }}
                        >
                            {announcementState.name !== "" &&
                            announcementState.name !== undefined ? (
                                <Badge
                                    color="iris"
                                    size="1"
                                    radius="full"
                                    className="px-2 py-1 rounded-xl text-xs m-0"
                                >
                                    {announcementState.name}
                                </Badge>
                            ) : (
                                <Badge
                                    color="iris"
                                    size="1"
                                    radius="full"
                                    className="px-2 py-1 rounded-xl text-xs m-0"
                                >
                                    <i>Untitled</i>
                                </Badge>
                            )}

                            <div className="flex flex-row text-[13px] overflow-hidden whitespace-nowrap text-ellipsis">
                                <div className="flex flex-row gap-1 ">
                                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                        {output}
                                    </ReactMarkdown>
                                </div>
                                {announcementState.content !== undefined &&
                                    (announcementState.content?.length >
                                        limitCount ||
                                        announcementState.content?.length ===
                                            0) && <p>...</p>}
                            </div>
                        </div>

                        <div className="flex items-center justify-end">
                            <div className="mx-1.5 text-[12px] w-10">
                                {date ?? ""}
                            </div>

                            {Array.from(serviceIcons).map((icon) =>
                                React.createElement(icon.Component, icon.props),
                            )}
                            {announcementState.user_id &&
                            announcementState.user_id !== "noAssignee" ? (
                                <div className="lb-avatar rounded-lg w-6 h-6 mx-1">
                                    {pictureURL && (
                                        <img
                                            className="lb-avatar-image"
                                            src={pictureURL}
                                            alt={userName}
                                        />
                                    )}
                                    <span>{userName ?? ""}</span>
                                </div>
                            ) : (
                                <AvatarIcon className="w-6 h-6 mx-1" />
                            )}

                            {announcementState.status ===
                                AnnouncementsState.draft && (
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button
                                            variant="ghost"
                                            className="ml-1 flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                                        >
                                            <DotsHorizontalIcon className="h-4 w-4" />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent
                                        align="end"
                                        className="w-[150px] max-h-60 p-0 rounded-md overflow-y-auto"
                                        onClick={(event) =>
                                            event.stopPropagation()
                                        }
                                    >
                                        <DropdownMenuItem asChild>
                                            <AlertDialog
                                                open={deleteDialogOpen}
                                                onOpenChange={
                                                    setDeleteDialogOpen
                                                }
                                            >
                                                <AlertDialogTrigger asChild>
                                                    <div className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex flex-row items-center">
                                                        <p className="flex-grow">
                                                            Delete
                                                        </p>
                                                        <TrashIcon className="justify-self-end" />
                                                    </div>
                                                </AlertDialogTrigger>
                                                <AlertDialogContent>
                                                    <div>
                                                        <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                            <AlertDialogTitle>
                                                                Are you
                                                                absolutely sure?
                                                            </AlertDialogTitle>
                                                            <AlertDialogDescription>
                                                                This action
                                                                cannot be
                                                                undone. This
                                                                will permanently
                                                                delete this
                                                                Announcement.
                                                            </AlertDialogDescription>
                                                        </AlertDialogHeader>
                                                    </div>
                                                    <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                                        <AlertDialogCancel>
                                                            Cancel
                                                        </AlertDialogCancel>
                                                        <AlertDialogAction
                                                            onClick={
                                                                handleDelete
                                                            }
                                                        >
                                                            Delete
                                                        </AlertDialogAction>
                                                        <AlertDialogCancel className="shadow-none absolute top-0 right-2 px-2 py-2 ">
                                                            <Cross2Icon />
                                                        </AlertDialogCancel>
                                                    </AlertDialogFooter>
                                                </AlertDialogContent>
                                            </AlertDialog>
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            )}
                        </div>
                    </div>
                </button>
            </CardContent>
        </Card>
    );
}
