import { Button } from "@/component/shadcn/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, URLS } from "@/constant";
import { ContactsAPI } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    CustomerCompany,
    ListCustomersResponse,
    TicketCompany,
    TicketCustomer,
    UserResponse,
} from "@/interfaces/serverData";
import { cn } from "@/lib/utils";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    AvatarIcon,
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    CopyIcon,
    ImageIcon,
    OpenInNewWindowIcon,
    Pencil2Icon,
} from "@radix-ui/react-icons";
import * as Toggle from "@radix-ui/react-toggle";
import { DataList } from "@radix-ui/themes";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Check } from "lucide-react";
import { X } from "lucide-react";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { CompanyDropdown } from "@/reusable_components/dropdowns/CompanyDropdown";

interface CustomerProfileProps {
    ticketID: string;
    userInfo: UserResponse | null;
    source: string;
    source_unique_name: string;
    account?: Account;
    userID: string;
    refetch: (success: boolean, message: string) => void;
}

function CustomerProfile({
    ticketID,
    userInfo,
    source,
    source_unique_name,
    account,
    userID,
    refetch,
}: CustomerProfileProps) {
    const SourceSvgImage: React.ElementType | undefined = useMemo(
        () => integrationBackEndDataMappingToSvg.get(source ?? "Unknown"),
        [source],
    );

    const updateCompanyForTicket = (companyID: string) => {
        const requestData: TicketCompany = {
            ids: [ticketID],
            company_id: companyID,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                refetch(true, "Updated Company!");
            } else {
                refetch(false, "Failed to update company");
            }
        });
    };

    const updateCustomerForTicket = (customerID: string) => {
        const requestData: TicketCustomer = {
            ids: [ticketID],
            customer_id: customerID,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                refetch(true, "Updated Customer!");
            } else {
                refetch(false, "Failed to update customer");
            }
        });
    };

    const displayText =
        source_unique_name.length > 0 && source === "Gmail"
            ? `${source} (${source_unique_name})`
            : source;

    const copyEmail = useCallback((): void => {
        navigator.clipboard.writeText(userInfo?.email ?? "");
    }, [userInfo?.email]);

    const copyUsername = useCallback((): void => {
        navigator.clipboard.writeText(userInfo?.username ?? "");
    }, [userInfo?.username]);

    const handleOpenInNewTab = (id: string, type: string) => {
        if (type === "Customer") {
            window.open(`/accounts/individual/${id}`, "_blank");
        } else if (type === "Company") {
            window.open(`/accounts/${id}`, "_blank");
        }
    };

    const [editing, setEditing] = useState<boolean>(false);

    const [companyID, setCompanyID] = useState<string>(
        userInfo?.company_id ?? account?.company?.id ?? "",
    );

    const [company, setCompany] = useState<CustomerCompany>();

    useEffect(() => {
        if (account?.company_id !== "" && account?.company_id !== undefined) {
            setCompanyID(account?.company_id ?? "");
            const { url } = ContactsAPI.getCompany;

            api.get(`${URLS.serverUrl}${url}/${account?.company_id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        const companyInfo: Account = res.data.data;
                        setCompany({
                            id: companyInfo.id,
                            name: companyInfo.name,
                            domain: companyInfo.domain,
                            image_url: companyInfo.image_url,
                            additional_properties:
                                companyInfo.additional_properties,
                            contract_value: companyInfo.contract_value,
                            contract_type: companyInfo.contract_type,
                        } as CustomerCompany);
                    }
                })
                .catch((res) => {
                    console.error(
                        "Error fetching full Assembly Customer:",
                        res,
                    );
                });
        } else if (
            account?.company?.id !== "" &&
            account?.company?.id !== undefined
        ) {
            setCompanyID(account?.company?.id ?? "");
            setCompany(account?.company);
        }
    }, [account]);

    const [contactEditing, setContactEditing] = useState<boolean>(false);

    const [contactID, setContactID] = useState<string>(account?.id ?? "");

    const api = useApi();

    const fetchContacts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListCustomersResponse> => {
        try {
            const { url, method } = ContactsAPI.listCustomers;
            let postURL = `${URLS.serverUrl}${url}`;
            if (companyID !== "") {
                postURL = `${URLS.serverUrl}${url}/company/${companyID}`;
            }

            const response = await api.get(postURL, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                params: {
                    limit: 100,
                    offset: pageParam,
                },
            });
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data: contactsData,
        fetchNextPage: fetchContactsNextPage,
        hasNextPage: contactsHasNextPage,
        isFetchingNextPage: isFetchingContactsNextPage,
        refetch: refetchContacts,
        isLoading: contactsIsLoading,
        isError: contactsIsError,
    } = useInfiniteQuery({
        queryKey: ["contacts_query", companyID],
        queryFn: fetchContacts,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchOnWindowFocus: true,
        enabled: companyID !== "" && companyID !== undefined, // Only fetch when companyID is not empty
    });

    useEffect(() => {
        if (contactsHasNextPage && !isFetchingContactsNextPage) {
            fetchContactsNextPage();
        }
    }, [
        contactsHasNextPage,
        isFetchingContactsNextPage,
        fetchContactsNextPage,
    ]);

    const capitalizeWords = (text: string) => {
        return text
            .toLowerCase()
            .split("_")
            .join(" ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    const contactsCombinedData =
        contactsData && Array.isArray(contactsData.pages)
            ? contactsData.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];

    const [showMoreProperties, setShowMoreProperties] = useState(false);

    function shouldShowMoreProperties(
        account: Account | undefined,
        company: CustomerCompany | undefined,
    ): boolean {
        const hasAccountProperties =
            account?.additional_properties &&
            Object.keys(account.additional_properties).length > 0;

        const hasCompanyProperties =
            company?.additional_properties &&
            Object.keys(company.additional_properties).length > 0;

        const hasAccountContractValue =
            account?.contract_value !== undefined &&
            account.contract_value !== 0;

        const hasAccountContractType =
            account?.contract_type !== undefined &&
            account.contract_type !== "";

        const hasCompanyContractType = company?.contract_type !== undefined;

        const hasCompanyContractValue =
            company?.contract_value !== undefined &&
            company.contract_value !== 0;

        const hasCompanyDomain = company?.domain !== undefined;

        // Return true if any of the conditions are met
        return (
            hasAccountProperties ||
            hasCompanyProperties ||
            hasAccountContractValue ||
            hasAccountContractType ||
            hasCompanyContractType ||
            hasCompanyContractValue ||
            hasCompanyDomain
        );
    }

    const dataListItemStyle = "flex flex-col gap-0 [&>*]:m-0";

    return (
        <div>
            {userInfo && (
                <div className="prose max-w-none text-xs flex flex-col gap-3">
                    <div>
                        <div className="flex flex-row items-center gap-1">
                            {contactEditing ? (
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            variant="outline"
                                            className="outline mr-2 outline-1 outline-gray-300 justify-between h-8 text-xs px-3 w-[200px]"
                                        >
                                            {contactsCombinedData.find(
                                                (c) => c.id === contactID,
                                            )?.name || "Select contact..."}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-[200px] p-0 ">
                                        <Command>
                                            <CommandInput
                                                placeholder="Search Contact..."
                                                className="h-7 text-xs"
                                            />
                                            <CommandList>
                                                <CommandEmpty className="text-xs pl-4 pt-4 pr-2 pb-2 ">
                                                    No contact found in this
                                                    company yet. Verify there
                                                    are contacts in this
                                                    company, or add one under
                                                    Accounts.
                                                </CommandEmpty>
                                                <CommandGroup>
                                                    {contactsCombinedData.map(
                                                        (c) => (
                                                            <CommandItem
                                                                key={c.id}
                                                                value={c.name}
                                                                onSelect={() => {
                                                                    setContactID(
                                                                        c.id,
                                                                    );
                                                                }}
                                                                className="text-xs"
                                                            >
                                                                {c.name}
                                                                <Check
                                                                    className={cn(
                                                                        "ml-auto",
                                                                        c.id ===
                                                                            contactID
                                                                            ? "opacity-100"
                                                                            : "opacity-0",
                                                                    )}
                                                                />
                                                            </CommandItem>
                                                        ),
                                                    )}
                                                </CommandGroup>
                                            </CommandList>
                                        </Command>
                                    </PopoverContent>
                                </Popover>
                            ) : (
                                <div className="flex flex-row items-center gap-1">
                                    {userInfo?.avatar ? (
                                        <div className="lb-avatar rounded-lg w-6 h-6">
                                            {userInfo?.avatar && (
                                                <img
                                                    className="lb-avatar-image"
                                                    src={userInfo?.avatar}
                                                    alt={userInfo?.username}
                                                />
                                            )}
                                            <span>
                                                {contactsCombinedData.find(
                                                    (c) => c.id === contactID,
                                                )?.name ??
                                                    userInfo?.name ??
                                                    userInfo?.username ??
                                                    ""}
                                            </span>
                                        </div>
                                    ) : (
                                        <AvatarIcon className="w-6 h-6" />
                                    )}

                                    <div className="font-medium mx-1 flex flex-col">
                                        <div className="text-sm flex items-center">
                                            {account?.name ??
                                                userInfo?.name ??
                                                userInfo?.username}
                                            {account && (
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <Toggle.Root
                                                                aria-label="Toggle italic"
                                                                className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4"
                                                                onClick={() =>
                                                                    handleOpenInNewTab(
                                                                        account.id ??
                                                                            "",
                                                                        "Customer",
                                                                    )
                                                                }
                                                            >
                                                                <OpenInNewWindowIcon className="h-3 w-3" />
                                                            </Toggle.Root>
                                                        </TooltipTrigger>
                                                        <TooltipContent className="bg-[#5B5BD6]">
                                                            <p>
                                                                Open Customer
                                                                Page
                                                            </p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            )}
                                        </div>
                                        {account && (
                                            <div className="text-muted-foreground">
                                                {userInfo?.name ??
                                                    userInfo?.username}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className="flex flex-row items-start gap-2 justify-between">
                                {!contactEditing ? (
                                    <Button
                                        variant="ghost"
                                        size="icon"
                                        className="ml-auto h-6 w-6 hover:bg-iris4"
                                        aria-label="Edit customer"
                                        onClick={() => {
                                            setContactEditing(!contactEditing);
                                        }}
                                    >
                                        <Pencil2Icon className="h-3 w-3" />
                                    </Button>
                                ) : (
                                    <div className="ml-auto flex flex-row items-center gap-3">
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            className="h-6 w-6 hover:bg-iris4"
                                            aria-label="Edit customer"
                                            onClick={() => {
                                                setContactEditing(false);
                                            }}
                                        >
                                            <X className="h-3 w-3 text-red-500" />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            className="h-6 w-6 hover:bg-iris4"
                                            aria-label="Edit customer"
                                            onClick={() => {
                                                setContactEditing(
                                                    !contactEditing,
                                                );
                                                updateCustomerForTicket(
                                                    contactID,
                                                );
                                            }}
                                        >
                                            <CheckIcon className="h-3 w-3 text-green-500" />
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <DataList.Root size="1" orientation="horizontal">
                        {userInfo?.email && (
                            <DataList.Item>
                                <DataList.Label
                                    className="text-xs"
                                    minWidth="60px"
                                >
                                    Email
                                </DataList.Label>
                                <DataList.Value className="text-xs">
                                    <div className="flex items-center gap-1.5 min-w-0">
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div className="truncate">
                                                        {userInfo?.email}
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>{userInfo?.email}</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Toggle.Root
                                                        aria-label="Toggle italic"
                                                        className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4 flex-shrink-0"
                                                        onClick={copyEmail}
                                                    >
                                                        <CopyIcon className="h-3 w-3" />
                                                    </Toggle.Root>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>Copy Email</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                </DataList.Value>
                            </DataList.Item>
                        )}
                        {userInfo?.username && (
                            <DataList.Item align="center">
                                <DataList.Label
                                    className="text-xs"
                                    minWidth="60px"
                                >
                                    Username
                                </DataList.Label>
                                <DataList.Value>
                                    <div className="flex items-center gap-1.5 min-w-0">
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <div className="truncate">
                                                        {userInfo?.username}
                                                    </div>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>{userInfo?.username}</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Toggle.Root
                                                        aria-label="Toggle italic"
                                                        className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4 flex-shrink-0"
                                                        onClick={copyUsername}
                                                    >
                                                        <CopyIcon className="h-3 w-3" />
                                                    </Toggle.Root>
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>Copy Username</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                </DataList.Value>
                            </DataList.Item>
                        )}

                        <DataList.Item align="center">
                            <DataList.Label className="text-xs" minWidth="60px">
                                Company
                            </DataList.Label>
                            <DataList.Value className="flex flex-row items-center p-0.5 overflow-x-auto">
                                {editing ? (
                                    <CompanyDropdown
                                        companyID={companyID}
                                        setCompany={(
                                            c: CustomerCompany,
                                            index?: number,
                                        ) => {
                                            setCompanyID(c.id);
                                        }}
                                        onSave={updateCompanyForTicket}
                                        editing={editing}
                                        setEditing={setEditing}
                                        width={150}
                                    />
                                ) : (
                                    <div className="flex items-center gap-1.5">
                                        {company && (
                                            <>
                                                {company?.image_url !== "" &&
                                                company?.image_url !==
                                                    undefined ? (
                                                    <div className="lb-avatar rounded w-5 h-5">
                                                        <img
                                                            className="lb-avatar-image"
                                                            src={
                                                                company.image_url
                                                            }
                                                            alt={company.name}
                                                        />

                                                        <span>
                                                            {company.name ?? ""}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <ImageIcon className="w-5 h-5" />
                                                )}
                                                {company?.name}
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <Toggle.Root
                                                                aria-label="Toggle italic"
                                                                className="px-1 py-1 hover:bg-iris4 data-[state=on]:bg-[#9B9EF0] flex items-center justify-center rounded bg-white text-muted-foreground leading-4"
                                                                onClick={() =>
                                                                    handleOpenInNewTab(
                                                                        company.id ??
                                                                            "",
                                                                        "Company",
                                                                    )
                                                                }
                                                            >
                                                                <OpenInNewWindowIcon className="h-3 w-3" />
                                                            </Toggle.Root>
                                                        </TooltipTrigger>
                                                        <TooltipContent className="bg-[#5B5BD6]">
                                                            <p>
                                                                Open Company
                                                                Page
                                                            </p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </>
                                        )}
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            className="h-6 w-6 hover:bg-iris4"
                                            aria-label="Edit customer"
                                            onClick={() => {
                                                setEditing(!editing);
                                            }}
                                        >
                                            <Pencil2Icon className="h-3 w-3" />
                                        </Button>
                                    </div>
                                )}
                            </DataList.Value>
                        </DataList.Item>

                        <DataList.Item align="center">
                            <DataList.Label className="text-xs" minWidth="60px">
                                Source
                            </DataList.Label>
                            <DataList.Value>
                                <div className="flex items-center text-xs">
                                    {SourceSvgImage && (
                                        <SourceSvgImage className="w-4 h-4" />
                                    )}
                                    <span className="px-1">{displayText}</span>
                                </div>
                            </DataList.Value>
                        </DataList.Item>
                    </DataList.Root>
                    <DataList.Root
                        size="1"
                        orientation="vertical"
                        className="flex flex-col gap-3"
                    >
                        {showMoreProperties && (
                            <>
                                {account?.additional_properties &&
                                    Object.entries(
                                        account.additional_properties,
                                    ).map(([key, value]) => (
                                        <DataList.Item
                                            key={key}
                                            className={dataListItemStyle}
                                        >
                                            <DataList.Label
                                                className="text-xs"
                                                minWidth="60px"
                                            >
                                                {capitalizeWords(key)}
                                            </DataList.Label>
                                            <DataList.Value>
                                                <div className="flex items-center text-xs">
                                                    <span className="pl-2">
                                                        {String(value)}
                                                    </span>
                                                </div>
                                            </DataList.Value>
                                        </DataList.Item>
                                    ))}

                                {company?.additional_properties &&
                                    Object.entries(
                                        company.additional_properties,
                                    ).map(([key, value]) => (
                                        <DataList.Item
                                            key={key}
                                            className={dataListItemStyle}
                                        >
                                            <DataList.Label
                                                className="text-xs"
                                                minWidth="60px"
                                            >
                                                {capitalizeWords(key)}
                                            </DataList.Label>
                                            <DataList.Value>
                                                <div className="flex items-center text-xs">
                                                    <span className="pl-2">
                                                        {String(value)}
                                                    </span>
                                                </div>
                                            </DataList.Value>
                                        </DataList.Item>
                                    ))}

                                {account?.contract_value !== undefined &&
                                    account?.contract_value !== 0 && (
                                        <DataList.Item
                                            className={dataListItemStyle}
                                        >
                                            <DataList.Label
                                                className="text-xs"
                                                minWidth="60px"
                                            >
                                                Contract Value
                                            </DataList.Label>
                                            <DataList.Value>
                                                <div className="flex items-center text-xs">
                                                    <span className="pl-2">
                                                        {account.contract_value}
                                                    </span>
                                                </div>
                                            </DataList.Value>
                                        </DataList.Item>
                                    )}

                                {account?.contract_type !== undefined &&
                                    account?.contract_type !== "" && (
                                        <DataList.Item
                                            className={dataListItemStyle}
                                        >
                                            <DataList.Label
                                                className="text-xs"
                                                minWidth="60px"
                                            >
                                                Contract Type
                                            </DataList.Label>
                                            <DataList.Value>
                                                <div className="flex items-center text-xs">
                                                    <span className="pl-2">
                                                        {account.contract_type}
                                                    </span>
                                                </div>
                                            </DataList.Value>
                                        </DataList.Item>
                                    )}

                                {company?.contract_type !== undefined && (
                                    <DataList.Item
                                        className={dataListItemStyle}
                                    >
                                        <DataList.Label
                                            className="text-xs"
                                            minWidth="60px"
                                        >
                                            Company Contract Type
                                        </DataList.Label>
                                        <DataList.Value>
                                            <div className="flex items-center text-xs">
                                                <span className="pl-2">
                                                    {company.contract_type}
                                                </span>
                                            </div>
                                        </DataList.Value>
                                    </DataList.Item>
                                )}

                                {company?.contract_value !== undefined &&
                                    company?.contract_value !== 0 && (
                                        <DataList.Item
                                            className={dataListItemStyle}
                                        >
                                            <DataList.Label
                                                className="text-xs"
                                                minWidth="60px"
                                            >
                                                Company Contract Value
                                            </DataList.Label>
                                            <DataList.Value>
                                                <div className="flex items-center text-xs">
                                                    <span className="pl-2">
                                                        {company.contract_value}
                                                    </span>
                                                </div>
                                            </DataList.Value>
                                        </DataList.Item>
                                    )}

                                {company?.domain !== undefined && (
                                    <DataList.Item
                                        className={dataListItemStyle}
                                    >
                                        <DataList.Label
                                            className="text-xs"
                                            minWidth="60px"
                                        >
                                            Company Domain
                                        </DataList.Label>
                                        <DataList.Value>
                                            <div className="flex items-center text-xs">
                                                <span className="pl-2">
                                                    {company.domain}
                                                </span>
                                            </div>
                                        </DataList.Value>
                                    </DataList.Item>
                                )}
                            </>
                        )}
                    </DataList.Root>
                    {shouldShowMoreProperties(account, company) && (
                        <Button
                            variant="outline"
                            className="text-xs ring-[0.8px] text-gray-700 ring-[#E0E1E6] flex flex-row gap-2 items-center h-7"
                            onClick={() =>
                                setShowMoreProperties(!showMoreProperties)
                            }
                        >
                            <span>
                                {showMoreProperties ? "Hide" : ""} More
                                Properties
                            </span>
                            {showMoreProperties ? (
                                <ChevronUpIcon className="h-3 w-3" />
                            ) : (
                                <ChevronDownIcon className="h-3 w-3" />
                            )}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

export default memo(CustomerProfile);
