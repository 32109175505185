import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import type {
    Category,
    GetTopicsResponse,
    GetUserResponse,
    Insight,
    InsightInfoResponse,
    Teams,
} from "@/interfaces/serverData";
import { CaretDownIcon, CaretUpIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { memo } from "react";
import DisplayCardContent from "./DisplayCardContent";
import type {
    QueryObserverResult,
    RefetchOptions,
    UseQueryResult,
} from "@tanstack/react-query";

interface DisplayCardProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    categories: Category[];
    sidebarLabels: string;
    type: string;
    insight: Insight;
    updateInsightState: ((newState: Partial<Insight>) => void) | undefined;
    insightUserInfo: GetUserResponse | undefined;
    userID: string;
    topicsMap: Map<string, GetTopicsResponse>;
    alertNum?: number;
    insightSelectedIsSaved?: boolean;
    teamsQuery?: UseQueryResult<Teams[], Error>;
    orgID?: string;
    refetch?: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<InsightInfoResponse | null, Error>>;
}

function DisplayCard({
    isOpen,
    setIsOpen,
    sidebarLabels,
    categories,
    type,
    insight,
    updateInsightState,
    insightUserInfo,
    userID,
    topicsMap,
    alertNum,
    insightSelectedIsSaved,
    teamsQuery,
    orgID,
    refetch,
}: DisplayCardProps) {
    return (
        <Card className="rounded-lg hover:outline-0.5 hover:outline-offset-0 flex flex-col px-4 py-2 shadow-sm border relative bg-white">
            <div
                className={`flex items-center justify-between ${isOpen && "mb-3"}`}
            >
                <div className={`flex items-center gap-1.5 ${sidebarLabels}`}>
                    {type}
                    {alertNum !== undefined && alertNum > 0 && (
                        <Badge
                            variant="outline"
                            color="gray"
                            className="text-[9px] p-1 py-0"
                        >
                            {alertNum}
                        </Badge>
                    )}
                </div>
                {isOpen ? (
                    <Button
                        type="button"
                        variant="ghost"
                        className="text-xs p-0.5"
                        onClick={() => setIsOpen(false)}
                    >
                        <CaretUpIcon className="w-4 h-4" />
                    </Button>
                ) : (
                    <Button
                        type="button"
                        variant="ghost"
                        className="text-xs p-0.5"
                        onClick={() => setIsOpen(true)}
                    >
                        <CaretDownIcon className="w-4 h-4" />
                    </Button>
                )}
            </div>
            {isOpen && (
                <DisplayCardContent
                    type={type}
                    insight={insight}
                    updateInsightState={updateInsightState}
                    insightUserInfo={insightUserInfo}
                    userID={userID}
                    topicsMap={topicsMap}
                    categories={categories}
                    teamsQuery={teamsQuery}
                    orgID={orgID}
                    insightSelectedIsSaved={insightSelectedIsSaved}
                    refetch={refetch}
                />
            )}
        </Card>
    );
}

export default memo(DisplayCard);
