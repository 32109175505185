import { EditorActionType } from "@/interfaces/serverData";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
    CLEAR_EDITOR_COMMAND,
    COMMAND_PRIORITY_LOW,
    KEY_ENTER_COMMAND,
} from "lexical";
import { useEffect } from "react";

const EnterCommand = ({ onSubmit = (type: EditorActionType) => {} }) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        return editor.registerCommand(
            KEY_ENTER_COMMAND,
            (event) => {
                if (event === undefined || event === null) {
                    return false;
                }
                console.log(event);
                if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
                    event.preventDefault();

                    onSubmit(EditorActionType.Reply);

                    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
                    return true;
                }
                return false;
            },
            COMMAND_PRIORITY_LOW,
        );
    }, [editor, onSubmit]);

    return null;
};

export default EnterCommand;
