import { TeamsDropdown } from "@/Ticket/TeamsDropdown";
import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";
import { Textarea } from "@/component/shadcn/ui/textarea";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { GradientPicker } from "@/design/GradientPicker";
import { useApi } from "@/interfaces/api";
import { type Category, type Teams, teamSchema } from "@/interfaces/serverData";
import { toggleTeamSelection } from "@/utilities/methods";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    type QueryObserverResult,
    type RefetchOptions,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { workspace } from "./DataTable/constants";
import { TeamBadges } from "./TeamBadges";

interface CategoriesDialogProps {
    type: string;
    triggerElement: React.ReactNode;
    teams: Teams[];
    refetchCategories: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Category[], Error>>;
    editingObject?: Category;
    currentTeam?: Teams;
    noDialog?: boolean;
}

export const CategoriesDialog: React.FC<CategoriesDialogProps> = ({
    type,
    triggerElement,
    teams,
    refetchCategories,
    editingObject,
    currentTeam,
    noDialog,
}) => {
    const { id } = useParams(); // team ID
    const api = useApi();
    const { toast } = useToast();
    const defaultBadgeColor = "#9B9EF0";
    const queryClient = useQueryClient();

    const [categoriesIsOpen, setCategoriesIsOpen] = useState(false);

    // Category Support
    const categoriesSchema = z.object({
        categoryName: z
            .string()
            .min(2, {
                message: "Category name must be at least 2 characters.",
            })
            .max(70, {
                message: "Category name must be at most 70 characters.",
            }),
        categoryDescription: z.string().max(1000, {
            message: "Description must be at most 1000 characters.",
        }),
        color: z.string(),
        categoryTeams: z.array(teamSchema).optional(),
    });
    const categoriesForm = useForm<z.infer<typeof categoriesSchema>>({
        resolver: zodResolver(categoriesSchema),
        defaultValues: {
            categoryName: editingObject?.name ?? "",
            categoryDescription: editingObject?.description ?? "",
            color: editingObject?.color ?? defaultBadgeColor,
            categoryTeams:
                editingObject?.teams ??
                (id && currentTeam ? [currentTeam] : [workspace]) ??
                [],
        },
    });
    const {
        control: itControl,
        setValue: itSetValue,
        handleSubmit,
    } = categoriesForm;

    const itToggleTeam = (team: Teams, categoryTeams: Teams[]) => {
        itSetValue("categoryTeams", toggleTeamSelection(team, categoryTeams));
    };

    // Create category mutation
    const createCategoryMutation = useMutation({
        mutationFn: async (newCategory: {
            name: string;
            description: string;
            color: string;
            team_ids: string[];
        }) => {
            const response: AxiosResponse = await api.put(
                `${URLS.serverUrl}${API.addCategory}`,
                newCategory,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            if (response.status !== 200) {
                throw new Error(
                    `Request failed with status ${response.status}`,
                );
            }
            refetchCategories();
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["categories"] });
            setCategoriesIsOpen(false);
            categoriesForm.reset();
            toast({
                title: "Created Category!",
                description: "Your category has been created successfully.",
            });
        },
        onError: () => {
            setCategoriesIsOpen(false);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    // Update category mutation
    const updateCategoryMutation = useMutation({
        mutationFn: async (updatedCategory: Category) => {
            const response: AxiosResponse = await api.patch(
                `${URLS.serverUrl}${API.editCategory}/${updatedCategory.id}`,
                updatedCategory,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            if (response.status !== 200) {
                throw new Error(
                    `Request failed with status ${response.status}`,
                );
            }
            refetchCategories();
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["categories"] });
            setCategoriesIsOpen(false);
            categoriesForm.reset();
            toast({
                title: "Updated Category!",
                description: "Your category has been updated successfully.",
            });
        },
        onError: () => {
            setCategoriesIsOpen(false);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    const categoriesOnSubmit = (values: z.infer<typeof categoriesSchema>) => {
        if (editingObject) {
            updateCategoryMutation.mutate({
                ...editingObject,
                name: values.categoryName,
                description: values.categoryDescription,
                color: values.color,
                teams:
                    values.categoryTeams?.filter(
                        (team) => team.id !== "workspace",
                    ) ?? [],
            });
        } else {
            createCategoryMutation.mutate({
                name: values.categoryName,
                description: values.categoryDescription,
                color: values.color,
                team_ids: id
                    ? [id]
                    : (values.categoryTeams
                          ?.map((team) => team.id)
                          .filter((teamID) => teamID !== "workspace") ?? []),
            });
        }
    };

    // Delete category mutation
    const deleteCategoryMutation = useMutation({
        mutationFn: async (id: string) => {
            const response: AxiosResponse = await api.delete(
                `${URLS.serverUrl}${API.deleteCategory}/${id}`,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            if (response.status !== 200) {
                throw new Error(
                    `Request failed with status ${response.status}`,
                );
            }
            refetchCategories();
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["categories"] });
            setCategoriesIsOpen(false);
            toast({
                title: "Deleted Category!",
                description: "Your category has been deleted successfully.",
            });
        },
        onError: () => {
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    // Delete category handler
    const handleDelete = (categoryId: string) => {
        deleteCategoryMutation.mutate(categoryId);
        setCategoriesIsOpen(false);
    };

    // Remove from team handler
    const handleRemoveTeam = (category: Category) => {
        let newCategoryTeams: Teams[];
        if (category.teams.length === 0) {
            const validTeams = [
                ...teams.filter((team) => team.id !== "workspace"),
            ];
            newCategoryTeams = validTeams.filter((team) => team.id !== id);
        } else {
            newCategoryTeams = category.teams.filter((team) => team.id !== id);
        }
        category.teams = newCategoryTeams;
        updateCategoryMutation.mutate(category);
    };

    return (
        <div>
            {noDialog ? (
                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                <div
                    onClick={() => {
                        if (editingObject) {
                            handleRemoveTeam(editingObject);
                        }
                    }}
                >
                    {triggerElement}
                </div>
            ) : (
                <Dialog
                    open={categoriesIsOpen}
                    onOpenChange={setCategoriesIsOpen}
                >
                    <DialogTrigger asChild>{triggerElement}</DialogTrigger>
                    <DialogContent className="max-w-[850px] w-full">
                        <div className="mx-8 my-3">
                            {(() => {
                                switch (type) {
                                    case "Edit":
                                    case "Create":
                                        return (
                                            <>
                                                <DialogHeader className="justify-left text-left items-left pb-5">
                                                    <DialogTitle>
                                                        {editingObject
                                                            ? "Edit Category"
                                                            : "Add Category"}
                                                    </DialogTitle>
                                                    <DialogDescription>
                                                        {editingObject
                                                            ? "Edit an existing category."
                                                            : "Add a new category with a description."}
                                                    </DialogDescription>
                                                </DialogHeader>
                                                <Form {...categoriesForm}>
                                                    <form
                                                        onSubmit={handleSubmit(
                                                            categoriesOnSubmit,
                                                        )}
                                                        className="space-y-8"
                                                    >
                                                        <FormField
                                                            control={
                                                                categoriesForm.control
                                                            }
                                                            name="categoryName"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                                        <FormLabel htmlFor="categoryName">
                                                                            Name
                                                                        </FormLabel>
                                                                        <FormControl>
                                                                            <Input
                                                                                id="categoryName"
                                                                                className="col-span-3"
                                                                                {...field}
                                                                            />
                                                                        </FormControl>
                                                                        <FormMessage className="text-xs" />
                                                                    </div>
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={
                                                                categoriesForm.control
                                                            }
                                                            name="categoryDescription"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                                        <FormLabel htmlFor="categoryDescription">
                                                                            Description
                                                                        </FormLabel>
                                                                        <FormControl>
                                                                            <Textarea
                                                                                id="categoryDescription"
                                                                                className="col-span-3"
                                                                                placeholder="Please be concise but descriptive, this will passed to the AI to help categorize issues."
                                                                                {...field}
                                                                                rows={
                                                                                    2
                                                                                }
                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={
                                                                categoriesForm.control
                                                            }
                                                            name="color"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                                        <FormLabel>
                                                                            Color
                                                                        </FormLabel>
                                                                        <FormControl>
                                                                            <GradientPicker
                                                                                id="colorPicker"
                                                                                background={
                                                                                    field.value
                                                                                }
                                                                                setBackground={(
                                                                                    id,
                                                                                    rowState,
                                                                                ) =>
                                                                                    categoriesForm.setValue(
                                                                                        "color",
                                                                                        rowState.colorSelected ??
                                                                                            defaultBadgeColor,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={itControl}
                                                            name="categoryTeams"
                                                            render={({
                                                                field,
                                                            }) => {
                                                                return (
                                                                    <FormItem>
                                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                                            <FormLabel>
                                                                                Teams
                                                                            </FormLabel>
                                                                            {id ? (
                                                                                <div className="flex flex-col gap-2 col-span-3">
                                                                                    <Button
                                                                                        variant="ghost"
                                                                                        className="flex items-center gap-2 justify-start p-0 w-full data-[state=open]:bg-muted overflow-hidden"
                                                                                        type="button"
                                                                                        disabled={
                                                                                            true
                                                                                        }
                                                                                    >
                                                                                        <TeamBadges
                                                                                            teams={
                                                                                                editingObject?.teams ??
                                                                                                (id &&
                                                                                                currentTeam
                                                                                                    ? [
                                                                                                          currentTeam,
                                                                                                      ]
                                                                                                    : [
                                                                                                          workspace,
                                                                                                      ]) ??
                                                                                                []
                                                                                            }
                                                                                            defaultIsWorkspace={
                                                                                                true
                                                                                            }
                                                                                        />
                                                                                    </Button>
                                                                                </div>
                                                                            ) : (
                                                                                <FormControl>
                                                                                    <TeamsDropdown
                                                                                        selectedTeams={
                                                                                            field?.value ??
                                                                                            []
                                                                                        }
                                                                                        teams={
                                                                                            teams
                                                                                        }
                                                                                        toggleTeam={
                                                                                            itToggleTeam
                                                                                        }
                                                                                        defaultIsWorkspace={
                                                                                            true
                                                                                        }
                                                                                    />
                                                                                </FormControl>
                                                                            )}
                                                                        </div>
                                                                        <FormMessage />
                                                                    </FormItem>
                                                                );
                                                            }}
                                                        />
                                                        <DialogFooter className="justify-end text-end items-end">
                                                            <Button
                                                                className="bg-iris9"
                                                                type="submit"
                                                            >
                                                                {editingObject
                                                                    ? "Update"
                                                                    : "Save changes"}
                                                            </Button>
                                                        </DialogFooter>
                                                    </form>
                                                </Form>
                                            </>
                                        );
                                    case "Delete":
                                        return (
                                            <>
                                                <DialogHeader className="justify-left text-left items-left pb-5">
                                                    <DialogTitle>
                                                        Are you absolutely sure?
                                                    </DialogTitle>
                                                    <DialogDescription>
                                                        {`This action cannot be undone. This will permanently delete the Category "${editingObject?.name}" from the Workspace.`}
                                                    </DialogDescription>
                                                </DialogHeader>
                                                <DialogFooter className="justify-end text-end items-end gap-3">
                                                    <Button
                                                        className="bg-iris9 py-0.5"
                                                        type="submit"
                                                        onClick={() =>
                                                            setCategoriesIsOpen(
                                                                false,
                                                            )
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className="bg-red-500 py-0.5"
                                                        type="submit"
                                                        onClick={() => {
                                                            if (editingObject) {
                                                                handleDelete(
                                                                    editingObject.id,
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </DialogFooter>
                                            </>
                                        );
                                    default:
                                        return null; // You could return a default view or nothing
                                }
                            })()}
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
};
