import AttributesBadge from "@/component/AttributesBadge";
import { Card } from "@/component/shadcn/ui/card";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetTeamResponse,
    GetUserResponse,
    HistoryResponse,
    Query,
    Ticket,
} from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { AvatarIcon, TriangleDownIcon } from "@radix-ui/react-icons";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

interface AssigneeDropdownProps<TData> {
    ticket: Ticket;
    userID: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
}

export function AssigneeDropdown<TData extends Query>({
    ticket,
    userID,
    refetchThreadData,
}: AssigneeDropdownProps<TData>) {
    const api = useApi();
    const assignText = "Unassigned...";

    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const [user, setUser] = useState<string>(assignText);
    const [enabled, setEnabled] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<GetUserResponse>();
    const [pictureURL, setPictureURL] = useState<string>("");

    function saveAssignee(assignee: GetUserResponse | undefined) {
        const requestData = {
            ids: [ticket.id],
            source: "Web",
            user_id: userID,
            assignee_user_id: assignee?.id ?? "",
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                if (assignee) {
                    setUser(
                        `${assignee.first_name} ${assignee.last_name ?? ""}`,
                    );
                    setPictureURL(assignee.picture_url);
                    setUserInfo(assignee);
                } else {
                    // Unassigned / No assignee
                    setUser(assignText);
                    setPictureURL("");
                    setUserInfo(undefined);
                }
                refetchThreadData();
                setEnabled(true);
            } else {
                setEnabled(false);
                console.log("Call to update tag failed");
            }
        });
    }

    const [users, setUsers] = useState<GetUserResponse[]>([]);

    useEffect(() => {
        if (ticket.team_owner_id && ticket.team_owner_id !== "") {
            const [url] = TeamsAPI.getTeam;
            api.get(`${URLS.serverUrl}${url}/${ticket.team_owner_id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            }).then((res) => {
                if (res.status === 200) {
                    const teamInfo: GetTeamResponse = res.data.data;
                    setUsers(teamInfo.members);
                }
            });
        } else {
            api.post(URLS.serverUrl + API.getAllUsers, {
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((res) => {
                if (res.status === 200) {
                    setUsers(res.data.data);
                } else {
                    console.log("Call to update tag failed");
                }
            });
        }
    }, [api, ticket.team_owner_id]);

    useEffect(() => {
        if (ticket.assignee_user_id) {
            const userData = users.find(
                (user) => user.id === ticket.assignee_user_id,
            );
            if (
                userData &&
                userData.first_name !== "" &&
                userData.last_name !== ""
            ) {
                setUser(`${userData.first_name} ${userData.last_name}`);
                setUserInfo(userData);
                setEnabled(true);
            } else {
                setEnabled(false);
            }
        } else {
            setUser(assignText)
            setUserInfo(undefined)
            setPictureURL("");
            setEnabled(false)
        }
    }, [ticket.assignee_user_id, api, users]);

    const [myUser, setMyUser] = useState<GetUserResponse>({
        id: "",
        email: "",
        username: "",
        first_name: "",
        last_name: "",
        picture_url: "",
        user_role: "",
    });

    useEffect(() => {
        if (userID) {
            const userData = users.find((user) => user.id === userID);

            if (
                userData &&
                userData.first_name !== "" &&
                userData.last_name !== ""
            ) {
                setMyUser(userData);
            }
        }
    }, [userID, users]);

    const [open, setOpen] = useState<boolean>(false);

    return (
        <div className="text-xs flex items-center">
            <Popover>
                <PopoverTrigger asChild>
                    <div>
                        <AttributesBadge>
                            <div className="lb-root flex flex-row gap-1 rounded-lg lb-comment-header mb-0 lb-comment-details">
                                {enabled ? (
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <div
                                                    className={
                                                        "lb-avatar rounded-lg w-4 h-3"
                                                    }
                                                >
                                                    {pictureURL && (
                                                        <img
                                                            className="lb-avatar-image"
                                                            src={pictureURL}
                                                            alt={user[0]}
                                                        />
                                                    )}
                                                    <span>{user[0] ?? ""}</span>
                                                </div>
                                            </TooltipTrigger>
                                            <TooltipContent
                                                sideOffset={5}
                                                className="bg-white text-gray11 mb-2 mt-3 flex flex-col opacity-100 mr-3"
                                                asChild
                                            >
                                                <Card className="bg-white text-gray11 p-3 mb-2 flex gap-1 flex-col text-left align-start opacity-100">
                                                    <p className="font-bold ">
                                                        {userInfo?.email ?? ""}
                                                    </p>
                                                </Card>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                ) : (
                                    <div className={"lb-avatar rounded-lg w-4 h-3"}>
                                        {pictureURL && (
                                            <img
                                                className="lb-avatar-image w-4 h-3"
                                                src={pictureURL}
                                                alt={user[0]}
                                            />
                                        )}
                                        <span>{user[0] ?? ""}</span>
                                    </div>
                                )}
                                <div className="lb-comment-author text-xs font-normal">
                                    {user}
                                </div>
                                <TriangleDownIcon className="h-3 w-3" />
                            </div>
                        </AttributesBadge>
                    </div>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] p-0">
                    <Command>
                        <CommandInput
                            placeholder="Search assignee..."
                            className="h-8 text-xs"
                        />
                        <CommandList>
                            <CommandEmpty>No assignee found.</CommandEmpty>
                            <CommandGroup>
                                {users.find((user) => user.id === userID) && (
                                    <CommandItem
                                        className="text-xs"
                                        onSelect={(currentValue) => {
                                            saveAssignee(myUser);
                                            setOpen(false);
                                        }}
                                    >
                                        <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details">
                                            <AvatarIcon className="w-3 h-3" />

                                            <span className="lb-comment-author text-xs font-normal">
                                                Assign to Me
                                            </span>
                                        </div>
                                    </CommandItem>
                                )}
                                <CommandItem
                                    className="text-xs"
                                    onSelect={(currentValue) => {
                                        saveAssignee(undefined);
                                        setOpen(false);
                                    }}
                                >
                                    <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details">
                                        <AvatarIcon className="w-3 h-3" />

                                        <span className="lb-comment-author text-xs font-normal">
                                            No Assignee
                                        </span>
                                    </div>
                                </CommandItem>
                                {users.map((user) => (
                                    <CommandItem
                                        key={user.id}
                                        value={`${user.first_name} ${user.last_name}`}
                                        onSelect={(currentValue) => {
                                            saveAssignee(user);
                                            setOpen(false);
                                        }}
                                    >
                                        <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details">
                                            <div
                                                className={"lb-avatar rounded-lg w-4 h-3"}
                                            >
                                                {user.picture_url && (
                                                    <img
                                                        className="lb-avatar-image"
                                                        src={user.picture_url}
                                                        alt={`${user.first_name} ${user.last_name}`}
                                                    />
                                                )}
                                                <span>{`${user.first_name} ${user.last_name}`}</span>
                                            </div>
                                            <span className="lb-comment-author text-xs font-normal">
                                                {`${user.first_name} ${user.last_name}`}
                                            </span>
                                        </div>
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
        </div>
    );
}
