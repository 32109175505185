import { Card, CardContent } from "@/component/shadcn/ui/card";
import { loadingTypes } from "@/constant";
import { BusinessHoursType } from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import { Separator } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import BusinessHoursComponent from "./BusinessHoursComponent";
import SignatureComponent from "./SignatureComponent";

const Profile = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );

    const { loading, isLoggedIn, user } = useAuthInfo();

    const [email, setEmail] = useState<string>("");
    const [fullName, setFullName] = useState<string>("");
    const [username, setUserName] = useState<string>("");
    const [userID, setUserID] = useState<string>("");

    useEffect(() => {
        if (!loading) {
            if (isLoggedIn) {
                setLoadingState(loadingTypes.loaded);
                setEmail(user.email);
                setFullName(`${user.firstName} ${user.lastName}`);
                setUserName(`${user.username}`);
                setUserID(user.userId);
            } else {
                setLoadingState(loadingTypes.error);
            }
        }
    }, [loading, isLoggedIn]);

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <h2 className="text-2xl font-semibold">Profile</h2>
                <p className="pb-10 text-sm text-gray11">
                    Manage your Assembly profile
                </p>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && (
                    <div className="flex flex-col gap-6">
                        <Card className="shadow-none py-6 px-6">
                            <CardContent>
                                <div className="flex flex-col gap-6">
                                    {/* {/* <div className="space-y-4  flex-row">
                                        <h4 className="text-sm font-medium">
                                            Profile Picture
                                        </h4>
                                        <Input id="picture" type="file" />
                                    </div>

                                    <Separator size="4" /> */}
                                    <div className="flex flex-row justify-between">
                                        <h4 className="text-sm font-medium">
                                            Email
                                        </h4>
                                        <h4 className="text-sm">{email}</h4>
                                    </div>

                                    <Separator size="4" />

                                    <div className="flex flex-row justify-between">
                                        <h4 className="text-sm font-medium">
                                            Full Name
                                        </h4>
                                        <h4 className="text-sm">{fullName}</h4>
                                    </div>

                                    <Separator size="4" />
                                    <div className="flex flex-row justify-between">
                                        <h4 className="text-sm font-medium">
                                            Username
                                        </h4>
                                        <h4 className="text-sm">{username}</h4>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                        <SignatureComponent userID={userID} />
                        <BusinessHoursComponent
                            type={BusinessHoursType.User}
                            id={userID}
                        />
                    </div>
                )}
            </Box>
        </div>
    );
};

export default Profile;
