import { Button } from "@/component/shadcn/ui/button";
import { cn } from "@/lib/utils";

const DialogTriggerButton = ({
    children,
    disabled,
    onClick,
    asChild,
    className,
}: {
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void | Promise<void>;
    asChild?: boolean;
    className?: string;
}) => {
    return (
        <Button
            className={cn(
                "outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm",
                className,
            )}
            size="sm"
            variant="outline"
            disabled={disabled}
            onClick={onClick}
            asChild={asChild}
            type={"button"}
        >
            {children}
        </Button>
    );
};

export default DialogTriggerButton;
