import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import type { HistoryResponse, MyFile } from "@/interfaces/serverData.js";
import { findNonInlineAttachmentsForGmail } from "@/utilities/methods";
import { emojiMap, formatEmojis, formatUsernames } from "@/utilities/methods";
import {
    Comment as CommentPrimitive,
    Timestamp,
} from "@liveblocks/react-ui/primitives";
import { Badge } from "@radix-ui/themes";
import clsx from "clsx";
import parse, { domToReact } from "html-react-parser";
import { EyeIcon, Loader2 } from "lucide-react";
import { type ComponentProps, Suspense } from "react";
import React from "react";
import { useState } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkEmoji from "remark-emoji";
import remarkGfm from "remark-gfm";
import { toHTML } from "slack-markdown";
import { Avatar } from "./Avatar";
import { FilePopUpDisplay } from "./FilePopUpDisplay";
import { GmailCommentRenderer } from "./GmailComment";
import { MyUser } from "./User";
import { getMetadataGmailHeaders } from "./utils";
/**
 * Custom comment component.
 */

interface CommentProps extends ComponentProps<"div"> {
    comment: HistoryResponse;
    source: string;
    filesMap: Map<string, MyFile[]>;
    isInternal: boolean;
    selectedFile?: MyFile;
    setSelectedFile: React.Dispatch<React.SetStateAction<MyFile | undefined>>;
    collapsed: "open" | "closed";
}

export function Comment({
    comment,
    className,
    source,
    filesMap,
    isInternal,
    selectedFile,
    setSelectedFile,
    collapsed = "open", // in open state by default
    ...props
}: CommentProps) {
    const [isCollapsed, setIsCollapsed] = useState(collapsed === "closed");

    if (!comment.content && !filesMap.get(comment.id)) {
        return null;
    }
    const commentDate = (comment: HistoryResponse): string | Date => {
        if (comment.timestamp) {
            try {
                const date = new Date(comment.timestamp);
                if (Number.isNaN(date.getTime())) {
                    throw new Error("Invalid date");
                }
                return date;
            } catch (err) {
                console.log(
                    `Could not convert comment's timestamp ${comment.timestamp} to a valid date, so using the original timestamp format. Error: ${err}`,
                );
                return new Date();
            }
        }
        return new Date();
    };

    const options = {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        replace: (domNode: any) => {
            if (domNode.attribs && domNode.name === "a") {
                return (
                    <CommentPrimitive.Link
                        href={domNode.attribs.href}
                        className="lb-comment-link text-[#5B5BD6] hover:text-gray-500"
                    >
                        {domToReact(domNode.children)}
                    </CommentPrimitive.Link>
                );
            }

            if (domNode.name === "code") {
                return (
                    <code className="text-balance">
                        {domToReact(domNode.children)}
                    </code>
                );
            }

            if (
                domNode.name === "span" &&
                domNode.attribs &&
                domNode.attribs.class === "s-mention s-user"
            ) {
                return (
                    <span className="text-iris10 hover:underline cursor-pointer">
                        {domToReact(domNode.children)}
                    </span>
                );
            }
        },
    };

    const timestampCutoff = "2024-09-30T23:50:00.000000Z";
    const parsedTimestampCutoff = new Date(timestampCutoff);
    const commentParsedTimestamp = new Date(comment.timestamp);

    const getMetadataNewTicket = (metadata: string) => {
        if (metadata === "" || metadata === null) {
            return null;
        }
        const metadataMap = JSON.parse(metadata);
        if (metadataMap.new_ticket_url && metadataMap.new_ticket_url !== "") {
            return (
                <>
                    <Markdown
                        className="text-sm font-normal lb-comment-content min-h-min text-balance"
                        remarkPlugins={[remarkGfm, remarkEmoji]}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                            a: (props) => (
                                <CommentPrimitive.Link
                                    href={props.href}
                                    className="lb-comment-link text-[#5B5BD6] hover:text-gray-500"
                                >
                                    {props.children}
                                </CommentPrimitive.Link>
                            ),
                        }}
                    >
                        {`Ticket created here: [${metadataMap.new_ticket_id}](${metadataMap.new_ticket_url})`}
                    </Markdown>
                    <br />
                </>
            );
        } else if (metadataMap.new_source_specific_id) {
            return (
                <>
                    <div className="text-sm font-normal lb-comment-content min-h-min text-balance flex flex-row gap-2 items-center">
                        <p>Ticket created here</p>
                        <Loader2 className="inline h-3 w-3 animate-spin" />
                    </div>
                    <br />
                </>
            );
        }
        return null;
    };

    const CommentHeader = () => {
        const timestamp = commentDate(comment);

        return (
            <div
                className="mb-0 flex flex-col items-start cursor-pointer"
                onClick={(e) => {
                    e.stopPropagation();
                    setIsCollapsed(!isCollapsed);
                }}
                onKeyDown={(e) => { }}
            >
                <div className="lb-comment-details items-center justify-center">
                    <Suspense
                        fallback={
                            <div className="relative aspect-square w-8 flex-none animate-pulse rounded-full bg-gray-100" />
                        }
                    >
                        <Avatar user={comment.user_data} />
                    </Suspense>
                    <div className="lb-comment-details-labels items-center">
                        <div className="flex flex-col gap-1">
                            <MyUser
                                user={comment.user_data}
                                className="lb-comment-author text-sm font-medium"
                                gmailHeadersMetadata={comment.metadata}
                                source={comment.source}
                            />
                        </div>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Timestamp
                                    date={timestamp}
                                    className="lb-comment-date text-xs font-normal"
                                    locale="en-US"
                                    title={(date) =>
                                        date.toLocaleString("en-US", {
                                            weekday: "long",
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                            hour: "numeric",
                                            minute: "numeric",
                                            second: "numeric",
                                            hour12: true,
                                            timeZoneName: "short",
                                        })
                                    }
                                >
                                    {(date) => {
                                        const now = new Date();
                                        const diffInDays = Math.floor(
                                            (now - date) /
                                            (1000 * 60 * 60 * 24),
                                        );

                                        if (diffInDays < 1) {
                                            return date.toLocaleString(
                                                "en-US",
                                                {
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    hour12: true,
                                                },
                                            );
                                        } else {
                                            return date
                                                .toLocaleString("en-US", {
                                                    month: "short",
                                                    day: "numeric",
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                    hour12: true,
                                                })
                                                .replace(",", " at");
                                        }
                                    }}
                                </Timestamp>
                            </TooltipTrigger>
                            <TooltipContent className="bg-[#5B5BD6] py-2.5 px-4 flex flex-col">
                                <p className="text-xs">
                                    {timestamp.toLocaleString()}
                                </p>
                            </TooltipContent>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Card
            className={`lb-comment ${comment.source === "Gmail" && getMetadataGmailHeaders(comment.metadata, "subject") ? "pt-3" : ""} shadow-sm outline-none rounded-lg ${clsx(className)}`}
        >
            {isCollapsed ? (
                <div {...props}>
                    <CommentHeader />
                </div>
            ) : (
                <>
                    <div {...props}>
                        <CommentHeader />
                        <div className="lb-comment:indent-content pl-1 min-h-min text-balance mt-2">
                            {getMetadataNewTicket(comment.metadata) && (
                                <div>
                                    {getMetadataNewTicket(comment.metadata)}
                                </div>
                            )}
                            {(comment.source === "CommunitySlack" ||
                                comment.source === "Slack") &&
                                commentParsedTimestamp < parsedTimestampCutoff ? (
                                <div className="text-sm font-normal lb-comment-content min-h-min text-balance">
                                    {parse(
                                        formatUsernames(
                                            toHTML(comment.content),
                                        ),
                                        options,
                                    )}
                                </div>
                            ) : comment.source === "Gmail" ? (
                                <div className="text-sm font-normal lb-comment-content min-h-min text-balance">
                                    <GmailCommentRenderer
                                        comment={comment}
                                        filesMap={filesMap}
                                    />
                                </div>
                            ) : (
                                <Markdown
                                    className="text-sm font-normal lb-comment-content min-h-min text-balance"
                                    remarkPlugins={[remarkGfm, remarkEmoji]}
                                    rehypePlugins={[rehypeRaw]}
                                    components={{
                                        a(props) {
                                            const { href, children } = props;
                                            const content =
                                                React.Children.toArray(
                                                    children,
                                                ).map((child) => {
                                                    if (
                                                        typeof child ===
                                                        "string"
                                                    ) {
                                                        return child
                                                            ?.replace(
                                                                /%E2%80%88/g,
                                                                "",
                                                            )
                                                            .replace(
                                                                /\u200B/g,
                                                                "",
                                                            )
                                                            .replace(
                                                                /%E2%80%8B/g,
                                                                "",
                                                            )
                                                            .replace(
                                                                /%E2%80%8C/g,
                                                                "",
                                                            )
                                                            .replace(
                                                                /%E2%80%8D/g,
                                                                "",
                                                            )
                                                            .replace(
                                                                /%E2%80%8E/g,
                                                                "",
                                                            )
                                                            .replace(
                                                                /%E2%80%8F/g,
                                                                "",
                                                            );
                                                    }
                                                    return child;
                                                });

                                            return (
                                                <CommentPrimitive.Link
                                                    href={href}
                                                    className="lb-comment-link text-[#5B5BD6] hover:text-gray-500"
                                                >
                                                    {content}
                                                </CommentPrimitive.Link>
                                            );
                                        },
                                        code(props) {
                                            const { children } = props;
                                            return (
                                                <code className="text-balance">
                                                    {children}
                                                </code>
                                            );
                                        },
                                        span(props) {
                                            const { children } = props;
                                            return (
                                                <span className="text-iris10 hover:underline cursor-pointer">
                                                    {children}
                                                </span>
                                            );
                                        },
                                    }}
                                >
                                    {formatEmojis(
                                        formatUsernames(comment.content),
                                    )}
                                </Markdown>
                            )}
                        </div>
                        <div className="ml-9 mt-2 flex items-center flex-wrap">
                            {findNonInlineAttachmentsForGmail(
                                comment.content,
                                filesMap.get(comment.id) ?? [],
                            ).map((file) => (
                                <FilePopUpDisplay key={file.id} file={file} />
                            ))}
                            {selectedFile && (
                                <FilePopUpDisplay
                                    file={selectedFile}
                                    onClose={() => setSelectedFile(undefined)}
                                    setPreviewOpen={true}
                                    isInlineImage={true}
                                />
                            )}
                        </div>
                        <div className="ml-9 mt-0.5 flex items-center gap-1.5">
                            {comment.reactions?.map((reaction) => (
                                <TooltipProvider key={reaction.name}>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="ghost"
                                                key={reaction.name}
                                                className="m-0 py-1 px-0"
                                            >
                                                <Badge
                                                    variant="outline"
                                                    color="gray"
                                                    size="1"
                                                    radius="full"
                                                    className="text-[11px] px-2.5 py-1 flex items-center gap-1.5 rounded-full"
                                                >
                                                    <Markdown
                                                        remarkPlugins={[
                                                            remarkGfm,
                                                            remarkEmoji,
                                                        ]}
                                                        rehypePlugins={[
                                                            rehypeRaw,
                                                        ]}
                                                    >
                                                        {reaction.emoji ||
                                                            (emojiMap.has(
                                                                reaction.name ??
                                                                "",
                                                            )
                                                                ? emojiMap.get(
                                                                    reaction.name ??
                                                                    "",
                                                                )
                                                                : `:${reaction.name}:`)}
                                                    </Markdown>
                                                    {reaction.count}
                                                </Badge>
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent className="bg-[#5B5BD6] max-w-30 break-words whitespace-normal overflow-visible">
                                            {reaction.users.length === 0
                                                ? "No one reacted"
                                                : reaction.users.length === 1
                                                    ? `${reaction.users[0]} reacted`
                                                    : reaction.users.length === 2
                                                        ? `${reaction.users.join(" and ")} reacted`
                                                        : `${reaction.users.slice(0, -1).join(", ")}, and ${reaction.users[reaction.users.length - 1]} reacted`}
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            ))}
                        </div>
                    </div>
                    {isInternal && (
                        <div className="mt-0.5 flex items-center text-xs text-gray-500">
                            <EyeIcon className="w-3 mx-1" />
                            Only visible to my team
                        </div>
                    )}
                </>
            )}
        </Card>
    );
}
