import { CardContent } from "@/component/shadcn/ui/card";
import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import { CheckCircledIcon } from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import { Box, Card, Flex, HoverCard, Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";
import { useSearch } from "../SearchContext";
import SlackAlerts, { SlackAlertsType } from "./SlackAlerts";
import Timer from "./Timer";

export default function Settings() {
    // const { id } = useParams(); // TODO: maybe configure settings for team ID
    const id = "";
    const { searchBarFilters, loading } = useSearch(); // using this for checking if slack integration is active
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [toastText, setToastText] = useState<string>("Updated Settings!");
    const [open, setOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const [slackModalOpen, setSlackModalOpen] = useState<boolean>(false);

    const [isSlackIntegrationEnabled, setIsSlackIntegrationEnabled] =
        useState<boolean>(false);

    const api = useApi();

    useEffect(() => {
        if (loading) {
            if (searchBarFilters.get("Slack")) {
                setIsSlackIntegrationEnabled(true);
            } else {
                setIsSlackIntegrationEnabled(false);
            }
        }
    }, [searchBarFilters, loading]);

    // Fetch the Slack notification settings using useQuery
    const {
        data: slackData,
        isError: slackError,
        isLoading: slackLoading,
        refetch: refetchSlackNotifs,
    } = useQuery({
        // TODO: handle omnichannels for team ID?
        queryKey: id ? [`fetchTeamSlackNotifs_${id}`] : ["fetchSlackNotifs"],
        queryFn: async () => {
            let endpoint = `${URLS.serverUrl}${API.getOmnichannels}/Slack`;
            if (id) {
                endpoint = `${URLS.serverUrl}${API.getOmnichannels}/Slack/team/${id}`;
            }
            const response = await api.get(endpoint, {
                headers: { Accept: "application/json" },
            });
            return response.data.data;
        },
    });

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <div className="flex flex-row justify-between">
                    <h2 className="text-2xl font-semibold">Settings</h2>
                </div>
                <p className="text-sm text-gray11 pb-3">
                    Manage general settings
                </p>
                <div className="flex flex-col gap-8">
                    <Timer />
                </div>
            </Box>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                {
                    <Flex direction="column" gap="4">
                        {slackLoading ? (
                            <Skeleton>
                                <Text>
                                    {[...Array(2)].map((_, index) => (
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        <Text key={index}>{loremIpsum}</Text>
                                    ))}
                                </Text>
                            </Skeleton>
                        ) : isSlackIntegrationEnabled ? (
                            <SlackAlerts
                                enabled={slackData?.enabled ?? false}
                                channel={
                                    slackData?.channel ?? { channel: undefined }
                                }
                                loadingState={
                                    slackError
                                        ? loadingTypes.error
                                        : slackLoading
                                          ? loadingTypes.loading
                                          : loadingTypes.loaded
                                }
                                refetch={refetchSlackNotifs}
                                isOpen={slackModalOpen}
                                onOpenChange={setSlackModalOpen}
                                inheritedFromOrg={false}
                                teamID={id}
                                type={SlackAlertsType.OMNICHANNEL}
                            />
                        ) : (
                            <HoverCard.Root>
                                <HoverCard.Trigger>
                                    <Card className="shadow-none px-1">
                                        <CardContent className="py-3 bg-gray-200">
                                            <div className="flex flex-col gap-6">
                                                <div className="flex flex-row items-center justify-between">
                                                    <div className="flex flex-row items-center gap-4">
                                                        <SlackSvg className="max-h-5 max-w-5 grayscale" />
                                                        <h2 className="text-md font-medium text-gray-500">
                                                            Slack Omnichannel
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </HoverCard.Trigger>
                                <HoverCard.Content>
                                    <Text>Connect Slack to enable</Text>
                                </HoverCard.Content>
                            </HoverCard.Root>
                        )}
                    </Flex>
                }
                <Toast.Provider swipeDirection="right">
                    <Toast.Root
                        className="ToastRoot"
                        open={open}
                        onOpenChange={setOpen}
                    >
                        <Toast.Title className="ToastTitle">
                            <Flex direction={"row"} align={"center"} gap="2">
                                <ToastSymbol color="green" />
                                {toastText}
                            </Flex>
                        </Toast.Title>
                    </Toast.Root>
                    <Toast.Viewport className="ToastViewport" />
                </Toast.Provider>
            </Box>
        </div>
    );
}
