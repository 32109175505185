import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    GetUserResponse,
    IconEntry,
    Query,
} from "@/interfaces/serverData";
import { getColor, getExternalIssueIcon } from "@/utilities/methods";
import {
    AvatarIcon,
    ComponentBooleanIcon,
    DotFilledIcon,
} from "@radix-ui/react-icons";
import { Badge, Box, Card, Flex, Grid, Text } from "@radix-ui/themes";
import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import { useQuery } from "@tanstack/react-query";
import React, { memo } from "react";
import { useMemo, useState } from "react";
import { integrationMappingToSvg } from "./Integrations/constant";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";

interface QuestionCardProps {
    issue: Query;
    users: GetUserResponse[];
}

export type BadgeColor = (typeof badgePropDefs.color.values)[number];

const QuestionCard = (props: QuestionCardProps) => {
    const convertTimestampToDate = (timestamp: string): string => {
        return timestamp.split("T")[0]; // Format as 'YYYY-MM-DD'
    };

    const SvgIcon: React.ElementType =
        integrationMappingToSvg.get(props.issue.source ?? "Unknown") ??
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        integrationMappingToSvg.get("default")!;

    const foundUser: GetUserResponse | undefined = useMemo(
        () =>
            props.users.find(
                (user) => user.id === props.issue.assignee_user_id,
            ),
        [props.issue.assignee_user_id, props.users],
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;

    const externalIssuesIcons = new Set<IconEntry>();
    // biome-ignore lint/complexity/noForEach: <explanation>
    props.issue.external_issues?.forEach((url) => {
        externalIssuesIcons.add({
            Component: getExternalIssueIcon(url),
            props: {
                width: 18,
                height: 18,
                style: { marginLeft: "-1", marginRight: "-1" },
            },
        });
    });

    return (
        <div>
            <Dialog>
                <DialogTrigger>
                    <Box
                        width={{
                            sm: "180px",
                            md: "215px",
                            lg: "240px",
                            xl: "300px",
                        }}
                    >
                        <Card
                            size="1"
                            asChild
                            style={{
                                minHeight: "65px",
                                transition: "transform 0.3s ease-in-out",
                                transform: "scale(1)",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                width={{
                                    sm: "180px",
                                    md: "215px",
                                    lg: "240px",
                                    xl: "300px",
                                }}
                            >
                                <Text
                                    size="2"
                                    style={{
                                        display: "-webkit-box",
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 5,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        padding: "5px",
                                    }}
                                >
                                    {props.issue.title !== ""
                                        ? props.issue.title
                                        : props.issue.query}
                                </Text>

                                <Flex
                                    gap="2"
                                    direction="column"
                                    style={{ paddingTop: "5px" }}
                                    align="start"
                                >
                                    {props.issue.topic.map(
                                        (category, index) =>
                                            category !== null &&
                                            category !== "" && (
                                                <Badge
                                                    color="gray"
                                                    size="2"
                                                    radius="full"
                                                    variant="outline"
                                                    key={category}
                                                >
                                                    <div className="flex flex-row items-center">
                                                        <DotFilledIcon
                                                            color="#9B9EF0"
                                                            style={{
                                                                transform:
                                                                    "scale(1.8)",
                                                            }}
                                                        />

                                                        <p className="pl-0.3">
                                                            {category}
                                                        </p>
                                                    </div>
                                                </Badge>
                                            ),
                                    )}
                                </Flex>
                                <Flex
                                    gap="3"
                                    align="center"
                                    direction={"row"}
                                    justify={"between"}
                                    style={{ paddingTop: "10px" }}
                                >
                                    <div className="flex items-center gap-1">
                                        <SvgIcon
                                            style={{
                                                width: "15px",
                                                height: "15px",
                                                marginTop: "2px",
                                            }}
                                        />
                                        {Array.from(externalIssuesIcons).map(
                                            (icon) =>
                                                React.createElement(
                                                    icon.Component,
                                                    icon.props,
                                                ),
                                        )}
                                        {props.issue.assignee_user_id &&
                                        props.issue.assignee_user_id !==
                                            "noAssignee" ? (
                                            <div className="lb-avatar rounded-lg w-4 h-4">
                                                {pictureURL && (
                                                    <img
                                                        className="lb-avatar-image"
                                                        src={pictureURL}
                                                        alt={userName}
                                                    />
                                                )}
                                                <span>{userName ?? ""}</span>
                                            </div>
                                        ) : (
                                            <AvatarIcon className="w-4 h-4" />
                                        )}
                                    </div>

                                    <Text size="1" color="gray">
                                        {convertTimestampToDate(
                                            props.issue.created_at,
                                        )}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Card>
                    </Box>
                </DialogTrigger>

                <DialogContent>
                    <DialogDescription className="text-[14px] font-normal text-[#333333] mb-4">
                        <Flex direction={"column"} gap="2" align={"start"}>
                            <Badge
                                radius="full"
                                color={getColor(props.issue.bot_category)}
                            >
                                {props.issue.bot_category}
                            </Badge>
                            <Text style={{ paddingLeft: "3px" }}>
                                {props.issue.title !== ""
                                    ? props.issue.title
                                    : props.issue.query}
                            </Text>
                            <Flex gap="2" style={{ paddingTop: "5px" }}>
                                {props.issue.topic.map(
                                    (category, index) =>
                                        category !== null &&
                                        category !== "" && (
                                            <Badge
                                                color="gray"
                                                size="2"
                                                radius="full"
                                                variant="outline"
                                                className="m-1"
                                                key={category}
                                            >
                                                <div className="flex flex-row items-center">
                                                    <DotFilledIcon
                                                        color="#9B9EF0"
                                                        style={{
                                                            transform:
                                                                "scale(1.8)",
                                                        }}
                                                    />

                                                    <p className="pl-0.3">
                                                        {category}
                                                    </p>
                                                </div>
                                            </Badge>
                                        ),
                                )}
                            </Flex>
                        </Flex>
                        <Flex
                            gap="3"
                            align="center"
                            direction={"row"}
                            justify={"between"}
                            style={{ paddingTop: "15px" }}
                        >
                            <div className="flex items-center gap-1">
                                <SvgIcon
                                    style={{
                                        width: "15px",
                                        height: "15px",
                                        marginTop: "2px",
                                    }}
                                />
                                {Array.from(externalIssuesIcons).map((icon) =>
                                    React.createElement(
                                        icon.Component,
                                        icon.props,
                                    ),
                                )}
                                {props.issue.assignee_user_id &&
                                props.issue.assignee_user_id !==
                                    "noAssignee" ? (
                                    <div className="lb-avatar rounded-lg w-4 h-4">
                                        {pictureURL && (
                                            <img
                                                className="lb-avatar-image"
                                                src={pictureURL}
                                                alt={userName}
                                            />
                                        )}
                                        <span>{userName ?? ""}</span>
                                    </div>
                                ) : (
                                    <AvatarIcon className="w-4 h-4" />
                                )}
                            </div>
                            <Text size="1" color="gray">
                                {convertTimestampToDate(props.issue.created_at)}
                            </Text>
                        </Flex>
                    </DialogDescription>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export interface metadata {
    scopes: string[];
    indexedOn: string;
    slack_team_id?: string;
}

interface AdminProductFeedbackPageProps {
    issues: Query[];
    users: GetUserResponse[];
}

const AdminProductFeedbackPage: React.FC<AdminProductFeedbackPageProps> = ({
    issues,
    users,
}) => {
    const [questions] = useState(
        new Map<string, Query[]>([
            ["Bug", issues.filter((issue) => issue.bot_category === "Bug")],
            [
                "Feature",
                issues.filter((issue) => issue.bot_category === "Feature"),
            ],
            ["Query", issues.filter((issue) => issue.bot_category === "Query")],
            [
                "Unknown",
                issues.filter((issue) => issue.bot_category === "Unknown"),
            ],
        ]),
    );

    const api = useApi();
    const categoriesDataQuery = useQuery<Category[]>({
        queryKey: ["categories"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getCategories}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });

    return (
        <div>
            <Flex direction="column" align="center" justify="center">
                <Box
                    mt="4"
                    height="100%" // Ensure it takes full height of its container
                    width="98%"
                >
                    <Grid
                        gap={{ sm: "3", md: "5", lg: "7", xl: "7" }}
                        style={{ width: "100%" }}
                        columns="repeat(4, 1fr)" // Create 4 equal columns
                    >
                        {Array.from(questions.entries()).map(
                            ([category, questionList]) => (
                                <Flex
                                    key={category}
                                    direction="column"
                                    align="start"
                                    gap="2"
                                    style={{
                                        maxHeight: "90vh",
                                        overflowY: "auto",
                                        width: "100%",
                                    }}
                                >
                                    <Badge
                                        color={"gray"}
                                        size="2"
                                        radius="full"
                                        variant="outline"
                                        className="m-1 hover:opacity-70 transition-opacity duration-300"
                                    >
                                        <div className="flex flex-row items-center">
                                            <ComponentBooleanIcon
                                                color={
                                                    categoriesDataQuery.data?.find(
                                                        (ca) =>
                                                            ca.name ===
                                                            category,
                                                    )?.color ?? "gray"
                                                }
                                            />
                                            <p className="pl-0.5">{category}</p>
                                        </div>
                                    </Badge>
                                    {questionList.map((question, index) => (
                                        <QuestionCard
                                            issue={question}
                                            users={users}
                                            key={question.id}
                                        />
                                    ))}
                                </Flex>
                            ),
                        )}
                    </Grid>
                </Box>
            </Flex>
        </div>
    );
};

export default memo(AdminProductFeedbackPage);
