import { ScrollArea } from "@/component/shadcn/ui/scroll-area";
import type { Account, GetUserResponse, Teams } from "@/interfaces/serverData";
import type { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import type { AccountsListType } from "../Accounts";
import { CustomersListCard } from "./CustomersListCard";

interface CustomersTableProps {
    customers: Account[];
    userID: string;
    users: GetUserResponse[];
    forceUpdate: (company: boolean, contacts: boolean, tickets: boolean) => Promise<void>;
    company: Account | undefined;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    teamsQuery: UseQueryResult<Teams[], Error>;
    orgID: string;
    listType: AccountsListType;
}

export function CustomersTable({
    customers,
    userID,
    users,
    forceUpdate,
    company,
    usersQuery,
    teamsQuery,
    orgID,
    listType,
}: CustomersTableProps) {
    const [filteredCustomers, setFilteredCustomers] =
        useState<Account[]>(customers);

    useEffect(() => {
        setFilteredCustomers(customers);
    }, [customers]);

    return (
        <div className="m-2 outline-none rounded-sm h-full w-full flex flex-col">
            {filteredCustomers?.length === 0 ? (
                <div className="flex w-full h-[50%] justify-center items-center">
                    <p className="text-md text-gray12">
                        No contacts, create or link a contact.
                    </p>
                </div>
            ) : (
                <ScrollArea className="max-h-[200px] overflow-auto w-full outline-none shadow-none">
                    {filteredCustomers?.map((customer) => (
                        <CustomersListCard
                            key={customer.id}
                            customer={customer}
                            userID={userID}
                            users={users}
                            forceUpdate={forceUpdate}
                            company={company}
                            usersQuery={usersQuery}
                            teamsQuery={teamsQuery}
                            orgID={orgID}
                            listType={listType}
                        />
                    ))}
                </ScrollArea>
            )}
        </div>
    );
}
