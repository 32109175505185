import { Badge as ScnBadge } from "@/component/shadcn/ui/badge";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Separator } from "@/component/shadcn/ui/separator";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    CustomerGroup,
    GetUserResponse,
    IconEntry,
    ScopeResponse,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    getOptions,
    getPlural,
    getStatusIcon,
    getTypeName,
} from "@/utilities/methods";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import {
    AvatarIcon,
    ComponentBooleanIcon,
    Cross2Icon,
    DotFilledIcon,
    GroupIcon,
    QuestionMarkIcon,
} from "@radix-ui/react-icons";
import { Badge as RuiBadge } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { HouseIcon, UsersIcon } from "lucide-react";
import React, { useCallback, useMemo, useState } from "react";
import FilterDropdownElement from "./FilterDropdownElement";
import { type FilterOption, menuThenMenuSources, sources } from "./constants";

interface SingleValueFilterDisplayProps {
    type: string;
    valList: string[];
    color: string;
    options?: { label: string; value: string; color?: string }[];
    users: GetUserResponse[];
}

const SingleValueFilterDisplay: React.FC<SingleValueFilterDisplayProps> =
    React.memo(({ type, valList, options, users, color }) => {
        const label = options?.find((item) => item.value === valList[0])?.label;
        switch (type.toLowerCase()) {
            case "source": {
                const [source, channelName] = valList[0].split(" -- ");
                const formattedLabel = channelName ? channelName.trim() : label;
                const formattedSource = source ? source.trim() : "";
                const Icon =
                    integrationBackEndDataMappingToSvg.get(formattedSource) ??
                    QuestionMarkIcon;
                return (
                    <div className="flex items-center gap-2">
                        <div className="flex items-center gap-1">
                            <Icon className="w-4 h-4" />
                            {formattedLabel}
                        </div>
                    </div>
                );
            }
            case "tag": {
                return (
                    <RuiBadge
                        color={"gray"}
                        size="2"
                        radius="full"
                        variant="outline"
                        className="m-0.5"
                    >
                        <div className="flex flex-row items-center">
                            <ComponentBooleanIcon color={color} />
                            <p className="pl-0.5">{label}</p>
                        </div>
                    </RuiBadge>
                );
            }
            case "topic": {
                const color = options?.find(
                    (option) => option.label === valList[0],
                )?.color;
                return (
                    <RuiBadge
                        color="gray"
                        size="2"
                        radius="full"
                        variant="outline"
                        className="m-0.5"
                        key={valList[0]}
                    >
                        <div className="flex flex-row items-center">
                            <DotFilledIcon
                                color={
                                    color && color !== "" ? color : "#9B9EF0"
                                }
                                style={{ transform: "scale(1.8)" }}
                            />
                            <p className="pl-0.3">{label}</p>
                        </div>
                    </RuiBadge>
                );
            }
            case "status":
            case "insight status": {
                const Icon = getStatusIcon(valList[0] ?? "Unknown");
                return (
                    <div className="flex items-center gap-1">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                );
            }
            case "assignee": {
                const pictureURL = users.find(
                    (user) => user.id === valList[0],
                )?.picture_url;
                return (
                    <div className="flex items-center gap-2">
                        <div className="lb-avatar rounded-lg w-5 h-5">
                            {pictureURL ? (
                                <img
                                    className="lb-avatar-image"
                                    src={pictureURL}
                                    alt={label}
                                />
                            ) : (
                                <AvatarIcon className="w-6 h-6" />
                            )}
                        </div>
                        <span className="lb-comment-author text-xs font-normal font-destructive">
                            {label}
                        </span>
                    </div>
                );
            }
            case "customer group": {
                return (
                    <div className="flex items-center gap-1">
                        <GroupIcon />
                        {label}
                    </div>
                );
            }
            case "assembly bot": {
                return (
                    <div className="flex items-center gap-1.5 py-1.5 px-0.5">
                        <img
                            src="https://avatars.slack-edge.com/2024-03-13/6794077921701_9cf81e90135145d4ed52_192.jpg"
                            alt="Assembly"
                            className="w-4 h-4"
                        />
                        Assembly Bot
                    </div>
                );
            }
            case "team": {
                return (
                    <div className="flex items-center gap-1">
                        {label === "General" ? (
                            <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                <HouseIcon
                                    className="text-iris9"
                                    strokeWidth={1.5}
                                    size={12}
                                />
                            </div>
                        ) : (
                            <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                <UsersIcon
                                    className="text-red9"
                                    strokeWidth={1.5}
                                    size={12}
                                />
                            </div>
                        )}
                        {label}
                    </div>
                );
            }
            default: {
                return <div>{valList}</div>;
            }
        }
    });

interface MultipleValuesFilterDisplayProps {
    type: string;
    valList: string[];
    users: GetUserResponse[];
    categories: Category[];
    options?: { label: string; value: string; color?: string }[];
}

const MultipleValuesFilterDisplay: React.FC<MultipleValuesFilterDisplayProps> =
    React.memo(({ type, valList, users, options, categories }) => {
        switch (type) {
            case "Source": {
                const valIcons: [IconEntry, string][] = valList.map((val) => {
                    const [source, channelName] = val?.split(" -- ") ?? [];
                    const label =
                        options?.find((item) => item.value === val)?.label ??
                        source;
                    const formattedLabel = channelName
                        ? channelName.trim()
                        : label;
                    const formattedSource = source ? source.trim() : "";
                    const IconComponent =
                        integrationBackEndDataMappingToSvg.get(
                            formattedSource,
                        ) ?? QuestionMarkIcon;
                    return [
                        {
                            Component: IconComponent,
                            props: {
                                width: 15,
                                height: 15,
                                style: {},
                            },
                        },
                        formattedLabel,
                    ];
                });
                return (
                    <div className="flex items-center gap-1">
                        {valIcons.map((elem, index) => (
                            // biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
                            <div className="flex items-center gap-1">
                                {React.createElement(elem[0].Component, {
                                    ...elem[0].props,
                                    key: index,
                                })}
                                {elem[1]}
                                {index !== valIcons.length - 1 && ","}
                            </div>
                        ))}
                    </div>
                );
            }
            case "Tag":
                return (
                    <div className="flex items-center">
                        {valList.map((val) => (
                            <ComponentBooleanIcon
                                color={
                                    categories.find(
                                        (category) => category.name === val,
                                    )?.color ?? "gray"
                                }
                                key={val}
                                className="-mx-0.5"
                            />
                        ))}
                    </div>
                );
            case "Topic":
                return (
                    <div className="flex items-center">
                        {valList.map((val) => {
                            const color = options?.find(
                                (option) => option.label === val,
                            )?.color;
                            return (
                                <DotFilledIcon
                                    color={
                                        color && color !== ""
                                            ? color
                                            : "#9B9EF0"
                                    }
                                    style={{ transform: "scale(1.8)" }}
                                    key={val}
                                    className="-mx-1"
                                />
                            );
                        })}
                    </div>
                );
            case "Status":
            case "Insight Status": {
                const valIcons: IconEntry[] = valList.map((val) => {
                    const IconComponent = getStatusIcon(val ?? "Unknown");
                    return {
                        Component: IconComponent,
                        props: {
                            width: 15,
                            height: 15,
                            style: { marginLeft: "-2" },
                        },
                    };
                });
                return (
                    <div className="flex items-center ml-0.5">
                        {valIcons.map((icon, index) =>
                            React.createElement(icon.Component, {
                                ...icon.props,
                                key: index,
                            }),
                        )}
                    </div>
                );
            }
            case "Assignee":
                return (
                    <div className="flex items-center gap-2 mr-2.5">
                        {valList.map((val) => {
                            const user = users.find((user) => user.id === val);
                            const pictureURL = user?.picture_url;
                            return (
                                <div
                                    className="lb-avatar rounded-lg w-5 h-5 -mr-2.5"
                                    key={val}
                                >
                                    {pictureURL ? (
                                        <img
                                            className="lb-avatar-image"
                                            src={pictureURL}
                                            alt={val}
                                        />
                                    ) : (
                                        <AvatarIcon className="w-6 h-6" />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                );
            case "Customer Group":
                return <GroupIcon />;
            case "Team":
                return (
                    <div className="flex items-center ml-0.5">
                        {valList.map((teamName, index) => {
                            if (teamName === "General") {
                                return (
                                    <HouseIcon
                                        className="text-iris9"
                                        strokeWidth={1.5}
                                        size={12}
                                    />
                                );
                            } else {
                                return (
                                    <UsersIcon
                                        className="text-red9"
                                        strokeWidth={1.5}
                                        size={12}
                                    />
                                );
                            }
                        })}
                    </div>
                );
            default:
                return <div>{valList.join(", ")}</div>;
        }
    });

interface FilterProps {
    type: string;
    values: Set<string>;
    filters: Map<string, Set<string>>;
    setFilters: (filters: Map<string, Set<string>>) => void;
    categories: Category[];
    topics: Topic[];
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
    teams: Teams[];
    channels: Map<string, ScopeResponse[]>;
    isSavedViewFilter: boolean; // Filter stays locked in, cannot change that filter type
    filterOptions: FilterOption[];
}

export function Filter({
    type,
    values,
    filters,
    setFilters,
    categories = [],
    topics,
    users,
    customerGroups,
    teams,
    channels,
    isSavedViewFilter,
    filterOptions,
}: FilterProps) {
    const [filterActiveMenu, setFilterActiveMenu] = useState<string | null>(
        type,
    );

    const handleItemSelect = (
        type: string,
        option: { label: string; value: string; key: string; color: string },
    ) => {
        return () => {
            const newFilters = new Map(filters); // Create a shallow copy of the filters Map
            let typeToStore = type;
            // Open up another context menu for these sources
            if (menuThenMenuSources.includes(option.value)) {
                setFilterActiveMenu(option.value);
                return;
            }

            // Handle source type channels/integrations separately
            if (sources.map((source) => source.value).includes(type)) {
                typeToStore = "Source";
                const [source, channelName] = option.value.split(" -- ");
                const sourcesStored = newFilters.get(typeToStore) ?? new Set();
                if (
                    channelName === "All Channels" ||
                    channelName === "All Emails" ||
                    channelName === "All API Keys"
                ) {
                    if (sourcesStored?.has(option.value)) {
                        // Remove the selected value
                        sourcesStored.delete(option.value);
                        if (sourcesStored.size === 0) {
                            newFilters.delete(typeToStore); // Remove filter if no values remain
                        } else {
                            newFilters.set(typeToStore, sourcesStored); // Update the type with new values
                        }
                    } else {
                        // Remove other existing filters for that source and then add All Channels
                        const filteredSources = Array.from(
                            sourcesStored,
                        ).filter((value) => {
                            const [elemSource] = value.split(" -- ");
                            return elemSource !== source;
                        });
                        filteredSources.push(option.value);
                        newFilters.set(typeToStore, new Set(filteredSources));
                    }
                    setFilters(newFilters);
                    return;
                }
                if (
                    sourcesStored.size === 1 &&
                    Array.from(sourcesStored).some(
                        (item) =>
                            item.includes("-- All Channels") ||
                            item.includes("-- All Emails") ||
                            item.includes("-- All API Keys"),
                    )
                ) {
                    newFilters.set(typeToStore, new Set([option.value]));
                    setFilters(newFilters);
                    return;
                }
            }

            if (newFilters.has(typeToStore)) {
                const currValues = new Set(newFilters.get(typeToStore)); // Shallow copy of the Set
                if (currValues.has(option.value)) {
                    currValues.delete(option.value); // Remove the selected value
                    if (currValues.size === 0) {
                        newFilters.delete(typeToStore); // Remove filter if no values remain
                    } else {
                        newFilters.set(typeToStore, currValues); // Update the type with new values
                    }
                } else {
                    currValues.add(option.value); // Add new value to the filter
                    newFilters.set(typeToStore, currValues); // Update the type with the new value set
                }
            } else {
                newFilters.set(typeToStore, new Set([option.value])); // Add new filter type if it doesn't exist
            }
            setFilters(newFilters);
        };
    };

    const handleOpenChange = useCallback((open: boolean) => {
        if (!open) {
            setFilterActiveMenu(type);
        }
    }, []);

    const supportedDirectSelectFilterOptions = ["Assembly Bot"];
    const api = useApi();

    const memoizedFilterDropdownElement = useMemo(() => {
        return filterOptions.map(
            (filterOption) =>
                filterActiveMenu === filterOption.type && (
                    <FilterDropdownElement
                        key={filterOption.type}
                        type={filterOption.type}
                        filters={filters}
                        handleItemSelect={handleItemSelect}
                        categories={categories}
                        topics={topics}
                        users={users}
                        customerGroups={customerGroups}
                        teams={teams}
                        channels={channels}
                        isSavedViewFilter={false}
                        extraOptions={filterOption.extraOptions ?? []}
                    />
                ),
        );
    }, [filterActiveMenu, filters, handleItemSelect, topics, users]);

    const categoryOptionsQuery = useQuery<Category[]>({
        queryKey: ["categories"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getCategories}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });

    const options = React.useMemo(() => {
        if (categoryOptionsQuery.isSuccess) {
            return getOptions(
                type,
                topics,
                users,
                customerGroups,
                categoryOptionsQuery.data,
                teams,
                channels,
                [],
                [],
                [],
                [],
            );
        }
    }, [
        type,
        topics,
        users,
        customerGroups,
        categoryOptionsQuery.status,
        categoryOptionsQuery.data,
    ]);

    const valList = React.useMemo(() => Array.from(values), [values]);

    const deleteFilter = (type: string) => () => {
        const newFilters = new Map(filters);
        newFilters.delete(type);
        setFilters(newFilters);
    };

    return (
        <div className="flex items-center bg-background mx-0.5">
            {supportedDirectSelectFilterOptions.includes(type) ? (
                <ScnBadge
                    variant="outline"
                    className="flex items-center gap-1 py-0.5 px-2 font-normal"
                >
                    <SingleValueFilterDisplay
                        type={type}
                        color={
                            categories.find(
                                (category) => category.name === valList[0],
                            )?.color ?? "gray"
                        }
                        valList={valList}
                        options={options}
                        users={users}
                    />
                    <Separator
                        orientation="vertical"
                        className="bg-sidebarBorder w-[2px] h-4 mx-1"
                    />
                    <div>
                        <Button
                            type="button"
                            variant="ghost"
                            onClick={deleteFilter(type)}
                            className="flex items-center hover:bg-muted text-xs rounded-md text-gray-700 hover:text-gray-950 h-6 w-6 p-0 m-0"
                        >
                            <Cross2Icon className="w-4 h-4" />
                        </Button>
                    </div>
                </ScnBadge>
            ) : (
                <ScnBadge
                    variant="outline"
                    className="flex items-center gap-1 py-1 px-2 font-normal"
                >
                    {getTypeName(type)}
                    <Separator
                        orientation="vertical"
                        className="bg-sidebarBorder w-[2px] h-4 mx-1"
                    />
                    {values.size === 1 ? (
                        <div className="flex items-center gap-1 font-normal">
                            is
                            <Separator
                                orientation="vertical"
                                className="bg-sidebarBorder w-[2px] h-4 mx-1.5"
                            />
                            <DropdownMenu
                                key={type}
                                onOpenChange={handleOpenChange}
                            >
                                <DropdownMenuTrigger asChild type="button">
                                    <div className="flex items-center gap-1.5">
                                        <SingleValueFilterDisplay
                                            type={type}
                                            valList={valList}
                                            options={options}
                                            users={users}
                                            color={
                                                categories.find(
                                                    (category) =>
                                                        category.name ===
                                                        valList[0],
                                                )?.color ?? "gray"
                                            }
                                        />
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="start"
                                    className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                >
                                    {memoizedFilterDropdownElement}
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    ) : (
                        <div className="flex items-center gap-1 font-normal">
                            is any of
                            <Separator
                                orientation="vertical"
                                className="bg-sidebarBorder w-[2px] h-4 mx-1.5"
                            />
                            <DropdownMenu
                                key={type}
                                onOpenChange={handleOpenChange}
                            >
                                <DropdownMenuTrigger asChild type="button">
                                    <div className="flex items-center gap-1.5">
                                        <MultipleValuesFilterDisplay
                                            type={type}
                                            valList={valList}
                                            categories={categories}
                                            users={users}
                                            options={options}
                                        />
                                        {/* TODO: gate this in a cleaner way */}
                                        {type !== "Source" && (
                                            <div>
                                                {values.size} {getPlural(type)}
                                            </div>
                                        )}
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="start"
                                    className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                >
                                    {memoizedFilterDropdownElement}
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    )}
                    <Separator
                        orientation="vertical"
                        className="bg-sidebarBorder w-[2px] h-4 mx-1"
                    />
                    <div>
                        <Button
                            type="button"
                            variant="ghost"
                            onClick={deleteFilter(type)}
                            className="flex items-center hover:bg-muted text-xs rounded-md text-gray-700 hover:text-gray-950 h-6 w-6 p-0 m-0"
                        >
                            <Cross2Icon className="w-4 h-4" />
                        </Button>
                    </div>
                </ScnBadge>
            )}
        </div>
    );
}
