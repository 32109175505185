import { Card, CardContent } from "@/component/shadcn/ui/card";
import {
    type Account,
    type AccountUpdatePayload,
    ContractType,
    type GetUserResponse,
    type IconEntry,
    type Teams,
} from "@/interfaces/serverData";
import {
    Cross2Icon,
    ImageIcon,
    LinkBreak1Icon,
    OpenInNewWindowIcon,
    Pencil1Icon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import React, { useCallback, useEffect } from "react";

import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { lastInteractionBadge } from "@/utilities/methods";
import type { UseQueryResult } from "@tanstack/react-query";
import debounce from "lodash/debounce";
import { Copy, MailIcon, SendIcon } from "lucide-react";
import { integrationBackEndDataMappingToSvg } from "../../Integrations/constant";
import AccountPopup from "../AccountPopup";
import type { AccountsListType } from "../Accounts";
import { SendNewMessageDialog } from "../SendNewMessageDialog";

interface CustomersListCardProps {
    customer: Account;
    userID: string;
    users: GetUserResponse[];
    forceUpdate: (
        company: boolean,
        contacts: boolean,
        tickets: boolean,
    ) => Promise<void>;
    company: Account | undefined;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    teamsQuery: UseQueryResult<Teams[], Error>;
    orgID: string;
    listType: AccountsListType;
}

export function CustomersListCard({
    customer,
    userID,
    users,
    forceUpdate,
    company,
    usersQuery,
    teamsQuery,
    orgID,
    listType,
}: CustomersListCardProps) {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { toast } = useToast();

    const [customerState, setCustomerState] = useState<Account>(customer);
    useEffect(() => {
        setCustomerState(customer);
    }, [customer]);

    const badgeLength = (customer?.name?.length ?? 3) * 0.8;
    const [limitCount, setLimitCount] = useState(
        window.innerWidth * 0.08 - badgeLength,
    );

    const api = useApi();
    const [serviceNames, setServiceNames] = useState<string[]>(
        Object.keys(customerState.metadata ?? {}),
    );

    useEffect(() => {
        if (!customerState.metadata) {
            return;
        }

        // try {
        //     const data = JSON.parse(customerState.metadata);
        //     const keys = Object.keys(data);
        //     setServiceNames(keys);
        // } catch (error) {
        //     console.error("Error parsing JSON:", error);
        // }
    }, [customerState.metadata]);

    const serviceIcons = useMemo(() => {
        const icons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        serviceNames.forEach((serviceName) => {
            const IconComponent =
                integrationBackEndDataMappingToSvg.get(serviceName);
            if (IconComponent) {
                icons.add({
                    Component: IconComponent,
                    props: {
                        width: 18,
                        height: 18,
                        style: {
                            marginLeft: "-2px",
                            marginRight: "-2px",
                        },
                    },
                });
            }
        });
        return icons;
    }, [serviceNames]);

    const location = useLocation();

    const handleRowClick = (id: string) => {
        const newPath = `${location.pathname}/${id}`;
        navigate(newPath, { replace: true });
    };

    const [maxWidth, setMaxWidth] = useState(window.innerWidth * 0.75);

    const updateDimensions = useCallback(() => {
        setMaxWidth(window.innerWidth * 0.75);
        setLimitCount(window.innerWidth * 0.08 - badgeLength);
    }, []);

    const debouncedUpdateDimensions = useMemo(
        () => debounce(updateDimensions, 200),
        [updateDimensions],
    );

    // Directly update dimensions when issue or topics change
    useEffect(() => {
        updateDimensions();
    }, [customerState]);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        // Handle window resize event with debounce
        debouncedUpdateDimensions();
        window.addEventListener("resize", debouncedUpdateDimensions);
        return () =>
            window.removeEventListener("resize", debouncedUpdateDimensions);
    }, [debouncedUpdateDimensions]);

    const handleOpenInNewTab = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        customerID: string,
    ) => {
        event.stopPropagation();
        window.open(`/accounts/${id}/${customerID}`, "_blank");
    };

    const handleCopyEmail = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, email: string | undefined) => {
        event.stopPropagation();
        if (email) {
            navigator.clipboard.writeText(email)
                .catch((error) => {
                    console.error('Error copying to clipboard: ', error);
                });
        }
    };

    const handleRemoveFromCompany = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        event.stopPropagation();
        const requestData: AccountUpdatePayload = {
            id: customer?.id ?? "",
            name: customer.name ?? "",
            domain: customer.domain ?? "",
            image_url: customer.image_url ?? "",
            contract_value: customer.contract_value ?? 0,
            contract_type: customer.contract_type
                ? (customer.contract_type as ContractType)
                : ContractType.Month,
            // plan: customer.plan ?? "",
            company_id: "",
        };
        api.patch(
            `${URLS.serverUrl}${ContactsAPI.updateCustomer.url}/${customer.id}`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((res) => {
                if (res.status !== 200) {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                } else {
                    toast({
                        title: "Updated Customer!",
                        description:
                            "The customer has been updated successfully.",
                    });
                    forceUpdate(false, true, false);
                }
            })
            .catch((res) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            });
    };

    const handleDelete = (contact: Account) => {
        api.delete(`${ContactsAPI.deleteCustomer.url}/${contact.id}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    forceUpdate(false, true, false);
                    toast({
                        title: "Deleted Customer!",
                        description:
                            "The customer has been deleted successfully.",
                    });
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
                toast({
                    title: "Failed to delete customer",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            });
    };

    return (
        <ContextMenu>
            <ContextMenuTrigger>
                <Card
                    className="p-2.5 border-l-transparent border-r-transparent border-b-transparent hover:bg-muted rounded w-full"
                    onClick={() => handleRowClick(`${customerState.id}`)}
                >
                    <CardContent className="p-0">
                        <button
                            className="text-xs bg-transparent border-none p-0 cursor-pointer w-full"
                            type="button"
                        >
                            <div className="flex items-center justify-between w-full gap-2">
                                <div className="flex items-center gap-2 w-full">
                                    {customerState.image_url !== "" &&
                                        customerState.image_url !== undefined ? (
                                        <div className="lb-avatar rounded w-5 h-5 flex-shrink-0">
                                            <img
                                                className="lb-avatar-image"
                                                src={customerState.image_url}
                                                alt={customerState.name}
                                            />

                                            <span>
                                                {customerState.name ?? ""}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="lb-avatar rounded w-6 h-6 flex-shrink-0">
                                            <ImageIcon className="w-5 h-5 mx-1" />
                                        </div>
                                    )}
                                    {customerState.name !== "" &&
                                        customerState.name !== undefined && (
                                            <p className="text-xs m-0 pl-2">
                                                {customerState.name}
                                            </p>
                                        )}

                                    {customerState.domain !== "" &&
                                        customerState.domain !== undefined && (
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <MailIcon className="w-3 h-3 flex-shrink-0 text-primary" />
                                                    </TooltipTrigger>
                                                    <TooltipContent
                                                        sideOffset={5}
                                                        className="bg-white text-gray11 mb-2 mt-3 flex flex-col opacity-100 mr-3"
                                                        asChild
                                                    >
                                                        <Card className="bg-white text-gray11 px-3 py-1.5 mb-2 flex gap-1 flex-col text-left align-start opacity-100">
                                                            {
                                                                customerState.domain
                                                            }
                                                        </Card>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        )}
                                </div>

                                <div className="flex items-center justify-end gap-2.5">
                                    <div className="flex items-center">
                                        {Array.from(serviceIcons).map((icon) =>
                                            React.createElement(
                                                icon.Component,
                                                icon.props,
                                            ),
                                        )}
                                    </div>
                                    {customerState.last_ticket &&
                                        lastInteractionBadge(
                                            customerState.last_ticket,
                                        )}
                                </div>
                            </div>
                        </button>
                    </CardContent>
                </Card>
            </ContextMenuTrigger>
            <ContextMenuContent className="w-60">
                <ContextMenuItem
                    inset
                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                    onClick={(e) => handleOpenInNewTab(e, customer.id)}
                >
                    <OpenInNewWindowIcon className="w-3.5 h-3.5" />
                    Open in New Tab
                </ContextMenuItem>
                <ContextMenuItem
                    inset
                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                    onClick={(e) => handleCopyEmail(e, customer.domain)}
                >
                    <Copy className="w-3.5 h-3.5" />
                    Copy Email
                </ContextMenuItem>
                <SendNewMessageDialog
                    accountType="customer"
                    account={customer}
                    triggerElement={
                        <ContextMenuItem
                            inset
                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                        >
                            <SendIcon className="w-3.5 h-3.5" />
                            Send Checkin
                        </ContextMenuItem>
                    }
                    updateData={forceUpdate}
                />
                <ContextMenuItem
                    inset
                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                    onClick={(e) => handleRemoveFromCompany(e)}
                >
                    <LinkBreak1Icon className="w-3.5 h-3.5" />
                    Remove From Company
                </ContextMenuItem>

                {/* Edit Customer */}
                <AccountPopup
                    triggerElement={
                        <ContextMenuItem
                            inset
                            onSelect={(e) => e.preventDefault()}
                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                        >
                            <Pencil1Icon className="w-3.5 h-3.5" />
                            Edit
                        </ContextMenuItem>
                    }
                    editing={true}
                    type="Customer"
                    editingObject={customer}
                    company={company}
                    updateData={forceUpdate}
                    usersQuery={usersQuery}
                    userID={userID}
                    notEditableFields={["Assignee", "Teams"]}
                    teamsQuery={teamsQuery}
                    orgID={orgID}
                    listType={listType}
                />

                {/* Delete Customer */}
                <AlertDialog
                    open={deleteDialogOpen}
                    onOpenChange={setDeleteDialogOpen}
                >
                    <AlertDialogTrigger asChild>
                        <ContextMenuItem
                            inset
                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                            onSelect={(e) => e.preventDefault()}
                        >
                            <TrashIcon className="w-3.5 h-3.5" />
                            Delete
                        </ContextMenuItem>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                        <div>
                            <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                <AlertDialogTitle>
                                    Are you absolutely sure?
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                    {`This action cannot be undone. This will permanently delete this "${customerState.name}" customer.`}
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                        </div>
                        <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                            <AlertDialogCancel
                                onClick={() => setDeleteDialogOpen(false)}
                            >
                                Cancel
                            </AlertDialogCancel>
                            <AlertDialogAction
                                onClick={() => {
                                    handleDelete(customer);
                                }}
                            >
                                Delete
                            </AlertDialogAction>
                            <AlertDialogCancel
                                className="shadow-none absolute top-0 right-2 px-2 py-2 "
                                onClick={() => setDeleteDialogOpen(false)}
                            >
                                <Cross2Icon />
                            </AlertDialogCancel>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </ContextMenuContent>
        </ContextMenu>
    );
}
