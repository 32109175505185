export const ErrorChip = ({
    label = "Oops! Looks like something's wrong. Try again, or notify us!",
}: { label?: string }) => {
    return (
        <div className="inline-flex items-center justify-center h-8 max-w-full w-fit rounded-full border border-red-500 bg-white px-2 py-1 text-sm font-medium text-red-500 whitespace-nowrap mx-[5px]">
            <span className="overflow-hidden text-ellipsis px-3 whitespace-nowrap">
                {label}
            </span>
        </div>
    );
};
