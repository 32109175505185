import { useState } from "react";
import { z } from "zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/interfaces/api";
import { API, URLS } from "@/constant";
import { Box, Flex } from "@radix-ui/themes";
import { KeyIcon, ClipboardIcon } from "lucide-react"; // Add ClipboardIcon
import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import { Input } from "@/component/shadcn/ui/input";
import { Label } from "@/component/shadcn/ui/label";
import IntegrationHeader from "../Admin/Integrations/IntegrationHeader";
import { useToast } from "@/component/shadcn/ui/use-toast";

interface APIKeyFull {
    api_key_id: string;
    expires_at_seconds: number;
    title: string;
}

interface ListAPIKeyResponse {
    keys: APIKeyFull[];
}

const apiKeySchema = z.object({
    title: z.string().min(1, "Title is required"),
    expirationDuration: z
        .number()
        .positive("Expiration must be a positive number")
        .optional(),
});

class APIKeyValidationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "APIKeyValidationError";
    }
}

export function APIKeyManagement() {
    const [newKey, setNewKey] = useState<string | null>(null);
    const [newKeyTextCopied, setNewKeyTextCopied] = useState(false);
    const [isOneTimeDialogOpen, setIsOneTimeDialogOpen] = useState(false);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const api = useApi();
    const { toast } = useToast();

    const { data, isLoading, error } = useQuery<ListAPIKeyResponse>({
        queryKey: ["listAPIKey"],
        queryFn: async () => {
            const url = `${URLS.serverUrl}${API.listAPIKey}`;
            const res = await api.get(url);
            if (res.status !== 200) {
                throw new Error("Failed to fetch API keys");
            }
            return res.data.data;
        },
    });

    const queryClient = useQueryClient();
    const { mutate: deleteAPIKey } = useMutation({
        mutationFn: async (apiKeyId: string) => {
            const url = `${URLS.serverUrl}${API.deleteAPIKey}/${apiKeyId}`;
            const res = await api.delete(url);
            if (res.status !== 200) {
                throw new Error("Failed to delete API key");
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["listAPIKey"] });
            toast({
                title: "API Key Deleted",
                description: "Your API key has been deleted successfully.",
            });
        },
        onError: () => {
            toast({
                title: "Failed to delete API key",
                description: "Please try again.",
                variant: "destructive",
            });
        },
    });

    const { mutate: createAPIKey } = useMutation({
        mutationFn: async (formData: FormData) => {
            const url = `${URLS.serverUrl}${API.createAPIKey}`;
            const title = formData.get("title") as string;
            const expirationDuration = formData.get("expirationDuration")
                ? Number(formData.get("expirationDuration"))
                : undefined;

            const parsed = apiKeySchema.safeParse({
                title,
                expirationDuration,
            });

            if (!parsed.success) {
                throw new Error(
                    parsed.error.errors.map((e) => e.message).join(", "),
                );
            }

            const res = await api.post(url, {
                title,
                expires_in_seconds: expirationDuration,
            });

            switch (res.status) {
                case 200:
                    return res.data.data;
                case 400:
                    throw new APIKeyValidationError(res.data.message);
                default:
                    throw new Error("Failed to create API key");
            }
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["listAPIKey"] });
            setNewKey(data.token);

            setIsOneTimeDialogOpen(true);
            setIsCreateDialogOpen(false);
        },
        onError: (error) => {
            if (error instanceof APIKeyValidationError) {
                toast({
                    title: "Duplicate API Key title",
                    description: "Please ensure the title is unique.",
                    variant: "destructive",
                });
            } else {
                toast({
                    title: "Failed to create API key",
                    description: "Please try again.",
                    variant: "destructive",
                });
            }
        },
    });

    const handleCreateAPIKey = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        createAPIKey(formData);
    };

    const getExpiryText = (expiresAtSeconds: number) => {
        if (expiresAtSeconds === 0) {
            return <p className="text-sm text-gray-500">Never expires</p>;
        } else {
            const expiryDate = new Date(expiresAtSeconds * 1000);
            if (expiryDate < new Date()) {
                return <p className="text-sm text-red-500">Expired</p>;
            }
            const formattedExpiry = expiryDate.toLocaleString(); // Display date and time in local format
            return (
                <p className="text-sm text-gray-500">
                    Expires at: {formattedExpiry}
                </p>
            );
        }
    };

    // Function to handle copying the API key to clipboard
    const handleCopyToClipboard = () => {
        if (newKey) {
            navigator.clipboard.writeText(newKey);
            setNewKeyTextCopied(true);
            setTimeout(() => {
                setNewKeyTextCopied(false);
            }, 2000);
        }
    };

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex
                    direction={"column"}
                    align={"start"}
                    gap="6"
                    style={{ paddingBottom: "20px" }}
                >
                    <IntegrationHeader
                        integrationType="API Keys"
                        description="Manage and create API Keys to feed in your own data into Assembly"
                        SvgIcon={KeyIcon}
                    />
                </Flex>
                <Dialog
                    open={isOneTimeDialogOpen}
                    onOpenChange={setIsOneTimeDialogOpen}
                >
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Your New API Key</DialogTitle>
                            <DialogDescription>
                                This is your new API key. Make sure to copy it
                                now, as you won't be able to see it again.
                            </DialogDescription>
                        </DialogHeader>
                        <div className="grid gap-4 py-4">
                            <div className="grid grid-cols-1 items-center gap-4">
                                <Input
                                    value={newKey || ""}
                                    readOnly
                                    className="w-full"
                                />
                            </div>
                        </div>
                        <DialogFooter>
                            <Button
                                onClick={handleCopyToClipboard} // Trigger copy action
                                className="bg-indigo-600 hover:bg-indigo-700 text-white"
                            >
                                <ClipboardIcon className="mr-2 h-4 w-4" />
                                Copy Key
                            </Button>
                            <Button
                                onClick={() => setIsOneTimeDialogOpen(false)}
                                className="bg-indigo-600 hover:bg-indigo-700 text-white"
                            >
                                Close
                            </Button>
                        </DialogFooter>
                        <p
                            className={`text-green-600 text-right ${!newKeyTextCopied ? "hidden" : ""}`}
                        >
                            Key copied!
                        </p>
                    </DialogContent>
                </Dialog>

                {isLoading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div>Error: {(error as Error).message}</div>
                ) : (
                    <div className="space-y-4">
                        {data?.keys.map((key) => (
                            <div
                                key={key.api_key_id}
                                className="flex items-center justify-between p-4 border rounded-lg"
                            >
                                <div>
                                    <p className="font-medium">{key.title}</p>
                                    {getExpiryText(key.expires_at_seconds)}
                                </div>
                                <AlertDialog>
                                    <AlertDialogTrigger asChild>
                                        <Button
                                            className="bg-[#5B5BD6] text-white"
                                            variant="outline"
                                        >
                                            Delete
                                        </Button>
                                    </AlertDialogTrigger>
                                    <AlertDialogContent
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <AlertDialogHeader>
                                            <AlertDialogTitle>
                                                Are you sure?
                                            </AlertDialogTitle>
                                            <AlertDialogDescription>
                                                This action cannot be undone.
                                                This will permanently delete
                                                your API key.
                                            </AlertDialogDescription>
                                        </AlertDialogHeader>
                                        <AlertDialogFooter>
                                            <AlertDialogCancel>
                                                Cancel
                                            </AlertDialogCancel>
                                            <AlertDialogAction
                                                className="bg-[#5B5BD6] text-white"
                                                onClick={() =>
                                                    deleteAPIKey(key.api_key_id)
                                                }
                                            >
                                                Delete
                                            </AlertDialogAction>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialog>
                            </div>
                        ))}

                        <Dialog
                            open={isCreateDialogOpen}
                            onOpenChange={setIsCreateDialogOpen}
                        >
                            <DialogTrigger asChild>
                                <Flex
                                    style={{
                                        width: "100%",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button
                                        className="mt-4 bg-[#5B5BD6] text-white"
                                        variant="outline"
                                    >
                                        <KeyIcon className="mr-2 h-4 w-4" />
                                        Create API Key
                                    </Button>
                                </Flex>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-[600px]">
                                <form
                                    onSubmit={handleCreateAPIKey}
                                    className="grid gap-4 py-4"
                                >
                                    <DialogHeader>
                                        <DialogTitle>
                                            Create New API Key
                                        </DialogTitle>
                                    </DialogHeader>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                        <Label
                                            htmlFor="title"
                                            className="text-right"
                                        >
                                            Title*
                                        </Label>
                                        <Input
                                            id="title"
                                            name="title"
                                            className="col-span-3"
                                            required
                                        />
                                    </div>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                        <Label
                                            htmlFor="expirationDuration"
                                            className="text-right"
                                        >
                                            Expiration
                                        </Label>
                                        <div className="col-span-3">
                                            <Input
                                                id="expirationDuration"
                                                name="expirationDuration"
                                                type="number"
                                                placeholder="Optional, in seconds"
                                            />
                                        </div>
                                    </div>
                                    <DialogFooter>
                                        <Button
                                            type="submit"
                                            className="bg-indigo-600 hover:bg-indigo-700 text-white"
                                        >
                                            Create API Key
                                        </Button>
                                    </DialogFooter>
                                </form>
                            </DialogContent>
                        </Dialog>
                    </div>
                )}
            </Box>
        </div>
    );
}
