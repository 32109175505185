import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    BotMetadata,
    OrgInfoResponse,
    ScopeResponse,
} from "@/interfaces/serverData";
import { AssemblyErrorMessage } from "@/reusable_components/loadingStates/ErrorMessage";
import {
    GitHubLogoIcon,
    Pencil1Icon,
    PlusIcon,
    ReloadIcon,
} from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Button,
    Card,
    Flex,
    Separator,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { metadata } from "../IntegrationsManagementPage";
import ModeratorCard from "./IntegrationCards/ModeratorCard";
import IntegrationHeader from "./IntegrationHeader";
import { useToast } from "@/component/shadcn/ui/use-toast";

const GitHubTicketIntegration = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const gitHubTicketContinue = () => {
        // const githubLogin =
        //     "https://github.com/login/oauth/authorize?client_id=";

        // const scopes = "write:issues%20write:projects%20read:user";
        // const adminType = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/githubticket?type=user`;
        // const githubUrl = `${githubLogin}${process.env.REACT_APP_GITHUB_TICKETS_CLIENT_ID}&redirect_uri=${adminType}&scope=${scopes}`;
        const url = `https://github.com/apps/${process.env.REACT_APP_GITHUB_TICKET_APP_NAME}/installations/new`;
        window.location.assign(url);
    };

    const { toast } = useToast();

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const [scopes, setScopes] = useState<ScopeResponse[]>([]);
    const [githubIssuesTicketChannels, setgithubIssuesTicketChannels] =
        useState<string[]>([]);
    const [githubDiscTicketChannels, setgithubDiscTicketChannels] = useState<
        string[]
    >([]);
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    const navigate = useNavigate();
    const addMoreIssuesRepositories = () => {
        navigate("/admin/integrations/update/githubticket");
    };
    const addMoreDiscussionsRepositories = () => {
        navigate("/admin/integrations/update/githubdiscussion");
    };

    useEffect(() => {
        const requestData = {
            types: ["GitHubTicket"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;

                        if (orgInfo.GitHubTicket) {
                            setIntegrationEnabled(true);
                        }

                        if (orgInfo.GitHubTicket?.[0]?.scopes) {
                            for (const repo of orgInfo.GitHubTicket[0].scopes) {
                                temp.scopes.push(repo.name);
                            }
                        }

                        setAdditionalMetadata(temp);
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    useEffect(() => {
        if (integrationEnabled) {
            const requestData = {
                type: "GitHubTicket",
            };
            api.post(URLS.serverUrl + API.getScopes, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    const dataItems: ScopeResponse[] = res.data.data;
                    setScopes(dataItems);
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/GitHubTicket`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const settingsData: BotMetadata = res.data.data;
                                const dataTemp: string[] = [];
                                if (settingsData.asm_ticket_channels) {
                                    for (const repo of settingsData.asm_ticket_channels) {
                                        dataTemp.push(repo.name);
                                    }
                                }
                                setgithubIssuesTicketChannels(dataTemp);

                                const spaceOwners: ScopeResponse[] = [];
                                if (settingsData.space_owners) {
                                    for (const space_owner of settingsData.space_owners) {
                                        spaceOwners.push(space_owner);
                                    }
                                }
                                setAdminGitHubUsers(spaceOwners);
                            } else {
                                setLoadingState(2);
                            }
                        })
                        .catch((res) => {
                            setLoadingState(2);
                        });
                })
                .catch((res) => {
                    console.log("Could not find GitHub Ticket Scopes");
                });

            api.get(
                `${URLS.serverUrl}${API.getBotSettingsV2}/GithubDiscussion`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status === 200) {
                        const settingsData: BotMetadata = res.data.data;
                        const dataTemp: string[] = [];
                        if (settingsData.asm_ticket_channels) {
                            for (const repo of settingsData.asm_ticket_channels) {
                                dataTemp.push(repo.name);
                            }
                        }
                        setgithubDiscTicketChannels(dataTemp);
                    } else {
                        setLoadingState(2);
                    }
                })
                .catch((res) => {
                    setLoadingState(2);
                });
        }
    }, [api, integrationEnabled]);

    const [adminGitHubUsers, setAdminGitHubUsers] = useState<ScopeResponse[]>(
        [],
    );

    function saveBotSettings() {
        const requests: Map<string, BotMetadata> = new Map<string, BotMetadata>(
            [
                [
                    "githubticket",
                    {
                        bot_type: "GitHubTicket",
                        space_owners: adminGitHubUsers,
                    },
                ],
                [
                    "githubdiscussion",
                    {
                        bot_type: "GithubDiscussion",
                        space_owners: adminGitHubUsers,
                    },
                ],
            ],
        );

        requests.forEach((reqData: BotMetadata, route: string) => {
            api.post(
                `${URLS.serverUrl}${API.saveBotSettingsV2}/${route}`,
                reqData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Updated Settings!",
                            description:
                                "Your settings have been updated successfully.",
                        });
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                })
                .finally(() => {
                    // only clear window once
                    if (route != "githubticket") {
                        return;
                    }
                });
        });
    }

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType={"GitHub Issues and Discussions"}
                        description="Stay up to date with Github issues and discussions, and automate GitHub issue creation with a “slash - / “ command or emojis"
                        SvgIcon={GitHubLogoIcon}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                        !integrationEnabled ? (
                            <Button mb={"20px"} onClick={gitHubTicketContinue}>
                                Enable
                            </Button>
                        ) : (
                            <Flex gap="2" direction="column">
                                <Button disabled={true}>Enabled</Button>
                                <Button
                                    size="1"
                                    onClick={gitHubTicketContinue}
                                    mb={"20px"}
                                >
                                    <ReloadIcon /> Refresh Scopes
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                    {loadingState === loadingTypes.loaded &&
                        integrationEnabled && (
                            <>
                                <Card
                                    style={{
                                        paddingRight: "0px",
                                        paddingLeft: "0px",
                                        marginBottom: "20px",
                                        width: "100%",
                                    }}
                                >
                                    <Flex
                                        direction={"column"}
                                        gap="2"
                                        mb="2"
                                        justify={"between"}
                                    >
                                        <Flex
                                            direction={"row"}
                                            justify={"between"}
                                            align={"center"}
                                            style={{
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                            }}
                                        >
                                            <Flex direction="column">
                                                <Text size={"2"}>
                                                    Connected Repositories:{" "}
                                                </Text>
                                                <Text size={"1"} color="gray">
                                                    Repositories that Assembly
                                                    has access to.
                                                </Text>
                                            </Flex>
                                            <Button
                                                variant="outline"
                                                size="1"
                                                onClick={gitHubTicketContinue}
                                            >
                                                <Pencil1Icon
                                                    width="15"
                                                    height="15"
                                                />
                                                Edit Repositories
                                            </Button>
                                        </Flex>
                                        {scopes?.length !== 0 && (
                                            <Separator
                                                size={"4"}
                                                mt="1"
                                                mb="1"
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                        {scopes?.length !== 0 && (
                                            <Flex
                                                style={{
                                                    paddingRight: "20px",
                                                    paddingLeft: "20px",
                                                }}
                                                direction="row"
                                                gap="3"
                                                align="center"
                                                wrap="wrap"
                                            >
                                                {scopes.map((item) => (
                                                    <Badge key={item.name}>
                                                        {item.name}
                                                    </Badge>
                                                ))}{" "}
                                            </Flex>
                                        )}
                                    </Flex>
                                </Card>
                                <Card
                                    style={{
                                        paddingRight: "0px",
                                        paddingLeft: "0px",
                                        marginBottom: "20px",
                                        width: "100%",
                                    }}
                                >
                                    <Flex
                                        direction={"column"}
                                        gap="2"
                                        mb="2"
                                        justify={"between"}
                                    >
                                        <Flex
                                            direction={"row"}
                                            justify={"between"}
                                            align={"center"}
                                            style={{
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                            }}
                                        >
                                            <Flex direction="column">
                                                <Text size={"2"}>
                                                    Issues Repos Selected:{" "}
                                                </Text>
                                                <Text size={"1"} color="gray">
                                                    Repos that Assembly will
                                                    listen to Github Issues and
                                                    create tickets for.
                                                </Text>
                                            </Flex>
                                            <Button
                                                variant="outline"
                                                size="1"
                                                onClick={
                                                    addMoreIssuesRepositories
                                                }
                                            >
                                                <PlusIcon
                                                    width="15"
                                                    height="15"
                                                />{" "}
                                                Add More Channels
                                            </Button>
                                        </Flex>
                                        {githubIssuesTicketChannels?.length !==
                                            0 && (
                                            <Separator
                                                size={"4"}
                                                mt="1"
                                                mb="1"
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                        {githubIssuesTicketChannels?.length !==
                                            0 && (
                                            <Flex
                                                style={{
                                                    paddingRight: "20px",
                                                    paddingLeft: "20px",
                                                }}
                                                direction="row"
                                                gap="3"
                                                align="center"
                                                wrap="wrap"
                                            >
                                                {githubIssuesTicketChannels.map(
                                                    (item) => (
                                                        <Badge key={item}>
                                                            {item}
                                                        </Badge>
                                                    ),
                                                )}{" "}
                                            </Flex>
                                        )}
                                    </Flex>
                                </Card>
                                <Card
                                    style={{
                                        paddingRight: "0px",
                                        paddingLeft: "0px",
                                        marginBottom: "20px",
                                        width: "100%",
                                    }}
                                >
                                    <Flex
                                        direction={"column"}
                                        gap="2"
                                        mb="2"
                                        justify={"between"}
                                    >
                                        <Flex
                                            direction={"row"}
                                            justify={"between"}
                                            align={"center"}
                                            style={{
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                            }}
                                        >
                                            <Flex direction="column">
                                                <Text size={"2"}>
                                                    Discussions Repos Selected:{" "}
                                                </Text>
                                                <Text size={"1"} color="gray">
                                                    Repos that Assembly will
                                                    listen to Github Discussions
                                                    and create tickets for.
                                                </Text>
                                            </Flex>
                                            <Button
                                                variant="outline"
                                                size="1"
                                                onClick={
                                                    addMoreDiscussionsRepositories
                                                }
                                            >
                                                <PlusIcon
                                                    width="15"
                                                    height="15"
                                                />{" "}
                                                Add More Channels
                                            </Button>
                                        </Flex>
                                        {githubDiscTicketChannels?.length !==
                                            0 && (
                                            <Separator
                                                size={"4"}
                                                mt="1"
                                                mb="1"
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                        {githubDiscTicketChannels?.length !==
                                            0 && (
                                            <Flex
                                                style={{
                                                    paddingRight: "20px",
                                                    paddingLeft: "20px",
                                                }}
                                                direction="row"
                                                gap="3"
                                                align="center"
                                                wrap="wrap"
                                            >
                                                {githubDiscTicketChannels.map(
                                                    (item) => (
                                                        <Badge key={item}>
                                                            {item}
                                                        </Badge>
                                                    ),
                                                )}{" "}
                                            </Flex>
                                        )}
                                    </Flex>
                                </Card>

                                <ModeratorCard
                                    adminUsers={adminGitHubUsers}
                                    setAdminUsers={setAdminGitHubUsers}
                                    saveBotSettings={saveBotSettings}
                                    className="mb-4"
                                    botType="GitHubTicket"
                                    integrationEnabled={integrationEnabled}
                                    getName={(option: ScopeResponse) =>
                                        `${option.name}`
                                    }
                                    integrationName={""}
                                />
                            </>
                        )}
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={index}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <AssemblyErrorMessage />
                )}
            </Box>
        </div>
    );
};

export default GitHubTicketIntegration;
