import { CreateInsightPopup } from "@/Insights/CreateInsightPopup";
import GenerateInsights from "@/Insights/GenerateInsights";
import SavedInsightsList from "@/Insights/SavedInsightsList";
import SuggestedInsightsList from "@/Insights/SuggestedInsightsList";
import TopInsights from "@/Insights/TopInsights";
import { InsightListType, useInsightsData } from "@/Insights/useInsightsData";
import { Button } from "@/component/shadcn/ui/button";
import { API, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetTopicsResponse,
    GetUserResponse,
    QueriesWithPaginationResponse,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import { getBadgeForTeam } from "@/utilities/methods";
import { useAuthInfo } from "@propelauth/react";
import { DoubleArrowLeftIcon } from "@radix-ui/react-icons";
import { Box, Callout, Flex, Heading, Skeleton, Text } from "@radix-ui/themes";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import type { DateRange } from "react-day-picker";
import { TeamBadges } from "../WorkspacePreferences/TeamBadges";

interface AdminInsightPageProps {
    userID: string;
    listType: InsightListType;
    tempTeamId?: string;
}

const AdminInsightsPage: React.FC<AdminInsightPageProps> = ({
    userID,
    listType,
    tempTeamId,
}) => {
    const teamID = window.location.pathname.split("/")[2] || "";
    const api = useApi();

    const [topicsMap, setTopicsMap] = useState<Map<string, GetTopicsResponse>>(
        new Map(),
    );
    const [topics, setTopics] = useState<Topic[]>([]);
    const [loadingTopicsState, setLoadingTopicsState] = useState<number>(0);

    const [users, setUsers] = useState<GetUserResponse[]>([]);

    const [onDashboard, setOnDashboard] = useState<boolean>(true);
    const [generatedIsOpen, setGeneratedIsOpen] = useState<boolean>(true);

    const [playgroundIsOpen, setPlaygroundIsOpen] = useState<boolean>(true);
    const [playgroundFilters, setPlaygroundFilters] = useState<
        Map<string, Set<string>>
    >(new Map());
    const [dateRange, setDateRange] = useState<DateRange>();
    const [suggestedIsOpen, setSuggestedIsOpen] = useState<boolean>(true);

    const {
        loadingState,
        aiInsights,
        topTopics,
        insightStates,
        handleSaveAIGeneratedInsight,
        updateInsightState,
        queryClient,
        generatedInsights,
        setGeneratedInsights,
    } = useInsightsData(userID, listType, tempTeamId, teamID);

    const fetchQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.queriesWithPagination,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 500,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return {
                    ...response.data.data,
                    currentStatus: "",
                };
            }
            return {
                data: [],
                has_next_page: false,
                next_cursor: 0,
                currentStatus: "",
            };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return {
                data: [],
                has_next_page: false,
                next_cursor: 0,
                currentStatus: "",
            };
        }
    };

    const fetchTeamQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        const teamID = window.location.pathname.split("/")[2];
        if (!teamID) {
            console.error("Team ID not found in URL");
            return {
                data: [],
                has_next_page: false,
                next_cursor: 0,
                currentStatus: "",
            };
        }

        try {
            const response = await api.get(
                `${URLS.serverUrl}${API.queriesByTeam}/${teamID}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 500,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return {
                    ...response.data.data,
                    currentStatus: "",
                };
            }
            return {
                data: [],
                has_next_page: false,
                next_cursor: 0,
                currentStatus: "",
            };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return {
                data: [],
                has_next_page: false,
                next_cursor: 0,
                currentStatus: "",
            };
        }
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey:
            listType === InsightListType.Team
                ? [`teamQueries_${tempTeamId ?? teamID}`]
                : ["queries"],
        queryFn:
            listType === InsightListType.Team ? fetchTeamQueries : fetchQueries,
        getNextPageParam: (lastPage) => {
            if (lastPage.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    // TODO: create a search index (decrypt query and title), then can just fetch some tickets (show more button) and search for the rest
    useEffect(() => {
        // Check if we've already fetched 2500 or more tickets
        const totalFetchedTickets = combinedData.length;
        // temporary fix for now (to prevent supabase from crashing to get all tickets all the time)
        if (hasNextPage && !isFetchingNextPage && totalFetchedTickets < 2500) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    // Grab the list of topics for the org
    useEffect(() => {
        let endpoint = URLS.serverUrl + API.getTopics;
        if (teamID) {
            endpoint = `${URLS.serverUrl}${API.getTopics}/team/${teamID}`;
        }
        api.get(endpoint, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: Topic[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                            key: topic.id,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setTopics(newTopics);
                    setLoadingTopicsState(1);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    // Grab the users for the org
    // TODO: only include users in the team?
    useEffect(() => {
        api.post(URLS.serverUrl + API.getAllUsers, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setUsers(res.data.data);
            } else {
                console.log("Call to grab users failed");
            }
        });
    }, [api]);

    // Only showing teams that the member is apart of in the filter
    // TODO: check that this is the format that we want
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    // Reset to dashboard page on reload
    useEffect(() => {
        setOnDashboard(true);
    }, []);

    return (
        <div>
            <Flex direction="column" align="center" justify="center">
                <Box mt="7" height="100%" width="98%">
                    <Flex
                        align="start"
                        direction="column"
                        justify={"start"}
                        style={{ paddingLeft: "20px" }}
                    >
                        <Heading
                            size="5"
                            align="left"
                            className="flex items-center gap-2"
                        >
                            Product Insights
                            {
                                // For Team view
                                (listType === InsightListType.Team &&
                                    teamID && (
                                        <TeamBadges
                                            teams={(
                                                teamsQuery.data ?? []
                                            ).filter(
                                                (team) => team.id === teamID,
                                            )}
                                            defaultIsWorkspace={false}
                                        />
                                    )) ||
                                    // For regular view
                                    (listType === InsightListType.General &&
                                        getBadgeForTeam("General"))
                            }
                        </Heading>
                        <Text mb="12px" size="2">
                            Navigate insights gathered from the issues. Create
                            your own insights or build off of our AI Generated
                            insights. To save an AI generated insight, press the
                            bookmark icon.
                        </Text>
                    </Flex>
                    <div className="flex items-center absolute top-4 right-4 my-4">
                        <CreateInsightPopup
                            userID={userID}
                            queryClient={queryClient}
                            issues={combinedData}
                            teamID={teamID}
                        />
                    </div>
                    {loadingState === 0 && (
                        <Flex maxWidth="85%" style={{ paddingLeft: "20px" }}>
                            <Text>
                                <Skeleton maxWidth="85%">
                                    {[...Array(6)].map((_, index) => (
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        <Text key={index}>{loremIpsum}</Text>
                                    ))}
                                </Skeleton>
                            </Text>
                        </Flex>
                    )}
                    {loadingState === 1 &&
                        loadingTopicsState === 1 &&
                        (onDashboard ? (
                            <div className="flex flex-col gap-2 px-2">
                                <TopInsights
                                    suggestedInsights={aiInsights}
                                    issuesCount={combinedData.length}
                                    userID={userID}
                                    handleSaveAIGeneratedInsight={
                                        handleSaveAIGeneratedInsight
                                    }
                                    updateInsightState={updateInsightState}
                                    topicsMap={topicsMap}
                                    setOnDashboard={setOnDashboard}
                                    generatedIsOpen={generatedIsOpen}
                                    setGeneratedIsOpen={setGeneratedIsOpen}
                                    teamID={teamID}
                                />
                                <SavedInsightsList
                                    savedInsights={Array.from(
                                        insightStates.values(),
                                    )}
                                    userID={userID}
                                    handleSaveAIGeneratedInsight={
                                        handleSaveAIGeneratedInsight
                                    }
                                    topicsMap={topicsMap}
                                    generatedIsOpen={generatedIsOpen}
                                    teamID={teamID}
                                    teamsQuery={teamsQuery}
                                    isGeneralTeam={
                                        listType === InsightListType.General
                                    }
                                />
                            </div>
                        ) : (
                            <div className="flex flex-col gap-2 px-2">
                                <div className="flex justify-start">
                                    <Button
                                        onClick={() => setOnDashboard(true)}
                                        variant="ghost"
                                        className="flex items-center justify-start gap-1 hover:bg-background text-gray-600 hover:text-gray-900 text-[12px] -mb-2 -mt-1"
                                    >
                                        <DoubleArrowLeftIcon />
                                        Back to Saved Insights
                                    </Button>
                                </div>
                                <GenerateInsights
                                    savedInsights={Array.from(
                                        insightStates.values(),
                                    )}
                                    userID={userID}
                                    handleSaveAIGeneratedInsight={
                                        handleSaveAIGeneratedInsight
                                    }
                                    topicsMap={topicsMap}
                                    topics={topics}
                                    users={users}
                                    generatedInsights={generatedInsights}
                                    setGeneratedInsights={setGeneratedInsights}
                                    filters={playgroundFilters}
                                    setFilters={setPlaygroundFilters}
                                    isOpen={playgroundIsOpen}
                                    setIsOpen={setPlaygroundIsOpen}
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                    suggestedIsOpen={suggestedIsOpen}
                                    teamID={teamID}
                                />
                                <SuggestedInsightsList
                                    suggestedInsights={aiInsights}
                                    generatedInsights={generatedInsights}
                                    issuesCount={combinedData.length}
                                    userID={userID}
                                    handleSaveAIGeneratedInsight={
                                        handleSaveAIGeneratedInsight
                                    }
                                    topicsMap={topicsMap}
                                    isOpen={suggestedIsOpen}
                                    setIsOpen={setSuggestedIsOpen}
                                    playgroundIsOpen={playgroundIsOpen}
                                    teamID={teamID}
                                />
                            </div>
                        ))}
                    {loadingState === 2 && (
                        <Callout.Root size="1" variant="outline" color="red">
                            <Callout.Text>
                                Sorry, something's wrong! Please notify us at
                                support@askassembly.app.
                            </Callout.Text>
                        </Callout.Root>
                    )}
                </Box>
            </Flex>
        </div>
    );
};

export default AdminInsightsPage;
