import {
    type Edge,
    type Node,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { Button } from "@/component/shadcn/ui/button";
import { Input } from "@/component/shadcn/ui/input";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    AddWorkflowRequest,
    AddWorkflowStep,
    Label,
    WorkflowValidationError,
} from "@/interfaces/serverData";
import {
    nodeToStepMetadata,
    serializeStepMetadata,
    validateWorkflow,
} from "@/utilities/methods";
import { Box } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WorkflowEditor from "./WorkflowEditor";
import { WorkflowTypeDropdown } from "./WorkflowTypeDropdown";

export const NewWorkflow = () => {
    const api = useApi();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { toast } = useToast();

    const [labels, setLabels] = useState<Label[]>();
    const [workflowName, setWorkflowName] = useState("");
    const [workflowDescription, setWorkflowDescription] = useState("");
    const [workflowType, setWorkflowType] = useState<Label>();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [initialNodes, setInitialNodes] = useState<Node[]>([]);
    const [initialEdges, setInitialEdges] = useState<Edge[]>([]);
    const [validationErrors, setValidationErrors] = useState<
        WorkflowValidationError[]
    >([]);

    const navigateToWorklowPage = async (withState?: string) => {
        if (withState) {
            if (withState === "success") {
                navigate("/preferences/workflows", {
                    state: {
                        showToast: true,
                        toastText: "Created Workflow!",
                    },
                });
            } else {
                navigate("/preferences/workflows", {
                    state: {
                        showToast: true,
                        toastText:
                            "Oops! Something's wrong. Please try again at a later time.",
                    },
                });
            }
        } else {
            navigate("/preferences/workflows");
        }
    };

    const validateWorkflowCallback = useCallback(() => {
        return validateWorkflow(
            workflowName,
            nodes,
            edges,
            setValidationErrors,
        );
    }, [nodes, edges, workflowName]);

    // TODO: turn this into a mutation
    const saveWorkflow = async () => {
        const isValid = validateWorkflowCallback();
        if (!isValid) {
            return; // Prevent saving if validation fails
        }

        // Edge mapping of child node id => parent node id
        const childToParent = new Map<string, string[]>();
        for (const e of edges) {
            const edge = e as unknown as Edge;
            if (childToParent.has(edge.target)) {
                const val: string[] = childToParent.get(edge.target) ?? [];
                val.push(edge.source);
                childToParent.set(edge.target, val);
            } else {
                childToParent.set(edge.target, [edge.source]);
            }
        }

        const steps: AddWorkflowStep[] = [];
        for (let i = 0; i < nodes.length; i++) {
            const node: Node = nodes[i];
            const type = node.type;
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            const nodeData: Record<string, any> = node.data;
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            const nodeMetadata: Map<string, any> = new Map(
                Object.entries(nodeData.metadata),
            );
            const step: AddWorkflowStep = {
                id: node.id,
                name: nodeMetadata.get("name") ?? "",
                type: type === "defaultTrigger" ? "trigger" : (type ?? ""),
                subtype:
                    (type === "trigger" || type === "defaultTrigger"
                        ? nodeMetadata.get("type")
                        : nodeMetadata.get("subtype")) ?? "",
                metadata: serializeStepMetadata(
                    nodeToStepMetadata(type ?? "", nodeMetadata),
                ),
                // TODO: handle when parent ids are more than one element (v shape)
                // TODO: probably store two instances of the same child node in workflow_steps
                parent_id: childToParent.get(node.id)?.[0] || "",
            };
            steps.push(step);
        }

        const requestData: AddWorkflowRequest = {
            name: workflowName,
            description: workflowDescription,
            steps: steps,
        };
        if (workflowType) {
            requestData.type_id = workflowType.id;
        }

        api.post(URLS.serverUrl + API.saveWorkflowNew, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                console.log(
                    `Created workflow ${res.data.data?.id} successfully`,
                );
                queryClient.invalidateQueries({ queryKey: ["workflowsNew"] });
                navigateToWorklowPage("success");
            } else {
                console.log("Failed to create workflow");
                navigateToWorklowPage("error");
            }
        });
    };

    useEffect(() => {
        api.get(`${URLS.serverUrl}${API.getLabels}/WorkflowType`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.data !== "") {
                        setLabels(res.data.data);
                    }
                }
            })
            .catch((res) => {
                console.log("Could not grab workflow type labels");
            });
    }, []);

    // Highlight nodes with errors
    useEffect(() => {
        const errorNodeIds = validationErrors.flatMap(
            (error) => error.nodeIds || [],
        );

        for (const error of validationErrors) {
            toast({
                variant: "destructive",
                title: "Validation Error",
                description: error.message,
            });
        }

        setNodes(
            nodes.map((node) => ({
                ...node,
                data: {
                    ...node.data,
                    errorStyle: errorNodeIds.includes(node.id)
                        ? "border-2 border-red-500 shadow-[0_0_0_2px_rgba(239,68,68,0.2)]"
                        : "",
                },
            })),
        );
    }, [validationErrors, setNodes, toast]);

    return (
        <Box className="h-screen">
            <ReactFlowProvider>
                <Box
                    mt={"3%"}
                    ml={"3%"}
                    mr={"3%"}
                    className="flex flex-col gap-2"
                >
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col">
                                <h2 className="text-2xl font-semibold flex items-center gap-2">
                                    Create Workflow
                                </h2>
                                <p className="text-sm text-gray11">
                                    Drag a block to start building your
                                    workflow.
                                </p>
                            </div>
                            <div className="flex items-center gap-4">
                                <Input
                                    className="text-sm font-medium w-[350px]"
                                    placeholder="New Workflow Name..."
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        setWorkflowName(event.target.value);
                                    }}
                                />

                                <WorkflowTypeDropdown
                                    workflowType={workflowType}
                                    setWorkflowType={setWorkflowType}
                                    labels={labels}
                                />
                            </div>
                            <Input
                                className="text-xs font-medium w-[700px] h-8"
                                placeholder="Description..."
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                    setWorkflowDescription(event.target.value);
                                }}
                            />
                        </div>
                        <div className="flex items-center gap-4">
                            <Button
                                className="bg-gray-200 text-sm"
                                variant="secondary"
                                onClick={() => navigateToWorklowPage()}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="bg-[#5B5BD6] text-sm"
                                onClick={() => saveWorkflow()}
                            >
                                Save Workflow
                            </Button>
                        </div>
                    </div>
                    <WorkflowEditor
                        nodes={nodes}
                        edges={edges}
                        setNodes={setNodes}
                        setEdges={setEdges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        setInitialNodes={setInitialNodes}
                        setInitialEdges={setInitialEdges}
                    />
                </Box>
            </ReactFlowProvider>
        </Box>
    );
};

export default NewWorkflow;
