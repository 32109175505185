import { useState } from "react";
import URLCustomPopup from "../../../component/UrlPopupComponent";

interface IntegrationProps {
    popupOpen: boolean;
    setPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
    index: (integrationType: string, intercomUrl: string) => void; // separate function for intercom, due to addition of URL
}

export const IntercomCardComponent = (props: IntegrationProps) => {
    const [intercomURL, setIntercomURL] = useState("");

    const [invalidUrl, setInvalidUrl] = useState<boolean>(false);

    const handleIntercomURLChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setIntercomURL((prev) => event.target.value);
        setInvalidUrl(
            !event.target.value.startsWith(
                "https://app.intercom.com/a/inbox/",
            ) && event.target.value.length !== 0,
        );
    };

    return (
        <URLCustomPopup
            open={props.popupOpen}
            text="Enter Intercom URL"
            suggestText="https://app.intercom.com/a/inbox/<app-id>"
            onClickConfirm={() => {
                props.index("intercom", intercomURL);
            }}
            onCancel={() => {
                props.setPopupOpen(false);
            }}
            jiraUrl={intercomURL}
            setJiraUrl={handleIntercomURLChange}
            invalidUrl={invalidUrl}
        />
    );
};
