import { Button } from "@/component/shadcn/ui/button";
import { cn } from "@/lib/utils";

const SaveButton = ({
    children,
    disabled,
    onClick,
    asChild,
    className,
}: {
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void | Promise<void>;
    asChild?: boolean;
    className?: string;
}) => {
    return (
        <Button
            className={cn(
                "bg-[#6d78d5] hover:bg-[#6d78d5]/90 h-6 shadow-none",
                className,
            )}
            size="sm"
            variant="default"
            disabled={disabled}
            onClick={onClick}
            asChild={asChild}
        >
            {children}
        </Button>
    );
};

export default SaveButton;
