import { API } from "@/constant";
import { URLS } from "@/constant";
import type { GetUserResponse } from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";

const useUser = () => {
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });
    return usersQuery;
};

export default useUser;
