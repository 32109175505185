import { InsightListType } from "@/Insights/useInsightsData";
import { Filter } from "@/IssuesTable/Filter";
import FilterDropdown from "@/IssuesTable/FilterDropdown";
import type { FilterOption } from "@/IssuesTable/constants";
import { FilterType } from "@/IssuesTable/constants";
import { Button } from "@/component/shadcn/ui/button";
import { Calendar } from "@/component/shadcn/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import { API } from "@/constant";
import { URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    ExportTicketsResponse,
    ResolutionTime,
    ResponseTime,
    SLABreach,
    StartStopPair,
    Teams,
    TickerTimerResponse,
    TimerAnalytics,
} from "@/interfaces/serverData";
import TimezoneDropdown from "@/reusable_components/timestampDropdown";
import { getFilterOption } from "@/utilities/methods";
import { CalendarIcon } from "@radix-ui/react-icons";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { useCallback } from "react";
import { useState } from "react";
import type { DateRange } from "react-day-picker";
import type { ChartData, GroupedDataItem } from "../AdminAnalyticsPage";
import type { SLABreachCount } from "./SLACountPage";

interface AnalyticsFiltersProps {
    range: DateRange | undefined;
    setRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
    playgroundFilters: Map<string, Set<string>>;
    setPlaygroundFilters: React.Dispatch<
        React.SetStateAction<Map<string, Set<string>>>
    >;
    listType: InsightListType;
    teamID: string;
    setTeamID: React.Dispatch<React.SetStateAction<string>>;
    teamsQueryData: Teams[] | undefined;
    handleFilterSelect: (
        type: string,
        option: { label: string; value: string; key: string; color: string },
    ) => () => void;
    activeTab: string;
    interactionsChartData: GroupedDataItem[];
    responseChartData: ChartData[];
    responseTimeQueryData: ResponseTime[];
    resolutionTimePerDay: ChartData[];
    resolutionTimeQueryData: ResolutionTime[];
    slaTimePerDay: ChartData[];
    slaTimeQueryData: SLABreach[];
    firstResponseTimePerDay: ChartData[];
    firstResponseTimeQueryData: ResponseTime[];
    slaBreachCountData: SLABreachCount[];
    ticketTimerData: TimerAnalytics[];
}

const AnalyticsFilters = ({
    range,
    setRange,
    playgroundFilters,
    setPlaygroundFilters,
    listType,
    teamID,
    setTeamID,
    teamsQueryData,
    handleFilterSelect,
    activeTab,
    interactionsChartData,
    responseChartData,
    responseTimeQueryData,
    resolutionTimePerDay,
    resolutionTimeQueryData,
    slaTimePerDay,
    slaTimeQueryData,
    firstResponseTimePerDay,
    firstResponseTimeQueryData,
    slaBreachCountData,
    ticketTimerData,
}: AnalyticsFiltersProps) => {
    const filterOptions: FilterOption[] = [
        getFilterOption("Source", FilterType.MenuThenSelect, true),
    ];
    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const handleMenuClick = useCallback(
        (menu: string) => () => {
            // Reset activeMenu when dropdown is closed
            setActiveMenu(menu);
        },
        [],
    );

    const api = useApi();

    function convertDateToString(
        dateString: string | undefined,
        timezone: string,
    ): string {
        if (dateString) {
            const date = new Date(dateString);
            return `${date.toLocaleDateString("en-US", {
                timeZone: timezone,
            })} ${date.toLocaleTimeString("en-US", {
                timeZone: timezone,
            })}`;
        }
        return "";
    }

    function convertToRawCSV(
        data: ExportTicketsResponse,
        timezone: string,
    ): string[][] {
        const headers = [
            "Ticket Number",
            "Ticket Status",
            "Ticket Title",
            "URL",
            "Source",
            "Topics",
            "Category",
            "Assignee",
            "Created",
            "First Opened",
            "Closed At",
        ];
        const rows = data.tickets.map((item) => {
            return [
                `${item.ticket_identifier}-${item.ticket_number}`,
                item.ticket_status,
                `"${item.title}"`,
                `https://dashboard.askassembly.app/issue/${item.ticket_identifier}-${item.ticket_number}`,
                item.source,
                `"${item.topic.join(", ")}"`,
                item.bot_category,
                item.user,
                convertDateToString(item.time_created, timezone),
                convertDateToString(item.time_responded, timezone),
                convertDateToString(item.time_closed, timezone),
            ];
        });
        return [headers, ...(rows as string[][])];
    }

    function downloadRawCSV(data: ExportTicketsResponse, timezone: string) {
        const csvData = convertToRawCSV(data, timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "export_tickets.csv");
    }

    const exportMutation = useMutation({
        mutationFn: async () => {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );

            // Convert Set to comma-separated string
            const sourceString = sourceFilter.join("&");
            const res = await api.get(URLS.serverUrl + API.exportTickets, {
                params: {
                    teamID: teamID,
                    sources: sourceString,
                    start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                    end: range?.to?.toJSON() ?? new Date().toJSON(),
                },
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (res.status === 200) {
                return res.data.data;
            }
            throw new Error("Error fetching data");
        },
    });

    const exportTicketTimerMutation = useMutation({
        mutationFn: async () => {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );

            const res = await api.post(
                URLS.serverUrl + API.timerAnalyticsExport,
                {
                    teamID: teamID,
                    start: range?.from?.toISOString(),
                    end: range?.to?.toISOString(),
                    sources: sourceFilter,
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            return res.data.data;
        },
    });

    const handleExport = async (timezone: string) => {
        try {
            const data = await exportMutation.mutateAsync();
            downloadRawCSV(data, timezone);
        } catch (error) {
            console.error("Error exporting tickets:", error);
        }
    };

    const convertChatToCSV = (data: GroupedDataItem[], timeZone: string) => {
        const headers = ["Date", "Count"];
        const rows = data.map((item) => [
            new Date(item.date).toLocaleDateString("en-US", {
                timeZone,
            }),
            item.count,
        ]);
        return [headers, ...rows];
    };

    const downloadCSVForChat = (timezone: string) => {
        const csvData = convertChatToCSV(interactionsChartData, timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "interactions_data.csv");
    };

    const convertResponseToCSV = (data: ChartData[], timeZone: string) => {
        const headers = ["Date", "Duration (minutes)"];
        const rows = data.map((item) => [
            new Date(item.date).toLocaleDateString("en-US", {
                timeZone,
            }),
            (item.duration / 60).toFixed(2), // Convert duration from seconds to minutes
        ]);
        return [headers, ...rows];
    };

    const downloadCSVForResponse = (timezone: string) => {
        const csvData = convertResponseToCSV(responseChartData, timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "aggregate_response_time.csv");
    };

    const convertRawResponseToCSV = (timeZone: string) => {
        const headers = [
            "Ticket Number",
            "URL",
            "Assignee",
            "Start",
            "End",
            "Duration (in minutes)",
            "Source",
            "Category",
            "Topics",
        ];
        const rows = responseTimeQueryData.map((item) => {
            const startDate = new Date(item.start);
            const endDate = new Date(item.end);
            return [
                item.ticket_number,
                item.url,
                item.assignee,
                `${startDate.toLocaleDateString("en-US", { timeZone })} ${startDate.toLocaleTimeString("en-US", { timeZone })}`,
                `${endDate.toLocaleDateString("en-US", { timeZone })} ${endDate.toLocaleTimeString("en-US", { timeZone })}`,
                item.duration,
                item.source,
                item.bot_category,
                `"${item.topics}"`,
            ];
        });
        return [headers, ...rows];
    };

    const convertResolutionToCSV = (data: ChartData[], timeZone: string) => {
        const headers = ["Date", "Duration (minutes)"];
        const rows = data.map((item) => [
            new Date(item.date).toLocaleDateString("en-US", {
                timeZone,
            }),
            (item.duration / 60).toFixed(2), // Convert duration from seconds to minutes
        ]);
        return [headers, ...rows];
    };

    const convertSLAToCSV = (data: ChartData[], timeZone: string) => {
        const headers = ["Date", "Duration (minutes)"];
        const rows = data.map((item) => [
            new Date(item.date).toLocaleDateString("en-US", {
                timeZone,
            }),
            (item.duration / 60).toFixed(2), // Convert duration from seconds to minutes
        ]);
        return [headers, ...rows];
    };

    // Function to handle raw CSV download
    const downloadRawResponseCSV = (timezone: string) => {
        const csvData = convertRawResponseToCSV(timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "raw_response_time.csv");
    };

    const downloadCSVForResolution = (timezone: string) => {
        const csvData = convertResolutionToCSV(resolutionTimePerDay, timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "aggregate_resolution_time.csv");
    };

    const convertRawResolutionToCSV = (timeZone: string) => {
        const headers = [
            "Ticket Number",
            "URL",
            "Assignee",
            "Start",
            "End",
            "Duration (in minutes)",
            "Source",
            "Category",
            "Topics",
        ];
        const rows = resolutionTimeQueryData.map((item) => {
            const startDate = new Date(item.start);
            const endDate = new Date(item.end);
            return [
                item.ticket_number,
                item.url,
                item.assignee,
                `${startDate.toLocaleDateString("en-US", { timeZone })} ${startDate.toLocaleTimeString("en-US", { timeZone })}`,
                `${endDate.toLocaleDateString("en-US", { timeZone })} ${endDate.toLocaleTimeString("en-US", { timeZone })}`,
                item.duration,
                item.source,
                item.bot_category,
                `"${item.topics}"`,
            ];
        });
        return [headers, ...rows];
    };

    const downloadRawResolutionCSV = (timezone: string) => {
        const csvData = convertRawResolutionToCSV(timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "raw_resolution_time.csv");
    };

    const downloadCSVForSLA = (timezone: string) => {
        const csvData = convertSLAToCSV(slaTimePerDay, timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "aggregate_sla_time.csv");
    };

    const convertRawSLAToCSV = (timeZone: string) => {
        const headers = [
            "Ticket Number",
            "URL",
            "Assignee",
            "Start",
            "End",
            "Duration (in minutes)",
            "Source",
            "Category",
            "Topics",
        ];
        const rows = slaTimeQueryData.map((item) => {
            const startDate = new Date(item.start);
            const endDate = new Date(item.end);
            return [
                item.ticket_number,
                item.url,
                item.assignee,
                `${startDate.toLocaleDateString("en-US", { timeZone })} ${startDate.toLocaleTimeString("en-US", { timeZone })}`,
                `${endDate.toLocaleDateString("en-US", { timeZone })} ${endDate.toLocaleTimeString("en-US", { timeZone })}`,
                item.duration,
                item.source,
                item.bot_category,
                `"${item.topics}"`,
            ];
        });
        return [headers, ...rows];
    };

    const downloadRawSLACSV = (timezone: string) => {
        const csvData = convertRawSLAToCSV(timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "raw_sla_time.csv");
    };

    const convertFirstResponseToCSV = (data: ChartData[], timeZone: string) => {
        const headers = ["Date", "Duration (minutes)"];
        const rows = data?.map((item) => [
            new Date(item.date).toLocaleDateString("en-US", {
                timeZone,
            }),
            (item.duration / 60).toFixed(2), // Convert duration from seconds to minutes
        ]);
        return [headers, ...rows];
    };

    const convertRawFirstResponseToCSV = (timeZone: string) => {
        const headers = [
            "Ticket Number",
            "URL",
            "Assignee",
            "Start",
            "End",
            "Duration (in minutes)",
            "Source",
            "Category",
            "Topics",
        ];
        const rows = firstResponseTimeQueryData?.map((item) => {
            const startDate = new Date(item.start);
            const endDate = new Date(item.end);
            return [
                item.ticket_number,
                item.url,
                item.assignee,
                `${startDate.toLocaleDateString("en-US", { timeZone })} ${startDate.toLocaleTimeString("en-US", { timeZone })}`,
                `${endDate.toLocaleDateString("en-US", { timeZone })} ${endDate.toLocaleTimeString("en-US", { timeZone })}`,
                item.duration,
                item.source,
                item.bot_category,
                `"${item.topics}"`,
            ];
        });
        return [headers, ...rows];
    };

    const downloadCSVForFirstResponse = (timezone: string) => {
        const csvData = convertFirstResponseToCSV(
            firstResponseTimePerDay,
            timezone,
        );
        const csvContent = csvData?.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "aggregate_first_response_time.csv");
    };

    const downloadFirstResponseRawCSV = (timezone: string) => {
        const csvData = convertRawFirstResponseToCSV(timezone);
        const csvContent = csvData?.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "raw_first_response_time.csv");
    };

    const convertSLABreachCountToCSV = (timeZone: string) => {
        const headers = ["Date", "Count"];
        const groupedData: {
            [key: string]: { count: number };
        } = {};

        // Iterate over SLA breach count data
        // biome-ignore lint/complexity/noForEach: <explanation>
        slaBreachCountData.forEach((item) => {
            // Extract the date from the `start` field
            const date = new Date(item.date).toISOString().split("T")[0];

            // Initialize if the date doesn't exist yet
            if (!groupedData[date]) {
                groupedData[date] = { count: 0 };
            }

            // Sum the durations and count occurrences for the given day
            groupedData[date].count += 1;
        });

        // flatten the object to an array
        const rows = Object.entries(groupedData).map(([date, { count }]) => [
            new Date(date).toLocaleDateString("en-US", {
                timeZone,
            }),
            count,
        ]);
        return [headers, ...(rows as string[][])];
    };

    const convertRawSLABreachCountToCSV = (timeZone: string) => {
        const headers = [
            "Ticket Number",
            "URL",
            "Assignee",
            "Source",
            "Category",
            "Topics",
        ];
        const rows = slaBreachCountData.map((item) => {
            return [
                item.ticket_number,
                item.url,
                item.assignee,
                item.source,
                item.bot_category,
                `"${item.topics}"`,
            ];
        });
        return [headers, ...rows];
    };

    const downloadSLABreachCountCSV = (timezone: string) => {
        const csvData = convertSLABreachCountToCSV(timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "aggregate_sla_breach_count.csv");
    };

    const downloadRawSLABreachCountCSV = (timezone: string) => {
        const csvData = convertRawSLABreachCountToCSV(timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "raw_sla_breach_count.csv");
    };

    const convertTicketTimerToCSV = () => {
        const headers = [
            "Ticket Number",
            "Duration (in hours)",
            "Original Timestamp",
            "Assignee User ID",
            "Topics",
        ];
        const rows = ticketTimerData?.map((item) => [
            item.ticket_number,
            item.total_duration,
            item.original_timestamp,
            item.assignee_user_id,
            item.topic?.join(" / "),
        ]);
        if (rows) {
            return [headers, ...rows];
        }
        return [];
    };

    function downloadTicketTimerCSV() {
        const csvData = convertTicketTimerToCSV();
        const csvContent = csvData?.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        const date = new Date().toISOString().split("T")[0];
        saveAs(blob, `timer_export_${date}.csv`);
    }

    const convertTicketTimerToCSVRaw = (
        timeZone: string,
        rawData: StartStopPair[],
    ) => {
        const headers = [
            "Ticket Number",
            "Source",
            "Start Time",
            "End Time",
            "Duration (minutes)",
            "Start User",
            "End User",
            "Original Timestamp",
            "Assignee",
            "Topics",
        ];

        const rows = rawData?.map((item) => [
            item.ticket_number,
            item.source,
            `${new Date(item.start_time).toLocaleDateString("en-US", { timeZone })} ${new Date(item.start_time).toLocaleTimeString("en-US", { timeZone })}`,
            Number.isNaN(new Date(item.end_time).getTime())
                ? "Timer Still Running"
                : `${new Date(item.end_time).toLocaleDateString("en-US", { timeZone })} ${new Date(item.end_time).toLocaleTimeString("en-US", { timeZone })}`,
            Number.isNaN(new Date(item.end_time).getTime()) ? 0 : item.duration,
            item.start_user,
            item.end_user,
            `${new Date(item.original_timestamp).toLocaleDateString("en-US", { timeZone })} ${new Date(item.original_timestamp).toLocaleTimeString("en-US", { timeZone })}`,
            item.assignee_user_id,
            item.topic?.join(" / "),
        ]);
        return [headers, ...rows];
    };

    function downloadRawTicketTimerCSV(
        timezone: string,
        rawData: TickerTimerResponse,
    ) {
        const csvData = convertTicketTimerToCSVRaw(timezone, rawData.data);
        const csvContent = csvData?.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        const date = new Date().toISOString().split("T")[0];
        saveAs(blob, `timer_raw_export_${date}.csv`);
    }

    const handleExportTicketTimer = async (timezone: string) => {
        try {
            downloadTicketTimerCSV();
            const rawData: TickerTimerResponse =
                await exportTicketTimerMutation.mutateAsync();
            downloadRawTicketTimerCSV(timezone, rawData);
        } catch (error) {
            console.error("Error exporting tickets:", error);
        }
    };

    const handleTimezoneSelect = (timezone: string) => {
        if (activeTab === "chat") {
            downloadCSVForChat(timezone);
            handleExport(timezone);
        } else if (activeTab === "response") {
            downloadCSVForResponse(timezone);
            downloadRawResponseCSV(timezone);
        } else if (activeTab === "resolution") {
            downloadCSVForResolution(timezone);
            downloadRawResolutionCSV(timezone);
        } else if (activeTab === "sla") {
            downloadCSVForSLA(timezone);
            downloadRawSLACSV(timezone);
        } else if (activeTab === "firstResponse") {
            downloadCSVForFirstResponse(timezone);
            downloadFirstResponseRawCSV(timezone);
        } else if (activeTab === "slaCount") {
            downloadSLABreachCountCSV(timezone);
            downloadRawSLABreachCountCSV(timezone);
        } else if (activeTab === "timer") {
            handleExportTicketTimer(timezone);
        }
    };

    return (
        <div className="flex flex-row items-center gap-2 mt-2">
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        className="flex items-center h-8 gap-2 text-muted-foreground text-xs border border-solid border-input"
                    >
                        <CalendarIcon className="h-3 w-3" />
                        {range?.from ? (
                            format(range.from, "PP")
                        ) : (
                            <span>Oldest</span>
                        )}
                        <div>to</div>
                        {range?.to ? (
                            format(range.to, "PP")
                        ) : (
                            <span>Latest</span>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                    <Calendar
                        mode="range"
                        selected={range}
                        onSelect={setRange}
                    />
                </PopoverContent>
            </Popover>

            {Array.from(playgroundFilters.entries()).map(([type, values]) => (
                <div key={type}>
                    <Filter
                        type={type}
                        values={values}
                        filters={playgroundFilters}
                        setFilters={setPlaygroundFilters}
                        topics={[]}
                        users={[]}
                        customerGroups={[]}
                        isSavedViewFilter={false}
                        categories={[]}
                        teams={[]}
                        channels={new Map()}
                        filterOptions={filterOptions}
                    />
                </div>
            ))}
            <FilterDropdown
                className="h-8 pt-0 min-w-[60px] border border-solid border-input"
                filters={playgroundFilters}
                filterOptions={filterOptions}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                handleItemSelect={handleFilterSelect}
                handleMenuClick={handleMenuClick}
                topics={[]}
                users={[]}
                customerGroups={[]}
                teams={[]}
                categories={[]}
                channels={new Map()}
            />

            {listType === InsightListType.General && (
                <Select
                    value={teamID}
                    onValueChange={(value) => setTeamID(value)}
                >
                    <SelectTrigger className="text-xs py-1 w-[100px]">
                        <SelectValue placeholder="Select Team" />
                    </SelectTrigger>
                    <SelectContent className="w-[250px]">
                        {teamsQueryData?.map((team) => (
                            <SelectItem
                                key={team.id}
                                value={team.id}
                                className="text-xs font-normal"
                            >
                                {team.team_name}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            )}
            <TimezoneDropdown
                onTimezoneSelect={(timezone) => {
                    handleTimezoneSelect(timezone);
                }}
                button={
                    <Button
                        className="bg-shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-1.5 justify-start data-[state=open]:bg-muted shadow-sm"
                        size="sm"
                        variant="outline"
                    >
                        Download CSV
                    </Button>
                }
            />
        </div>
    );
};

export default AnalyticsFilters;
