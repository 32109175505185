import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import { Checkbox } from "@/component/shadcn/ui/checkbox";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuPortal,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";
import {
    Table as ShadcnTable,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/component/shadcn/ui/table";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { ContactsAPI } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Account, GetUserResponse, Teams } from "@/interfaces/serverData";
import AccountPopup from "@/pages/Admin/CRM/AccountPopup";
import type { AccountsListType } from "@/pages/Admin/CRM/Accounts";
import { convertAccountLabelsToMap, getNavLink } from "@/utilities/methods";
import {
    Cross2Icon,
    OpenInNewWindowIcon,
    Pencil1Icon,
    TrashIcon,
} from "@radix-ui/react-icons";
import type { UseQueryResult } from "@tanstack/react-query";
import {
    type ColumnDef,
    type ColumnFiltersState,
    type Row,
    type SortingState,
    type VisibilityState,
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { MergeIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { AccountsDataTablePagination } from "./accounts-data-table-pagination";
import type { Table } from "@tanstack/react-table";
import { DataTableToolbarDebounce } from "./data-table-toolbar-debounce";
import { DataTableViewOptions } from "./data-table-view-options";

interface AccountsDataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    handleRowClick: (row: Row<TData>) => void;
    pageIndex: number;
    setPageIndex: React.Dispatch<React.SetStateAction<number>>;
    pageSize: number;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    updateData: () => Promise<void>;
    teamsQuery: UseQueryResult<Teams[], Error>;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    userID: string;
    orgID: string;
    listType: AccountsListType;
    input: string;
    setInput: (input: string) => void;
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
    fetchNextPage: () => Promise<any>;
}

// TODO: need to convert other rows to using row id. This is currently only used by company table.
export function AccountsDataTable<TData extends { id: string }, TValue>({
    columns,
    data,
    handleRowClick,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    updateData,
    teamsQuery,
    usersQuery,
    userID,
    orgID,
    listType,
    input,
    setInput,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
}: AccountsDataTableProps<TData, TValue>) {
    const teamID = window.location.pathname.split("/")[2] || "";
    const api = useApi();
    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>(
        {},
    );
    const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState<
        number | null
    >(null);
    const [selectedAccounts, setSelectedAccounts] = useState<Set<Account>>(
        new Set(),
    );
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
        {},
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<SortingState>([]);

    const [contextMenuRow, setContextMenuRow] = useState<
        Row<Account> | undefined
    >();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const handleRowRightClick = (row: Row<TData>) => {
        setContextMenuRow(row as unknown as Row<Account>);
    };

    // Memoize so that a new reference is only created when data changes
    const memoizedData = useMemo(() => data ?? [], [data]);
    const memoizedColumns = useMemo(() => columns ?? [], [columns]);
    const { toast } = useToast();

    const table = useReactTable({
        data: memoizedData,
        columns: memoizedColumns,
        state: {
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
            pagination: {
                pageIndex,
                pageSize,
            },
        },
        enableColumnResizing: true,
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        pageCount: Math.ceil(memoizedData.length / pageSize),
        initialState: {
            pagination: {
                pageSize: pageSize,
                pageIndex: pageIndex,
            },
        },
    });

    const handleCheckboxClickWithShift = (
        event: React.MouseEvent,
        rowIndex: number,
    ) => {
        const rows = table.getRowModel().rows;
        const row = rows[rowIndex];
        const checked = row?.getIsSelected();

        // Handle shift + click
        if (event.shiftKey && lastSelectedRowIndex != null && row?.id) {
            // Ensure the range is always in the correct order (start from the smaller index)
            const start = Math.min(lastSelectedRowIndex, rowIndex);
            const end = Math.max(lastSelectedRowIndex, rowIndex);

            const newSelection = { ...rowSelection };
            const newSelectionSet = new Set(selectedAccounts);
            for (let i = start; i <= end; i++) {
                const currRow = rows[i];
                if (currRow?.id) {
                    // Toggle all the rows based on whether the selected row is originally checked or not
                    newSelection[currRow?.id] = !checked;
                    if (checked) {
                        newSelectionSet.delete(
                            currRow.original as unknown as Account,
                        ); // Unselect if the row was checked
                    } else {
                        newSelectionSet.add(
                            currRow.original as unknown as Account,
                        ); // Add to selection if unchecked
                    }
                }
            }
            setRowSelection(newSelection);
        } else if (row?.id) {
            // If shift is not held, toggle the selection for just this row
            setRowSelection((prev) => ({
                ...prev,
                [row?.id]: !prev[row?.id],
            }));
            setLastSelectedRowIndex(null);
            const newSelectionSet = new Set(selectedAccounts);
            if (checked) {
                newSelectionSet.delete(row.original as unknown as Account); // Unselect the row
            } else {
                newSelectionSet.add(row.original as unknown as Account); // Select the row
            }
        }
        setLastSelectedRowIndex(rowIndex);
    };

    const handleOpenInNewTabRow = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        row: Row<Account> | undefined,
    ) => {
        if (row) {
            handleOpenInNewTab(event, row.original);
        }
    };

    const handleOpenInNewTab = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        account: Account,
    ) => {
        event.stopPropagation();
        if (account) {
            window.open(getNavLink(account, listType, teamID), "_blank");
        }
    };

    const handleDeleteRow = (row: Row<Account> | undefined) => {
        if (!row?.original.id) {
            return;
        }
        handleDelete(row.original);
    };

    const handleDelete = (account: Account | undefined) => {
        if (account?.account_type === "Customer") {
            api.delete(`${ContactsAPI.deleteCustomer.url}/${account?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        updateData();
                        table.toggleAllRowsSelected(false);
                        toast({
                            title: "Deleted Customer!",
                            variant: "default",
                        });
                    } else {
                    }
                })
                .catch((err) => {
                    console.log(err);
                    toast({
                        title: "Failed to delete customer",
                        variant: "destructive",
                    });
                });
        } else {
            api.delete(`${ContactsAPI.deleteCompany.url}/${account?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        updateData();
                        table.toggleAllRowsSelected(false);
                        toast({
                            title: "Deleted Company!",
                            variant: "default",
                        });
                    } else {
                    }
                })
                .catch((err) => {
                    console.log(err);
                    toast({
                        title: "Failed to delete company",
                        variant: "destructive",
                    });
                });
        }
    };

    // TODO: add a backend bulk endpoint for delete
    const handleBulkDelete = () => {
        for (const account of Array.from(selectedAccounts)) {
            if (account?.account_type === "Customer") {
                api.delete(`${ContactsAPI.deleteCustomer.url}/${account?.id}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            updateData();
                            table.toggleAllRowsSelected(false);
                        } else {
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        toast({
                            title: "Failed to delete account",
                            variant: "destructive",
                        });
                    });
            } else {
                api.delete(`${ContactsAPI.deleteCompany.url}/${account?.id}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            updateData();
                            table.toggleAllRowsSelected(false);
                        } else {
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        toast({
                            title: "Failed to delete account",
                            variant: "destructive",
                        });
                    });
            }
        }
        toast({
            title: "Bulk Deleted Accounts!",
            variant: "default",
        });
    };

    const handleMergeCustomers = () => {
        if (selectedAccounts.size <= 1) {
            return;
        }
        const requestData = {
            customer_ids: Array.from(selectedAccounts).map(
                (account) => account.id,
            ),
        };
        api.post(ContactsAPI.mergeCustomers.url, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    updateData();
                    table.toggleAllRowsSelected(false);
                    toast({
                        title: "Merged Customers!",
                        variant: "default",
                    });
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
                toast({
                    title: "Failed to merge customers",
                    variant: "destructive",
                });
            });
    };

    useEffect(() => {
        const newSelectedAccounts = new Set<Account>();

        // biome-ignore lint/complexity/noForEach: <explanation>
        table.getRowModel().rows.forEach((row) => {
            if (row.getIsSelected()) {
                newSelectedAccounts.add(row.original as unknown as Account);
            }
        });

        // Only update if the selection has changed
        if (
            selectedAccounts.size !== newSelectedAccounts.size ||
            ![...selectedAccounts].every(
                (account, index) => [...newSelectedAccounts][index] === account,
            )
        ) {
            setSelectedAccounts(newSelectedAccounts);
        }
    }, [rowSelection, selectedAccounts, table]);

    const account =
        selectedAccounts.size === 1
            ? Array.from(selectedAccounts)[0]
            : contextMenuRow?.original;

    // Add this effect to check when we're near the end of the data
    useEffect(() => {
        const currentPage = table.getState().pagination.pageIndex;
        const totalPages = table.getPageCount();
        
        // If we're within 1 page of the end and there's more data to fetch
        if (totalPages - currentPage <= 1 && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [table.getRowModel().rows.length, table.getState().pagination.pageIndex, table.getState().pagination.pageSize, hasNextPage, isFetchingNextPage, fetchNextPage]);

    // Add the filtering for each column
    return (
        <div className="flex flex-col space-y-4 mt-5 w-full">
            <DataTableViewOptions table={table} />
            <ContextMenu>
                <ContextMenuTrigger className={`[data-state='open']`}>
                    <div className="rounded-md border">
                        <ShadcnTable>
                            <TableHeader>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id} className="bg-[#fffdf9]">
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <TableHead
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.column.getSize(),
                                                    }}
                                                    className={`px-0 text-xs h-10 bg-[#fffdf9] text-gray-600 font-medium ${header?.id === "select" ? "" : "border-r last:border-r-0 "}`}
                                                >
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                              header.column
                                                                  .columnDef
                                                                  .header,
                                                              header.getContext(),
                                                          )}
                                                </TableHead>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableHeader>
                            <TableBody>
                                {table.getRowModel().rows?.length ? (
                                    table
                                        .getRowModel()
                                        .rows.map((row, rowIndex) => {
                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    data-state={
                                                        row.getIsSelected() &&
                                                        "selected"
                                                    }
                                                    className={`cursor-pointer hover:bg-gray-100 ${row.getIsSelected() ? "bg-iris3 hover:bg-iris3" : ""}`}
                                                    onContextMenu={() =>
                                                        handleRowRightClick(row)
                                                    }
                                                    onClick={(event) => {
                                                        handleRowClick(row);
                                                    }}
                                                >
                                                    {/* Checkbox Column */}
                                                    <TableCell  className={`p-0 h-10 border-b relative `}>
                                                        <Checkbox
                                                            className={`${
                                                                row.getIsSelected()
                                                                    ? "opacity-100"
                                                                    : "opacity-0"
                                                            } mx-auto block hover:opacity-100 hover:shadow-[0_0_10px_4px] hover:shadow-iris5 data-[state=checked]:bg-[#5e6ad2] data-[state=checked]:text-[#eceefb] outline-1 outline hover:outline-iris10 outline-[#eceefb] cursor-pointer`}
                                                            checked={row.getIsSelected()}
                                                            onCheckedChange={row.getToggleSelectedHandler()}
                                                            onClick={(
                                                                event,
                                                            ) => {
                                                                event.stopPropagation();
                                                                handleCheckboxClickWithShift(
                                                                    event,
                                                                    rowIndex,
                                                                );
                                                            }}
                                                        />
                                                    </TableCell>

                                                    {/* Other columns */}
                                                    {row
                                                        .getVisibleCells()
                                                        .map((cell) => {
                                                            if (
                                                                cell.column
                                                                    .id !==
                                                                "select"
                                                            ) {
                                                                return (
                                                                    <TableCell
                                                                        key={
                                                                            cell.id
                                                                        }
                                                                        className={`p-0 h-10 border-r last:border-r-0 focus-within:outline focus-within:outline-2 focus-within:outline-[#5e6ad2] focus-within:rounded focus-within:z-20 border-b relative`}
                                                                        style={{
                                                                            width: cell.column.getSize(),
                                                                        }}
                                                                    >
                                                                        {flexRender(
                                                                            cell
                                                                                .column
                                                                                .columnDef
                                                                                .cell,
                                                                            cell.getContext(),
                                                                        )}
                                                                    </TableCell>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                </TableRow>
                                            );
                                        })
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={columns?.length}
                                            className="h-24 text-center"
                                        >
                                            No results.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </ShadcnTable>
                    </div>
                </ContextMenuTrigger>
                <ContextMenuPortal forceMount>
                    <ContextMenuContent className="w-60">
                        {selectedAccounts.size <= 1 && (
                            <ContextMenuItem
                                inset
                                className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                onClick={(event) => {
                                    if (selectedAccounts.size === 0) {
                                        handleOpenInNewTabRow(
                                            event,
                                            contextMenuRow,
                                        );
                                    } else if (selectedAccounts.size === 1) {
                                        handleOpenInNewTab(
                                            event,
                                            Array.from(selectedAccounts)[0],
                                        );
                                    }
                                }}
                            >
                                <OpenInNewWindowIcon className="w-3.5 h-3.5" />
                                Open in New Tab
                            </ContextMenuItem>
                        )}

                        {selectedAccounts.size <= 1 && (
                            <AccountPopup
                                triggerElement={
                                    <ContextMenuItem
                                        inset
                                        onSelect={(e) => e.preventDefault()}
                                        className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                    >
                                        <Pencil1Icon className="w-3.5 h-3.5" />
                                        Edit
                                    </ContextMenuItem>
                                }
                                editing={true}
                                type={
                                    account?.account_type === "Company"
                                        ? "Company"
                                        : "Customer"
                                }
                                editingObject={account}
                                labels={convertAccountLabelsToMap(
                                    account?.labels ?? [],
                                    account?.account_type ?? "Customer",
                                )}
                                updateData={updateData}
                                teamsQuery={teamsQuery}
                                usersQuery={usersQuery}
                                userID={userID}
                                orgID={orgID}
                                listType={listType}
                                contacts={account?.associated_customers}
                                company={account?.company}
                            />
                        )}

                        {/* Delete Customer */}
                        <AlertDialog
                            open={deleteDialogOpen}
                            onOpenChange={setDeleteDialogOpen}
                        >
                            <AlertDialogTrigger asChild>
                                <ContextMenuItem
                                    inset
                                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                    onSelect={(e) => e.preventDefault()}
                                >
                                    <TrashIcon className="w-3.5 h-3.5" />
                                    {selectedAccounts.size > 1
                                        ? "Bulk Delete"
                                        : "Delete"}
                                </ContextMenuItem>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <div>
                                    <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                        <AlertDialogTitle>
                                            Are you absolutely sure?
                                        </AlertDialogTitle>
                                        <AlertDialogDescription>
                                            {`This action cannot be undone. This will permanently delete account(s): ${selectedAccounts.size > 0 ? Array.from(selectedAccounts).map((a) => ` ${a.name}`) : contextMenuRow?.original.name}`}
                                        </AlertDialogDescription>
                                    </AlertDialogHeader>
                                </div>
                                <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                    <AlertDialogCancel
                                        onClick={() =>
                                            setDeleteDialogOpen(false)
                                        }
                                    >
                                        Cancel
                                    </AlertDialogCancel>
                                    <AlertDialogAction
                                        onClick={() => {
                                            if (selectedAccounts.size === 0) {
                                                handleDeleteRow(contextMenuRow);
                                            } else {
                                                handleBulkDelete();
                                            }
                                        }}
                                    >
                                        Delete
                                    </AlertDialogAction>
                                    <AlertDialogCancel
                                        className="shadow-none absolute top-0 right-2 px-2 py-2 "
                                        onClick={() =>
                                            setDeleteDialogOpen(false)
                                        }
                                    >
                                        <Cross2Icon />
                                    </AlertDialogCancel>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>

                        {/* Conditionally render the ContextMenuSub only if there are selected accounts */}
                        {selectedAccounts.size !== 0 &&
                            Array.from(selectedAccounts).every(
                                (account) =>
                                    account.account_type === "Customer",
                            ) && (
                                <ContextMenuItem
                                    inset
                                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                    onClick={(event) => {
                                        handleMergeCustomers();
                                    }}
                                >
                                    <MergeIcon className="w-3.5 h-3.5" />
                                    Merge Accounts
                                </ContextMenuItem>
                            )}
                    </ContextMenuContent>
                </ContextMenuPortal>
            </ContextMenu>
            <AccountsDataTablePagination
                table={table}
                setPageSize={setPageSize}
                setPageIndex={setPageIndex}
            />
        </div>
    );
}
