import { Card } from "@/component/shadcn/ui/card";
import type {
    AddWorkflowStep,
    Label,
    UpdateWorkflowRequest,
    Workflow,
    WorkflowValidationError,
} from "@/interfaces/serverData";
import {
    type Edge,
    type Node,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
} from "@xyflow/react";

import { Button } from "@/component/shadcn/ui/button";
import { Input } from "@/component/shadcn/ui/input";
import { toast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import {
    lastTriggered,
    nodeToStepMetadata,
    serializeStepMetadata,
    validateWorkflow,
    workflowEnabled,
    workflowFrequencyBadge,
    workflowTypeBadge,
} from "@/utilities/methods";
import {
    type QueryObserverResult,
    type RefetchOptions,
    useMutation,
} from "@tanstack/react-query";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import WorkflowEditor from "./WorkflowEditor";
import { WorkflowTypeDropdown } from "./WorkflowTypeDropdown";

const areEqual = (
    prevProps: WorkflowDisplayProps,
    nextProps: WorkflowDisplayProps,
) => {
    return (
        prevProps.workflow === nextProps.workflow &&
        prevProps.refetch === nextProps.refetch &&
        prevProps.isAdmin === nextProps.isAdmin
    );
};

interface WorkflowDisplayProps {
    workflow: Workflow;
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Workflow[], Error>>;
    isAdmin: boolean;
}

function WorkflowDisplay({ workflow, refetch, isAdmin }: WorkflowDisplayProps) {
    const api = useApi();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [editable, setEditable] = useState<boolean>(false);
    const [workflowName, setWorkflowName] = useState(workflow.name);
    const [workflowDescription, setWorkflowDescription] = useState(
        workflow.description,
    );
    const [workflowType, setWorkflowType] = useState<Label>();
    const [labels, setLabels] = useState<Label[]>();

    // Store the initial state of nodes and edges when the workflow loads
    const [initialNodes, setInitialNodes] = useState<Node[]>([]);
    const [initialEdges, setInitialEdges] = useState<Edge[]>([]);
    const [workflowKey, setWorkflowKey] = useState(0);
    const [validationErrors, setValidationErrors] = useState<
        WorkflowValidationError[]
    >([]);

    const validateWorkflowCallback = useCallback(() => {
        return validateWorkflow(
            workflowName,
            nodes,
            edges,
            setValidationErrors,
        );
    }, [nodes, edges, workflowName]);

    const updateWorkflowMutation = useMutation({
        mutationFn: (id: string) => {
            const isValid = validateWorkflowCallback();
            if (!isValid) {
                throw new Error("Workflow is not valid"); // Prevent saving if validation fails
            }

            // Edge mapping of child node id => parent node id
            const childToParent = new Map<string, string[]>();
            for (const e of edges) {
                const edge = e as unknown as Edge;
                if (childToParent.has(edge.target)) {
                    const val: string[] = childToParent.get(edge.target) ?? [];
                    val.push(edge.source);
                    childToParent.set(edge.target, val);
                } else {
                    childToParent.set(edge.target, [edge.source]);
                }
            }
            const steps: AddWorkflowStep[] = [];
            for (let i = 0; i < nodes.length; i++) {
                const node: Node = nodes[i];
                const type = node.type;
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                const nodeData: Record<string, any> = node.data;
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                const nodeMetadata: Map<string, any> = new Map(
                    Object.entries(nodeData.metadata),
                );
                const step: AddWorkflowStep = {
                    id: node.id,
                    name: nodeMetadata.get("name") ?? "",
                    type: type ?? "",
                    subtype:
                        (type === "trigger"
                            ? nodeMetadata.get("type")
                            : nodeMetadata.get("subtype")) ?? "",
                    metadata: serializeStepMetadata(
                        nodeToStepMetadata(type ?? "", nodeMetadata),
                    ),
                    // TODO: handle when parent ids are more than one element (v shape)
                    // TODO: probably store two instances of the same child node in workflow_steps
                    parent_id: childToParent.get(node.id)?.[0] || "",
                };
                steps.push(step);
            }
            const requestData: UpdateWorkflowRequest = {
                steps: steps,
            };
            if (workflowName !== workflow.name) {
                requestData.name = workflowName;
            }
            if (workflowDescription !== workflow.description) {
                requestData.description = workflowDescription;
            }
            if (workflowType !== workflow.type) {
                if (workflowType) {
                    requestData.type_id = workflowType?.id;
                } else {
                    requestData.type_id = "";
                }
            }
            console.log("request data is", requestData);
            return api.patch(
                `${URLS.serverUrl}${API.updateWorkflowNew}/${id}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
        },
        onSuccess: () => {
            refetch();
            toast({
                title: "Updated workflow!",
                variant: "default",
            });
            setEditable(false);
        },
        onError: (error) => {
            console.error("Update workflow failed:", error.message);
            if (error.message === "Workflow is not valid") {
                const errorNodeIds = validationErrors.flatMap(
                    (error) => error.nodeIds || [],
                );

                for (const error of validationErrors) {
                    toast({
                        variant: "destructive",
                        title: "Validation Error",
                        description: error.message,
                    });
                }
                setNodes(
                    nodes.map((node) => ({
                        ...node,
                        data: {
                            ...node.data,
                            errorStyle: errorNodeIds.includes(node.id)
                                ? "border-2 border-red-500 shadow-[0_0_0_2px_rgba(239,68,68,0.2)]"
                                : "",
                        },
                    })),
                );
            } else {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            }
        },
    });

    const handleUpdateWorkflow = () => {
        updateWorkflowMutation.mutate(workflow.id ?? "");
    };

    useEffect(() => {
        api.get(`${URLS.serverUrl}${API.getLabels}/WorkflowType`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.data !== "") {
                        setLabels(res.data.data);
                        setWorkflowType(workflow.type);
                    }
                }
            })
            .catch((res) => {
                console.log("Could not grab workflow type labels");
            });
    }, []);

    const resetValues = () => {
        setWorkflowName(workflow.name);
        setWorkflowDescription(workflow.description);
        setWorkflowType(workflow.type);
        setEditable(false);
    };

    const cancelEdits = () => {
        resetValues();

        // Reset nodes and edges to their original state
        setNodes(initialNodes as never[]);
        setEdges(initialEdges as never[]);
        nodesUpdatedRef.current = true;
    };

    const nodesUpdatedRef = useRef(false);
    useEffect(() => {
        if (nodesUpdatedRef.current) {
            nodesUpdatedRef.current = false;
            setWorkflowKey((prevKey) => prevKey + 1);
        }
    }, [nodes, edges]);
    console.log("nodes are", nodes);
    console.log("edges are", edges);

    useEffect(() => {
        resetValues();
    }, [workflow]);

    return (
        <ReactFlowProvider>
            <Card className="rounded-lg hover:outline-0.5 hover:outline-offset-0 flex flex-col px-4 py-2 shadow-sm border relative bg-white">
                <div className="flex items-start justify-between my-3">
                    <div className="flex flex-col items-start gap-1">
                        <div className="text-lg font-semibold flex items-center gap-2">
                            {workflowEnabled(workflow)}
                            {editable ? (
                                <Input
                                    className="text-sm font-semibold w-[250px]"
                                    placeholder="New Workflow Name..."
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        setWorkflowName(event.target.value);
                                    }}
                                    value={workflowName}
                                />
                            ) : (
                                workflow.name
                            )}
                            {editable ? (
                                <WorkflowTypeDropdown
                                    workflowType={workflowType}
                                    setWorkflowType={setWorkflowType}
                                    labels={labels}
                                />
                            ) : (
                                workflowTypeBadge(workflow)
                            )}
                            {lastTriggered(workflow)}
                            {workflowFrequencyBadge(workflow)}
                        </div>
                        <div className="text-sm text-gray11">
                            {editable ? (
                                <Input
                                    className="text-xs font-medium w-[510px] h-8"
                                    placeholder="Description..."
                                    value={workflowDescription}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        setWorkflowDescription(
                                            event.target.value,
                                        );
                                    }}
                                />
                            ) : (
                                workflow.description
                            )}
                        </div>
                    </div>
                    {isAdmin && (
                        <div className="flex items-center gap-4">
                            {editable ? (
                                <div className="flex items-center gap-1">
                                    <Button
                                        size="sm"
                                        className="text-xs"
                                        variant="secondary"
                                        onClick={() => cancelEdits()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size="sm"
                                        className="bg-[#5B5BD6] text-xs"
                                        onClick={handleUpdateWorkflow}
                                    >
                                        Save
                                    </Button>
                                </div>
                            ) : (
                                <Button
                                    size="sm"
                                    className="bg-gray-200 text-xs"
                                    variant="secondary"
                                    onClick={() => setEditable(true)}
                                >
                                    Edit
                                </Button>
                            )}
                        </div>
                    )}
                </div>
                <div>
                    <WorkflowEditor
                        nodes={nodes}
                        edges={edges}
                        setNodes={setNodes}
                        setEdges={setEdges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        setInitialNodes={setInitialNodes}
                        setInitialEdges={setInitialEdges}
                        workflow={workflow}
                        editable={editable}
                        workflowKey={workflowKey}
                    />
                </div>
            </Card>
        </ReactFlowProvider>
    );
}

export default memo(WorkflowDisplay, areEqual);
