import { ContactsAPI, URLS } from "@/constant";
import type {
    OnboardingChecklist,
    OnboardingChecklistPayload,
    OnboardingChecklistStatus,
} from "@/interfaces/onboardingServerData";
import type { AxiosInstance } from "axios";

export function saveAssignee(
    assigneeID: string,
    api: AxiosInstance,
    ids: string[],
    userID: string,
    parent_id: string,
    currentItem?: OnboardingChecklist,
    updateIssueState?: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
) {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        assignee_user_id: assigneeID === "noAssignee" ? "" : assigneeID,
        parent_id: parent_id,
    };
    api.patch(
        `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
        requestData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    ).then((res) => {
        if (res.status === 200) {
            if (currentItem) {
                updateIssueState?.(
                    { assignee_user_id: assigneeID },
                    currentItem,
                );
            }
            console.log(`Updated assignee to ${assigneeID} successfully`);
        } else {
            console.log("Call to update assignee failed");
        }
    });
}

export function saveStatus(
    task_status: OnboardingChecklistStatus,
    api: AxiosInstance,
    ids: string[],
    userID: string,
    onboarding_id: string,
    currentItem?: OnboardingChecklist,
    updateIssueState?: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
) {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        task_status: task_status,
        onboarding_id: onboarding_id,
    };
    api.patch(
        `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
        requestData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    ).then((res) => {
        if (res.status === 200) {
            if (currentItem) {
                updateIssueState?.({ task_status: task_status }, currentItem);
            }
            console.log(`Updated status to ${task_status} successfully`);
        } else {
            console.log("Call to update status failed");
        }
    });
}

export function saveEnabled(
    enabled: boolean,
    api: AxiosInstance,
    ids: string[],
    userID: string,
    parent_id: string,
    currentItem?: OnboardingChecklist,
    updateIssueState?: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
) {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        enabled: enabled,
        parent_id: parent_id,
    };
    api.patch(
        `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
        requestData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    ).then((res) => {
        if (res.status === 200) {
            if (currentItem) {
                updateIssueState?.({ enabled: enabled }, currentItem);
            }
            console.log(`Updated enabled to ${enabled} successfully`);
        } else {
            console.log("Call to update enabled failed");
        }
    });
}

export function deleteItem(
    api: AxiosInstance,
    ids: string[],
    deleteIssue: (ids: string[]) => void,
    userID: string,
    parent_id: string,
) {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        parent_id: parent_id,
    };
    api.post(
        `${URLS.serverUrl}${ContactsAPI.deleteOnboardingChecklistItem.url}`,
        requestData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    ).then((res) => {
        if (res.status === 200) {
            deleteIssue(ids);
            console.log("Deleted item successfully");
        } else {
            console.log("Call to delete item failed");
        }
    });
}

export function saveAll(
    payload: Partial<OnboardingChecklistPayload>,
    api: AxiosInstance,
    ids: string[],
    userID: string,
    onboarding_id: string,
    currentItem?: OnboardingChecklist,
    updateIssueState?: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
) {
    console.log(payload);
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        parent_id: payload.parent_id,
        onboarding_id: onboarding_id,
        ...payload,
    };
    api.patch(
        `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
        requestData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    ).then((res) => {
        if (res.status === 200) {
            if (currentItem) {
                updateIssueState?.(payload, currentItem);
            }
            console.log("Updated all fields successfully");
        } else {
            console.log("Call to update completed failed");
        }
    });
}
