import { TeamsDropdown } from "@/Ticket/TeamsDropdown";
import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import { Textarea } from "@/component/shadcn/ui/textarea";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { ContactsAPI, URLS } from "@/constant";
import { GradientPicker } from "@/design/GradientPicker";
import { useApi } from "@/interfaces/api";
import {
    type AccountsLabel,
    AccountsLabelsType,
    type Teams,
    teamSchema,
} from "@/interfaces/serverData";
import {
    getAccountsLabelIcon,
    getAccountsLabelType,
    toggleTeamSelection,
} from "@/utilities/methods";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    type QueryObserverResult,
    type RefetchOptions,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { type TagsRowState, workspace } from "./DataTable/constants";
import { TeamBadges } from "./TeamBadges";

interface AccountsLabelsDialogProps {
    type: string;
    triggerElement: React.ReactNode;
    teams: Teams[];
    refetchAccountsLabels: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<AccountsLabel[], Error>>;
    editingObject?: AccountsLabel;
    currentTeam?: Teams;
    noDialog?: boolean;
}

export const AccountsLabelsDialog: React.FC<AccountsLabelsDialogProps> = ({
    type,
    triggerElement,
    teams,
    refetchAccountsLabels,
    editingObject,
    currentTeam,
    noDialog,
}) => {
    const { id } = useParams(); // team ID
    const api = useApi();
    const { toast } = useToast();
    const defaultBadgeColor = "#9B9EF0";
    const queryClient = useQueryClient();

    const [isOpen, setIsOpen] = useState(false);

    // Validation Schema for form
    const formSchema = z.object({
        labelName: z
            .string()
            .min(2, {
                message: "Accounts Label name must be at least 2 characters.",
            })
            .max(70, {
                message: "Accounts Label name must be at most 70 characters.",
            }),
        labelDescription: z.string().max(1000, {
            message: "Description must be at most 1000 characters.",
        }),
        labelType: z.nativeEnum(AccountsLabelsType),
        color: z.string(),
        labelTeams: z.array(teamSchema).optional(),
    });
    // Form initialization
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            labelName: editingObject?.name ?? "",
            labelDescription: editingObject?.description ?? "",
            labelType: editingObject?.type ?? undefined,
            color: editingObject?.color ?? defaultBadgeColor,
            labelTeams:
                editingObject?.teams ??
                (id && currentTeam ? [currentTeam] : [workspace]) ??
                [],
        },
    });
    const { control, setValue } = form;

    // Create accounts label mutation
    const createAccountsLabelMutation = useMutation({
        mutationFn: async (newAccountsLabel: {
            name: string;
            description: string;
            color: string;
            type: AccountsLabelsType;
            team_ids: string[];
        }) => {
            const { url } = ContactsAPI.createAccountsLabel;
            const response: AxiosResponse = await api.put(
                `${URLS.serverUrl}${url}`,
                newAccountsLabel,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            if (response.status !== 200) {
                throw new Error(
                    `Request failed with status ${response.status}`,
                );
            }
            refetchAccountsLabels();
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["accountsLabels"] });
            setIsOpen(false);
            form.reset();
            toast({
                title: "Created Account Label!",
                description:
                    "Your account label has been created successfully.",
            });
        },
        onError: () => {
            setIsOpen(false);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    // Update accounts label mutation
    const updateAccountsLabelMutation = useMutation({
        mutationFn: async (updatedAccountsLabel: AccountsLabel) => {
            const { url } = ContactsAPI.updateAccountsLabel;
            const response: AxiosResponse = await api.patch(
                `${URLS.serverUrl}${url}/${editingObject?.id}`,
                updatedAccountsLabel,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            if (response.status !== 200) {
                throw new Error(
                    `Request failed with status ${response.status}`,
                );
            }
            refetchAccountsLabels();
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["accountsLabels"] });
            setIsOpen(false);
            form.reset();
            toast({
                title: "Updated Account Label!",
                description:
                    "Your account label has been updated successfully.",
            });
        },
        onError: () => {
            setIsOpen(false);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    // Delete accounts label mutation
    const deleteAccountsLabelMutation = useMutation({
        mutationFn: async (id: string) => {
            const { url } = ContactsAPI.deleteAccountsLabel;
            const response: AxiosResponse = await api.delete(
                `${URLS.serverUrl}${url}/${id}`,
                {
                    headers: { "Content-Type": "application/json" },
                },
            );
            if (response.status !== 200) {
                throw new Error(
                    `Request failed with status ${response.status}`,
                );
            }
            refetchAccountsLabels();
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["accountsLabels"] });
            toast({
                title: "Deleted Account Label!",
                description:
                    "Your account label has been deleted successfully.",
            });
        },
        onError: () => {
            setIsOpen(false);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    // Form submission handler
    const onSubmit = (values: z.infer<typeof formSchema>) => {
        if (editingObject) {
            updateAccountsLabelMutation.mutate({
                ...editingObject,
                name: values.labelName,
                description: values.labelDescription,
                type: values.labelType,
                color: values.color,
                teams:
                    values.labelTeams?.filter(
                        (team) => team.id !== "workspace",
                    ) ?? [],
            });
        } else {
            createAccountsLabelMutation.mutate({
                name: values.labelName,
                description: values.labelDescription,
                type: values.labelType,
                color: values.color,
                team_ids: id
                    ? [id]
                    : (values.labelTeams
                          ?.map((team) => team.id)
                          .filter((teamID) => teamID !== "workspace") ?? []),
            });
        }
    };

    // Delete accounts label handler
    const handleDelete = (accountsLabelID: string) => {
        deleteAccountsLabelMutation.mutate(accountsLabelID);
        setIsOpen(false);
    };

    // Remove from team handler
    const handleRemoveTeam = (label: AccountsLabel) => {
        let newAccountsLabelTeams: Teams[];
        if (label.teams.length === 0) {
            const validTeams = [
                ...teams.filter((team) => team.id !== "workspace"),
            ];
            newAccountsLabelTeams = validTeams.filter((team) => team.id !== id);
        } else {
            newAccountsLabelTeams = label.teams.filter(
                (team) => team.id !== id,
            );
        }
        label.teams = newAccountsLabelTeams;
        updateAccountsLabelMutation.mutate(label);
    };

    const toggleTeam = (team: Teams, labelTeams: Teams[]) => {
        setValue("labelTeams", toggleTeamSelection(team, labelTeams));
    };

    return (
        <div>
            {noDialog ? (
                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                <div
                    onClick={() => {
                        if (editingObject) {
                            handleRemoveTeam(editingObject);
                        }
                    }}
                >
                    {triggerElement}
                </div>
            ) : (
                <Dialog open={isOpen} onOpenChange={setIsOpen}>
                    <DialogTrigger asChild>{triggerElement}</DialogTrigger>
                    <DialogContent className="max-w-[850px] w-full">
                        <div className="mx-8 my-3">
                            {(() => {
                                switch (type) {
                                    case "Edit":
                                    case "Create":
                                        return (
                                            <>
                                                <DialogHeader className="justify-left text-left items-left pb-5">
                                                    <DialogTitle>
                                                        {editingObject
                                                            ? "Edit Account Label"
                                                            : "Add Account Label"}
                                                    </DialogTitle>
                                                    <DialogDescription>
                                                        {editingObject
                                                            ? "Edit an existing account label."
                                                            : "Add a new account label with a description."}
                                                    </DialogDescription>
                                                </DialogHeader>
                                                <Form {...form}>
                                                    {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                                                    <form
                                                        onSubmit={form.handleSubmit(
                                                            onSubmit,
                                                        )}
                                                        className="space-y-8"
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                    >
                                                        <FormField
                                                            control={
                                                                form.control
                                                            }
                                                            name="labelName"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                                        <FormLabel htmlFor="labelName">
                                                                            Name
                                                                        </FormLabel>
                                                                        <FormControl>
                                                                            <Input
                                                                                id="labelName"
                                                                                className="col-span-3"
                                                                                {...field}
                                                                            />
                                                                        </FormControl>
                                                                        <FormMessage className="text-xs" />
                                                                    </div>
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={
                                                                form.control
                                                            }
                                                            name="labelDescription"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                                        <FormLabel htmlFor="labelDescription">
                                                                            Description
                                                                        </FormLabel>
                                                                        <FormControl>
                                                                            <Textarea
                                                                                id="labelDescription"
                                                                                className="col-span-3"
                                                                                {...field}
                                                                                rows={
                                                                                    2
                                                                                }
                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={
                                                                form.control
                                                            }
                                                            name="labelType"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                                        <FormLabel htmlFor="labelType">
                                                                            Type
                                                                        </FormLabel>
                                                                        <FormControl
                                                                            className="w-full"
                                                                            data-side="bottom"
                                                                        >
                                                                            <Select
                                                                                value={
                                                                                    field.value
                                                                                }
                                                                                onValueChange={(
                                                                                    selectedOption: AccountsLabelsType,
                                                                                ) => {
                                                                                    field.onChange(
                                                                                        selectedOption,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <SelectTrigger
                                                                                    className="w-[180px] flex items-center gap-2"
                                                                                    id="labelType"
                                                                                >
                                                                                    <SelectValue
                                                                                        placeholder={
                                                                                            field.value
                                                                                                ? getAccountsLabelType(
                                                                                                      field.value,
                                                                                                  )
                                                                                                : "Select a type"
                                                                                        }
                                                                                    />
                                                                                </SelectTrigger>
                                                                                <SelectContent>
                                                                                    {Object.values(
                                                                                        AccountsLabelsType,
                                                                                    ).map(
                                                                                        (
                                                                                            labelType,
                                                                                        ) => (
                                                                                            <SelectItem
                                                                                                key={
                                                                                                    labelType
                                                                                                }
                                                                                                value={
                                                                                                    labelType
                                                                                                }
                                                                                            >
                                                                                                <div className="px-0.5 flex items-center gap-2">
                                                                                                    {getAccountsLabelIcon(
                                                                                                        labelType,
                                                                                                    )}
                                                                                                    {getAccountsLabelType(
                                                                                                        labelType,
                                                                                                    )}
                                                                                                </div>
                                                                                            </SelectItem>
                                                                                        ),
                                                                                    )}
                                                                                </SelectContent>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </div>
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={
                                                                form.control
                                                            }
                                                            name="color"
                                                            render={({
                                                                field,
                                                            }) => {
                                                                console.log(
                                                                    "form value is",
                                                                    field.value,
                                                                );
                                                                return (
                                                                    <FormItem>
                                                                        <div className="grid grid-cols-4 items-center gap-4">
                                                                            <FormLabel>
                                                                                Color
                                                                            </FormLabel>
                                                                            <FormControl>
                                                                                <GradientPicker
                                                                                    id="colorPicker"
                                                                                    background={
                                                                                        field.value
                                                                                    }
                                                                                    setBackground={(
                                                                                        id: string,
                                                                                        rowState: Partial<TagsRowState>,
                                                                                    ) =>
                                                                                        form.setValue(
                                                                                            "color",
                                                                                            rowState.colorSelected ??
                                                                                                defaultBadgeColor,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </FormControl>
                                                                        </div>
                                                                    </FormItem>
                                                                );
                                                            }}
                                                        />
                                                        <FormField
                                                            control={control}
                                                            name="labelTeams"
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                                        <FormLabel htmlFor="tagDescription">
                                                                            Teams
                                                                        </FormLabel>
                                                                        {id ? (
                                                                            <div className="flex flex-col gap-2 col-span-3">
                                                                                <Button
                                                                                    variant="ghost"
                                                                                    className="flex items-center gap-2 justify-start p-0 w-full data-[state=open]:bg-muted overflow-hidden"
                                                                                    type="button"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                >
                                                                                    <TeamBadges
                                                                                        teams={
                                                                                            currentTeam
                                                                                                ? [
                                                                                                      currentTeam,
                                                                                                  ]
                                                                                                : []
                                                                                        }
                                                                                        defaultIsWorkspace={
                                                                                            true
                                                                                        }
                                                                                    />
                                                                                </Button>
                                                                            </div>
                                                                        ) : (
                                                                            <FormControl>
                                                                                <TeamsDropdown
                                                                                    selectedTeams={
                                                                                        field?.value ??
                                                                                        []
                                                                                    }
                                                                                    teams={
                                                                                        teams
                                                                                    }
                                                                                    toggleTeam={
                                                                                        toggleTeam
                                                                                    }
                                                                                    defaultIsWorkspace={
                                                                                        true
                                                                                    }
                                                                                />
                                                                            </FormControl>
                                                                        )}
                                                                    </div>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <DialogFooter className="justify-end text-end items-end">
                                                            <Button
                                                                className="bg-iris9"
                                                                type="submit"
                                                            >
                                                                {editingObject
                                                                    ? "Update"
                                                                    : "Save changes"}
                                                            </Button>
                                                        </DialogFooter>
                                                    </form>
                                                </Form>
                                            </>
                                        );
                                    case "Delete":
                                        return (
                                            <>
                                                <DialogHeader className="justify-left text-left items-left pb-5">
                                                    <DialogTitle>
                                                        Are you absolutely sure?
                                                    </DialogTitle>
                                                    <DialogDescription>
                                                        {`This action cannot be undone. This will permanently delete the Accounts Label "${editingObject?.name}" from the Workspace.`}
                                                    </DialogDescription>
                                                </DialogHeader>
                                                <DialogFooter className="justify-end text-end items-end gap-3">
                                                    <Button
                                                        className="bg-iris9 py-0.5"
                                                        type="submit"
                                                        onClick={() =>
                                                            setIsOpen(false)
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className="bg-red-500 py-0.5"
                                                        type="submit"
                                                        onClick={() => {
                                                            if (editingObject) {
                                                                handleDelete(
                                                                    editingObject.id,
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </DialogFooter>
                                            </>
                                        );
                                    default:
                                        return null; // You could return a default view or nothing
                                }
                            })()}
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
};
