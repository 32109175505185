import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import {
    BusinessHoursType,
    type GetUserResponse,
} from "@/interfaces/serverData";
import { Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import BusinessHoursComponent from "./BusinessHoursComponent";
import { generateMembersColumns } from "./DataTable/columns";
import type { MembersRowState } from "./DataTable/constants";
import { MembersDataTable } from "./DataTable/members-data-table";

interface MembersProps {
    org_id: string;
}

const Members: React.FC<MembersProps> = ({ org_id }) => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [rowState, setRowState] = useState<Map<string, MembersRowState>>(
        new Map(),
    );
    const { data = [] } = useQuery({
        queryKey: ["members"],
        queryFn: () => fetchMembers(),
        refetchInterval: 30000, // refetch every 30 secs
        refetchOnWindowFocus: true,
    });

    const updateRowState = (id: string, newState: Partial<MembersRowState>) => {
        setRowState((prevState) => {
            const newStateMap = new Map(prevState);
            const currentState = newStateMap.get(id) || {
                id: "",
                name: "",
                email: "",
                username: "",
                picture_url: "",
                role: "",
            };
            newStateMap.set(id, { ...currentState, ...newState });
            return newStateMap;
        });
    };

    const fetchMembers = async (): Promise<GetUserResponse[]> => {
        const response = await api.post(URLS.serverUrl + API.getAllUsers, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.status === 200) {
            setLoadingState(1);
            return response.data.data;
        }
        setLoadingState(2);
        return [];
    };

    useEffect(() => {
        if (data) {
            const newRowState = new Map<string, MembersRowState>();
            for (let i = 0; i < data.length; i++) {
                const user: GetUserResponse = data[i];
                newRowState.set(String(i), {
                    id: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                    email: user.email,
                    username: user.username,
                    picture_url: user.picture_url,
                    role: user.user_role,
                });
            }
            setRowState(newRowState);
            setLoadingState(1);
        }
    }, [data]);

    return (
        <div>
            <Box mt={"5%"} ml={"7%"} mr={"7%"}>
                <h2 className="text-2xl font-semibold">Members</h2>
                <p className="text-sm text-gray11 pb-10">
                    Members with access to this workspace. Admins can change the
                    roles of members.
                </p>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && (
                    <div className="flex flex-row gap-8">
                        <MembersDataTable
                            data={data}
                            columns={generateMembersColumns(
                                rowState,
                                updateRowState,
                            )}
                        />
                        <div className="mt-12">
                            <BusinessHoursComponent
                                type={BusinessHoursType.Org}
                                id={org_id}
                            />
                        </div>
                    </div>
                )}
            </Box>
        </div>
    );
};

export default Members;
