import { Callout } from "@radix-ui/themes";

export const AssemblyErrorMessage = ({
    message,
    className,
}: { message?: string; className?: string }) => {
    return (
        <Callout.Root
            size="1"
            variant="outline"
            color="red"
            className={className}
        >
            <Callout.Text className="text-xs">
                {message ??
                    "Sorry, something's wrong! Please notify us at support@askassembly.app."}
            </Callout.Text>
        </Callout.Root>
    );
};
