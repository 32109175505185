import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";
import type { HistoryResponse, MyFile } from "@/interfaces/serverData";
import { replaceInlineImageDataForGmail } from "@/utilities/methods";
import { Comment as CommentPrimitive } from "@liveblocks/react-ui/primitives";
import DOMPurify from "dompurify";
import parse, {
    domToReact,
    type HTMLReactParserOptions,
} from "html-react-parser";
import { EllipsisIcon } from "lucide-react";

import { useState } from "react";
import type React from "react";

interface CommentContentProps {
    comment: HistoryResponse;
    filesMap: Map<string, MyFile[]>;
}

const sanitizeConfig = {
    ALLOWED_TAGS: [
        "img",
        "div",
        "p",
        "a",
        "strong",
        "b",
        "em",
        "i",
        "blockquote",
        "br",
        "ol",
        "ul",
        "li",
        "code",
        "table",
        "tbody",
        "tr",
        "td",
        "span",
    ],
    ALLOWED_ATTR: [
        "src",
        "alt",
        "class",
        "style",
        "dir",
        "href",
        "width",
        "height",
        "valign",
        "align",
        "rowspan",
        "colspan",
        "style",
    ],
    ADD_URI_SAFE_ATTR: ["src"], // Explicitly allow src attribute for images
    ALLOW_DATA_ATTR: true,
};

function shouldHideNode(node: Element): boolean {
    if (!node || !(node instanceof Element)) return false;

    // Check for gmail_quote class
    if (
        node.classList.contains("gmail_quote") &&
        node.tagName.toLowerCase() === "div"
    ) {
        return true;
    }

    // Check for blockquote
    if (node.tagName.toLowerCase() === "blockquote") {
        return true;
    }

    // Check for div with border-top style and email headers
    if (
        node.tagName.toLowerCase() === "div" &&
        node.getAttribute("style")?.includes("border-top:solid")
    ) {
        const text = node.textContent || "";

        if (
            text.includes("From:") && // little more lenient because of border-top:solid here
            (text.includes("Sent:") ||
                text.includes("To:") ||
                text.includes("Subject:"))
        ) {
            console.log("Found email header div");
            return true;
        }
    }

    // Check for RplyFwdMsg div or any element after it
    if (node.tagName.toLowerCase() === "div") {
        // Check if this node or any parent has RplyFwdMsg
        let current: Element | null = node;
        while (current) {
            if (current.id?.includes("RplyFwdMsg")) {
                // If we find RplyFwdMsg in the hierarchy, hide this node
                const text = current.textContent || "";
                if (
                    text.includes("From:") &&
                    text.includes("Sent:") &&
                    text.includes("Subject:") &&
                    (text.includes("Bcc:") || text.includes("To:"))
                ) {
                    console.log("Found email header div in hierarchy");
                    return true;
                }
            }
            current = current.parentElement;
        }
    }

    return false;
}

function removeHiddenContent(content: string): {
    visibleContent: string;
    removedContent: string;
} {
    if (content === undefined) {
        return {
            visibleContent: "",
            removedContent: "",
        };
    }
    const cleanedContent = content
        .replace(
            /<p class="?MsoNormal"?>\s*<o:p>\s*&nbsp;\s*<\/o:p>\s*<\/p>/g,
            "",
        ) // Remove Microsoft Office empty paragraphs
        .replace(/^(?:\s*<br\s*\/?>\s*)*/, "") // Remove leading br tags
        .replace(/^\s+|\s+$/g, ""); // Trim whitespace

    const parser = new DOMParser();
    const doc = parser.parseFromString(cleanedContent, "text/html");

    let visibleContent = "";
    let removedContent = "";

    const processContent = (node: Node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as Element;

            if (shouldHideNode(element)) {
                // Add this and all following siblings to removedContent
                let current: Node | null = element;
                const tempDiv = document.createElement("div");

                while (current) {
                    tempDiv.appendChild(current.cloneNode(true));
                    current = current.nextSibling;
                }
                removedContent += tempDiv.innerHTML;

                // Remove these nodes from the original document which we'll use as visible nodes
                current = element;
                while (current) {
                    const next = current.nextSibling;
                    if (current.parentNode) {
                        current.parentNode.removeChild(current);
                    }
                    current = next;
                }
                return;
            }
        }

        // Process children if we haven't found a hidden node yet
        if (node.parentNode) {
            for (const child of Array.from(node.childNodes)) {
                processContent(child);
            }
        }
    };

    processContent(doc.body);
    visibleContent = doc.body?.innerHTML ?? "";

    return {
        visibleContent,
        removedContent,
    };
}

export const GmailCommentRenderer: React.FC<CommentContentProps> = ({
    comment,
    filesMap,
}) => {
    const [showHiddenContent, setShowHiddenContent] = useState(false);
    let { visibleContent, removedContent } = removeHiddenContent(
        comment.content,
    );

    const parserOptions: HTMLReactParserOptions = {
        replace: (domNode) => {
            if (domNode === undefined) return null;
            if (domNode.name === "p") {
                const isEmptyParagraph =
                    // Basic empty cases
                    !domNode.children?.length ||
                    // Empty text node
                    (domNode.children[0].type === "text" &&
                        !domNode.children[0].data?.trim()) ||
                    // Empty paragraph with only &nbsp;
                    (domNode.children?.[0]?.type === "text" &&
                        domNode.children[0].data === "\u00a0");
                if (isEmptyParagraph) {
                    return null;
                }
                return (
                    <p
                        style={{
                            margin: "1em 0",
                            lineHeight: "1.4",
                            padding: 0,
                            display: "block",
                        }}
                    >
                        {domToReact(domNode.children, parserOptions)}
                    </p>
                );
            }

            if (domNode.name === "div") {
                // Only process divs that contain actual content
                const hasContent = domNode.children?.some(
                    (child) =>
                        (child.type === "text" && child.data.trim()) ||
                        child.type === "tag",
                );
                if (!hasContent) return null;
                return (
                    <div style={{ margin: "0", padding: "0" }}>
                        {domToReact(domNode.children, parserOptions)}
                    </div>
                );
            }

            if (domNode.name === "br") {
                const parent = domNode.parent;
                const prevSibling = domNode.prev;
                const nextSibling = domNode.next;

                // Skip br if:
                const shouldSkip =
                    // It's at the start of content
                    !prevSibling ||
                    // It's consecutive br tags
                    prevSibling?.name === "br" ||
                    nextSibling?.name === "br" ||
                    // It's inside an empty container
                    !parent?.children?.some(
                        (child) => child.type === "text" && child.data?.trim(),
                    );

                return shouldSkip ? null : <br />;
            }

            if (domNode.attribs && domNode.name === "a") {
                return (
                    <CommentPrimitive.Link
                        href={domNode.attribs.href}
                        className="lb-comment-link text-[#5B5BD6] hover:text-gray-500"
                    >
                        {domToReact(domNode.children)}
                    </CommentPrimitive.Link>
                );
            }
            // Handle markdown-style links in text nodes
            if (domNode.type === "text" && domNode.data) {
                const markdownLinkRegex = /\[(.*?)\]\((.*?)\)/g;
                const hasMarkdownLink = markdownLinkRegex.test(domNode.data);

                if (hasMarkdownLink) {
                    const parts = domNode.data.split(markdownLinkRegex);
                    const links = [...domNode.data.matchAll(markdownLinkRegex)];

                    return (
                        <>
                            {parts.map((part, index) => {
                                if (index < links.length) {
                                    const [_, text, url] = links[index];
                                    return (
                                        <>
                                            {part}
                                            <CommentPrimitive.Link
                                                href={url || "#"}
                                                className="lb-comment-link text-[#5B5BD6] hover:text-gray-500"
                                            >
                                                {text}
                                            </CommentPrimitive.Link>
                                        </>
                                    );
                                }
                                return part;
                            })}
                        </>
                    );
                }
            }

            if (domNode.name === "b" || domNode.name === "strong") {
                return (
                    <strong className="font-bold">
                        {domToReact(domNode.children)}
                    </strong>
                );
            }

            if (domNode.name === "i" || domNode.name === "em") {
                return (
                    <em className="italic">{domToReact(domNode.children)}</em>
                );
            }

            if (domNode.name === "code") {
                return (
                    <code className="text-balance">
                        {domToReact(domNode.children)}
                    </code>
                );
            }

            if (domNode.name === "ol") {
                // Check if this is a new section by looking for a preceding strong/bold header
                const prevSibling = domNode.prev;
                const isNewSection =
                    prevSibling?.name === "p" &&
                    prevSibling.children?.[0]?.name === "strong";

                // Start at 1 for new sections, otherwise continue numbering
                const startNumber = isNewSection ? 1 : undefined;

                return (
                    <ol className="list-decimal ml-4" start={startNumber}>
                        {domToReact(domNode.children, parserOptions)}
                    </ol>
                );
            }

            if (domNode.name === "ul") {
                // Check if this ul follows a numbered item (either ol or p with number)
                const prevElement = domNode.prev;
                const isChildOfNumbered =
                    prevElement?.name === "ol" ||
                    (prevElement?.name === "p" &&
                        prevElement.children?.[0]?.data?.match(/^\d+\s*\./));

                return (
                    <ul
                        className={`list-disc ${isChildOfNumbered ? "ml-8" : "ml-4"}`}
                    >
                        {domToReact(domNode.children)}
                    </ul>
                );
            }

            if (domNode.name === "li") {
                // Check if this is part of a sub-list
                const parentList = domNode.parent;
                const isSubListItem =
                    parentList?.prev?.name === "ol" ||
                    (parentList?.prev?.name === "p" &&
                        parentList.prev.children?.[0]?.data?.match(
                            /^\d+\s*\./,
                        ));

                return (
                    <li className={`list-item ${isSubListItem ? "mb-2" : ""}`}>
                        {domToReact(domNode.children, parserOptions)}
                    </li>
                );
            }
        },
    };

    // Check if visible and removed content are the same by comparing text content
    const parser = new DOMParser();
    const visibleDoc = parser.parseFromString(visibleContent, "text/html");
    const removedDoc = parser.parseFromString(removedContent, "text/html");

    const visibleText = visibleDoc.body.textContent?.trim();
    const removedText = removedDoc.body.textContent?.trim();

    // If texts are identical, ignore the removed content
    if (visibleText === removedText) {
        removedContent = "";
    }

    return (
        <div>
            {/* Visible content */}
            {parse(
                DOMPurify.sanitize(
                    replaceInlineImageDataForGmail(
                        visibleContent,
                        filesMap.get(comment.id) ?? [],
                        comment.id,
                    ),
                    sanitizeConfig,
                ),
                parserOptions,
            )}

            {/* Show more button if there's hidden content */}
            {removedContent && (
                <DialogTriggerButton
                    className={`h-4 items-center justify-center px-2 ${showHiddenContent ? "bg-muted" : ""}`}
                    onClick={() => setShowHiddenContent(!showHiddenContent)}
                >
                    <EllipsisIcon className="w-4 h-4" />
                </DialogTriggerButton>
            )}

            {/* Hidden content */}
            {showHiddenContent && removedContent && (
                <div className="text-gray-500 mt-2 pl-2 border-l-2 border-gray-200">
                    {parse(
                        DOMPurify.sanitize(
                            replaceInlineImageDataForGmail(
                                removedContent,
                                filesMap.get(comment.id) ?? [],
                                comment.id,
                            ),
                            sanitizeConfig,
                        ),
                        parserOptions,
                    )}
                </div>
            )}
        </div>
    );
};
