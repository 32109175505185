import { Button } from "@/component/shadcn/ui/button";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import type {
    Category,
    CustomerGroup,
    GetUserResponse,
    ScopeResponse,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import { cn } from "@/lib/utils";
import { getTypeName } from "@/utilities/methods";
import { PlusCircledIcon } from "@radix-ui/react-icons";
import { memo, useCallback, useMemo } from "react";
import FilterDropdownElement from "./FilterDropdownElement";
import { type FilterOption, FilterType } from "./constants";

interface FilterDropdownProps {
    className?: string;
    filters: Map<string, Set<string>>;
    filterOptions: FilterOption[];
    activeMenu: string | null;
    setActiveMenu: React.Dispatch<React.SetStateAction<string | null>>;
    handleItemSelect: (
        type: string,
        option: { label: string; value: string; key: string; color: string },
    ) => () => void;
    handleMenuClick: (menu: string) => () => void;
    categories: Category[];
    topics: Topic[];
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
    teams: Teams[];
    channels: Map<string, ScopeResponse[]>;
}

function FilterDropdown({
    className,
    filters,
    filterOptions,
    activeMenu,
    setActiveMenu,
    handleItemSelect,
    handleMenuClick,
    categories = [],
    topics,
    users,
    customerGroups,
    teams,
    channels,
}: FilterDropdownProps) {
    const handleOpenChange = useCallback((open: boolean) => {
        if (!open) {
            setActiveMenu(null);
        }
    }, []);

    const memoizedFilterDropdownElement = useMemo(() => {
        return filterOptions?.map(
            (filterOption) =>
                activeMenu === filterOption.type && (
                    <FilterDropdownElement
                        key={filterOption.type}
                        type={filterOption.type}
                        filters={filters}
                        handleItemSelect={handleItemSelect}
                        categories={categories}
                        topics={topics}
                        users={users}
                        customerGroups={customerGroups}
                        teams={teams}
                        channels={channels}
                        isSavedViewFilter={false}
                        extraOptions={filterOption.extraOptions ?? []}
                        interactionTypes={[]}
                    />
                ),
        );
    }, [activeMenu, filters, handleItemSelect, topics, users]);

    return (
        <DropdownMenu onOpenChange={handleOpenChange}>
            <DropdownMenuTrigger asChild type="button">
                <Button
                    variant="ghost"
                    size="sm"
                    className={cn(
                        "flex items-center pt-1 gap-2 text-muted-foreground hover:text-gray-950",
                        className,
                    )}
                >
                    <PlusCircledIcon className="w-4 h-4 text-gray-700" />
                    {filters.size === 0 && (
                        <div className="text-xs">Filter</div>
                    )}
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                align="start"
                className="fixed w-[320px] max-h-100 p-0 bg-muted rounded-md shadow-lg"
            >
                {activeMenu === null && (
                    <Command className="rounded-md shadow-md text-xs pb-1 overflow-y-auto">
                        <CommandInput
                            placeholder="Filter..."
                            className="px-1 text-[13px]"
                        />
                        <CommandList>
                            <CommandEmpty className="text-xs px-4 py-2">
                                No results found.
                            </CommandEmpty>
                            {filterOptions
                                .filter(
                                    (filterOption) => filterOption.showTrigger,
                                )
                                .map((filterOption) => (
                                    <CommandItem
                                        className="px-1 py-0"
                                        key={filterOption.type}
                                    >
                                        <Button
                                            type="button"
                                            variant="ghost"
                                            onClick={
                                                filterOption.filterType ===
                                                FilterType.DirectSelect
                                                    ? handleItemSelect(
                                                          filterOption.type,
                                                          {
                                                              value: "true",
                                                              label: "True",
                                                              key: "true",
                                                              color: "",
                                                          },
                                                      )
                                                    : handleMenuClick(
                                                          filterOption.type,
                                                      )
                                            }
                                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 w-full my-0 text-left flex justify-start h-8"
                                        >
                                            <span className="ml-1">
                                                {getTypeName(filterOption.type)}
                                            </span>
                                        </Button>
                                    </CommandItem>
                                ))}
                        </CommandList>
                    </Command>
                )}
                {memoizedFilterDropdownElement}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

export default memo(FilterDropdown);
