import {
    type FilterType,
    insightStatuses,
    sources,
    ticketStatuses,
} from "@/IssuesTable/constants";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, URLS } from "@/constant";
import {
    type Account,
    type AccountMonetaryRecord,
    type AccountsLabel,
    AccountsLabelsType,
    type Category,
    type CustomerGroup,
    type CustomerTicket,
    type GetTopicsResponse,
    type GetUserResponse,
    type Insight,
    type InsightData,
    type Label,
    type MyFile,
    type QueriesWithPaginationResponse,
    type Query,
    type ScopeResponse,
    type Teams,
    type Template,
    type Ticket,
    type TicketStatus,
    type TicketTag,
    type TicketTopics,
    type TimeRepeat,
    type Topic,
    type UploadedFile,
    type UploadedFileWithMetadata,
    type UserResponse,
    type Workflow,
    type WorkflowValidationError,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";

import type { IssueGroupInfo } from "@/IssuesTable/IssuesList";
import AttributesBadge from "@/component/AttributesBadge";
import { Badge as ScnBadge } from "@/component/shadcn/ui/badge";
import {
    $createImageNode,
    $isImageNode,
    ImageNode,
} from "@/component/textEditor/nodes/ImageNode";
import { VariableNode } from "@/component/textEditor/nodes/TextVariableNode";
import { IssueListType } from "@/pages/Admin/AdminQueriesPage";
import { AccountsListType } from "@/pages/Admin/CRM/Accounts";
import { LABEL_TYPES } from "@/pages/WorkspacePreferences/constants";
import {
    type TextMatchTransformer,
    type Transformer,
    TRANSFORMERS as originalTransformers,
} from "@lexical/markdown";
import { createLinkMatcherWithRegExp } from "@lexical/react/LexicalAutoLinkPlugin";
import {
    AvatarIcon,
    CaretSortIcon,
    CaretUpIcon,
    CheckCircledIcon,
    ComponentBooleanIcon,
    CrossCircledIcon,
    DotFilledIcon,
    DrawingPinIcon,
    EnvelopeOpenIcon,
    ExclamationTriangleIcon,
    GroupIcon,
    type LapTimerIcon,
    OpenInNewWindowIcon,
    PersonIcon,
    QuestionMarkCircledIcon,
    QuestionMarkIcon,
    ReaderIcon,
    ResetIcon,
    type StopwatchIcon,
    TriangleDownIcon,
} from "@radix-ui/react-icons";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { Badge } from "@radix-ui/themes";
import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import type {
    InfiniteData,
    QueryClient,
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import type { AxiosInstance } from "axios";
import { format, parse } from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import { TextNode } from "lexical";
import _ from "lodash";
import {
    ActivityIcon,
    BoxIcon,
    DollarSignIcon,
    Eclipse,
    HotelIcon,
    HouseIcon,
    ImageIcon,
    LayersIcon,
    ListChecksIcon,
    ListXIcon,
    MailIcon,
    MailboxIcon,
    MailsIcon,
    MoonStarIcon,
    MoveRightIcon,
    NotepadText,
    PlusIcon,
    SparklesIcon,
    SquareArrowUpIcon,
    StarIcon,
    StoreIcon,
    TagIcon,
    TargetIcon,
    TrendingDownIcon,
    TrendingUpIcon,
    UsersIcon,
} from "lucide-react";
import { createPortal } from "react-dom";
import ReactDOMServer from "react-dom/server";
import type { Edge } from "reactflow";
import GreenFlagIcon from "../images/greenFlag.png";
import RedFlagIcon from "../images/redFlag.png";
import YellowFlagIcon from "../images/yellowFlag.png";

export type BadgeColor = (typeof badgePropDefs.color.values)[number];

export function getColor(input: string): BadgeColor {
    switch (input) {
        case "Query":
            return "green";
        case "Feature":
            return "blue";
        case "Bug":
            return "red";
        default:
            return "gray";
    }
}

export function getColorLight(input: string): string {
    switch (input) {
        case "Query":
            return "#5BB98B";
        case "Feature":
            return "#5EB1EF";
        case "Bug":
            return "#EB8E90";
        default:
            return "#BBBBBB";
    }
}

export function getTopicColor(input: string): BadgeColor {
    switch (input) {
        case "autoresponder setup":
            return "green";
        case "channel activity":
            return "pink";
        default:
            return "iris";
    }
}

export function processInsightMetadata(
    insight: Insight,
): [boolean, string, string] {
    if (insight.metadata) {
        const metadata = new Map(Object.entries(JSON.parse(insight.metadata)));
        const isAIGenerated =
            metadata.has("ai_generated") &&
            Boolean(metadata.get("ai_generated"));
        const topicGeneratedFrom: string =
            (metadata.get("topic_generated_from") as string) ?? "";
        const categoryGeneratedFrom: string =
            (metadata.get("category_generated_from") as string) ?? "";
        return [isAIGenerated, topicGeneratedFrom, categoryGeneratedFrom];
    }
    return [false, "", ""];
}

export async function saveAIGeneratedInsight(
    insight: Insight,
    queryClient: QueryClient,
    userID: string,
    api: AxiosInstance,
    teamID?: string,
) {
    const requestData: InsightData = {
        title: insight.title,
        description: insight.description,
        user_id: userID,
        related_issues_added: insight.related_issues.map((ri) => ri.id),
        metadata: insight.metadata,
    };

    if (teamID && teamID !== "") {
        requestData.team_id = teamID;
    }

    try {
        const res = await api.post(
            URLS.serverUrl + API.saveInsight,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        if (res.status === 200) {
            console.log(`Saved insight id ${res.data.data} successfully`);
            await queryClient.refetchQueries({
                queryKey: ["insights"],
                exact: true,
            });
            return res.data.data;
        } else {
            console.log("Call to update status failed");
            return "";
        }
    } catch (error) {
        console.error("Error saving insight:", error);
        return "";
    }
}

export function getExternalIssueIcon(url: string): React.ElementType {
    const icon = url.toLowerCase().includes("linear")
        ? integrationBackEndDataMappingToSvg.get("Linear")
        : url.toLowerCase().includes("github")
            ? integrationBackEndDataMappingToSvg.get("GitHubTicket")
            : url.toLowerCase().includes("atlassian")
                ? integrationBackEndDataMappingToSvg.get("Jira")
                : url.toLowerCase().includes("intercom")
                    ? integrationBackEndDataMappingToSvg.get("Intercom")
                    : undefined;

    return icon || ReaderIcon;
}

export function getExternalIssueText(url: string): string {
    const text = url.toLowerCase().includes("linear")
        ? "Linear"
        : url.toLowerCase().includes("github")
            ? "GitHub"
            : url.toLowerCase().includes("atlassian")
                ? "Jira"
                : url.toLowerCase().includes("intercom")
                    ? "Intercom"
                    : "";
    return text;
}

export const areQueriesEqual = (query1: Query, query2: Query): boolean => {
    return (
        query1.id === query2.id ||
        query1.title === query2.title ||
        query1.query === query2.query
    );
};

export const addToCustomSet = (
    query: Query,
    set: Set<Query>,
    compareFn: (q1: Query, q2: Query) => boolean,
) => {
    if (
        !Array.from(set).some((existingQuery) =>
            compareFn(existingQuery, query),
        )
    ) {
        set.add(query);
    }
};

export function getPlural(type: string) {
    switch (type) {
        case "Source": {
            return "sources";
        }
        case "Broadcast": {
            return "broadcasts";
        }
        case "Tag": {
            // backend category -> tag -> "Category" on UI
            return "categories";
        }
        case "Topic": {
            // backend topics -> "Tag" on UI
            return "tags";
        }
        case "Status":
        case "Insight Status": {
            return "statuses";
        }
        case "Assignee": {
            return "assignees";
        }
        case "Team": {
            return "Teams";
        }
        case "Template": {
            return "Templates";
        }
    }
}

export function getLowercase(type: string) {
    switch (type) {
        case "Tag": {
            // backend category -> tag -> "Category" on UI
            return "category";
        }
        case "Topic":
        case "Add Topic":
        case "Remove Topic": {
            // backend topics -> "Tag" on UI
            return "tag";
        }
        case "InteractionType": {
            return "interaction type";
        }
        case "CommunitySlack": {
            return "Community Slack channels";
        }
        case "Slack":
        case "Discord": {
            return `${type} channels`;
        }
        case "API": {
            return "API keys";
        }
        case "TeamOwner": {
            return "team owner";
        }
        default: {
            return type.toLowerCase();
        }
    }
}

export function getTypeName(type: string) {
    switch (type) {
        case "Tag": {
            // backend category -> tag -> "Category" on UI
            return "Category";
        }
        case "Topic": {
            // backend topics -> "Tag" on UI
            return "Tag";
        }
        case "InteractionType": {
            return "Interaction Type";
        }
        case "Status":
        case "Insight Status": {
            return "Status";
        }
        default: {
            return type;
        }
    }
}

export function getOptions(
    type: string,
    topics: Topic[],
    users: GetUserResponse[],
    customerGroups: CustomerGroup[],
    categories: Category[],
    teams: Teams[],
    channels: Map<string, ScopeResponse[]>,
    templates: Template[],
    customers: Account[],
    companies: Account[],
    interactionTypes: Label[],
    organizationSources?: string[],
    orgID?: string,
    addClosedNoWorkflowsStatus?: boolean,
) {
    switch (type) {
        case "Source": {
            if (organizationSources !== undefined) {
                return sources.filter((broadcast) =>
                    organizationSources.includes(broadcast.value),
                );
            } else {
                return sources;
            }
        }
        case "InteractionType": {
            return interactionTypes.map((interactionType) => ({
                label: interactionType.name,
                value: interactionType.name,
                color: interactionType.color,
                key: interactionType.id,
            }));
        }
        case "Tag": {
            return categories.map((category) => ({
                label: category.name,
                value: category.name,
                color: category.color,
                key: category.id,
            }));
        }
        case "Topic":
        case "Add Topic":
        case "Remove Topic": {
            return topics;
        }
        case "Status": {
            // Include closed no workflows for dev org and latchel if specified
            if (
                (addClosedNoWorkflowsStatus &&
                    orgID &&
                    orgID === "fc12e902-f8ee-4176-947b-7f716469d1d6") ||
                orgID === "5c6113ba-8059-470e-b9f0-8f8d45ac1e16"
            ) {
                return [
                    ...ticketStatuses,
                    {
                        label: "Closed - No Workflows",
                        value: "ClosedNoWorkflows",
                        color: "",
                        key: "ClosedNoWorkflows",
                    },
                ];
            }

            return ticketStatuses;
        }
        case "Insight Status": {
            return insightStatuses;
        }
        case "Assignee": {
            const options = users.map((user) => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
                color: "",
                key: user.id,
            }));
            options.push({
                color: "",
                label: "No Assignee",
                value: "noAssignee",
                key: "NoAssignee",
            });
            return options;
        }
        case "Company": {
            const options = companies.map((c) => ({
                label: c.name,
                value: c.id,
                color: "",
                key: c.id,
            }));
            return options;
        }
        case "Customer": {
            const options = customers.map((c) => ({
                label: c.name,
                value: c.id,
                color: "",
                key: c.id,
            }));
            return options;
        }
        case "Customer Group": {
            const options = customerGroups.map((cg) => ({
                label: cg.group_name,
                value: cg.group_name,
                color: "",
                key: cg.id ?? "",
            }));
            return options;
        }
        case "Team": {
            const options = teams.map((team) => ({
                label: team.team_name,
                value: team.id,
                color: "",
                key: team.id,
            }));
            return options;
        }
        case "TeamOwner": {
            const options = teams.map((team) => ({
                label: team.team_name,
                value: team.id,
                color: "",
                key: team.id,
            }));
            options.push({
                color: "",
                label: "No Team Owner",
                value: "noTeamOwner",
                key: "noTeamOwner",
            });
            return options;
        }
        case "Slack":
        case "Discord":
        case "CommunitySlack": {
            const typeChannels = channels?.get(type);
            if (!typeChannels?.length) return [];
            return typeChannels.map((channel) => {
                if (!channel.name || !channel.key) return null;
                return {
                    label: `${type} -- #${channel.name}`,
                    value: `${type} -- ${channel.name} -- ${channel.key}`,
                    color: "",
                    key: channel.key,
                };
            }).filter(Boolean);
        }
        case "Gmail": {
            return channels?.get(type)?.map((channel) => ({
                label: `${type} -- ${channel.name}`,
                value: `${type} -- ${channel.name} -- ${channel.key}`,
                color: "",
                key: channel.key,
            }));
        }
        case "API": {
            return channels?.get(type)?.map((channel) => ({
                label: `${type} -- ${channel.name}`,
                value: `${type} -- ${channel.name} -- ${channel.key}`,
                color: "",
                key: channel.key,
            }));
        }
        case "Template": {
            const options = templates.map((template) => ({
                label: template.name,
                value: template.name,
                color: "",
                key: template.id,
            }));
            return options;
        }
    }
}

export function getStatusFullIcon(status: string) {
    const Icon = getStatusIcon(status ?? "Unknown");
    switch (status) {
        case "Open": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#FFEFD6] border border-[#FFDFB5] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "NeedsResponse": {
            return (props: React.ComponentProps<typeof LapTimerIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#EEF1F0] border border-[#E6E9E8] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "Breaching": {
            return (
                props: React.ComponentProps<typeof ExclamationTriangleIcon>,
            ) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#FFF6F8] border border-[#FFE9ED] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "Escalated": {
            return (props: React.ComponentProps<typeof SquareArrowUpIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#f5dfdf] border border-[#edc2c2] shadow-sm">
                    <Icon className="h-3.5 w-3.5" />
                </div>
            );
        }
        case "Closed": {
            return (props: React.ComponentProps<typeof CheckCircledIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#EDF2FE] border border-[#E1E9FF] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "New": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#e8f5fc] border border-[#bbdced] shadow-sm">
                    <Icon className="h-3.5 w-3.5" />
                </div>
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                    <Icon />
                </div>
            );
        }
    }
}

export function getStatusIcon(status: string) {
    switch (status) {
        case "Open": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <TargetIcon
                    strokeWidth={1.5}
                    size={16}
                    {...props}
                    color="#EC9455"
                />
            );
        }
        case "NeedsResponse": {
            return (props: React.ComponentProps<typeof ResetIcon>) => (
                <ResetIcon {...props} color="#868E8B" />
            );
        }
        case "Breaching": {
            return (
                props: React.ComponentProps<typeof ExclamationTriangleIcon>,
            ) => <ExclamationTriangleIcon {...props} color="#f57695" />;
        }
        case "Escalated": {
            return (props: React.ComponentProps<typeof SquareArrowUpIcon>) => (
                <SquareArrowUpIcon {...props} color="#B91C1C" />
            );
        }
        case "Closed":
        case "ClosedNoWorkflows": {
            return (props: React.ComponentProps<typeof MoonStarIcon>) => (
                <MoonStarIcon
                    strokeWidth={1.5}
                    size={16}
                    {...props}
                    color="#3358D4"
                />
            );
        }
        case "New": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <MailsIcon {...props} color="#8acaed" />
            );
        }
        case "Any status":
        case "*ANY*": {
            return (props: React.ComponentProps<typeof BoxIcon>) => (
                <BoxIcon {...props} color="#808080" />
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function getAnnouncementsFullIcon(status: string) {
    const Icon = getAnnouncementStatusIcon(status);
    switch (status) {
        case "Draft": {
            return (props: React.ComponentProps<typeof ReaderIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#F4FAFF] border border-[#D5EFFF] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "Sent": {
            return (props: React.ComponentProps<typeof EnvelopeOpenIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#FDF7FD] border border-[#F7DEF8] shadow-sm">
                    <Icon />
                </div>
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function getAnnouncementStatusIcon(status: string) {
    switch (status) {
        case "Draft": {
            return (props: React.ComponentProps<typeof ResetIcon>) => (
                <ReaderIcon {...props} color="#0090FF" />
            );
        }
        case "Sent": {
            return (props: React.ComponentProps<typeof EnvelopeOpenIcon>) => (
                <EnvelopeOpenIcon {...props} color="#AB4ABA" />
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function getTrendFullIcon(trend: string, size = 3.5) {
    const Icon = getTrendIcon(trend ?? "Unknown");
    const sizeClass = `w-${size} h-${size}`;
    switch (trend) {
        case "Increasing": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#f5e4e9] border border-[#fad2dd] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Increasing Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        case "Decreasing": {
            return (props: React.ComponentProps<typeof LapTimerIcon>) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#dff5df] border border-[#bce6bc] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Decreasing Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        case "Stagnant": {
            return (
                props: React.ComponentProps<typeof ExclamationTriangleIcon>,
            ) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#d5e9ed] border border-[#bfdbe0] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Stagnant Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        case "Variable": {
            return (props: React.ComponentProps<typeof CheckCircledIcon>) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#ebebf7] border border-[#cccced] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Variable Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                    <Icon className={sizeClass} />
                </div>
            );
        }
    }
}

export function getTrendIcon(trend: string) {
    switch (trend) {
        case "Increasing": {
            return (props: React.ComponentProps<typeof TrendingUpIcon>) => (
                <TrendingUpIcon {...props} color="#d45b7b" />
            );
        }
        case "Decreasing": {
            return (props: React.ComponentProps<typeof TrendingDownIcon>) => (
                <TrendingDownIcon {...props} color="#4d804d" />
            );
        }
        case "Stagnant": {
            return (props: React.ComponentProps<typeof MoveRightIcon>) => (
                <MoveRightIcon {...props} color="#2f7180" />
            );
        }
        case "Variable": {
            return (props: React.ComponentProps<typeof ActivityIcon>) => (
                <ActivityIcon {...props} color="#5B5BD6" />
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
    return _.isEqual(arr1, arr2);
}

export function workflowsAreEqual(
    workflowA: Workflow | undefined,
    workflowB: Workflow | undefined,
): boolean {
    if (!workflowA && !workflowB) {
        return true;
    } else if (!workflowA && workflowB) {
        return false;
    } else if (workflowA && !workflowB) {
        return false;
    }
    if (
        workflowA?.name !== workflowB?.name ||
        workflowA?.description !== workflowB?.description ||
        workflowA?.enabled !== workflowB?.enabled ||
        workflowA?.freq !== workflowB?.freq ||
        workflowA?.last_triggered !== workflowB?.last_triggered
    ) {
        return false;
    }

    if (workflowA?.steps.length !== workflowB?.steps.length) {
        return false;
    }
    // Compare each workflow step
    return (
        JSON.stringify(workflowA?.steps) === JSON.stringify(workflowB?.steps)
    );
}

export function formatUsernames(content: string) {
    if (content === undefined) {
        return "";
    }
    const userMatch = /<@([^>]+)>/g;

    const content1 = content.replace(userMatch, (match, username) => {
        return `<span class="mention">@${username}</span>`;
    });

    const channelMatch = /<#([^>]+)>/g;
    return content1.replace(channelMatch, (match, channel) => {
        return `<span class="mention">#${channel}</span>`;
    });
}

export const emojiMap = new Map<string, string>([
    ["large_green_circle", "🟢"],
    ["large_orange_circle", "🟠"],
    ["red_circle", "🔴"],
    ["flag-us", "🇺🇸"], // United States
    ["flag-ca", "🇨🇦"], // Canada
    ["flag-gb", "🇬🇧"], // United Kingdom
    ["flag-fr", "🇫🇷"], // France
    ["flag-de", "🇩🇪"], // Germany
    ["flag-it", "🇮🇹"], // Italy
    ["flag-es", "🇪🇸"], // Spain
    ["flag-jp", "🇯🇵"], // Japan
    ["flag-kr", "🇰🇷"], // South Korea
    ["flag-br", "🇧🇷"], // Brazil
    ["flag-cn", "🇨🇳"], // China
    ["flag-in", "🇮🇳"], // India
    ["flag-au", "🇦🇺"], // Australia
    ["flag-ru", "🇷🇺"], // Russia
    ["flag-se", "🇸🇪"], // Sweden
    ["flag-no", "🇳🇴"], // Norway
    ["flag-nz", "🇳🇿"], // New Zealand
    ["flag-mx", "🇲🇽"], // Mexico
    ["flag-pt", "🇵🇹"], // Portugal
    ["flag-ch", "🇨🇭"], // Switzerland
    ["flag-at", "🇦🇹"], // Austria
    ["flag-be", "🇧🇪"], // Belgium
    ["flag-dk", "🇩🇰"], // Denmark
    ["flag-fi", "🇫🇮"], // Finland
    ["flag-gr", "🇬🇷"], // Greece
    ["flag-ru", "🇷🇺"], // Russia
    ["flag-ua", "🇺🇦"], // Ukraine
    ["flag-ph", "🇵🇭"], // Philippines
    ["flag-za", "🇿🇦"], // South Africa
    ["flag-tw", "🇹🇼"], // Taiwan
    ["flag-id", "🇮🇩"], // Indonesia
    ["satellite_antenna", "📡"],
    ["waving_white_flag", "🏳️"],
]);

export function formatEmojis(content: string) {
    return content.replace(/(:[\w-]+:)/g, (match) => {
        const emojiName = match.slice(1, -1);
        // console.log("emoji name is", emojiName);
        return emojiMap.get(emojiName) || match;
    });
}

export function cleanText(text: string): string {
    // Replace HTML tags with an empty string
    const htmlTagRegex = /<[^>]*>/g;
    let cleanedText = text.replace(htmlTagRegex, " ");

    // Remove images
    cleanedText = cleanedText.replace(/!\[.*?\]\(.*?\)/g, "");
    // Remove headers
    cleanedText = cleanedText.replace(/^#.*$/gm, "");
    // Remove blockquotes
    cleanedText = cleanedText.replace(/^> .*/gm, "");

    // Replace newlines with a single space and trim leading/trailing spaces
    return cleanedText.replace(/(\r\n|\n|\r)/gm, " ").trim();
}

export function getHtmlStringFromReactContent(
    reactElement: React.ReactNode,
): string {
    return ReactDOMServer.renderToString(reactElement);
}

export function replaceInlineImageDataForGmail(
    content: string,
    files: MyFile[],
    commentID: string,
): string {
    const div = document.createElement("div");
    div.innerHTML = content;

    const allImages = div.querySelectorAll("img");
    const processedSrcs = new Set();

    // biome-ignore lint/complexity/noForEach: <explanation>
    allImages.forEach((img) => {
        const src = img.getAttribute("src");
        if (processedSrcs.has(src)) return;
        processedSrcs.add(src);

        const isInlineImage =
            img.previousSibling?.nodeType === Node.TEXT_NODE ||
            img.nextSibling?.nodeType === Node.TEXT_NODE;

        const isSignatureImage = img.closest(
            [
                "table.MsoNormalTable",
                '[data-smartmail="gmail_signature"]',
                ".gmail_signature",
                ".signature",
                '[class*="signature"]',
            ].join(","),
        );

        img.style.cursor = "pointer";
        img.style.maxWidth = isSignatureImage ? "85px" : "200px";
        img.style.height = "auto";
        img.classList.add("inline-email-image");
        if (isInlineImage) {
            img.style.display = "inline-block";
            img.style.verticalAlign = "middle";
        }

        // Handle CID images
        if (src?.startsWith("cid:")) {
            const cidName = src.replace("cid:", "");
            const matchingFile = files.find(
                (file) => file.inline_content_id === cidName,
            );

            if (matchingFile) {
                img.src = matchingFile.url_local;
                img.setAttribute("data-file-id", matchingFile.id);
                img.setAttribute("data-comment-id", commentID);

                // Handle dimensions if available
                if (matchingFile.width && matchingFile.height) {
                    try {
                        const width = Number.parseInt(matchingFile.width);
                        const height = Number.parseInt(matchingFile.height);
                        if (width > 200) {
                            const aspectRatio = height / width;
                            img.style.width = "200px";
                            img.style.height = `${200 * aspectRatio}px`;
                        } else {
                            img.style.width = `${width}px`;
                            img.style.height = "auto";
                        }
                    } catch (err) {
                        console.error("Failed to parse image dimensions:", err);
                    }
                }
            } else {
                // Create spinner container only for missing CID images
                const container = document.createElement("div");
                container.style.display = "flex";
                container.style.alignItems = "center";
                container.style.gap = "8px";

                const spinner = document.createElement("div");
                spinner.className =
                    "w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin";

                container.appendChild(img.cloneNode(true));
                container.appendChild(spinner);
                img.parentNode?.replaceChild(container, img);
            }
        } else if (src) {
            // Handle public URLs
            img.setAttribute("data-public-url", src);
            img.setAttribute("data-comment-id", commentID);
        }
    });

    return div.innerHTML;
}

export function findNonInlineAttachmentsForGmail(
    content: string,
    files: MyFile[],
): MyFile[] {
    const div = document.createElement("div");
    div.innerHTML = content;

    // Find all img elements with content id which indicates inline image
    const images = div.querySelectorAll('img[src^="cid:"]');
    const usedContentIds = new Set<string>();
    // biome-ignore lint/complexity/noForEach: <explanation>
    images.forEach((img) => {
        const cidName = img.getAttribute("src")?.replace("cid:", "");
        if (cidName) {
            usedContentIds.add(cidName);
        }
    });

    // If the file has an inline_content_id but it's not used in the content,
    // or if it doesn't have an inline_content_id at all, it's an attachment
    const nonInlineFiles = files.filter((file) => {
        return (
            !file.inline_content_id ||
            !usedContentIds.has(file.inline_content_id)
        );
    });

    return nonInlineFiles;
}

export const getIconForType = (type: string) => {
    switch (type) {
        case "Tag":
            return <TagIcon className="w-3 h-3" />;
        case "Topic":
        case "Add Topic":
            return <ListChecksIcon className="w-3 h-3" />;
        case "Remove Topic":
            return <ListXIcon className="w-3 h-3" />;
        case "Status":
        case "Insight Status":
            return <StarIcon className="w-3.5 h-3.5" />;
        case "Assignee":
            return <PersonIcon className="w-3.5 h-3.5" />;
        default:
            return null;
    }
};

export const getLabelForType = (type: string) => {
    switch (type) {
        case "Tag":
            return "Category";
        case "Topic":
            return "Tag";
        case "Add Topic":
            return "Add Tag";
        case "Remove Topic":
            return "Remove Tag";
        case "Status":
        case "Insight Status":
            return "Status";
        default:
            return type;
    }
};

export function saveTag(
    tag: string,
    api: AxiosInstance,
    issueGroupInfos: IssueGroupInfo[],
    updateIssueState: (
        newState: Partial<Query>,
        issueGroup: IssueGroupInfo,
    ) => void,
    userID: string,
) {
    const requestData: TicketTag = {
        ids: issueGroupInfos.map((ig) => ig.issueId),
        tag: tag,
        source: "Web",
        user_id: userID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            for (const issueGroup of issueGroupInfos) {
                updateIssueState({ bot_category: tag }, issueGroup);
            }
            console.log(`Updated tag to ${tag} successfully`);
        } else {
            console.log("Call to update tag failed");
        }
    });
}

export const toggleTopicSelection = (
    topic: string,
    api: AxiosInstance,
    issueGroupInfos: IssueGroupInfo[],
    updateIssueState: (
        newState: Partial<Query>,
        issueGroup: IssueGroupInfo,
    ) => void,
    userID: string,
    add: boolean,
) => {
    let addedTopics: string[] = [];
    let deletedTopics: string[] = [];

    if (add) {
        addedTopics = [topic];
    } else {
        deletedTopics = [topic];
    }

    saveTopics(addedTopics, deletedTopics, api, userID, issueGroupInfos).then(
        (success: boolean) => {
            if (success) {
                for (const issueGroup of issueGroupInfos) {
                    // Create a deep copy of the current issue's topics
                    const currentIssueTopics = [
                        ...(issueGroup.issueTopics ?? []),
                    ];

                    // Calculate new topics specific to this issue
                    let newTopicsSelected: string[];
                    if (add) {
                        // Only add if the topic isn't already present for this specific issue
                        newTopicsSelected = currentIssueTopics.includes(topic)
                            ? currentIssueTopics
                            : [...currentIssueTopics, topic];
                    } else {
                        // Remove topic only from this specific issue
                        newTopicsSelected = currentIssueTopics.filter(
                            (t) => t !== topic,
                        );
                    }

                    // Update this specific issue's topics
                    issueGroup.issueTopics = [...newTopicsSelected];

                    // Update state with a new array reference for this specific issue
                    updateIssueState(
                        {
                            topic: [...newTopicsSelected],
                        },
                        issueGroup,
                    );
                }
            }
        },
    );
};

export async function saveTopics(
    addedTopics: string[],
    deletedTopics: string[],
    api: AxiosInstance,
    userID: string,
    issueGroupInfos: IssueGroupInfo[],
): Promise<boolean> {
    const requestData: TicketTopics = {
        ids: issueGroupInfos.map((ig) => ig.issueId),
        added_topics: addedTopics,
        deleted_topics: deletedTopics,
        source: "Web",
        user_id: userID,
    };

    try {
        const res = await api.patch(
            URLS.serverUrl + API.saveTicket,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        if (res.status === 200) {
            console.log("Updated topics successfully");
            return true;
        } else {
            console.log("Call to update topics failed");
            return false;
        }
    } catch (error) {
        console.log("Error updating topics:", error);
        return false;
    }
}

export function saveStatus(
    status: string,
    api: AxiosInstance,
    issueGroupInfos: IssueGroupInfo[],
    updateIssueState: (
        newState: Partial<Query>,
        issueGroup: IssueGroupInfo,
        newGroup?: string,
    ) => void,
    userID: string,
    refetch: (
        options?: RefetchOptions,
    ) => Promise<
        QueryObserverResult<
            InfiniteData<QueriesWithPaginationResponse, unknown>,
            Error
        >
    >,
) {
    for (const issueGroup of issueGroupInfos) {
        updateIssueState({ disabled: true }, issueGroup);
    }
    const requestData: TicketStatus = {
        ids: issueGroupInfos.map((ig) => ig.issueId),
        status: status,
        source: "Web",
        user_id: userID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            for (const issueGroup of issueGroupInfos) {
                updateIssueState(
                    { ticket_status: status, disabled: false },
                    issueGroup,
                    status,
                );
            }
        } else {
            console.log("Call to update status failed");
        }
    });
}

export function saveAssignee(
    assigneeID: string,
    api: AxiosInstance,
    issueGroupInfos: IssueGroupInfo[],
    updateIssueState: (
        newState: Partial<Query>,
        issueGroup: IssueGroupInfo,
    ) => void,
    userID: string,
) {
    const requestData = {
        ids: issueGroupInfos.map((ig) => ig.issueId),
        source: "Web",
        user_id: userID,
        assignee_user_id: assigneeID === "noAssignee" ? "" : assigneeID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            for (const issueGroup of issueGroupInfos) {
                updateIssueState({ assignee_user_id: assigneeID }, issueGroup);
            }
            console.log(`Updated assignee to ${assigneeID} successfully`);
        } else {
            console.log("Call to update assignee failed");
        }
    });
}

export const mapsEqual = (
    mapA: Map<string, Set<string>>,
    mapB: Map<string, Set<string>>,
) => {
    if (mapA.size !== mapB.size) return false;
    for (const [key, value] of Array.from(mapA.entries())) {
        if (!mapB.has(key) || mapB.get(key) !== value) {
            return false;
        }
    }
    return true;
};

export const toggleTeamSelection = (team: Teams, tagTeams: Teams[]) => {
    let newTagTeams = Array.from(tagTeams);
    if (newTagTeams.map((t) => t.id).includes(team.id)) {
        // Can't untoggle workspace if that's the only option selected
        if (team.team_name !== "Workspace") {
            // Delete the team if it's already in tag teams
            newTagTeams = newTagTeams.filter((t) => t.id !== team.id);
        }
    } else {
        // Workspace represents an organization level setting so if selected, it should not include other teams
        if (
            team.team_name === "Workspace" ||
            newTagTeams.map((t) => t.team_name).includes("Workspace")
        ) {
            newTagTeams = [team];
        } else {
            // Add the team if it's not there yet
            newTagTeams.push(team);
        }
    }
    return newTagTeams;
};

export const toggleCompanySelection = (
    company: Account,
    customerCompany: Account | undefined,
) => {
    // Removing customer from company
    if (customerCompany?.id === company.id) {
        return undefined;
    } else {
        return company;
    }
};

// Temporary solution to resolve the slightly different Ticket & Query types
// TODO: these two types should be combined in the future
export function convertTicketToQuery(ticket: Ticket): Query {
    return {
        id: ticket.id,
        ticket_identifier: ticket.ticket_identifier,
        ticket_number: ticket.number,
        number: ticket.number,
        title: ticket.title,
        query: ticket.query,
        created_at: ticket.created_at,
        ticket_updated_at: ticket.updated_at,
        bot_category: ticket.bot_category,
        source: ticket.source || undefined,
        topic: ticket.topic,
        ticket_status: ticket.ticket_status,
        url: ticket.url,
        source_specific_id: ticket.source_specific_id,
        ai_response: ticket.ai_response || undefined,
        internal_note: ticket.internal_note || undefined,
        assignee_user_id: ticket.assignee_user_id || undefined,
        external_issues: ticket.external_issues.map((issue) =>
            issue.toString(),
        ),
        breaching: ticket.breaching || undefined,
        user_info: {
            id: ticket.assignee_user_id || "",
            name: "", // Placeholder for the name
            email: "", // Placeholder for email
        },
        assembly_responded: false, // Placeholder value
        business_hours_id: ticket.business_hours_id || undefined,
        original_timestamp: ticket.created_at, // Placeholder for the original timestamp
        disabled: false, // Placeholder for the disabled flag
        teams: ticket.teams,
        user: ticket.assignee_user_id || "Unknown", // Use assignee_user_id or a fallback
        time_created: ticket.created_at,
        time_responded: ticket.updated_at,
        time_closed: "", // Placeholder, should be set if ticket has a closed date
        source_unique_name: ticket.source_unique_name,
    };
}

export const insightRelatedInteractionsBadge = (count: number) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="gray"
                        size="2"
                        radius="full"
                        variant="soft"
                        className="m-0.5"
                    >
                        <div className="flex flex-row items-center">
                            <CaretUpIcon />
                            <p className="pl-0.5">{count}</p>
                        </div>
                    </Badge>
                </TooltipTrigger>

                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[85px] text-center z-50">
                        <p>Interaction Count</p>
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const insightMonetaryValueBadge = (monetary_value: number) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color={"gray"}
                        size="2"
                        radius="medium"
                        variant="outline"
                        className="ring-[0.8px] text-gray-700 ring-[#E0E1E6] flex items-center gap-0.5 "
                    >
                        <DollarSignIcon strokeWidth={1.5} size={11} />
                        <p className="text-xs">
                            {Number.isInteger(monetary_value)
                                ? monetary_value.toString()
                                : monetary_value.toFixed(2)}
                        </p>
                    </Badge>
                </TooltipTrigger>
                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[120px] text-center z-50">
                        <p>Monetary Value Per Month</p>
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const handleAccountBadgeClick = (
    account: AccountMonetaryRecord,
    event?: React.MouseEvent,
) => {
    if (event) {
        event.stopPropagation();
    }
    switch (account.type) {
        case "Company":
            window.open(
                `${process.env.REACT_APP_CALLBACK_URL}accounts/${account.id}`,
                "_blank",
            );
            return;
        case "Customer":
            window.open(
                `${process.env.REACT_APP_CALLBACK_URL}accounts/individual/${account.id}`,
                "_blank",
            );
            return;
        default:
            return;
    }
};

export const insightAccountBadge = (
    c: AccountMonetaryRecord,
    handleClick: (
        account: AccountMonetaryRecord,
        event?: React.MouseEvent,
    ) => void,
) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color={c.type === "Company" ? "blue" : "orange"}
                        size="2"
                        radius="medium"
                        variant="outline"
                        className="text-xs px-2 py-1.5 flex items-center gap-1 text-gray-700 bg-gray-50 cursor-pointer"
                        key={c.id}
                        onClick={(event) => handleAccountBadgeClick(c, event)}
                    >
                        {c.image_url !== "" && c.image_url !== undefined ? (
                            <div className="lb-avatar rounded w-4 h-4">
                                <img
                                    className="lb-avatar-image"
                                    src={c.image_url}
                                    alt={c.name}
                                />
                            </div>
                        ) : (
                            <div className="lb-avatar rounded w-4 h-4">
                                <ImageIcon className="w-4 h-4" />
                            </div>
                        )}
                        {c.name}
                    </Badge>
                </TooltipTrigger>
                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[105px] text-center z-50 flex items-center gap-1">
                        <p>
                            {c.type === "Company"
                                ? "Company Customer"
                                : "Individual Customer"}
                        </p>
                        <OpenInNewWindowIcon className="w-5 h-5" />
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const insightAdditionalAccountsBadge = (
    numRemainingCustomers: number,
) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="gray"
                        size="2"
                        radius="medium"
                        variant="outline"
                        className="text-xs px-2 py-1.5 flex items-center gap-0.5 text-gray-700 bg-gray-50"
                    >
                        <PlusIcon className="w-3 h-3" />
                        {numRemainingCustomers}
                    </Badge>
                </TooltipTrigger>
                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[88px] text-center z-50">
                        <p>{`+${numRemainingCustomers} More Customer${numRemainingCustomers === 1 ? "" : "s"}`}</p>
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const getFilterOption = (
    type: string,
    filterType: FilterType,
    showTrigger: boolean,
) => {
    return {
        type: type,
        filterType: filterType,
        showTrigger: showTrigger,
    };
};

export const lastInteractionBadge = (last_ticket: CustomerTicket) => {
    let lastTicketDate = new Date(last_ticket?.created_at);
    if (Number.isNaN(lastTicketDate.getTime()) || !last_ticket?.created_at) {
        lastTicketDate = new Date();
    }

    const today = new Date();

    const isToday =
        lastTicketDate.getDate() === today.getDate() &&
        lastTicketDate.getMonth() === today.getMonth() &&
        lastTicketDate.getFullYear() === today.getFullYear();

    let date: string;
    if (isToday) {
        const userLocale = navigator.language || "en-US";
        date = lastTicketDate.toLocaleTimeString(userLocale, {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    } else {
        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        date = lastTicketDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="iris"
                        size="1"
                        radius="medium"
                        variant="soft"
                        className="text-[11px] px-2 py-1 items-center"
                    >
                        <div className="flex flex-row gap-1.5">
                            <MailboxIcon className="h-4 w-4" />
                            <p>{date}</p>
                        </div>
                    </Badge>
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6]">
                    <p>Last Interaction</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export const ticketCountBadge = (status: string, count: number) => {
    const Icon = getStatusIcon(status ?? "Unknown");
    const type = status === "NeedsResponse" ? "Needs Response" : status;
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        size="1"
                        color="gray"
                        radius="medium"
                        variant={undefined}
                        className={`text-xs text-gray px-2 py-1 items-center border ${type === "Breaching" ? "bg-[#FFF6F8] border-[#FFE9ED]" : "bg-[#EEF1F0] border-[#E6E9E8]"}`}
                    >
                        <div className="flex flex-row items-center gap-1.5">
                            {Icon ? (
                                <Icon />
                            ) : (
                                <QuestionMarkCircledIcon className="w-2 h-2" />
                            )}
                            <p>{type}</p>
                            <p>{count}</p>
                        </div>
                    </Badge>
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6]">
                    <p>{`Number of ${type} Interactions`}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export const healthScoreBadge = (score: number, showExactScore: boolean) => {
    // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
    let flag;
    let color = "yellow";

    if (score < 2) {
        flag = RedFlagIcon;
        color = "red";
    } else if (score < 4) {
        flag = YellowFlagIcon;
        color = "yellow";
    } else {
        flag = GreenFlagIcon;
        color = "green";
    }

    if (showExactScore) {
        return (
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Badge
                            size="1"
                            color={color}
                            radius="medium"
                            variant="outline"
                            className="flex items-center gap-1 bg-transparent py-1 px-2"
                        >
                            {score}
                            <img
                                src={flag}
                                alt="greenFlag"
                                className="h-3.5 w-3.5"
                            />
                        </Badge>
                    </TooltipTrigger>
                    <TooltipContent className="bg-[#5B5BD6]">
                        <p>Health Score</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        );
    } else {
        return (
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Badge
                            size="1"
                            radius="medium"
                            className="text-xs text-gray items-center bg-transparent px-0"
                        >
                            <img
                                src={flag}
                                alt="greenFlag"
                                className="h-3.5 w-3.5"
                            />
                        </Badge>
                    </TooltipTrigger>
                    <TooltipContent className="bg-[#5B5BD6]">
                        <p>{`Health Score: ${score}`}</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        );
    }
};

export const getNavLink = (
    account: Account,
    listType: AccountsListType,
    teamID?: string,
) => {
    if (account.account_type === "Company") {
        if (listType === AccountsListType.Team && teamID) {
            return `/teams/${teamID}/accounts/${account.id}`;
        } else {
            return `/accounts/${account.id}`;
        }
    }
    if (listType === AccountsListType.Team && teamID) {
        return `/teams/${teamID}/accounts/individual/${account.id}`;
    } else {
        return `/accounts/individual/${account.id}`;
    }
};

export const assigneeBadge = (
    assignee_user_id: string | undefined,
    users: GetUserResponse[],
) => {
    const foundUser: GetUserResponse | undefined = users.find(
        (user) => user.id === assignee_user_id,
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;
    return (
        <TooltipProvider>
            {assignee_user_id && assignee_user_id !== "" ? (
                <Tooltip>
                    <TooltipTrigger asChild>
                        <div className="lb-avatar rounded-lg w-5 h-5 mx-1">
                            {pictureURL && (
                                <img
                                    className="lb-avatar-image"
                                    src={pictureURL}
                                    alt={userName}
                                />
                            )}
                            <span>{userName ?? ""}</span>
                        </div>
                    </TooltipTrigger>
                    <TooltipContent className="bg-[#5B5BD6]">
                        <p>{`Assignee: ${userName}`}</p>
                    </TooltipContent>
                </Tooltip>
            ) : (
                <Tooltip>
                    <TooltipTrigger asChild>
                        <AvatarIcon className="w-5 h-5 mx-1" />
                    </TooltipTrigger>
                    <TooltipContent className="bg-[#5B5BD6]">
                        <p>No Assignee</p>
                    </TooltipContent>
                </Tooltip>
            )}
        </TooltipProvider>
    );
};

export const getBadgeForTeam = (
    team_name: string,
    includeArrow?: boolean,
    collapsed?: boolean,
) => {
    switch (team_name) {
        case "Workspace":
            return collapsed ? (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div>
                                <AttributesBadge className="text-xs font-normal">
                                    <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                                        <HotelIcon className="text-blue9 w-2 h-2 !size-2" />
                                    </div>
                                    {includeArrow && (
                                        <TriangleDownIcon className="h-3 w-3" />
                                    )}
                                </AttributesBadge>
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6] py-1.5 px-3 flex flex-col z-50">
                            Workspace
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            ) : (
                <AttributesBadge className="text-xs font-normal">
                    <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                        <HotelIcon className="text-blue9 w-2 h-2 !size-2" />
                    </div>
                    Workspace
                    {includeArrow && <TriangleDownIcon className="h-3 w-3" />}
                </AttributesBadge>
            );
        case "General":
        case "NoTeamOwner":
        case "Unassigned...":
            return collapsed ? (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div>
                                <AttributesBadge className="text-xs font-normal">
                                    <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                        <HouseIcon className="text-iris9 w-2 h-2 !size-2" />
                                    </div>
                                    {includeArrow && (
                                        <TriangleDownIcon className="h-3 w-3" />
                                    )}
                                </AttributesBadge>
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6] py-1.5 px-3 flex flex-col z-50">
                            {team_name === "General"
                                ? team_name
                                : "No Team Owner"}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            ) : (
                <AttributesBadge className="text-xs font-normal">
                    <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                        <HouseIcon className="text-iris9 w-2 h-2 !size-2" />
                    </div>
                    {team_name === "NoTeamOwner" ? "No Team Owner" : team_name}
                    {includeArrow && <TriangleDownIcon className="h-3 w-3" />}
                </AttributesBadge>
            );
        default:
            return collapsed ? (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div>
                                <AttributesBadge className="text-xs font-normal">
                                    <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                        <UsersIcon className="text-red9 w-2 h-2 !size-2" />
                                    </div>
                                    {includeArrow && (
                                        <TriangleDownIcon className="h-3 w-3" />
                                    )}
                                </AttributesBadge>
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6] py-1.5 px-3 flex flex-col z-50">
                            {team_name}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            ) : (
                <AttributesBadge className="text-xs font-normal">
                    <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                        <UsersIcon className="text-red9 w-2 h-2 !size-2" />
                    </div>
                    {team_name}
                    {includeArrow && <TriangleDownIcon className="h-3 w-3" />}
                </AttributesBadge>
            );
    }
};

export const getAccountsLabelType = (type: AccountsLabelsType) => {
    switch (type) {
        case AccountsLabelsType.Tier:
            return "Tier";
        case AccountsLabelsType.Stage:
            return "Stage";
        case AccountsLabelsType.CompanyType:
            return "Company Type";
    }
};

export const getAccountsLabelIcon = (
    type: AccountsLabelsType,
    color?: string,
    size?: number,
) => {
    const sizeClass = size ? `h-${size} w-${size}` : "h-4 w-4";
    switch (type) {
        case AccountsLabelsType.Tier:
            return <SparklesIcon color={color} className={sizeClass} />;
        case AccountsLabelsType.Stage:
            return <Eclipse color={color} className={sizeClass} />;
        case AccountsLabelsType.CompanyType:
            return <StoreIcon color={color} className={sizeClass} />;
    }
};

export const getBadgeForAccountsLabel = (
    label: AccountsLabel,
    condensed?: boolean,
) => {
    return (
        <div className="flex items-center flex-wrap gap-2">
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger>
                        {condensed ? (
                            <ScnBadge
                                className="flex items-center gap-2 font-normal px-2 py-1.5"
                                variant="outline"
                            >
                                {getAccountsLabelIcon(label.type, label.color)}
                            </ScnBadge>
                        ) : (
                            <ScnBadge
                                className="flex items-center gap-2 font-normal px-2 py-1.5"
                                variant="outline"
                            >
                                {getAccountsLabelIcon(label.type, label.color)}
                                {label.name}
                            </ScnBadge>
                        )}
                    </TooltipTrigger>
                    <TooltipContent className="bg-[#5B5BD6]">
                        <p className="flex items-center gap-1">
                            <p className="font-semibold">
                                {getAccountsLabelType(label.type)}:
                            </p>{" "}
                            {label.name}
                        </p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};

export function convertAccountLabelsToMap(
    labels: AccountsLabel[],
    accountType: string,
): Map<AccountsLabelsType, AccountsLabel | undefined> {
    const newLabelsByGroup = new Map<
        AccountsLabelsType,
        AccountsLabel | undefined
    >([
        [
            AccountsLabelsType.Tier,
            labels.find((al) => al.type === AccountsLabelsType.Tier) ??
            undefined,
        ],
        [
            AccountsLabelsType.Stage,
            labels.find((al) => al.type === AccountsLabelsType.Stage) ??
            undefined,
        ],
    ]);

    if (accountType === "Company") {
        newLabelsByGroup.set(
            AccountsLabelsType.CompanyType,
            labels.find((al) => al.type === AccountsLabelsType.CompanyType) ??
            undefined,
        );
    }
    return newLabelsByGroup;
}

export const convertMarkdownToPlainText = (markdown: string): string => {
    let plainText = markdown;

    // Remove bold formatting (both **bold** and __bold__)
    plainText = plainText.replace(/(\*\*|__)(.*?)\1/g, "$2");

    // Remove italic formatting (both *italic* and _italic_)
    plainText = plainText.replace(/(\*|_)(.*?)\1/g, "$2");

    // Remove strikethrough formatting (~~strikethrough~~)
    plainText = plainText.replace(/~~(.*?)~~/g, "$1");

    // Remove inline code formatting (e.g. `code`)
    plainText = plainText.replace(/`(.*?)`/g, "$1");

    // Remove unordered list formatting (e.g. - item or * item)
    plainText = plainText.replace(/^(\*|\-|\+)\s+(.*)/gm, "$2");

    // Remove ordered list formatting (e.g. 1. item)
    plainText = plainText.replace(/^\d+\.\s+(.*)/gm, "$1");

    // Remove blockquote formatting (e.g. > blockquote)
    plainText = plainText.replace(/^>\s+(.*)/gm, "$1");

    // Remove any remaining Markdown headers (e.g. # Header, ## Header, ### Header)
    plainText = plainText.replace(/^#+\s+/gm, "");

    // Return the plain text without any Markdown formatting
    return plainText.trim();
};

export function substituteVariablesInMarkdown(
    content: string,
    customerInfo?: UserResponse,
    customerAccount?: Account,
    companyAccount?: Account,
    aiResponse?: string,
): string {
    return content.replace(/{{(.*?)}}/g, (match, variableName) => {
        let value: string;
        switch (variableName) {
            case "customer name":
                value = customerAccount?.name || customerInfo?.name || match;
                break;
            case "customer username":
                value = customerInfo?.username || match;
                break;
            case "customer email":
                value = customerInfo?.email || match;
                break;
            case "company name":
                if (companyAccount?.name) {
                    value = companyAccount?.name || match;
                } else if (customerAccount?.company) {
                    value = customerAccount.company?.name || match;
                } else {
                    value = match;
                }
                break;
            case "ai response":
                value = aiResponse || match;
                break;
            default:
                value = match; // Return the original match if variable name is not recognized
                break;
        }
        return value;
    });
}

// Define your custom transformer for variables
export const VARIABLE_TRANSFORMER: TextMatchTransformer = {
    dependencies: [TextNode],

    // Export function: Convert the TextVariableNode back to the format {{variable}}
    export: (node, exportChildren, exportFormat) => {
        if (node instanceof VariableNode) {
            return `{{${node.getTextContent()}}}`;
        }
        return null;
    },

    importRegExp: /{{([^}]+)}}/,
    regExp: /{{([^}]+)}}/,

    // Replace function: When a match is found, replace it with a TextVariableNode
    replace: (node, match) => {
        if (match?.[1]) {
            const variableNode = new VariableNode(match[1]);
            node.replace(variableNode);
        }
    },
    // Trigger (not required but can be used to explicitly specify how to trigger transformation)
    trigger: "{{",
    type: "text-match",
};

const IMAGE_TRANSFORMER = {
    dependencies: [ImageNode],
    importRegExp: /!\[([^\]]*)\]\(([^)]+)\)/,
    export: (node) => {
        if (!$isImageNode(node)) {
            return null;
        }
        return `![${node.getAltText()}](${node.getSrc()})`;
    },
    replace: (textNode, match) => {
        const [, altText, src] = match;
        const imageNode = $createImageNode({
            altText,
            src,
        });
        textNode.replace(imageNode);
    },
    type: "text-match",
};

export const TRANSFORMERS: Transformer[] = [
    IMAGE_TRANSFORMER, // Resolve images before links in markdown
    ...originalTransformers,
    VARIABLE_TRANSFORMER,
] as Transformer[];

// Component to render {{variable}} tags
export const VariableTag = ({ variable }: { variable: string }) => {
    return (
        <span
            style={{
                backgroundColor: "transparent",
                border: "1px solid #5B5BD6",
                padding: "2px 5px",
                margin: "1px 3px",
                borderRadius: "6px",
                color: "#5B5BD6",
                fontSize: "14px",
                fontWeight: "bold",
                userSelect: "none",
            }}
        >
            {variable}
        </span>
    );
};

export const renderVariables = (text: string) => {
    const regex = /{{(.*?)}}/g;
    return text.replace(regex, (match, content) => {
        return `<span style="background-color: transparent; 
                                border: 1px solid #5B5BD6; 
                                padding: 1px 4px; 
                                margin: 1px 2px; 
                                border-radius: 6px; 
                                color: #5B5BD6; 
                                font-size: 11px; 
                                line-height: 1.4;
                                display: inline-block;
                                user-select: none;" 
                    contenteditable="false">
                    ${content}
                </span>`;
    });
};

const urlRegExp = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
);
export function validateUrl(url: string): boolean {
    return url === "https://" || urlRegExp.test(url);
}

const URL_REGEX =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
const EMAIL_REGEX =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
export const MATCHERS = [
    createLinkMatcherWithRegExp(URL_REGEX, (text) => {
        return text;
    }),
    createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => {
        return `mailto:${text}`;
    }),
];

export const getTopicColors = (topics: GetTopicsResponse[]) => {
    return topics.map((topic) => ({
        color: topic.color ?? "#9B9EF0",
        label: topic.topic_name,
        value: topic.topic_name,
        key: topic.id,
    }));
};

export const getInteractionsHeading = (
    listType: IssueListType,
    viewName?: string,
    teamName?: string,
    includeInteractions?: boolean,
): string => {
    if (listType === IssueListType.View && viewName) {
        return includeInteractions ? `${viewName} Interactions` : viewName;
    } else if (listType === IssueListType.Team && teamName) {
        return includeInteractions ? `${teamName} Interactions` : teamName;
    }
    switch (listType) {
        case IssueListType.Inbox:
            return "Inbox";
        case IssueListType.Issues:
            return "Interactions";
        default:
            return "Interactions";
    }
};

export const getInteractionsIcon = (listType: IssueListType, size: number) => {
    const iconSize = `${size}`;
    switch (listType) {
        case IssueListType.Inbox:
            return <DrawingPinIcon className={`h-${iconSize} w-${iconSize}`} />;
        case IssueListType.Issues:
            return <MailboxIcon className={`h-${iconSize} w-${iconSize}`} />;
        case IssueListType.Team:
            return <UsersIcon className={`h-${iconSize} w-${iconSize}`} />;
        case IssueListType.View:
            return <LayersIcon className={`h-${iconSize} w-${iconSize}`} />;
        default:
            return <MailboxIcon className={`h-${iconSize} w-${iconSize}`} />;
    }
};

export const SavedViewBadge = (name: string, className?: string) => {
    return (
        <ScnBadge
            variant="outline"
            className={`gap-1 py-1 px-1.5 text-xs font-normal ${className}`}
        >
            <div className="flex items-center justify-center rounded-lg p-1 bg-gray3 border border-gray4 shadow-sm">
                <LayersIcon strokeWidth={1.5} size={12} />
            </div>
            {name}
        </ScnBadge>
    );
};

export const lastTriggered = (workflow: Workflow) => {
    let lastTriggeredDate = new Date(workflow?.last_triggered);
    if (
        Number.isNaN(lastTriggeredDate.getTime()) ||
        !workflow?.last_triggered
    ) {
        lastTriggeredDate = new Date();
    }

    const today = new Date();

    const isToday =
        lastTriggeredDate.getDate() === today.getDate() &&
        lastTriggeredDate.getMonth() === today.getMonth() &&
        lastTriggeredDate.getFullYear() === today.getFullYear();

    let date: string;
    if (isToday) {
        const userLocale = navigator.language || "en-US";
        date = lastTriggeredDate.toLocaleTimeString(userLocale, {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    } else {
        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        date = lastTriggeredDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }

    return workflow.last_triggered ? (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="iris"
                        size="1"
                        radius="medium"
                        variant="soft"
                        className="text-[11px] px-2 py-1 items-center"
                    >
                        <div className="flex flex-row items-center gap-1.5">
                            <TargetIcon
                                className="text-[#5e6ad2]"
                                strokeWidth={1.5}
                                size={12}
                            />
                            <p>{date}</p>
                        </div>
                    </Badge>
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6]">
                    <p>Last Triggered</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    ) : (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="iris"
                        size="1"
                        radius="medium"
                        variant="soft"
                        className="text-[11px] px-2 py-1 items-center"
                    >
                        <div className="flex flex-row items-center gap-1.5">
                            <TargetIcon
                                className="text-[#5e6ad2]"
                                strokeWidth={1.5}
                                size={12}
                            />
                            Never
                        </div>
                    </Badge>
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6]">
                    <p>Never Triggered</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export const workflowTypeBadge = (workflow: Workflow) => {
    if (
        workflow?.type &&
        workflow?.type.id !== "00000000-0000-0000-0000-000000000000"
    ) {
        return (
            <Badge
                variant="soft"
                size="2"
                className="text-gray-500"
                style={{
                    backgroundColor: workflow.type?.color,
                }}
            >
                {workflow.type.name}
            </Badge>
        );
    }
};

export const workflowFrequencyBadge = (workflow: Workflow) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="gray"
                        size="1"
                        radius="full"
                        variant="soft"
                        className="m-0.5 text-[11px]"
                    >
                        <div className="flex flex-row items-center gap-0.5">
                            <CaretUpIcon />
                            {workflow.freq}
                        </div>
                    </Badge>
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6]">
                    <p>Frequency</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export const workflowEnabled = (workflow: Workflow) => {
    if (workflow.enabled) {
        return (
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <CheckCircledIcon className="text-green-500 w-5 h-5" />
                    </TooltipTrigger>
                    <TooltipContent className="bg-[#5B5BD6]">
                        <p>Workflow is Enabled</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        );
    } else {
        return (
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <CrossCircledIcon className="text-red-500 w-5 h-5" />
                    </TooltipTrigger>
                    <TooltipContent className="bg-[#5B5BD6]">
                        <p>Workflow is Disabled</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        );
    }
};

export function capitalizeType(str: string): string {
    if (str === "interactiontype") return "InteractionType";
    if (!str) return str;

    // Split the string into words, capitalize each word, and join them back
    return str
        .split(" ") // Split by spaces
        .map(
            (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(" ");
}

export const WorkflowNodesDropdownTrigger = (
    type: string,
    filters: Map<
        string,
        Set<{ label: string; value: string; key: string; color: string }>
    >,
    users?: GetUserResponse[],
    customers?: Account[],
    companies?: Account[],
    interactionTypes?: Label[],
    categories?: Category[],
    topics?: Topic[],
    index?: number,
) => {
    let typeSet:
        | Set<{
            label: string;
            value: string;
            key: string;
            color: string;
        }>
        | undefined;
    if (index !== undefined) {
        typeSet = filters.get(`${type}_${index}`);
    } else {
        typeSet = filters.get(type);
    }
    const currOption =
        typeSet && typeSet.size === 1
            ? Array.from(typeSet)[0]
            : {
                label: `Select ${getLowercase(type)}`,
                value: `Select ${getLowercase(type)}`,
                color: "",
                key: `Select ${getLowercase(type)}`,
            };
    switch (type) {
        case "Assignee": {
            const pictureURL = (users ?? []).find(
                (user) => user.id === currOption.key,
            )?.picture_url;
            return (
                <div className="flex items-center justify-between hover:bg-muted">
                    <div className="flex items-center gap-1.5 p-1">
                        {currOption.value !== "Select assignee" && (
                            <div className="lb-avatar rounded-lg w-5 h-5">
                                {pictureURL ? (
                                    <img
                                        className="lb-avatar-image"
                                        src={pictureURL}
                                        alt={currOption.label}
                                    />
                                ) : (
                                    <AvatarIcon className="w-6 h-6" />
                                )}
                            </div>
                        )}
                        <span className="lb-comment-author text-xs font-normal font-destructive w-full truncate">
                            {currOption.label}
                        </span>
                    </div>
                    <CaretSortIcon />
                </div>
            );
        }
        case "Template": {
            return (
                <div className="flex items-center justify-between hover:bg-muted">
                    <div className="flex items-center gap-1.5 p-1">
                        {currOption.value !== "Select template" && (
                            <NotepadText className="w-4 h-4" />
                        )}
                        <span className="lb-comment-author text-xs font-normal font-destructive w-full truncate">
                            {currOption.label}
                        </span>
                    </div>
                    <CaretSortIcon />
                </div>
            );
        }
        case "Topic": {
            const defaultVals = ["Select tag", "Any tag"];
            return (
                <div className="flex items-center justify-between text-xs hover:bg-muted">
                    {currOption.value !== "Select tag" ? (
                        <Badge
                            color="gray"
                            size="2"
                            radius="full"
                            variant="outline"
                            className="m-0 rounded-xl py-1 px-2 max-w-full"
                        >
                            <div className="flex flex-row items-center max-w-full">
                                {currOption.key === "*ANY*" &&
                                    currOption.value === "Any tag" ? (
                                    <BoxIcon className="w-3 h-3 mr-0.5" />
                                ) : (
                                    <DotFilledIcon
                                        color={
                                            currOption.color !== ""
                                                ? currOption.color
                                                : (topics?.find(
                                                    (topic) =>
                                                        topic.key ===
                                                        currOption.key,
                                                )?.color ?? "#9B9EF0")
                                        }
                                        style={{
                                            transform: "scale(1.8)",
                                        }}
                                    />
                                )}
                                <p className="pl-0.3 w-full truncate">
                                    {currOption.label}
                                </p>
                            </div>
                        </Badge>
                    ) : (
                        <>
                            <div className="flex items-center gap-1 w-full truncate">
                                <p>{currOption.label}</p>
                            </div>
                            <CaretSortIcon />
                        </>
                    )}
                </div>
            );
        }
        case "Tag": {
            return (
                <div className="flex items-center justify-between text-xs hover:bg-muted">
                    {currOption.value !== "Select category" ? (
                        <Badge
                            color="gray"
                            size="2"
                            radius="full"
                            variant="outline"
                            className="m-0 rounded-xl py-1 px-2 max-w-full"
                        >
                            <div className="flex flex-row items-center max-w-full">
                                {currOption.key === "*ANY*" &&
                                    currOption.value === "Any category" ? (
                                    <BoxIcon className="w-3 h-3 mr-0.5" />
                                ) : (
                                    <ComponentBooleanIcon
                                        color={
                                            currOption?.color !== ""
                                                ? currOption.color
                                                : (categories?.find(
                                                    (category) =>
                                                        category.name ===
                                                        currOption.value,
                                                )?.color ?? "gray")
                                        }
                                    />
                                )}
                                <p className="pl-0.5 w-full truncate">
                                    {currOption.label}
                                </p>
                            </div>
                        </Badge>
                    ) : (
                        <p className="w-full truncate">{currOption.label}</p>
                    )}
                    <CaretSortIcon />
                </div>
            );
        }
        case "InteractionType": {
            return (
                <div className="flex items-center justify-between text-xs hover:bg-muted">
                    {currOption.value !== "Select interaction type" ? (
                        <Badge
                            color="gray"
                            size="2"
                            variant="outline"
                            className="m-0 py-1 px-2 max-w-full"
                        >
                            <div className="flex flex-row gap-0.5 items-center max-w-full">
                                {currOption.key === "*ANY*" &&
                                    currOption.value === "Any interaction type" ? (
                                    <BoxIcon className="w-3 h-3 mr-0.5" />
                                ) : (
                                    <MailIcon
                                        color={
                                            currOption?.color !== ""
                                                ? currOption.color
                                                : (interactionTypes?.find(
                                                    (interactionType) =>
                                                        interactionType.name ===
                                                        currOption.value,
                                                )?.color ?? "gray")
                                        }
                                        className="w-3.5 h-3.5"
                                    />
                                )}
                                <p className="pl-0.5 w-full truncate">
                                    {currOption.label}
                                </p>
                            </div>
                        </Badge>
                    ) : (
                        <p className="w-full truncate">{currOption.label}</p>
                    )}
                    <CaretSortIcon />
                </div>
            );
        }
        case "Team": {
            return (
                <div className="flex items-center justify-between text-xs hover:bg-muted">
                    <div className="flex items-center gap-1.5">
                        {currOption.value !== "Select team" &&
                            (currOption.value === "General" ? (
                                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                    <HouseIcon
                                        className="text-iris9"
                                        strokeWidth={1.5}
                                        size={10}
                                    />
                                </div>
                            ) : (
                                <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                    <UsersIcon
                                        className="text-red9"
                                        strokeWidth={1.5}
                                        size={10}
                                    />
                                </div>
                            ))}
                        <div className="w-full truncate">
                            {currOption.label}
                        </div>
                    </div>
                    <CaretSortIcon />
                </div>
            );
        }
        case "Status": {
            const Icon = getStatusIcon(currOption.key ?? "Unknown");
            return (
                <div className="flex items-center justify-between text-xs hover:bg-muted">
                    <div className="flex items-center gap-2">
                        {currOption.value !== "Select status" && (
                            <Icon className="w-4 h-4" />
                        )}
                        <div className="w-full truncate">
                            {currOption.label}
                        </div>
                    </div>
                    <CaretSortIcon />
                </div>
            );
        }
        case "Customer Group": {
            return (
                <div className="flex items-center justify-between text-xs hover:bg-muted">
                    <div className="flex items-center gap-1.5">
                        {currOption.value !== "Select customer group" && (
                            <GroupIcon />
                        )}
                        {currOption.label}
                    </div>
                    <div className="w-full truncate">
                        <CaretSortIcon />
                    </div>
                </div>
            );
        }
        case "Source": {
            const [source, channelName] = currOption.label.split(" -- ");
            const formattedLabel = channelName
                ? channelName.trim()
                : currOption.label;
            const formattedSource = source
                ? source.trim().replace(/\s+/g, "")
                : "";
            const Icon =
                integrationBackEndDataMappingToSvg.get(formattedSource) ??
                QuestionMarkIcon;
            return (
                <div className="flex items-center justify-between text-xs hover:bg-muted">
                    <div className="flex items-center gap-1.5">
                        {currOption.value !== "Select source" && (
                            <Icon className="w-4 h-4" />
                        )}
                        <div className="w-full truncate">{formattedLabel}</div>
                    </div>
                </div>
            );
        }
        default:
            return (
                <div className="flex items-center justify-between text-xs hover:bg-muted">
                    {currOption.label}
                </div>
            );
    }
};

export function stepToNodeMetadata(
    type: string,
    name: string,
    subtype: string,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    metadata: Map<string, any>,
) {
    switch (type) {
        case "trigger": {
            const triggerMetadata: {
                type: string;
                name: string;
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                from?: any;
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                to?: any;
                timezone?: { label: string; value: string };
                date?: Date;
                time?: string;
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                repeat?: TimeRepeat;
            } = { type: Array.from(metadata.keys())[0], name: name };

            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            const values: Map<string, any>[] = Array.from(metadata.values());
            let unixTimestamp: number | undefined;
            let unixEndTimestamp: number | undefined;
            for (const map of values) {
                for (const [key, value] of Object.entries(map)) {
                    switch (key) {
                        case "from":
                            if (value.length === 1) {
                                triggerMetadata.from = value[0];
                            }
                            break;
                        case "to":
                            if (value.length === 1) {
                                triggerMetadata.to = value[0];
                            }
                            break;
                        case "timezone":
                            triggerMetadata.timezone = {
                                label: value.name,
                                value: value.key,
                            };
                            break;
                        case "time":
                            unixTimestamp = value;
                            break;
                        case "repeat": {
                            if (value.end?.time) {
                                unixEndTimestamp = value.end.time;
                            }
                            const repeat = JSON.parse(JSON.stringify(value));
                            triggerMetadata.repeat = repeat;
                            break;
                        }
                        default:
                            console.error(
                                "Trigger step key is not supported: ",
                                key,
                            );
                            break;
                    }
                }
            }

            if (unixTimestamp && triggerMetadata.timezone) {
                const date = convertUnixToLocalTime(
                    unixTimestamp,
                    triggerMetadata.timezone.value,
                );
                triggerMetadata.date = date;
                triggerMetadata.time = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
            }
            if (unixEndTimestamp && triggerMetadata.timezone) {
                if (triggerMetadata?.repeat?.end) {
                    triggerMetadata.repeat.end.time = convertUnixToLocalTime(
                        unixEndTimestamp,
                        triggerMetadata.timezone.value,
                    );
                }
            }
            return triggerMetadata;
        }
        case "condition": {
            const condFilters = new Map<
                string,
                Set<{
                    label: string;
                    value: string;
                    key: string;
                    color: string;
                }>
            >();
            let i = 0;
            for (const [key, value] of metadata.entries()) {
                switch (key) {
                    case "source": {
                        for (const [sourceKey, sourceValue] of Object.entries(
                            value,
                        )) {
                            for (const val of sourceValue as {
                                key: string;
                                name: string;
                            }[]) {
                                if (val.key === "*ALL*") {
                                    condFilters.set(
                                        `Source_${i}`,
                                        new Set([
                                            {
                                                label: sourceKey,
                                                value: sourceKey,
                                                key: sourceKey,
                                                color: "",
                                            },
                                        ]),
                                    );
                                } else {
                                    condFilters.set(
                                        `Source_${i}`,
                                        new Set([
                                            {
                                                label: `${sourceKey} -- ${val.name} `,
                                                value: `${sourceKey} -- ${val.name} -- ${val.key}`,
                                                key: val.key,
                                                color: "",
                                            },
                                        ]),
                                    );
                                }
                                i += 1;
                            }
                        }
                        break;
                    }
                    case "label": {
                        for (const [labelKey, labelValue] of Object.entries(
                            value,
                        )) {
                            for (const label of labelValue as {
                                key: string;
                                name: string;
                                color: string;
                            }[]) {
                                condFilters.set(
                                    `${capitalizeType(labelKey)}_${i}`,
                                    new Set([
                                        {
                                            label: label.name,
                                            value: label.name,
                                            key: label.key,
                                            color: label.color ?? "", // TODO: grab the label color or store it in metadata
                                        },
                                    ]),
                                );
                                i += 1;
                            }
                        }
                        break;
                    }
                    case "customer_group":
                        for (const val of Array.from(value) as {
                            key: string;
                            name: string;
                        }[]) {
                            condFilters.set(
                                `Customer Group_${i}`,
                                new Set([
                                    {
                                        label: val.name,
                                        value: val.name,
                                        key: val.key,
                                        color: "",
                                    },
                                ]),
                            );
                            i += 1;
                        }
                        break;
                    case "team":
                    case "status":
                    case "customer":
                    case "company": {
                        for (const val of Array.from(value) as {
                            key: string;
                            name: string;
                        }[]) {
                            condFilters.set(
                                `${capitalizeType(key)}_${i}`,
                                new Set([
                                    {
                                        label: val.name,
                                        value: val.name,
                                        key: val.key,
                                        color: "",
                                    },
                                ]),
                            );
                            i += 1;
                        }
                        break;
                    }
                }
            }
            return { subtype: subtype, name: name, filters: condFilters };
        }
        case "wait": {
            const timeString = metadata.get("time");
            const timeInt = Number.parseInt(timeString, 10);
            const waitMetadata: {
                subtype: string;
                unit: string;
                time: number;
                filterComboType?: string;
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                filters?: Map<string, any>;
            } = { unit: metadata.get("unit"), time: timeInt, subtype: subtype };
            if (subtype === "while") {
                const conditions = metadata.get("condition");
                waitMetadata.filterComboType = conditions.type;

                const condFilters = new Map<
                    string,
                    Set<{
                        label: string;
                        value: string;
                        key: string;
                        color: string;
                    }>
                >();
                let i = 0;
                for (const [key, value] of Object.entries(conditions.filters)) {
                    switch (key) {
                        case "label": {
                            for (const [labelKey, labelValue] of Object.entries(
                                value,
                            )) {
                                for (const label of labelValue as {
                                    key: string;
                                    name: string;
                                    color: string;
                                }[]) {
                                    condFilters.set(
                                        `${capitalizeType(labelKey)}_${i}`,
                                        new Set([
                                            {
                                                label: label.name,
                                                value: label.name,
                                                key: label.key,
                                                color: label.color ?? "", // TODO: grab the label color or store it in metadata
                                            },
                                        ]),
                                    );
                                    i += 1;
                                }
                            }
                            break;
                        }
                        case "status": {
                            for (const val of Array.from(value) as {
                                key: string;
                                name: string;
                            }[]) {
                                condFilters.set(
                                    `${capitalizeType(key)}_${i}`,
                                    new Set([
                                        {
                                            label: val.name,
                                            value: val.name,
                                            key: val.key,
                                            color: "",
                                        },
                                    ]),
                                );
                                i += 1;
                            }
                            break;
                        }
                    }
                }
                waitMetadata.filters = condFilters;
            }
            return waitMetadata;
        }
        case "action": {
            const actFilters = new Map<
                string,
                Set<{
                    label: string;
                    value: string;
                    key: string;
                    color: string;
                }>
            >();
            let message: string | undefined = undefined;
            for (const [key, value] of metadata.entries()) {
                switch (key.toLowerCase()) {
                    case "template":
                    case "assignee":
                    case "status":
                    case "recipient": {
                        const val = [...value][0];
                        actFilters.set(
                            capitalizeType(key),
                            new Set([
                                {
                                    label: val.name,
                                    value: val.name,
                                    key: val.key,
                                    color: "",
                                },
                            ]),
                        );
                        break;
                    }
                    case "label": {
                        for (const [labelKey, labelValue] of Object.entries(
                            value,
                        )) {
                            for (const label of labelValue as {
                                key: string;
                                name: string;
                                color: string;
                            }[]) {
                                actFilters.set(
                                    capitalizeType(labelKey),
                                    new Set([
                                        {
                                            label: label.name,
                                            value: label.name,
                                            key: label.key,
                                            color: "",
                                        },
                                    ]),
                                );
                            }
                        }
                        break;
                    }
                    case "message": {
                        message = [...value][0].name;
                    }
                }
            }
            if (message) {
                return {
                    message: message,
                    subtype: subtype,
                    name: name,
                    filters: actFilters,
                };
            }
            return { subtype: subtype, name: name, filters: actFilters };
        }
        default:
            return {}; // default case, returns an empty object if type doesn't match
    }
}

export function nodeToStepMetadata(
    type: string,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    metadata: Map<string, any>,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
): Map<string, any> {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const stepMetadata: Map<string, any> = new Map();
    switch (type) {
        case "defaultTrigger":
        case "trigger": {
            // Process to and from data
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            const toAndFrom: Map<string, any> = new Map();
            if (metadata.get("from")) {
                toAndFrom.set("from", [metadata.get("from")]);
            }
            if (metadata.get("to")) {
                toAndFrom.set("to", [metadata.get("to")]);
            }
            stepMetadata.set(metadata.get("type"), toAndFrom);

            // Process time and repeat data (convert to unix time)
            // If date field is included, all other time related fields should be included
            if (metadata.get("date")) {
                const repeat = metadata.get("repeat");
                if (repeat.end?.time) {
                    repeat.end.time = convertToUnixTime(
                        repeat.end.time,
                        "00:00",
                        metadata.get("timezone").value,
                    );
                }
                if (repeat.end?.count) {
                    repeat.end.count = Number(repeat.end.count);
                }
                if (repeat.freq?.time) {
                    repeat.freq.time = Number(repeat.freq.time);
                }
                const timeIs = {
                    time: convertToUnixTime(
                        new Date(metadata.get("date")),
                        metadata.get("time"),
                        metadata.get("timezone").value,
                    ),
                    timezone: {
                        key: metadata.get("timezone").value,
                        name: metadata.get("timezone").label,
                    },
                    repeat: repeat,
                };
                stepMetadata.set("time_is", timeIs);
            }
            return stepMetadata;
        }
        case "condition": {
            for (const [typeIndex, value] of metadata.get("filters") ?? []) {
                const conditionType = typeIndex.toLowerCase().split("_")[0];
                switch (conditionType) {
                    case "source": {
                        let currSources = stepMetadata.get("source");
                        for (const sourceType of value) {
                            const sourceTypeVal = sourceType.value;
                            const [source, channelName, channelID] =
                                sourceTypeVal.split(" -- ");
                            if (!currSources) {
                                stepMetadata.set("source", new Map());
                                currSources = new Map();
                            }
                            // If source type is already in list, just add the key name
                            if (currSources.has(source)) {
                                // Append the additional channel if specified
                                if (channelName && channelID) {
                                    const existingSourceChannels =
                                        currSources.get(source);
                                    // Only update the channels if existing selection doesn't already include ALL channels
                                    if (
                                        !(
                                            existingSourceChannels.length ===
                                            1 &&
                                            existingSourceChannels[0].key ===
                                            "*ALL*"
                                        )
                                    ) {
                                        const newSourceChannels = [
                                            ...existingSourceChannels,
                                            {
                                                key: channelID,
                                                name: channelName,
                                            },
                                        ];
                                        currSources.set(
                                            source,
                                            newSourceChannels,
                                        );
                                    }
                                } else {
                                    // If none specified, replace the source's value with ALL
                                    currSources.set(source, [
                                        { key: "*ALL*", name: "*ALL*" },
                                    ]);
                                }
                            } else {
                                // Add specific source channel if selected
                                if (channelName && channelID) {
                                    currSources.set(source, [
                                        { key: channelID, name: channelName },
                                    ]);
                                } else {
                                    // Otherwise add all channels for that source
                                    currSources.set(source, [
                                        { key: "*ALL*", name: "*ALL*" },
                                    ]);
                                }
                                stepMetadata.set("source", currSources);
                            }
                        }
                        stepMetadata.set("source", currSources);
                        break;
                    }
                    case "topic":
                    case "tag":
                    case "interactiontype": {
                        let currLabelTypes = stepMetadata.get("label");
                        if (!currLabelTypes) {
                            stepMetadata.set("label", new Map());
                            currLabelTypes = new Map();
                        }
                        let currLabels = Array.from(
                            stepMetadata.get("label").get(conditionType) ?? [],
                        );
                        for (const label of value) {
                            if (!currLabels) {
                                currLabels = [
                                    { key: label.key, name: label.label },
                                ];
                            } else {
                                currLabels.push({
                                    key: label.key,
                                    name: label.label,
                                });
                            }
                        }
                        currLabelTypes.set(conditionType, currLabels);
                        stepMetadata.set("label", currLabelTypes);
                        break;
                    }
                    case "team":
                    case "customer group":
                    case "status":
                    case "customer":
                    case "company": {
                        let currVals = stepMetadata.get(conditionType);
                        for (const v of value) {
                            if (!currVals) {
                                currVals = [{ key: v.key, name: v.label }];
                            } else {
                                currVals.push({ key: v.key, name: v.label });
                            }
                        }
                        let type = conditionType;
                        if (conditionType === "customer group") {
                            type = "customer_group";
                        }
                        stepMetadata.set(type, currVals);
                        break;
                    }
                }
            }
            return stepMetadata;
        }
        case "wait": {
            stepMetadata.set("time", Number(metadata.get("time")) ?? 0);
            stepMetadata.set("unit", metadata.get("unit") ?? "days");
            if (metadata.get("subtype") === "while") {
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                const whileCondMap: Map<string, any> = new Map();
                whileCondMap.set("type", metadata.get("filterComboType"));

                // Process the actual filters for the condition
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                const condFilters: Map<string, any> = new Map();
                for (const [typeIndex, value] of metadata.get("filters") ??
                    []) {
                    const conditionType = typeIndex.toLowerCase().split("_")[0];
                    switch (conditionType) {
                        case "topic":
                        case "tag": {
                            let currLabelTypes = condFilters.get("label");
                            if (!currLabelTypes) {
                                condFilters.set("label", new Map());
                                currLabelTypes = new Map();
                            }
                            let currLabels = Array.from(
                                condFilters.get("label").get(conditionType) ??
                                [],
                            );
                            for (const label of value) {
                                if (!currLabels) {
                                    currLabels = [
                                        { key: label.key, name: label.label },
                                    ];
                                } else {
                                    currLabels.push({
                                        key: label.key,
                                        name: label.label,
                                    });
                                }
                            }
                            currLabelTypes.set(conditionType, currLabels);
                            condFilters.set("label", currLabelTypes);
                            break;
                        }
                        case "status": {
                            let currVals = condFilters.get(conditionType);
                            for (const v of value) {
                                if (!currVals) {
                                    currVals = [{ key: v.key, name: v.label }];
                                } else {
                                    currVals.push({
                                        key: v.key,
                                        name: v.label,
                                    });
                                }
                            }
                            let type = conditionType;
                            if (conditionType === "customer group") {
                                type = "customer_group";
                            }
                            condFilters.set(type, currVals);
                            break;
                        }
                    }
                }

                whileCondMap.set("filters", Object.fromEntries(condFilters));
                stepMetadata.set("condition", whileCondMap);
            }
            return stepMetadata;
        }
        case "action": {
            const filters = metadata.get("filters") ?? new Map();
            switch (metadata.get("subtype")) {
                case "send_template":
                case "add_assignee":
                case "add_interaction_type":
                case "add_category":
                case "add_tag":
                case "change_status":
                case "send_template_new_message": {
                    const type = getTypeFromActionType(metadata.get("subtype"));
                    const typeSet = filters.get(type);
                    const option = [...typeSet][0];
                    // Combine into labels metadata field
                    if (LABEL_TYPES.includes(type)) {
                        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                        const labels = new Map<string, any>();
                        labels.set(type.toLowerCase(), [
                            { key: option.key, name: option.label },
                        ]);
                        stepMetadata.set("label", labels);
                    } else {
                        stepMetadata.set(type.toLowerCase(), [
                            { key: option.key, name: option.label },
                        ]);
                    }
                    if (
                        metadata.get("subtype") === "send_template_new_message"
                    ) {
                        const option = [...filters.get("Recipient")][0];
                        stepMetadata.set("recipient", [
                            { key: "recipient", name: option.label },
                        ]);
                    }
                    break;
                }
                case "send_message":
                case "send_internal_message":
                case "send_new_message": {
                    // Store the message text in the name field
                    stepMetadata.set("message", [
                        { key: "message", name: metadata.get("message") },
                    ]);
                    // For send new message, also store the recipient (customer or company)
                    if (metadata.get("subtype") === "send_new_message") {
                        const option = [...filters.get("Recipient")][0];
                        stepMetadata.set("recipient", [
                            { key: "recipient", name: option.label },
                        ]);
                    }
                    break;
                }
            }
            return stepMetadata;
        }
        default:
            return new Map(); // Return an empty Map for the default case
    }
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function blockHeight(type: string, metadata: any): number {
    switch (type) {
        case "condition": {
            const numFilters = metadata.filters?.size;
            return 130 + (numFilters + 1) * 75;
        }
        case "trigger":
            switch (metadata.type) {
                case "time_is":
                    if (metadata.repeat?.type === "custom") {
                        return 400;
                    } else {
                        return 300;
                    }
                case "issue_created":
                    return 200;
                default:
                    return 250;
            }
        case "wait":
            switch (metadata.subtype) {
                case "while":
                    return 375;
                default:
                    return 200;
            }
        case "action":
            return 275;

        default:
            return 200;
    }
}

export const getTypeFromActionType = (action_type: string) => {
    switch (action_type) {
        case "send_template":
        case "send_template_new_message": {
            return "Template";
        }
        case "send_message": {
            return "Message";
        }
        case "add_assignee": {
            return "Assignee";
        }
        case "add_category":
        case "category_updated": {
            return "Tag";
        }
        case "add_tag":
        case "tag_updated": {
            return "Topic";
        }
        case "change_status":
        case "status_updated": {
            return "Status";
        }
        default: {
            return action_type;
        }
    }
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function serializeStepMetadata(stepMetadata: Map<string, any>): object {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const plainObject: { [key: string]: any } = {};
    stepMetadata.forEach((value, key) => {
        if (value instanceof Map) {
            // If it's a Map, recursively convert it to an object
            plainObject[key] = Object.fromEntries(value);
        } else if (Array.isArray(value)) {
            // If it's an array, just use it as is
            plainObject[key] = value;
        } else {
            plainObject[key] = value;
        }
    });
    return plainObject;
}

export function validateWorkflow(
    workflowName: string,
    nodes: Node[],
    edges: Edge[],
    setValidationErrors: (
        value: React.SetStateAction<WorkflowValidationError[]>,
    ) => void,
) {
    const errors: WorkflowValidationError[] = [];

    // Basic form validation
    if (!workflowName.trim()) {
        errors.push({
            type: "noSelection",
            message: "Workflow name is required",
        });
    }

    // 1. Check nodes without selections
    const nodesWithoutSelection = nodes.filter((node) => {
        if (node.type === "condition") {
            const filters = node.data.metadata?.filters;

            if (!filters || filters?.size === 0) {
                return true;
            }

            const allFiltersHaveValues = Array.from(filters.values()).some(
                (filterSet) => {
                    return filterSet?.size === 0;
                },
            );
            return allFiltersHaveValues;
        } else if (node.type === "action") {
            const subtype = node.data.metadata?.subtype;
            if (!subtype) return true;
            if (
                subtype === "send_message" ||
                subtype === "send_internal_message"
            ) {
                return !node.data.metadata?.message;
            } else if (subtype === "send_new_message") {
                return (
                    !node.data.metadata?.message ||
                    !node.data.metadata?.filters?.size
                );
            } else if (subtype === "send_template_new_message") {
                return node.data.metadata?.filters?.size !== 2;
            } else {
                return !node.data.metadata?.filters?.size;
            }
        } else if (
            node.type === "wait" &&
            node.data.metadata?.subtype === "while"
        ) {
            const filters = node.data.metadata?.filters;

            if (!filters || filters?.size === 0) {
                return true;
            }

            const allFiltersHaveValues = Array.from(filters.values()).some(
                (filterSet) => {
                    return filterSet?.size === 0;
                },
            );
            return allFiltersHaveValues;
        }
        return false;
    });

    if (nodesWithoutSelection.length > 0) {
        errors.push({
            type: "noSelection",
            message: "Some nodes have incomplete or missing filters",
            nodeIds: nodesWithoutSelection.map((n) => n.id),
        });
    }

    // 2. Check for conflicting filters in condition nodes
    for (const node of nodes) {
        if (
            node.type === "condition" &&
            node.data.metadata?.subtype === "all"
        ) {
            const filters = node.data.metadata?.filters;
            if (!filters) continue;

            // Check for repeated filter types
            const filterTypes = new Set();
            for (const key of filters.keys()) {
                const filterType = key.split("_")[0];
                if (filterTypes.has(filterType) && filterType !== "Topic") {
                    errors.push({
                        type: "conflictingFilters",
                        message: `Condition node "${node.data.label}" has duplicate filter type: ${filterType}`,
                        nodeIds: [node.id],
                    });
                    break;
                }
                filterTypes.add(filterType);
            }
        }
    }

    // 3. Check for disconnected nodes (except default trigger)
    const connectedNodeIds = new Set(
        edges.flatMap((edge) => [edge.source, edge.target]),
    );
    const disconnectedNodes = nodes.filter(
        (node) =>
            node.type !== "defaultTrigger" && !connectedNodeIds.has(node.id),
    );

    if (disconnectedNodes.length > 0) {
        errors.push({
            type: "disconnected",
            message: "Some nodes are not connected to the workflow",
            nodeIds: disconnectedNodes.map((n) => n.id),
        });
    }

    // 4. Check for action nodes
    const hasActionNode = nodes.some((node) => node.type === "action");
    if (!hasActionNode) {
        errors.push({
            type: "noAction",
            message: "Workflow must include at least one action node",
        });
    }

    // 5: Check for child trigger nodes (no incoming edges to trigger nodes allowed)
    const triggerNodeIDs = nodes
        .filter((node) => node.type === "trigger")
        .map((node) => node.id);
    const hasEdgeThatPointToTrigger = edges.some((edge) =>
        triggerNodeIDs.includes(edge.target),
    );
    if (hasEdgeThatPointToTrigger) {
        errors.push({
            type: "edgePointsToTrigger",
            message: "Trigger nodes must be at the start of the workflow",
        });
    }

    // 6. Check time is node values if that's the trigger used
    nodes.filter((node) => {
        if (
            (node.type === "trigger" || node.type === "defaultTrigger") &&
            node.data.metadata?.type === "time_is"
        ) {
            const startDate = new Date(node.data.metadata?.date);
            const [hours, minutes] = node.data.metadata?.time
                ?.split(":")
                .map(Number);
            startDate.setHours(hours, minutes, 0, 0);
            const currentDate = new Date();
            if (startDate.getTime() < currentDate.getTime()) {
                errors.push({
                    type: "invalidValue",
                    message:
                        "Scheduled time has already passed, please select the current time or a time in the future",
                    nodeIds: [node.id],
                });
            }
            if (
                node.data.metadata?.repeat?.type === "custom" &&
                node.data.metadata?.repeat?.end?.type === "on"
            ) {
                const endDate = new Date(node.data.metadata?.repeat?.end?.time);
                if (endDate.getTime() <= startDate.getTime()) {
                    errors.push({
                        type: "invalidValue",
                        message:
                            "Custom repeat ends before the workflow has started.",
                        nodeIds: [node.id],
                    });
                }
            }
        }
        return false;
    });

    // 7. TEMPORARY: No wait with while filters for time is (all items type) trigger
    const hasTimeIsTrigger = nodes.some(
        (node) =>
            (node.type === "trigger" || node.type === "defaultTrigger") &&
            node.data.metadata?.type === "time_is",
    );
    const hasWaitWithConditions = nodes.some(
        (node) =>
            node.type === "wait" && node.data.metadata?.subtype === "while",
    );
    if (hasTimeIsTrigger && hasWaitWithConditions) {
        errors.push({
            type: "invalidValue",
            message:
                "A wait block with filters cannot be in a workflow with a 'Time Is' trigger block currently.",
        });
    }
    // TODO: Check for no repeated filters

    setValidationErrors(errors);
    return errors.length === 0;
}

export function convertToUnixTime(date: Date, time: string, timezone: string) {
    const dateTimeString = `${format(date, "yyyy-MM-dd")} ${time}`;
    const combinedDate = parse(dateTimeString, "yyyy-MM-dd HH:mm", new Date());
    // Convert the date to UTC based on the timezone
    const utcDate = fromZonedTime(combinedDate, timezone);
    return Math.floor(utcDate.getTime() / 1000);
}

export function convertUnixToLocalTime(
    unixTimestamp: number,
    timezone: string,
) {
    const date = new Date(unixTimestamp * 1000);
    // Convert Date to the desired timezone
    const zonedDate = toZonedTime(date, timezone);
    return zonedDate;
}

// Used to determine if an issue card should re-render
export function hasIssueChanged(oldQuery: Query, newQuery: Query): boolean {
    if (
        oldQuery.id !== newQuery.id ||
        oldQuery.ticket_identifier !== newQuery.ticket_identifier ||
        oldQuery.ticket_number !== newQuery.ticket_number ||
        oldQuery.number !== newQuery.number ||
        oldQuery.title !== newQuery.title ||
        oldQuery.query !== newQuery.query ||
        oldQuery.created_at !== newQuery.created_at ||
        oldQuery.ticket_updated_at !== newQuery.ticket_updated_at ||
        oldQuery.bot_category !== newQuery.bot_category ||
        oldQuery.source !== newQuery.source ||
        oldQuery.ticket_status !== newQuery.ticket_status ||
        // oldQuery.url !== newQuery.url ||
        oldQuery.source_specific_id !== newQuery.source_specific_id ||
        // oldQuery.source_unique_name !== newQuery.source_unique_name ||
        oldQuery.ai_response !== newQuery.ai_response ||
        // oldQuery.internal_note !== newQuery.internal_note ||
        oldQuery.assignee_user_id !== newQuery.assignee_user_id ||
        oldQuery.breaching !== newQuery.breaching ||
        // oldQuery.assembly_responded !== newQuery.assembly_responded ||
        // oldQuery.business_hours_id !== newQuery.business_hours_id ||
        oldQuery.original_timestamp !== newQuery.original_timestamp ||
        oldQuery.disabled !== newQuery.disabled ||
        oldQuery.user !== newQuery.user ||
        oldQuery.time_created !== newQuery.time_created ||
        oldQuery.time_responded !== newQuery.time_responded ||
        oldQuery.time_closed !== newQuery.time_closed
        // oldQuery.integration_id !== newQuery.integration_id
    ) {
        return true;
    }

    if (
        !arraysAreEqual(oldQuery.topic, newQuery.topic) ||
        !arraysAreEqual(oldQuery.external_issues, newQuery.external_issues) ||
        !arraysAreEqual(oldQuery.teams, newQuery.teams)
    ) {
        return true;
    }
    if (
        JSON.stringify(oldQuery.user_info) !==
        JSON.stringify(newQuery.user_info)
    ) {
        return true;
    }

    return false;
}

const MAX_INTERNAL_FILE_SIZE = 1024 * 1024; // 1MB limit for internal messages
export const validateInternalMessageFiles = (
    files: (UploadedFile | UploadedFileWithMetadata)[],
) => {
    let totalSize = 0;
    for (const file of files) {
        if (file.type === "file") {
            totalSize += (file as UploadedFileWithMetadata).file_size;
        } else {
            // For image files, get size from src if available
            const imgFile = file as UploadedFile;
            const base64String = imgFile.src.split(",")[1];
            const byteString = atob(base64String);
            if (byteString) {
                totalSize += byteString.length;
            }
        }
    }
    return totalSize <= MAX_INTERNAL_FILE_SIZE;
};
