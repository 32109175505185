import { API, ContactsAPI, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    CustomerGroup,
    GetTopicsResponse,
    GetUserResponse,
    Integration,
    Label,
    ListCustomersResponse,
    OrgInfoResponse,
    ScopeResponse,
    Teams,
    Template,
} from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useEffect, useRef } from "react";

// A Hook for grabbing workflow node dropdown options
export function useWorkflowOptionsData(teamID?: string) {
    const api = useApi();
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    // TODO: make this depend on the team
    const interactionTypesQuery = useQuery<Label[]>({
        queryKey: ["interactionType"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getLabels}/InteractionType`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });

    // TODO: make this depend on the team
    const categoriesQuery = useQuery<Category[]>({
        queryKey: ["categories"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getCategories}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });

    const topicsQuery = useQuery<GetTopicsResponse[]>({
        queryKey: teamID ? [`teamTopics_${teamID}`] : ["topics"],
        queryFn: teamID ? () => fetchTeamTopics() : () => fetchTopics(),
    });
    const fetchTopics = async (): Promise<GetTopicsResponse[]> => {
        const response = await api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };
    const fetchTeamTopics = async (): Promise<GetTopicsResponse[]> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getTopics}/team/${teamID}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    const customerGroupsQuery = useQuery<CustomerGroup[]>({
        queryKey: ["customerGroups"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getCustomerGroups, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            const customerGroups: CustomerGroup[] = data.data;
            return customerGroups;
        },
    });

    const channelsQuery = useQuery<Map<string, ScopeResponse[]>>({
        queryKey: ["channels"],
        queryFn: async () => {
            const theMap = new Map<string, ScopeResponse[]>();
            // Fetching the orgInfo first
            const res = await fetch(URLS.serverUrl + API.getItemsByOrgID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
                body: JSON.stringify({
                    types: [
                        "Slack",
                        "CommunitySlack",
                        "Discord",
                        "Google",
                        "API",
                    ],
                }),
            });

            if (res.ok) {
                const orgInfo: OrgInfoResponse = (await res.json()).data;
                // Handle Slack scopes asynchronously
                if (orgInfo.Slack) {
                    api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/Slack`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels.concat(
                                        res.data.data
                                            ?.trigger_based_ticket_channels,
                                    );
                                theMap.set("Slack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle CommunitySlack scopes asynchronously
                if (orgInfo.CommunitySlack) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/CommunitySlack`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels.concat(
                                        res.data.data
                                            ?.trigger_based_ticket_channels,
                                    );
                                theMap.set("CommunitySlack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle Discord scopes asynchronously
                if (orgInfo.Discord) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/Discord`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("Discord", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Grab Google integrations
                if (orgInfo.Google) {
                    api.get(
                        `${URLS.serverUrl}${API.getUniqueIntegrations}/Google`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name,
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("Gmail", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Grab API integrations
                if (orgInfo.API) {
                    api.get(
                        `${URLS.serverUrl}${API.getUniqueIntegrations}/API`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name,
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("API", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }
            }
            return theMap;
        },
    });

    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    const templatesQuery = useQuery<Template[]>({
        queryKey: teamID ? [`teamTemplates_${teamID}`] : ["templates"],
        queryFn: teamID ? () => fetchTeamTemplates() : () => fetchTemplates(),
    });
    const fetchTemplates = async (): Promise<Template[]> => {
        const response: AxiosResponse<{ data: Template[] }> = await api.get(
            `${URLS.serverUrl}${API.getTemplates}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch data");
    };
    const fetchTeamTemplates = async (): Promise<Template[]> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getTemplates}/team/${teamID}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const fetchContacts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListCustomersResponse> => {
        try {
            const { url, method } = ContactsAPI.listCustomers;
            const response = await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                params: {
                    limit: 100,
                    offset: pageParam,
                },
            });
            console.log("response status was", response.status);
            if (response.status === 200) {
                console.log("response data", response.data);
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };
    const fetchTeamContacts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListCustomersResponse> => {
        try {
            const { url, method } = ContactsAPI.listCustomers;
            const response = await api.get(
                `${URLS.serverUrl}${url}/team/${teamID}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 100,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data: customersData,
        fetchNextPage: fetchCustomersNextPage,
        hasNextPage: customersHasNextPage,
        isFetchingNextPage: isFetchingCustomersNextPage,
        isLoading: customersIsLoading,
    } = useInfiniteQuery({
        queryKey: teamID ? [`teamCustomers_${teamID}`] : ["customers"],
        queryFn: teamID ? fetchTeamContacts : fetchContacts,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
    // Fetch all the data
    useEffect(() => {
        if (customersHasNextPage && !isFetchingCustomersNextPage) {
            fetchCustomersNextPage();
        }
    }, [
        customersHasNextPage,
        isFetchingCustomersNextPage,
        fetchCustomersNextPage,
    ]);
    const customers =
        customersData?.pages && Array.isArray(customersData.pages)
            ? customersData.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];

    const fetchCompanies = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListCustomersResponse> => {
        try {
            const { url, method } = ContactsAPI.listCompanies;
            const response = await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                params: {
                    limit: 100,
                    offset: pageParam,
                },
            });
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };
    const fetchTeamCompanies = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListCustomersResponse> => {
        try {
            const { url, method } = ContactsAPI.listCompanies;
            const response = await api.get(
                `${URLS.serverUrl}${url}/team/${teamID}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 100,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };
    const {
        data: companiesData,
        fetchNextPage: fetchCompaniesNextPage,
        hasNextPage: companiesHasNextPage,
        isFetchingNextPage: isFetchingCompaniesNextPage,
        isLoading: companiesIsLoading,
    } = useInfiniteQuery({
        queryKey: teamID ? [`teamCompanies_${teamID}`] : ["companies"],
        queryFn: teamID ? fetchTeamCompanies : fetchCompanies,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });

    // Fetch all the data
    useEffect(() => {
        if (companiesHasNextPage && !isFetchingCompaniesNextPage) {
            fetchCompaniesNextPage();
        }
    }, [
        companiesHasNextPage,
        isFetchingCompaniesNextPage,
        fetchCompaniesNextPage,
    ]);
    const companies =
        companiesData?.pages && Array.isArray(companiesData.pages)
            ? companiesData.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];

    const isLoading =
        interactionTypesQuery.isLoading ||
        categoriesQuery.isLoading ||
        topicsQuery.isLoading ||
        teamsQuery.isLoading ||
        customerGroupsQuery.isLoading ||
        channelsQuery.isLoading ||
        usersQuery.isLoading ||
        templatesQuery.isLoading ||
        customersIsLoading ||
        companiesIsLoading;

    return {
        interactionTypesQuery,
        categoriesQuery,
        topicsQuery,
        teamsQuery,
        customerGroupsQuery,
        channelsQuery,
        usersQuery,
        templatesQuery,
        customers,
        companies,
        isLoading,
    };
}
