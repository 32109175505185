import { type SerializedTextNode, type TextModeType, TextNode } from "lexical";

export class VariableNode extends TextNode {
    // biome-ignore lint/complexity/noUselessConstructor: <explanation>
    constructor(variable: string) {
        super(variable);
    }

    static getType() {
        return "variable";
    }

    static clone(node: VariableNode) {
        return new VariableNode(node.getTextContent());
    }

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    createDOM(config: any) {
        const span = document.createElement("span");

        // Rectangular outline styles with added margins
        span.style.backgroundColor = "transparent";
        span.style.border = "1px solid #5B5BD6";
        span.style.padding = "2px 5px";
        span.style.margin = "1px 2px";
        span.style.borderRadius = "6px"; // Slightly rounded corners (more rectangular)
        span.style.color = "#5B5BD6";
        span.style.fontSize = "13px";
        span.style.userSelect = "none"; // Prevent text selection
        span.setAttribute("data-type", "variable-node");
        span.textContent = this.getTextContent();
        span.setAttribute("contenteditable", "false"); // Make the node non-editable

        return span;
    }

    updateDOM(prevNode: VariableNode, dom: HTMLElement): boolean {
        if (this.getTextContent() !== prevNode.getTextContent()) {
            dom.textContent = this.getTextContent(); // Update the text content if it has changed
        }
        return false;
    }

    render() {
        const content = this.getTextContent();
        return `<span style="background-color: transparent; border: 1px solid #5B5BD6; padding: 2px 5px; margin: 1px 3px; border-radius: 6px; color: #5B5BD6; font-size: 13px; user-select: none;" contenteditable="false">${content}</span>`;
    }

    exportJSON(): SerializedTextNode {
        return {
            type: "variable",
            version: 1,
            detail: 0, // Can be used for detailed information (default 0 for simple nodes)
            format: 0, // Format value, for simple nodes you can keep it as 0
            mode: "text" as TextModeType,
            style: "",
            text: this.getTextContent(),
        };
    }
}
