"use client";

import {
    type ColumnFiltersState,
    type SortingState,
    type VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, Trash2Icon } from "lucide-react";
import * as React from "react";

import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";

import { Button } from "@/component/shadcn/ui/button";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/component/shadcn/ui/table";
import {
    dataSample,
    generateOnboardingColumns,
} from "./OnboardingTableColumns";
import { PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { GetUserResponse } from "@/interfaces/serverData";

import {
    OnboardingChecklist,
    OnboardingChecklistType,
    OnboardingChecklistStatus,
} from "@/interfaces/onboardingServerData";

export function getEmptyRow(
    userID: string,
    parentID: string,
): OnboardingChecklist {
    // Initialize with one empty row
    return {
        id: "1",
        name: "",
        user_id: userID,
        description: "",
        assignee_user_id: "",
        onboarding_type: OnboardingChecklistType.Task,
        start_date: new Date(),
        deadline: undefined,
        task_status: OnboardingChecklistStatus.NotStarted,
        created_at: new Date().toISOString(),
        parent_id: parentID,
        order_position: 0,
        updated_at: new Date().toISOString(),
        enabled: false,
        org_id: "",
    };
}

interface OnboardingTableProps {
    userID: string;
    users: GetUserResponse[];
    data: OnboardingChecklist[];
    setData: (data: OnboardingChecklist[]) => void;
    saveToDB?: boolean;
    addIssue?: (
        issue: OnboardingChecklist,
        parentId: string,
    ) => Promise<string>;
    saveIssue?: (
        issue: Partial<OnboardingChecklist>,
        columnId: string,
        value: string,
        onboardingMainItemId: string,
    ) => void;
    deleteIssues?: (
        userID: string,
        issueIDs: string[],
        deleteOnUI?: (ids: string[]) => void,
    ) => void;
    milestoneID?: string;
    onboardingMainItemId?: string;
}

export function OnboardingTable({
    userID,
    users,
    data,
    setData,
    saveToDB = false,
    addIssue,
    saveIssue,
    deleteIssues,
    milestoneID = "",
    onboardingMainItemId = "",
}: OnboardingTableProps) {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] =
        React.useState<VisibilityState>({});
    const [rowSelection, setRowSelection] = React.useState({});

    const handleAddTask = () => {
        const newRow: OnboardingChecklist = getEmptyRow(userID, milestoneID);
        if (data.length > 0) {
            if (data[data.length - 1].name === "") {
                // don't allow them to add an empty row if the previous row is still empty
                return;
            }
        }
        newRow.id = (data.length + 1).toString();
        setData([...data, newRow]);
        if (saveToDB) {
            addIssue?.(newRow, milestoneID).then((id) => {
                newRow.id = id;
                setData([...data, newRow]);
            });
        }
    };

    const table = useReactTable({
        data,
        columns: generateOnboardingColumns(users ?? [], userID),
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
        meta: {
            updateData: (rowIndex: number, columnId: string, value: any) => {
                const updatedData = data.map(
                    (row: OnboardingChecklist, index: number) => {
                        if (index === rowIndex) {
                            const updatedRow = {
                                ...row,
                                [columnId]: value,
                            };
                            const update: Partial<OnboardingChecklist> = {
                                [columnId]: value,
                                parent_id: milestoneID,
                                id: row.id,
                            };
                            if (saveToDB) {
                                saveIssue?.(
                                    update,
                                    columnId,
                                    value,
                                    onboardingMainItemId,
                                );
                            }
                            return updatedRow;
                        }
                        return row;
                    },
                );
                setData(updatedData);
            },
        },
    });

    const selectedCountTable = table.getSelectedRowModel().rows.length;

    const deleteSelectedIssues = async () => {
        const selectedRows = table.getSelectedRowModel().rows;
        const selectedIds = selectedRows.map((row) => row.original.id);

        await deleteIssues?.(userID, selectedIds, (ids: string[]) => {
            const updatedData = data.filter((row) => !ids.includes(row.id));
            setData(updatedData);
            table.setRowSelection({});
        });
    };

    return (
        <div className="w-full pt-2">
            <div className="rounded-md border w-full">
                <Table className="[&_tr:hover]:bg-gray-50 table-fixed w-full items-center">
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow
                                key={headerGroup.id}
                                className="bg-[#fffdf9]"
                            >
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead
                                            key={header.id}
                                            className={`text-xs h-10 bg-[#fffdf9] text-gray-600 font-medium ${header?.id === "select" ? "" : "border-r last:border-r-0 "}`}
                                            style={{
                                                width: header.getSize(),
                                                maxWidth: header.getSize(),
                                            }}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                      header.column.columnDef
                                                          .header,
                                                      header.getContext(),
                                                  )}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={
                                        row.getIsSelected() && "selected"
                                    }
                                    className="hover:bg-gray-50"
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell
                                            key={cell.id}
                                            className={`p-0 h-10 ${cell.column.id === "select" ? "" : "border-r last:border-r-0 focus-within:outline focus-within:outline-2 focus-within:outline-[#5e6ad2] focus-within:rounded focus-within:z-20"} border-b relative `}
                                            style={{
                                                width: cell.column.getSize(),
                                                maxWidth: cell.column.getSize(),
                                            }}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={table.getAllColumns().length}
                                    className="text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <div className="w-full px-1 py-1">
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button
                                className="p-0 m-0 w-full h-4 outline-none bg-[#F0F0EF] items-center justify-center hover:bg-[#e9e9e7] shadow-sm rounded-full flex"
                                size="sm"
                                variant="outline"
                                onClick={handleAddTask}
                            >
                                <PlusIcon className="h-3 w-3" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent side="bottom">
                            <p>Add row</p>
                        </TooltipContent>
                    </Tooltip>
                </div>
                <div className="flex justify-end items-center my-2 mx-2">
                    {selectedCountTable > 0 && (
                        <Button
                            className="h-6 shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-1 justify-start data-[state=open]:bg-muted shadow-sm text-red9"
                            size="sm"
                            variant="outline"
                            onClick={deleteSelectedIssues}
                        >
                            <TrashIcon className="w-4 h-4" />
                            Delete ({selectedCountTable})
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
