import TicketDisplay from "@/Ticket/TicketDisplay";
import { API, APIWithMethod, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetTeamResponse,
    HistoryResponse,
    Ticket,
    TicketStatus,
    View,
} from "@/interfaces/serverData";
import { Box, Flex, Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { IssueListType } from "./AdminQueriesPage";

import type { NextTicket } from "@/interfaces/serverData";
import { AssemblyErrorMessage } from "@/reusable_components/loadingStates/ErrorMessage";
import { useAuthInfo } from "@propelauth/react";
import { useLocation, useNavigate } from "react-router-dom";

interface AdminTicketPageProps {
    listType: IssueListType;
    identifier: string;
    userID: string;
    teamID?: string;
    viewID?: string;
}

const AdminTicketPage: React.FC<AdminTicketPageProps> = ({
    listType,
    identifier,
    userID,
    teamID,
    viewID,
}) => {
    const api = useApi();
    const location = useLocation();

    const [loadedPathInfo, setLoadedPathInfo] = useState<boolean>(
        [
            IssueListType.Inbox,
            IssueListType.Issues,
            IssueListType.View,
        ].includes(listType),
    );
    const [teamName, setTeamName] = useState<string>();
    const [viewName, setViewName] = useState<string>();

    const {
        data: ticketData,
        isLoading: ticketIsLoading,
        isSuccess: ticketIsSuccess,
        error: ticketError,
        refetch: refetchTicketData,
    } = useQuery({
        queryKey: ["ticket", identifier, userID],
        queryFn: () => fetchTicket(),
        refetchInterval: (error: unknown) => {
            if ((error as Error)?.message === "Ticket not found") {
                console.log("ticket was not found")
                return false;
            }
            return 10000;
        },
        refetchOnWindowFocus: true,
    });
    const {
        data: threadData = [],
        isLoading: threadIsLoading,
        refetch: refetchThreadData,
    } = useQuery({
        queryKey: ["ticket_thread", identifier],
        queryFn: () => fetchTicketThread(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const fetchTicket = async (): Promise<Ticket | null> => {
        let endpoint = `${URLS.serverUrl}${API.getTicketInfo}/${identifier}`;
        if (teamID) {
            endpoint = `${URLS.serverUrl}${API.getTicketInfo}/team/${teamID}/issue/${identifier}`;
        }
        try {
            const response = await api.get(endpoint, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            if (response.status === 200) {
                const ticketData: Ticket = response.data.data;
                return ticketData;
            } else {
                console.error('Non-200 response:', {
                    status: response.status,
                    statusText: response.statusText,
                    data: response.data
                });
                throw new Error("Failed to fetch ticket");
            }
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        } catch (error: any) {
            if (error.response?.status === 404) {
                throw new Error("Ticket not found");
            }
            throw new Error("Failed to fetch ticket");
        }
    };

    const fetchTicketThread = async (): Promise<HistoryResponse[]> => {
        const response = await api.post(
            URLS.serverUrl + API.getHistoryRecords,
            {
                identifier: identifier,
                parent_id: "",
                latest_timestamp: "",
            },
        );
        if (response.status === 200) {
            return response.data.data;
        } else {
            throw new Error("Failed to fetch thread");
        }
    };

    const navigate = useNavigate();

    function moveToNextTicket() {
        if (!location.state || location.state.key !== "issue") {
            console.log("removed local storage");
            localStorage.removeItem("viewedTickets");
        }

        const requestData = {
            ids: JSON.parse(localStorage.getItem("viewedTickets") || "[]"),
            team_id: teamID,
        };

        api.post(`${URLS.serverUrl}${API.getMostRecentTicket}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const nextTicket: NextTicket = res.data.data;

                    const nextTicketURL = `${nextTicket.ticket_identifier}-${nextTicket.ticket_number}`;
                    if (teamID) {
                        navigate(`/teams/${teamID}/issue/${nextTicketURL}`, {
                            state: { key: "issue" },
                        });
                    } else {
                        navigate(`/issue/${nextTicketURL}`, {
                            state: { key: "issue" },
                        });
                    }
                }
            })
            .catch((res) => { });
    }

    function saveStatus(status: string) {
        if (!ticketData?.id) {
            return;
        }
        const requestData: TicketStatus = {
            ids: [ticketData?.id],
            status: status,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                refetchThreadData();
                console.log(`Updated status to ${status} successfully`);
                ticketData.ticket_status = status;
                refetchTicketData();
            } else {
                console.log("Call to update status failed");
            }
        });
    }

    useEffect(() => {
        if (ticketData?.id) {
            markTicketAsViewed(ticketData.id);
        }
    }, [ticketData?.id]);

    // Function to mark a ticket as viewed
    function markTicketAsViewed(ticketId: string) {
        const viewedTickets = JSON.parse(
            localStorage.getItem("viewedTickets") || "[]",
        );
        if (!viewedTickets.includes(ticketId)) {
            viewedTickets.push(ticketId);
            localStorage.setItem(
                "viewedTickets",
                JSON.stringify(viewedTickets),
            );
        }
    }

    function removeTicketsViewed(ticketId: string) {
        const viewedTickets = JSON.parse(
            localStorage.getItem("viewedTickets") || "[]",
        );
        const updatedTickets = viewedTickets.filter(
            (id: string) => id !== ticketId,
        );
        localStorage.setItem("viewedTickets", JSON.stringify(updatedTickets));
    }

    function moveToPreviousTicket() {
        if (ticketData?.id) {
            removeTicketsViewed(ticketData.id);
        }
        if (window.history.length > 1) {
            navigate(-1);
        }
    }

    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    useEffect(() => {
        if (viewID && viewID !== "") {
            const [url] = APIWithMethod.getView;
            api.get(`${URLS.serverUrl}${url}/${viewID}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            }).then((res) => {
                if (res.status === 200) {
                    const view: View = res.data.data;
                    setViewName(view.name);
                }
            });
            if (listType === IssueListType.View) {
                setLoadedPathInfo(true);
            }
        }
    }, [api, listType, viewID]);

    useEffect(() => {
        if (teamID && teamID !== "") {
            const [url] = TeamsAPI.getTeam;
            api.get(`${URLS.serverUrl}${url}/${teamID}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            }).then((res) => {
                if (res.status === 200) {
                    const teamInfo: GetTeamResponse = res.data.data;
                    setTeamName(teamInfo.team.team_name);
                }
            });
            if (listType === IssueListType.Team) {
                setLoadedPathInfo(true);
            }
        }
    }, [api, listType, teamID]);

    return (
        <div className="overflow-hidden">
            <Flex direction="column" align="center" justify="center">
                <Box
                    height="100%" // Ensure it takes full height of its container
                    width="100%"
                    pl="100"
                >
                    <div className="h-full flex-1 flex-col space-y-8 md:flex ">
                        {(ticketIsLoading || !loadedPathInfo) && (
                            <div>
                                <Skeleton>
                                    <Text>
                                        {[...Array(6)].map((_, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            <Text key={index}>
                                                {loremIpsum}
                                            </Text>
                                        ))}
                                    </Text>
                                </Skeleton>
                            </div>
                        )}
                        {ticketData && ticketIsSuccess && loadedPathInfo && (
                            <TicketDisplay
                                threadData={threadData}
                                refetchTicketData={refetchTicketData}
                                refetchThreadData={refetchThreadData}
                                loadingThreadState={threadIsLoading}
                                identifier={identifier}
                                ticket={ticketData}
                                userID={userID}
                                listType={listType}
                                moveToNextTicket={moveToNextTicket}
                                moveToPreviousTicket={moveToPreviousTicket}
                                saveStatus={saveStatus}
                                errorThreadState={false}
                                teamID={teamID}
                                teamName={teamName}
                                viewID={viewID}
                                viewName={viewName}
                            />
                        )}
                        {ticketError && (
                            <div className="flex items-center justify-center h-screen">
                                <AssemblyErrorMessage
                                    message={
                                        (ticketError as Error).message ===
                                            "Ticket not found"
                                            ? "Ticket not found! This ticket either doesn't exist, or it might have been deleted."
                                            : "Sorry, something's wrong! Please notify us at support@askassembly.app."
                                    }
                                />
                            </div>
                        )}
                    </div>
                </Box>
            </Flex>
        </div>
    );
};
export default AdminTicketPage;
