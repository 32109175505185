import type { EmailRecipient } from "@/component/textEditor/ReplyFromPlugin";
import type { LiveObject } from "@liveblocks/client";
import { z } from "zod";

// response type from /auth server call
export interface RepositoryDataResponse {
    default_branch: string;
    name: string;
    owner: string;
    server: string;
    url: string;
    visability: number;
    org_id: string;
}

export interface OrgDataResponse {
    id: string;
    name: string;
    url: string;
    server: string;
}

// request type to /index server call
export interface IndexDataRequest {
    server: string;
    owner: string;
    ref_name: string;
    name: string;
    key: string;
    is_reindex: boolean;
}

// search_natural

export interface SearchResponse {
    id: string;
    org_id: string;
    kind: string;
    // githubblame?: { [line_number: string]: GitHubBlameResponse };
    // nodes?: NodeResponse[][];
}

interface GitHubBlameResponseByLine {
    [line_number: string]: GitHubBlameResponse;
}

export interface DescribeResponse {
    summary: string;
    ids: string[];
    nodes?: CodeResponse[];
    githubblame?: GitHubBlameResponseByLine[];
}

export interface SearchResponseIntegration {
    id: string;
    channel_id: string;
    channel_name: string;
    org_id: string;
    message: string;
    sent: string;
    url: string;
    kind: string;
}

export interface DescribeResponseIntegration {
    summary: string;
    ids: string[];
    nodes?: SearchResponseIntegration[];
}
export interface GitHubBlameResponse {
    Id: string;
    Name: string;
    CommittedDate: string;
    Message: string;
    PRsResponsible: GitHubBlamePRResponse[];
}

export interface GitHubBlamePRResponse {
    Number: number;
    Url: string;
    MergedAt: string;
}

export interface FilterResponse {
    name: string;
    id: string;
}

export interface NodeResponse {
    kind: string; // 'jira' or 'pr' or 'code', etc.
    url: string;
    org_id: string;
}

export const NodeResponseKinds = {
    jira: "Jira",
    pr: "PullRequest",
    linear: "Linear",
    slack: "Slack",
    communityslack: "CommunitySlack",
    notion: "Notion",
    web: "Web",
    code: "code",
    githubTicket: "GitHubTicket",
    confluence: "Confluence",
};

export interface CodeResponse extends NodeResponse {
    signature: string;
    filepath: string;
    server: string;
    owner: string;
    repo: string;
    body: string;
    branch: string;
    hash: string;
    location: string;
}

export interface PRResponse extends NodeResponse {
    pr_number: number;
    merged_at: string;
    head: string;
}

export interface JiraResponse extends NodeResponse {
    pr_number: number;
    key: string;
    summary: string;
    status: string;
    content: string;
    url: string;
    jira_created_at: string;
    jira_updated_at: string;
}

export interface ConfluenceResponse extends NodeResponse {
    todo: string;
}

export interface LinearResponse extends NodeResponse {
    linear_created_at: string;
    linear_updated_at: string;
    summary: string;
    key: string;
}

export interface SlackResponse extends NodeResponse {
    channel_name: string;
    channel_id: string;
    url: string;
    sent: string;
}

export interface NotionResponse extends NodeResponse {
    page_id: string;
    page_title: string;
}

export interface WebResponse extends NodeResponse {
    key: string; // page title
}

export interface ConfluenceResponse extends NodeResponse {
    page_id: string;
    title: string;
}

export interface ScopeResponse {
    key: string;
    name: string;
    owner?: string;
    ref_name?: string;
    server?: string;
    repository_id?: string;
    source_specific_id?: string;
}

export interface Channels {
    key: string;
    channel_name: string;
    source: string;
}

export interface GeneralNotificationSettings {
    new_needs_response: boolean;
    updated_needs_response: boolean;
    sla_breaching_notification: boolean;
    sla_notification: boolean;
}

export interface GetNotificationOptionsResponse {
    metadata: GeneralNotificationSettings;
    inherited_from_org: boolean;
}

export interface SaveNotificationOptionsPayload {
    metadata: GeneralNotificationSettings;
    team_id?: string;
}

export interface SlackNotificationSettings {
    channelKey?: string;
}

export interface SaveNotificationSettingsResponse {
    enabled: boolean;
    metadata: { channel: ScopeResponse | undefined };
    team_id?: string;
}

export interface BotMetadata {
    bot_type: string;
    auto_escalate?: boolean;
    preview_message?: boolean;
    auto_respond?: boolean;
    channels_selected?: ScopeResponse[];
    confidence_enabled?: boolean;
    icon_url?: string;
    responding_enabled?: boolean;
    asm_ticket_channels?: ScopeResponse[];
    trigger_based_ticket_channels?: ScopeResponse[];
    index_code?: boolean;
    space_owners?: ScopeResponse[];
}

export interface BotConnection {
    bot_type: string;
    connection_source: string;
    connection_unique_name: string;
    connection_channels: ScopeResponse[];
    bot_channels?: ScopeResponse[];
    connection_priority?: string;
    operation: string;
}

export interface IntegrationMetadataResponse {
    unique_name: string;
    scopes: ScopeResponse[];
    date: string;
    slack_team_id?: string;
    token_scopes?: string[];
}

export interface QueriesResponse {
    data: Query[];
}

export interface AnalyticsResponse {
    count: number;
}

export interface TimerAnalyticsResponse {
    data: TimerAnalytics[];
}

export interface TimerAnalytics {
    analytics_id: string;
    source: string;
    total_duration: number;
    ticket_number: number;
    original_timestamp: string;
    assignee_user_id: string;
    topic: string[];
}

export interface OrgInfoResponse {
    Code?: IntegrationMetadataResponse[];
    Slack?: IntegrationMetadataResponse[];
    CommunitySlack?: IntegrationMetadataResponse[];
    Linear?: IntegrationMetadataResponse[];
    Jira?: IntegrationMetadataResponse[];
    Google?: IntegrationMetadataResponse[];
    PullRequest?: IntegrationMetadataResponse[];
    Notion?: IntegrationMetadataResponse[];
    Web?: IntegrationMetadataResponse[];
    Discord?: IntegrationMetadataResponse[];
    GitHubTicket?: IntegrationMetadataResponse[];
    GithubDiscussion?: IntegrationMetadataResponse[];
    ChatWidget?: IntegrationMetadataResponse[];
    Confluence?: IntegrationMetadataResponse[];
    HubSpot?: IntegrationMetadataResponse[];
    Intercom?: IntegrationMetadataResponse[];
    API?: IntegrationMetadataResponse[];
    Salesforce?: IntegrationMetadataResponse[];
    GoogleSheet?: IntegrationMetadataResponse[];
}

export interface GetApiKeysResponse {
    created_at: number;
    key: string;
}

export interface ConnectionMetadata {
    connection: string;
    scopes: ScopeResponse[];
    priority: string;
}
export interface GetConnectionsResponse {
    channels: ScopeResponse[];
    connected_integrations: ConnectionMetadata;
}

export interface TicketTag {
    ids: string[];
    tag: string;
    source: string;
    user_id: string;
}

export interface Assignee {
    ids: string[];
    source: string;
    user_id: string;
    assignee_user_id: string;
}

export interface TicketTopics {
    ids: string[];
    added_topics: string[];
    deleted_topics: string[];
    source: string;
    user_id: string;
}

export interface TicketStatus {
    ids: string[];
    status: string;
    source: string;
    user_id: string;
}

export interface TicketTeams {
    ids: string[];
    added_teams: string[];
    removed_teams: string[];
    source: string;
    user_id: string;
}

export interface TicketCompany {
    ids: string[];
    company_id: string;
    source: string;
    user_id: string;
}

export interface TicketCustomer {
    ids: string[];
    customer_id: string;
    source: string;
    user_id: string;
}

export interface TopicCount {
    topic: TopicInfo;
    count: string;
}

export interface RelatedIssue {
    id: string;
    org_specific_id: string;
    title: string;
    query: string;
    tag: string;
    status: string;
    created_at: string;
    ticket_updated_at: string;
    source?: string;
    source_unique_name?: string;
    user_info?: UserResponse;
    assignee_user_id?: string;
}

export interface Question {
    summary: string;
    topic: string;
    category: string;
    count: number;
    related_questions: RelatedIssue[];
}

export interface Insight {
    id: string;
    created_at: string;
    updated_at: string;
    title: string;
    description: string;
    user_id: string;
    status: string;
    related_issues: RelatedIssue[];
    count: number;
    metadata: string;
    external_issues: ExternalIssues[];
    announcements: Announcement[];
    issue_counts?: IssueCount[];
    trend?: string;
    teams: Teams[];
    monetary_value: number;
    accounts: AccountMonetaryRecord[];
}

export interface ExternalIssues {
    id: string;
    parent_id: string;
    url: string;
}

export interface Message {
    id: string;
    text: string;
    user_id: string;
    ts: string;
    reactions: Reaction[];
}

export type UserResponse = {
    id: string;
    name: string;
    username?: string;
    email?: string;
    url?: string;
    avatar?: string;
    assembly_customer_id?: string;
    company_id?: string;
};

export type Query = {
    id: string;
    ticket_identifier: string;
    ticket_number: number;
    number: number;
    title: string;
    query: string;
    created_at: string;
    ticket_updated_at: string;
    bot_category: string;
    source?: string;
    topic: string[];
    ticket_status: string;
    url: string;
    source_specific_id: string;
    source_unique_name: string;
    ai_response?: string;
    internal_note?: string;
    assignee_user_id?: string;
    team_owner_id?: string;
    external_issues: string[];
    breaching?: string;
    user_info: UserResponse;
    assembly_responded: boolean;
    business_hours_id?: string;
    original_timestamp: string;
    disabled?: boolean;
    teams: Teams[];
    user: string;
    time_created?: string;
    time_responded?: string;
    time_closed?: string;
    integration_id?: string;
};

export interface ExportTicketsResponse {
    tickets: Query[];
}

export interface StartStopPair {
    ticket_number: number;
    source: string;
    start_time: string;
    end_time: string;
    duration: string;
    start_user: string;
    end_user: string;
    original_timestamp: string;
    assignee_user_id: string;
    topic: string[];
}

export interface TickerTimerResponse {
    data: StartStopPair[];
}

export interface GmailIntegrationFields {
    to?: EmailRecipient[];
    cc?: EmailRecipient[];
    bcc?: EmailRecipient[];
    subject?: EmailRecipient[];
    from?: EmailRecipient[];
}

export interface Ticket {
    id: string;
    ticket_identifier: string;
    number: number;
    created_at: string;
    updated_at: string;
    query: string;
    title: string;
    bot_category: string;
    source: string;
    source_unique_name: string;
    topic: string[];
    ticket_status: string;
    url: string;
    source_specific_id: string;
    integration_id: string;
    ai_response: string;
    internal_note: string;
    assignee_user_id: string;
    team_owner_id?: string;
    external_issues: ExternalIssues[];
    channel_name: string;
    breaching?: string;
    insights: Insight[]; // Returning abridged version, no related_issues, orgID, and userID
    issueCounts?: IssueCount[];
    trend?: string;
    business_hours_id?: string;
    teams: Teams[];
    user_info: UserResponse;
    gmail_integration_fields?: GmailIntegrationFields;
    parent_ticket_info?: LinkedIssue;
    child_tickets_info?: LinkedIssue[]
}

export interface LinkedIssue {
    id: string,
    ticket_identifier: string,
    number: string,
    title: string,
    query: string,
    status: string,
    source: string,
}

export interface NextTicket {
    id: string;
    ticket_identifier: string;
    ticket_number: number;
}

export interface GetThreadInfoResponse {
    thread: Message[];
    users: { [key: string]: UserResponse };
}

export interface HistoryResponse {
    id: string;
    timestamp: string;
    type: string;
    content: string;
    source: string;
    source_unique_name: string;
    metadata: string;
    user_data: UserResponse;
    reactions: Reaction[];
    files: MyFile[];
}

export interface Reaction {
    emoji?: string;
    name?: string;
    users: string[];
    count: number;
}

export interface ExcelRow {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    [key: string]: any; // Dynamic key-value pair for the row data
}

// Define a type for your CSV data
export interface CsvRow {
    [key: string]: string | number;
}

export interface MyFile {
    id: string;
    url_private: string;
    url_private_download: string;
    name: string;
    mimetype: string;
    user: string;
    url_local: string;
    excel_rows?: ExcelRow[];
    csv_rows?: CsvRow[];
    html_url?: string;
    too_big_to_preview?: boolean;
    bytes?: string;
    inline_content_id?: string;
    width?: string;
    height?: string;
    isPublicUrl?: boolean;
}

export interface PublishMessage {
    message: string;
    source_specific_id?: string;
    integration_id: string;
    source: string;
    user: string;
    message_title?: string;
    images: UploadedFile[];
    files?: UploadedFileWithMetadata[];
    recipient?: string; // email
    subject?: string; // email
    gmail_integration_fields?: GmailIntegrationFields; // email
    metadata?: string;
}

export interface CreateEscalation {
    parent_id: string;
    escalation_type: string;
    message: string;
    title?: string;
    user: string;
    team_owner?: string; // Assembly Web
    gmail_integration_fields?: GmailIntegrationFields; // email
    integration_id?: string; // Gmail
    channel?: string; // Slack
    images: UploadedFile[];
    files?: UploadedFileWithMetadata[];
}

export interface InsightData {
    existing_insight_id?: string;
    title?: string;
    description?: string;
    user_id?: string;
    status?: string;
    related_issues_added?: string[];
    related_issues_removed?: string[];
    related_issues?: string[];
    metadata?: string;
    team_id?: string;
    added_teams?: string[];
    removed_teams?: string[];
}

export interface SaveInsightResponse {
    id: string;
}

export interface GetUserResponse {
    id: string;
    email: string;
    username: string;
    first_name: string;
    last_name: string;
    picture_url: string;
    user_role: string;
}

export interface IconEntry {
    Component: React.ElementType;
    props?: React.SVGProps<SVGElement>;
}

interface TicketComment {
    user: string;
    comment: string;
}

export interface CreateTicketPayload {
    query: TicketComment[];
    title: string;
    source: string;
    user: string;
    question: string;
    source_specific_id: string;
}

export interface CreateTicketFromUIPayload {
    title: string;
    message: string;
    user: string;
    status: string;
    assignee_user_id: string;
    team_owner_id: string;
    tag: string;
    topic: string[];
}

export interface CreateTicketFromUIResponse {
    id: string;
    org_specific_id: string;
}

export interface CreateHistoryPayload {
    id: string;
    parent_id: string;
    history_type: string;
    content: string;
    source: string;
    unique_name: string;
    user: string;
    metadata: string;
    source_specific_id: string;
    reactions: Reaction[];
    files: MyFile[];
    original_timestamp?: number;
}

export enum PublishTicketType {
    Issue = "Issue",
    Insight = "Insight",
}

export interface PublishTicketPayload {
    connection: string;
    title: string;
    source: string;
    user: string;
    question: string;
    url: string;
    comments: TicketComment[];
    system_id: string;
    system_type: PublishTicketType;
    destination?: ScopeResponse;
    priority?: string;
}

export interface PublishTicketResponse {
    urls: string[];
}

export interface QueriesWithPaginationResponse {
    data: Query[];
    has_next_page: boolean;
    next_cursor: number;
    currentStatus: string;
    nextStatus?: string;
}

export interface InteractionsAnalyticsResponse {
    id: string;
    ticket_number: number;
    original_timestamp: string;
}

export interface InteractionsAnalyticsPaginationResponse {
    data: InteractionsAnalyticsResponse[];
    has_next_page: boolean;
    next_cursor: number;
}

export interface ResponseTimeResponse {
    results: ResponseTime[];
    average: number;
}

export interface SLAResponse {
    results: SLABreach[];
    average: number;
}

export interface SLABreach {
    ticket_number: string;
    url: string;
    assignee: string;
    start: string;
    end: string;
    duration: number;
    source: string;
    bot_category: string;
    topics: string;
}

export interface ResponseTime {
    ticket_number: string;
    url: string;
    assignee: string;
    start: string;
    end: string;
    duration: number;
    source: string;
    bot_category: string;
    topics: string;
}

export interface ResolutionTimeResponse {
    average: number;
    results: ResolutionTime[];
}

export interface ResolutionTime {
    ticket_number: string;
    url: string;
    assignee: string;
    start: string;
    end: string;
    duration: number;
    source: string;
    bot_category: string;
    topics: string;
}

export interface GetTopicsResponse {
    id: string;
    org_id: string;
    topic_name: string;
    count: string;
    type: string;
    created_at?: string;
    description?: string;
    color?: string;
    teams: Teams[];
}

export interface InsightsWithPaginationResponse {
    data: Insight[];
    has_next_page: boolean;
    next_cursor: number;
}

export interface InsightsResponse {
    pages: {
        data: Insight[];
    }[];
}

export type InsightInfoResponse = {
    insight: Insight;
    issue_counts: IssueCount[];
    trend: string;
};

export interface IssueCount {
    date: string;
    numberOfIssues: number;
}

export interface SLAResponse {
    id: string;
    enabled: boolean;
    channels: ScopeResponse[]; // Adjust type as necessary
    teams: Teams[];
    breach_target: number;
    breach_target_unit: string;
    notification?: number;
    notification_time_unit?: string;
    notification_enabled: boolean;
    owner_id: string;
    created_at?: string;
    updated_at?: string;
    source: string;
}

export interface SLACreatePayload {
    operation: string;
    enabled: boolean;
    channels: ScopeResponse[];
    teams: Teams[];
    breach_target: number;
    breach_target_unit: string;
    notification?: number;
    notification_time_unit?: string;
    source: string;
    id?: string;
}

export interface SLADeletePayload {
    id: string;
    operation: string;
}

export interface CustomerGroup {
    id?: string;
    created_at: string;
    updated_at: string;
    group_name: string;
    description: string;
    user_id: string;
    metadata: "";
    editable: boolean;
    integration: string;
}

export interface CustomerGroupMetadata {
    Slack?: Array<ScopeResponse>;
    Discord?: Array<ScopeResponse>;
    CommunitySlack?: Array<ScopeResponse>;
}

export interface CustomerGroupCreatePayload {
    operation: string;
    metadata: string;
    id?: string;
    group_name: string;
    description: string;
}

export interface CustomerGroupDeletePayload {
    id: string;
    operation: string;
}

export interface CustomerTicket {
    id: string;
    customer_id: string;
    ticket_id: string;
    created_at: string;
    updated_at: string;
}

export interface CustomerCompany {
    // todo - see if we can stop using this at some point, makes life a lot harder with this one random conversion
    id: string;
    name: string;
    domain: string;
    image_url: string;
    additional_properties?: Record<string, unknown>;
    contract_value?: number;
    contract_type?: ContractType;
}

interface Metadata {
    [key: string]: ScopeResponse[]; // This allows string indexing with arbitrary values (could be improved with stricter types)
}

export enum ContractType {
    Year = "Year",
    Month = "Month",
}

export interface CompanyCreatePayload {
    operation: string;
    enabled: boolean;
    name: string;
    image_url?: string;
    contract_value?: number;
    contract_type?: ContractType;
    plan?: string;
    domain?: string;
}

export interface AccountResult {
    companies: Account[];
    total: number;
}

export interface ListAccountsResponse {
    data: Account[];
    has_next_page: boolean;
    next_cursor: number;
}

export interface ListCustomersResponse {
    data: Account[];
    has_next_page: boolean;
    next_cursor: number;
}

export interface CRMProperties {
    name: string;
    group_name: string;
    label: string;
}

export interface Account {
    id: string;
    name: string;
    domain: string;
    email?: string;
    metadata?: Metadata;
    org_id: string;
    assignee_user_id: string;
    created_at: string;
    updated_at: string;
    plan: string;
    contract_value: number;
    contract_type: string;
    account_type: string;
    image_url: string;
    description?: string;
    company?: CustomerCompany;
    company_id?: string;
    associated_customers?: Account[];
    last_ticket: CustomerTicket;
    teams: Teams[];
    labels: AccountsLabel[];
    notes?: string;
    additional_properties?: Record<string, unknown>;
    health_score?: number;
    preferred_communication?: Map<string, ScopeResponse>;
    renewal_date?: string;
    attributes_schema?: AttributesSchema
}

interface AttributesSchema {
    custom: string[];
    [key: string]: string[];
}

export interface IndividualCreatePayload {
    name: string;
    image_url?: string;
    contract_value?: number;
    contract_type?: string;
    renewal_date?: string;
    tier?: string;
    stage?: string;
    company_type?: string;
    domain?: string;
    company_id?: string;
    contacts?: Account[];
    assignee_user_id?: string;
    teams?: Teams[];
}

export interface AccountUpdatePayload {
    id: string;
    name: string;
    image_url?: string;
    contract_value?: number;
    contract_type?: string;
    renewal_date?: string;
    tier?: string;
    stage?: string;
    company_type?: string;
    domain: string;
    company_id?: string;
    contacts?: Account[];
    assignee_user_id?: string;
    teams?: Teams[];
    notes?: string;
    health_score?: number;
    preferred_communication?: { [k: string]: ScopeResponse };
}

export interface Announcement {
    id: string;
    created_at: string;
    updated_at: string;
    name: string;
    content: string;
    status: string;
    user_id: string;
    metadata: string;
    customer_groups: ScopeResponse[];
    announcement_type: string;
    insight_id: string;
    recommended_answer: string;
    files: (UploadedFile | UploadedFileWithMetadata)[];
}

export interface AnnouncementsWithPaginationResponse {
    data: Announcement[];
    has_next_page: boolean;
    next_cursor: number;
}

export interface AnnouncementsResponse {
    pages: {
        data: Announcement[];
    }[];
}

export interface InsightMonetaryResponse {
    sum: number;
    result: MonetaryRecord[];
}

export interface MonetaryRecord {
    customer_id: string;
    customer_name: string;
    customer_image_url: string;
    company_id: string;
    company_name: string;
    company_image_url: string;
    contract_value: number;
    contract_type: string;
}

export interface AccountMonetaryRecord {
    id: string;
    name: string;
    image_url: string;
    type: string;
    value_per_month: number;
    company_individuals: Individual[];
}

export interface Individual {
    id: string;
    name: string;
    image_url: string;
}

export interface TopicInfo {
    ID: string;
    Name: string;
}

export interface InsightFilters {
    topics?: TopicInfo[];
    tags?: string[];
    statuses?: string[];
    sources?: string[];
    assignee_user_ids?: string[];
    oldest?: string;
    latest?: string;
    exclude_existing_ri?: boolean;
    team_id?: string;
}

export interface ErrorResponse {
    details?: string;
}

export interface View {
    id: string;
    name: string;
    description: string;
    user_id: string;
    filters: Map<string, Set<string>>;
    group: string;
    is_internal_team: boolean;
}

export interface Teams {
    id: string;
    team_name: string;
    description: string;
    org_id?: string;
}

export interface GetTeamResponse {
    team: Teams;
    members: GetUserResponse[];
}

export interface UploadedFile {
    src: string;
    alt: string;
    type: string;
}

export interface UploadedFileWithMetadata {
    file_name: string;
    file_type: string;
    file_size: number;
    file: File;
    type: string;
}

export interface GetTopicsPrefResponse {
    id: string;
    created_at: string;
    updated_at: string;
    org_id: string;
    ai_tagging: boolean;
    add_new_ai_topics: boolean;
    type: string;
    type_id: string;
}

export interface TeamSources {
    id: string;
    source: string;
    scopes: ScopeResponse[];
}

export interface TimeRange {
    timeFrom: string;
    timeTo: string;
}
export interface Day {
    day: string;
    toggleOn: boolean;
    timeRanges: TimeRange[];
}

export enum BusinessHoursType {
    User = "User",
    Team = "Team",
    Org = "Org",
}

export interface GetBusinessHoursPayload {
    type: BusinessHoursType;
    type_id: string;
}

export interface SaveBusinessHoursPayload {
    operation: string;
    id?: string;
    timezone?: string;
    times?: Day[];
    type?: BusinessHoursType;
    type_id?: string;
    excluded_Dates?: string[];
}

export interface BusinessHours {
    id: string;
    created_at: string;
    updated_at: string;
    org_id: string;
    timezone: string;
    times: Day[];
    type: BusinessHoursType;
    type_id: string;
    excluded_dates: string[];
}

export interface GetTimeLeftWithinBusinessHoursPayload {
    target_time: string;
    business_hours_id: string;
}

export interface GetTimeLeftWithinBusinessHoursResponse {
    time_left_seconds: number;
    paused: boolean;
    when_restart?: string;
}

export type UploadedFileLive = LiveObject<{
    src: string;
    alt: string;
}>;

export interface IssueScore {
    issue: Query;
    score: number;
    ticket_identifier?: string;
}

export interface SaveTopicPrefsPayload {
    ai_tagging: boolean;
    add_new_ai_topics: boolean;
    team_id?: string;
}

export interface TopicPrefSettings {
    ai_tagging: boolean;
    add_new_ai_topics: boolean;
    inherited_from_org: boolean;
}

export interface SaveTopicPayload {
    name: string;
    description: string;
    color: string;
    type: string;
    team_ids: string[];
}

export interface EditTopicPayload {
    id: string;
    name?: string;
    description?: string;
    color?: string;
    teams?: string[];
}

export interface Category {
    id: string;
    name: string;
    description: string;
    color: string;
    teams: Teams[];
    type: string;
    count: number;
}

export type Topic = {
    color: string;
    label: string;
    value: string;
    key: string;
};

export enum AccountsLabelsType {
    Tier = "Tier",
    Stage = "Stage",
    CompanyType = "CompanyType",
}

export interface AccountsLabel {
    id: string;
    name: string;
    description: string;
    color: string;
    teams: Teams[];
    type: AccountsLabelsType;
    count: number;
}

export const teamSchema = z.object({
    id: z.string(),
    team_name: z.string(),
    description: z.string(),
});

export interface GenTitleDescForInsightResponse {
    title: string;
    description: string;
}

export interface Integration {
    id: string;
    integration: string;
    unique_name: string;
    enabled: boolean;
}

export interface Template {
    id: string;
    created_at: string;
    updated_at: string;
    name: string;
    template: string;
    metadata: Map<string, string>;
    personal: boolean;
    user_id: string;
    teams: Teams[];
}

export const SUPPORTED_VARIABLES = [
    "customer name",
    "customer username",
    "customer email",
    "company name",
    "ai response",
];

export enum LabelsType {
    InteractionType = "InteractionType",
    WorkflowType = "WorkflowType",
    Tier = "Tier",
    Stage = "Stage",
    CompanyType = "CompanyType",
}
export interface Label {
    id: string;
    name: string;
    description: string;
    color: string;
    teams?: Teams[];
    type?: LabelsType;
    count?: number;
}

// Workflow Defintions
export enum WorkflowStepType {
    Trigger = "trigger",
    Condition = "condition",
    Wait = "wait",
    Action = "action",
}
export enum WorkflowStepSubtype {
    // Trigger Subtypes
    IssueCreated = "issue_created",
    InteractionTypeUpdated = "interaction_type_updated",
    TagUpdated = "tag_updated",
    StatusUpdated = "status_updated",

    // Condition Subtypes
    Any = "any",
    All = "all",

    // Wait Business Hours Subtypes
    Org = "org",
    Team = "team",
    Assignee = "assignee",

    // Action Subtypes
    SendTemplate = "send_template",
    SendMessage = "send_message",
    AddAssignee = "add_assignee",
    AddInteractionType = "add_interaction_type",
    AddTag = "add_tag",
    ChangeStatus = "change_status",
}
export interface WorkflowStep {
    id: string;
    name: string;
    parent_id?: string;
    type: WorkflowStepType;
    subtype: WorkflowStepSubtype;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    metadata: Map<string, any>;
}
export interface Workflow {
    id: string;
    created_at: string;
    updated_at: string;
    name: string;
    description: string;
    type: Label | undefined;
    enabled: boolean;
    freq: number;
    last_triggered: string;
    steps: WorkflowStep[];
}

export interface TimeRepeat {
    type: string;
    freq: TimeFreq;
    end: TimeEnd;
}

export interface TimeFreq {
    time: number;
    unit: string;
}

export interface TimeEnd {
    type: string;
    time: Date;
    count: number;
}

export interface AddWorkflowStep {
    id: string;
    name: string;
    parent_id: string;
    type: string;
    subtype: string;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    metadata: { [key: string]: any };
}
export interface AddWorkflowRequest {
    name: string;
    description: string;
    type_id?: string;
    steps: AddWorkflowStep[];
}
export interface UpdateWorkflowRequest {
    name?: string;
    description?: string;
    type_id?: string;
    enabled?: boolean;
    steps?: AddWorkflowStep[];
}
export interface WorkflowStepWithRunInfo {
    analytics_id: string;
    id: string;
    name: string;
    parent_id?: string;
    type: WorkflowStepType;
    subtype: WorkflowStepSubtype;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    metadata: Map<string, any>;
    run_id?: string;
    start_time?: string;
    end_time?: string;
    success: boolean;
    last_step: boolean;
}
export interface GetWorkflowRunsForIssueResponse {
    run_id_to_info: Map<string, WorkflowStepWithRunInfo[]>;
    run_id_to_workflow: Map<string, Workflow>;
}

export enum EditorActionType {
    Reply = "Reply",
    InternalReply = "InternalReply",
    NewEmail = "NewEmail",
    New = "New",
    Escalation = "Escalation"
}

export enum EditorType {
    TicketReply = "TicketReply",
    New = "New",
    Escalation = "Escalation"
}

export interface WorkflowValidationError {
    message: string;
    nodeIds?: string[];
    type:
    | "disconnected"
    | "noAction"
    | "noSelection"
    | "conflictingFilters"
    | "invalidValue";
}

export type WorkflowsFilterType = {
    type: string;
    enabled: boolean;
    id: string;
};

export type SendMessageResponse = {
    id: string;
    url: string;
};

export enum UpdateBotPrefTypes {
    ASMTicket = "ASMTicket",
    TriggerBased = "TriggerBased",
}