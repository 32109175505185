import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import { API, ContactsAPI, URLS } from "@/constant";
import CalendarBadge from "@/design/CalendarBadge";
import { useApi } from "@/interfaces/api";
import {
    type GetOnboardingResponse,
    type OnboardingChecklist,
    OnboardingChecklistType,
} from "@/interfaces/onboardingServerData";
import type {
    Account,
    AccountsLabel,
    GetUserResponse,
    IconEntry,
    InteractionsAnalyticsPaginationResponse,
    Query,
    ScopeResponse,
    Teams,
} from "@/interfaces/serverData";
import { getBadgeForAccountsLabel, getStatusIcon } from "@/utilities/methods";
import { CircularProgress } from "@heroui/progress";
import { AvatarIcon, CalendarIcon, CheckIcon } from "@radix-ui/react-icons";
import { Badge, DataList, Flex } from "@radix-ui/themes";
import {
    type UseQueryResult,
    useInfiniteQuery,
    useQuery,
} from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import {
    BookUserIcon,
    Clapperboard,
    MailboxIcon,
    NotepadTextIcon,
    SendIcon,
    SquareCheckIcon,
    TableProperties,
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import React from "react";
import type { DateRange } from "react-day-picker";
import Markdown from "react-markdown";
import { useLocation, useNavigate } from "react-router-dom";
import type { GroupedDataItem } from "../AdminAnalyticsPage";
import InteractionsComponent from "../Analytics/InteractionsPage";
import { integrationBackEndDataMappingToSvg } from "../Integrations/constant";
import type { AccountsListType } from "./Accounts";
import { CustomersTable } from "./CustomersTable/CustomersTable";
import {
    getMilestoneProgressValue,
    getProgressColor,
} from "./Onboarding/MilestoneCard";
import { SendNewMessageDialog } from "./SendNewMessageDialog";

export interface Company360Props {
    orgID: string;
    userID: string;
    account: Account;
    tickets: Query[];
    contacts: Account[];
    updateData: (
        company: boolean,
        contacts: boolean,
        tickets: boolean,
    ) => Promise<void>;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    teamsQuery: UseQueryResult<Teams[], Error>;
    channelsQuery: UseQueryResult<Map<string, ScopeResponse[]>, Error>;
    listType: AccountsListType;
}

export const Company360 = ({
    orgID,
    userID,
    account,
    tickets,
    contacts,
    updateData,
    usersQuery,
    teamsQuery,
    channelsQuery,
    listType,
}: Company360Props) => {
    const api = useApi();
    const navigate = useNavigate();
    const location = useLocation();

    const [needsResponseCount, setNeedsResponseCount] = useState<number>();
    const [breachingCount, setBreachingCount] = useState<number>();

    // Process last interaction date
    let lastTicketDate = new Date(account.last_ticket?.created_at);
    if (
        Number.isNaN(lastTicketDate.getTime()) ||
        !account.last_ticket?.created_at
    ) {
        lastTicketDate = new Date();
    }
    const today = new Date();
    const isToday =
        lastTicketDate.getDate() === today.getDate() &&
        lastTicketDate.getMonth() === today.getMonth() &&
        lastTicketDate.getFullYear() === today.getFullYear();
    let date: string;
    if (isToday) {
        const userLocale = navigator.language || "en-US";
        date = lastTicketDate.toLocaleTimeString(userLocale, {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    } else {
        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        date = lastTicketDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
            year: "numeric",
        });
    }

    const BreachingIcon = getStatusIcon("Breaching");
    const NeedsResponseIcon = getStatusIcon("NeedsResponse");
    useEffect(() => {
        let nrCount = 0;
        let bCount = 0;

        for (const interaction of tickets) {
            if (interaction.ticket_status === "NeedsResponse") {
                nrCount += 1;
            }
            if (interaction.ticket_status === "Breaching") {
                bCount += 1;
            }
        }
        setNeedsResponseCount(nrCount);
        setBreachingCount(bCount);
    }, [tickets]);

    const [range, setRange] = useState<DateRange | undefined>({
        from: new Date(new Date().setDate(new Date().getDate() - 14)),
        to: new Date(),
    });
    const fetchInteractionsAnalytics = async ({
        pageParam = 0,
    }: {
        pageParam?: number;
    }): Promise<InteractionsAnalyticsPaginationResponse> => {
        try {
            const response = await api.get(
                `${URLS.serverUrl}${API.getInteractionsAnalytics}`,
                {
                    params: {
                        source: "",
                        teamID: "",
                        customerID: "",
                        companyID: account.id,
                        start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                        end: range?.to?.toJSON() ?? new Date().toJSON(),
                        limit: 1000,
                        offset: pageParam,
                    },
                    headers: { "Content-Type": "application/json" },
                },
            );

            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data: interactionsData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        status: interactionsStatus,
    } = useInfiniteQuery({
        queryKey: [
            [`interactions_analytics_${""}`],
            Array.from([]),
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: fetchInteractionsAnalytics,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 100000,
        refetchOnWindowFocus: true,
    });

    const interactionsCombinedData = useMemo(() => {
        return interactionsData && Array.isArray(interactionsData.pages)
            ? interactionsData.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                ) // Filter out null or undefined items in page.data
            : [];
    }, [interactionsData]);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const parsedData = interactionsCombinedData?.map((item) => {
        const utcDate = parseISO(item.original_timestamp);
        // Convert to user's timezone
        const zonedDate = toZonedTime(utcDate, userTimeZone);
        return {
            date: format(zonedDate, "yyyy-MM-dd"),
        };
    });

    // Rest of the grouping logic remains the same
    const groupedData = parsedData.reduce(
        (acc: { [key: string]: GroupedDataItem }, { date }) => {
            acc[date] = acc[date] || { date, count: 0 };
            acc[date].count += 1;
            return acc;
        },
        {},
    );

    // Fill in missing dates from range.from to range.to
    if (range?.from && range?.to) {
        const startDate = range.from;
        const endDate = range.to;
        const currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            const dateStr = format(currentDate, "yyyy-MM-dd");
            if (!groupedData[dateStr]) {
                groupedData[dateStr] = { date: dateStr, count: 0 };
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
    }

    const orderedGroupedData = Object.values(groupedData).sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );

    // Onboarding
    const fetchMilestones = async (): Promise<GetOnboardingResponse> => {
        try {
            const { url } = ContactsAPI.getOnboardingChecklist;
            const response = await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                params: {
                    company_id: account.id,
                    onboarding_type: OnboardingChecklistType.Milestone,
                },
            });
            if (response.data === null) {
                return {
                    onboarding: undefined,
                    onboarding_checklist: [],
                };
            }
            if (response.status === 200) {
                return response.data.data;
            }
            return {
                onboarding: undefined,
                onboarding_checklist: [],
            };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return {
                onboarding: undefined,
                onboarding_checklist: [],
            };
        }
    };

    const {
        data: onboardingData,
        isLoading: loadingOnboardingData,
        isError: errorOnboardingData,
        refetch: refetchOnboardingData,
    } = useQuery({
        queryKey: ["milestones", account.id],
        queryFn: fetchMilestones,
    });

    const [onboardingItems, setOnboardingItems] = useState<
        Map<string, OnboardingChecklist>
    >(
        new Map(
            onboardingData?.onboarding_checklist?.map((item) => [
                item.id,
                item,
            ]),
        ),
    );
    useEffect(() => {
        setOnboardingItems(
            new Map(
                onboardingData?.onboarding_checklist?.map((item) => [
                    item.id,
                    item,
                ]),
            ),
        );
    }, [onboardingData]);

    const foundUser: GetUserResponse | undefined = (usersQuery?.data ?? []).find(
        (user) => user.id === account?.assignee_user_id,
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;
    const renewalDate = account?.renewal_date
        ? new Date(account?.renewal_date)
        : new Date();
    const formattedRenewalDate = renewalDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        timeZone: "UTC"
    });
    const [serviceIcons, setServiceIcons] = useState<Set<IconEntry>>(
        new Set<IconEntry>(),
    );
    useEffect(() => {
        const serviceNames = Object.keys(account?.metadata ?? {});
        const serviceIcons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        serviceNames.forEach((serviceName) => {
            const IconComponent =
                integrationBackEndDataMappingToSvg.get(serviceName);
            if (IconComponent) {
                serviceIcons.add({
                    Component: IconComponent,
                    props: {
                        width: 18,
                        height: 18,
                        style: {
                            marginLeft: "-2px",
                            marginRight: "-2px",
                        },
                    },
                });
            }
        });
        setServiceIcons(serviceIcons);
    }, [account?.metadata]);

    return (
        <Flex direction="column" gap="4" className="py-2 px-4">
            <div className="flex items-center gap-3 justify-between w-full">
                <Card className="w-full rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 pt-4 pb-3.5 flex justify-between gap-3 items-start">
                    <div className="flex flex-col gap-1">
                        <div className="text-sm font-semibold">Actions</div>
                        <SendNewMessageDialog
                            accountType="company"
                            triggerElement={
                                <Button
                                    className="text-sm h-[1.5rem] py-0.5"
                                    size="sm"
                                    variant="secondary"
                                >
                                    <div className="flex items-center gap-1.5 w-full">
                                        <div>Send Checkin</div>
                                        <SendIcon className="w-3 h-3" />
                                    </div>
                                </Button>
                            }
                            account={account}
                            updateData={updateData}
                        />
                    </div>
                    <Clapperboard className="h-4 w-4" />
                </Card>
                <Card className="w-full rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex justify-between items-start">
                    <div className="flex flex-col gap-0.5">
                        <div className="text-sm font-semibold">
                            Last Interaction
                        </div>
                        <div className="text-lg">
                            {account?.last_ticket ? (
                                isToday ? `Today, ${date}` : date
                            ) : "Never"}
                        </div>
                    </div>
                    <MailboxIcon className="h-4 w-4 text-primary" />
                </Card>
                {/* TODO: add links to interactions page with breaching filter */}
                <Card className="w-full rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex justify-between items-start">
                    <div className="flex flex-col gap-0.5">
                        <div className="text-sm font-semibold">Breaching</div>
                        <div className="text-lg">{`${breachingCount} Interactions`}</div>
                    </div>
                    <BreachingIcon className="h-4 w-4 text-#FFE9ED" />
                </Card>
                <Card className="w-full rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex justify-between items-start">
                    <div className="flex flex-col gap-0.5">
                        <div className="text-sm font-semibold">
                            Needs Response
                        </div>
                        <div className="text-lg">{`${needsResponseCount} Interactions`}</div>
                    </div>
                    <NeedsResponseIcon className="h-4 w-4 text-#E6E9E8" />
                </Card>
            </div>
            <div className="flex items-start gap-3 justify-between w-full">
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                <div
                    className="w-3/5"
                    onClick={() =>
                        navigate(`${location.pathname}/interactions`)
                    }
                >
                    <InteractionsComponent
                        interactionsChartData={orderedGroupedData}
                        interactionsQueryStatus={interactionsStatus}
                        className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4"
                    />
                </div>
                <div className="flex flex-col gap-3 w-2/5">
                    <Card
                        className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex flex-col gap-0.5 w-full max-h-[200px]"
                        onClick={() =>
                            navigate(`${location.pathname}/contacts`)
                        }
                    >
                        <div className="flex justify-between">
                            <div className="text-sm font-semibold">
                                Contacts
                            </div>
                            <BookUserIcon className="h-4 w-4" />
                        </div>
                        <div className="h-full overflow-y-auto">
                            <CustomersTable
                                userID=""
                                forceUpdate={updateData}
                                users={usersQuery?.data ?? []}
                                customers={contacts}
                                company={account}
                                usersQuery={usersQuery}
                                teamsQuery={teamsQuery}
                                orgID={orgID}
                                listType={listType}
                            />
                        </div>
                    </Card>
                    {onboardingItems.size > 0 ? (
                        <Card
                            className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex flex-col gap-2 w-full max-h-[250px]"
                            onClick={() =>
                                navigate(`${location.pathname}/onboarding`)
                            }
                        >
                            <div className="flex justify-between items-start ">
                                <div className="text-sm font-semibold">
                                    Onboarding
                                </div>
                                <SquareCheckIcon className="h-4 w-4" />
                            </div>
                            <div className="flex flex-col gap-2">
                                {Array.from(onboardingItems.values())
                                    .filter(
                                        (item) =>
                                            item.onboarding_type ===
                                            OnboardingChecklistType.Milestone,
                                    )
                                    .map((item) => {
                                        const foundUser:
                                            | GetUserResponse
                                            | undefined = (
                                                usersQuery?.data ?? []
                                            ).find(
                                                (user) =>
                                                    user.id ===
                                                    item.assignee_user_id,
                                            );
                                        const pictureURL =
                                            foundUser?.picture_url ?? "";
                                        const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;
                                        return (
                                            <Card
                                                key={item.id}
                                                className="flex items-center gap-2 justify-between rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none px-4 py-3"
                                            >
                                                <div className="flex items-center gap-2">
                                                    <span className="text-sm font-medium">
                                                        {item.name}
                                                    </span>
                                                    <Badge
                                                        color={"gray"}
                                                        size="2"
                                                        variant="outline"
                                                        className="ring-[0.8px] text-gray-400 ring-[#E0E1E6] rounded-md"
                                                    >
                                                        {item.completed_count} /{" "}
                                                        {item.total_tasks}
                                                        <CircularProgress
                                                            aria-label="Loading..."
                                                            showValueLabel={
                                                                getMilestoneProgressValue(
                                                                    item.total_tasks ??
                                                                    0,
                                                                    item.completed_count ??
                                                                    0,
                                                                ) === 1
                                                            }
                                                            classNames={{
                                                                svg: "w-5 h-5",
                                                                track: "stroke-gray-200",
                                                                indicator:
                                                                    getProgressColor(
                                                                        item,
                                                                        item.completed_count ??
                                                                        0,
                                                                        item.total_tasks ??
                                                                        0,
                                                                    ),
                                                            }}
                                                            strokeWidth={4}
                                                            size="sm"
                                                            valueLabel={
                                                                <CheckIcon className="w-3 h-3" />
                                                            }
                                                            value={
                                                                getMilestoneProgressValue(
                                                                    item.total_tasks ??
                                                                    0,
                                                                    item.completed_count ??
                                                                    0,
                                                                ) * 100
                                                            }
                                                        />
                                                    </Badge>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    {item.deadline && (
                                                        <CalendarBadge
                                                            date={item.deadline}
                                                            iconColor="text-red-500"
                                                        />
                                                    )}

                                                    {item.assignee_user_id &&
                                                        item.assignee_user_id !==
                                                        "noAssignee" ? (
                                                        <div className="lb-avatar rounded-lg w-6 h-6 mx-1">
                                                            {pictureURL && (
                                                                <img
                                                                    className="lb-avatar-image"
                                                                    src={
                                                                        pictureURL
                                                                    }
                                                                    alt={
                                                                        userName
                                                                    }
                                                                />
                                                            )}
                                                            <span>
                                                                {userName ?? ""}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <AvatarIcon className="w-6 h-6 mx-1" />
                                                    )}
                                                </div>
                                            </Card>
                                        );
                                    })}
                            </div>
                        </Card>
                    ) : (
                        account?.notes && (
                            <Card className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex justify-between items-start"
                                onClick={() =>
                                    navigate(`${location.pathname}/notes`)
                                }>
                                <div className="flex flex-col gap-0.5 w-full">
                                    <div className="text-sm font-semibold">
                                        Notes
                                    </div>
                                    <div className="flex-grow overflow-hidden">
                                        <Markdown
                                            className="text-sm text-gray-500"
                                            components={{
                                                a: ({ node, ...props }) => (
                                                    <a
                                                        {...props}
                                                        style={{
                                                            textDecoration: "underline",
                                                            color: "#5B5BD6",
                                                        }}
                                                    />
                                                ),
                                                code(props) {
                                                    const { children } = props;
                                                    return (
                                                        <code className="text-balance">
                                                            {children}
                                                        </code>
                                                    );
                                                },
                                                ul: ({ children }) => (
                                                    <ul className="list-disc pl-5">{children}</ul> // Unordered list with bullets
                                                ),
                                                ol: ({ children }) => (
                                                    <ol className="list-decimal pl-5">
                                                        {children}
                                                    </ol> // Ordered list with numbers
                                                ),
                                                li: ({ children }) => (
                                                    <li className="ml-2">{children}</li> // Spacing for list items
                                                ),
                                                p: ({ children }) => (
                                                    <p className="leading-tight">{children}</p> // For paragraphs (if needed)
                                                ),
                                            }}
                                        >
                                            {account?.notes}
                                        </Markdown>
                                    </div>
                                </div>
                                <NotepadTextIcon className="h-4 w-4" />
                            </Card>
                        )
                    )}
                    <Card
                        className="rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none hover:shadow px-5 py-4 flex flex-col gap-2 w-full"
                        onClick={() =>
                            navigate(`${location.pathname}/attributes`)
                        }
                    >
                        <div className="flex justify-between items-start ">
                            <div className="text-sm font-semibold">
                                Attributes
                            </div>
                            <TableProperties className="h-4 w-4" />
                        </div>
                        <div className="flex flex-col gap-2">
                            {account?.attributes_schema ? (
                                <DataList.Root size="3" trim={"both"} className="my-2 mb-4">
                                    <DataList.Item
                                        align="center"
                                        className="w-full flex flex-wrap border border-gray-300 m-0 -my-2.5"
                                    >
                                        <DataList.Label className="text-sm w-[250px] border-r border-gray-300 p-2.5">
                                            Contract Value
                                        </DataList.Label>
                                        <DataList.Value className="text-sm">
                                            {(account?.contract_value || account?.contract_type) && <Badge
                                                color="mint"
                                                size="2"
                                                radius="medium"
                                                variant="soft"
                                                className="text-xs items-center"
                                            >
                                                <p>
                                                    {account.contract_type && account.contract_value
                                                        ? `${account.contract_type} | $${account.contract_value}`
                                                        : account.contract_type
                                                            ? account.contract_type
                                                            : account.contract_value
                                                                ? `$${account.contract_value}`
                                                                : null}
                                                </p>
                                            </Badge>}
                                        </DataList.Value>
                                    </DataList.Item>
                                    {account?.attributes_schema?.custom?.map((key, index) => (
                                        <DataList.Item
                                            align="center"
                                            className={"w-full flex border-b border-l border-r border-gray-300 m-0 -my-2.5"}
                                            key={key}
                                        >
                                            <DataList.Label className="text-sm w-[250px] border-r border-gray-300 p-2.5">
                                                {key}
                                            </DataList.Label>
                                            <DataList.Value className="text-sm py-2.5 px-3 flex-1 min-w-0 break-words whitespace-normal overflow-hidden">
                                                {String(account?.additional_properties?.[key] ?? '')}
                                            </DataList.Value>
                                        </DataList.Item>
                                    ))}
                                </DataList.Root>
                            ) : (
                                // Default trimmed set of attributes to show
                                <DataList.Root size="3" trim={"both"} className="my-2 mb-4">
                                    <DataList.Item
                                        align="center"
                                        className="w-full flex flex-wrap border border-gray-300 m-0 -my-2.5"
                                    >
                                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5">
                                            Assignee
                                        </DataList.Label>
                                        <DataList.Value>
                                            {account.assignee_user_id && account.assignee_user_id !== "" && pictureURL ? (
                                                <div className="flex items-center gap-2 text-sm">
                                                    <div className="lb-avatar rounded-lg w-5 h-5 mr-1">
                                                        <img
                                                            className="lb-avatar-image"
                                                            src={pictureURL}
                                                            alt={userName}
                                                        />
                                                        <span>{userName ?? ""}</span>
                                                    </div>
                                                    {userName}
                                                </div>
                                            ) : (
                                                <div className="flex items-center gap-2 text-sm">
                                                    <div className="lb-avatar rounded-lg w-5 h-5 mr-1">
                                                        <AvatarIcon className="w-5 h-5" />
                                                    </div>
                                                    No Assignee
                                                </div>
                                            )}
                                        </DataList.Value>
                                    </DataList.Item>
                                    {(account?.contract_value || account?.contract_type) && <DataList.Item
                                        align="center"
                                        className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                                    >
                                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                                            Contract Value
                                        </DataList.Label>
                                        <DataList.Value className="text-sm">
                                            <Badge
                                                color="mint"
                                                size="2"
                                                radius="medium"
                                                variant="soft"
                                                className="text-xs items-center"
                                            >
                                                <p>
                                                    {account.contract_type && account.contract_value
                                                        ? `${account.contract_type} | $${account.contract_value}`
                                                        : account.contract_type
                                                            ? account.contract_type
                                                            : account.contract_value
                                                                ? `$${account.contract_value}`
                                                                : null}
                                                </p>
                                            </Badge>
                                        </DataList.Value>
                                    </DataList.Item>}
                                    {account?.renewal_date && <DataList.Item
                                        align="center"
                                        className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                                    >
                                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5">
                                            Renewal Date
                                        </DataList.Label>
                                        <DataList.Value className="text-sm">
                                            {account?.renewal_date && (
                                                <div className="flex items-center gap-2">
                                                    <CalendarIcon />
                                                    {formattedRenewalDate}
                                                </div>
                                            )}
                                        </DataList.Value>
                                    </DataList.Item>}
                                    {account?.labels && <DataList.Item
                                        align="center"
                                        className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                                    >
                                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5">
                                            Labels
                                        </DataList.Label>
                                        <DataList.Value>
                                            {account?.labels?.map((label: AccountsLabel) =>
                                                getBadgeForAccountsLabel(label),
                                            )}
                                        </DataList.Value>
                                    </DataList.Item>}
                                    <DataList.Item
                                        align="center"
                                        className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                                    >
                                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5">
                                            Sources
                                        </DataList.Label>
                                        <DataList.Value>
                                            {account?.metadata && (
                                                <div className="flex flex-row mx-1">
                                                    {Array.from(serviceIcons).map((icon) =>
                                                        React.createElement(
                                                            icon.Component,
                                                            icon.props,
                                                        ),
                                                    )}
                                                </div>
                                            )}
                                        </DataList.Value>
                                    </DataList.Item>
                                </DataList.Root>
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </Flex>
    );
};
