import type React from "react";
import { type ReactNode, createContext, useContext, useState } from "react";
import { useEffect } from "react";
import type { FilterMetadata } from "../component/SideBar/SideBarComponent";
import { API, URLS } from "../constant";
import { useApi } from "../interfaces/api";
import type { OrgInfoResponse, ScopeResponse } from "../interfaces/serverData";
import { readableIntegrationMapping } from "./Admin/constant";

interface SearchContextProps {
    searchBarFilters: Map<string, FilterMetadata>;
    setSearchBarFilters: (filters: Map<string, FilterMetadata>) => void;
    loading: boolean;
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined);

export const useSearch = () => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error("useSearch must be used within a SearchProvider");
    }
    return context;
};

interface SearchProviderProps {
    children: ReactNode;
}

export const SearchProvider: React.FC<SearchProviderProps> = ({ children }) => {
    const [searchBarFilters, setSearchBarFilters] = useState<
        Map<string, FilterMetadata>
    >(new Map());
    const [loading, setLoading] = useState<boolean>(false);

    const api = useApi();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await api.get(
                    URLS.serverUrl + API.getIntegrations,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                if (res.status === 200) {
                    const temp = new Map<string, FilterMetadata>();
                    if (res.data?.data) {
                        const filters: string[] = res.data.data;
                        let i = 0;
                        for (const filter of filters) {
                            const name = readableIntegrationMapping.get(filter);
                            if (name !== undefined) {
                                let enabled = false;
                                if (i === 0) {
                                    enabled = true;
                                }
                                i += 1;
                                temp.set(name, {
                                    granularFilters: new Map<string, boolean>(),
                                    enabled: enabled,
                                });
                            }
                        }
                    }

                    const requestData = {
                        types: [
                            "Code",
                            "Slack",
                            "Jira",
                            "Linear",
                            "Google",
                            "Notion",
                            "Web",
                            "GitHubTicket",
                            "GithubDiscussion",
                            "Confluence",
                            "CommunitySlack",
                        ],
                    };

                    const itemsRes = await api.post(
                        URLS.serverUrl + API.getItemsByOrgID,
                        requestData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    );

                    if (itemsRes.status === 200 && itemsRes.data?.data) {
                        const orgInfo: OrgInfoResponse = itemsRes.data.data;
                        if (orgInfo.Code?.scopes) {
                            for (const repo of orgInfo.Code.scopes) {
                                const tempRepoResp: ScopeResponse = repo;
                                temp.get("Code")?.granularFilters.set(
                                    tempRepoResp,
                                    false,
                                );
                                temp.get("Pull Requests")?.granularFilters.set(
                                    tempRepoResp,
                                    false,
                                );
                            }
                        }
                        if (orgInfo.Slack?.scopes) {
                            for (const s of orgInfo.Slack.scopes) {
                                const tempSlackChannel: ScopeResponse = s;
                                temp.get("Slack")?.granularFilters.set(
                                    tempSlackChannel,
                                    false,
                                );
                            }
                        }
                        if (orgInfo.CommunitySlack?.scopes) {
                            for (const s of orgInfo.CommunitySlack.scopes) {
                                const tempSlackChannel: ScopeResponse = s;
                                temp.get("CommunitySlack")?.granularFilters.set(
                                    tempSlackChannel,
                                    false,
                                );
                            }
                        }
                        setSearchBarFilters(temp);
                        setLoading(true);
                    } else {
                        console.log("failed to get results");
                        setLoading(true);
                    }
                } else {
                    setLoading(true);
                }
            } catch (error) {
                console.log("System is down.", error);
                setLoading(true);
            }
        };

        fetchData();
    }, [setSearchBarFilters, api]);

    return (
        <SearchContext.Provider
            value={{ searchBarFilters, setSearchBarFilters, loading }}
        >
            {children}
        </SearchContext.Provider>
    );
};
