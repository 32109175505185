import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";

import { OnboardingChecklistStatus } from "@/interfaces/onboardingServerData";
import { TriangleDownIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useState } from "react";

interface OnboardingAssigneeDropdownProps {
    incomingStatus?: OnboardingChecklistStatus;
    userID: string;
    saveStatus: (status: OnboardingChecklistStatus) => void;
    statuses: OnboardingChecklistStatus[];
    className?: string;
}

export function StatusDropdown({
    incomingStatus = OnboardingChecklistStatus.NotStarted,
    userID,
    saveStatus,
    statuses,
    className,
}: OnboardingAssigneeDropdownProps) {
    const [open, setOpen] = useState<boolean>(false);

    const handleStatusSelect = (selectedStatus: OnboardingChecklistStatus) => {
        saveStatus(selectedStatus);
        setOpen(false);
    };

    return (
        <div className="text-xs flex items-center w-full h-full">
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Badge
                        color={"gray"}
                        size="2"
                        radius="medium"
                        variant="outline"
                        className={`ring-[0px] w-full flex items-center ${className}`}
                    >
                        <div className="flex flex-row items-center justify-between w-full">
                            <span className="text-xs font-medium">
                                {incomingStatus}
                            </span>
                            <TriangleDownIcon className="h-3 w-3" />
                        </div>
                    </Badge>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] text-xs p-0">
                    <Command>
                        <CommandInput
                            placeholder="Search status..."
                            className="h-9 text-xs"
                        />
                        <CommandList>
                            <CommandEmpty className="text-xs">
                                No statuses found.
                            </CommandEmpty>
                            <CommandGroup>
                                {statuses.map((status) => (
                                    <CommandItem
                                        key={status}
                                        value={status}
                                        onSelect={() =>
                                            handleStatusSelect(status)
                                        }
                                        className="text-xs"
                                    >
                                        {status}
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
        </div>
    );
}
