import type { UserResponse } from "@/interfaces/serverData";
import clsx from "clsx";
import type { ComponentProps } from "react";
import { getMetadataGmailHeaders } from "./utils";

interface UserProps extends ComponentProps<"span"> {
    user: UserResponse;
    gmailHeadersMetadata?: string;
    source?: string;
}

export function MyUser({
    user,
    className,
    gmailHeadersMetadata,
    source,
    ...props
}: UserProps) {
    function getUserName(user: UserResponse) {
        return user.name ?? user.username ?? user.email ?? "Anonymous";
    }

    const metadata =
        source === "Gmail"
            ? getMetadataGmailHeaders(gmailHeadersMetadata)
            : null;

    return (
        <span className={clsx(className, "")} {...props}>
            {metadata ? <div>{metadata}</div> : <div>{getUserName(user)}</div>}
        </span>
    );
}
