import type { FilterMetadata } from "@/component/SideBar/SideBarComponent";
import { InfoCircledIcon, MixerHorizontalIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogComponentHandler from "../../../component/DialogComponent";
import { URLS } from "../../../constant";
import { API } from "../../../constant";
import { useApi } from "../../../interfaces/api";

import { FancyMultiSelect } from "@/component/MultiSelect";
import type { BotMetadata, ScopeResponse } from "@/interfaces/serverData";
import { useSearch } from "@/pages/SearchContext";
import "../toast_styles.css";
import { useToast } from "@/component/shadcn/ui/use-toast";
import "@radix-ui/themes/styles.css";
import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";
import { Badge } from "@/component/shadcn/ui/badge";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import { Input } from "@/component/shadcn/ui/input";
import { Separator } from "@/component/shadcn/ui/separator";
import { Switch } from "@/component/shadcn/ui/switch";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";

interface SlackConfigProps {
    autoEscalate: boolean;
    setAutoEscalate: React.Dispatch<React.SetStateAction<boolean>>;
    previewMessage: boolean;
    setPreviewMessage: React.Dispatch<React.SetStateAction<boolean>>;
    autoRespond: boolean;
    setAutoRespond: React.Dispatch<React.SetStateAction<boolean>>;
    confidenceScore: boolean;
    setConfidenceScore: React.Dispatch<React.SetStateAction<boolean>>;
    indexCode: boolean;
    setIndexCode: React.Dispatch<React.SetStateAction<boolean>>;
    iconUrl: string;
    setIconUrl: React.Dispatch<React.SetStateAction<string>>;
    channels: ScopeResponse[];
    selectedChannels: ScopeResponse[];
    setSelectedChannels: React.Dispatch<React.SetStateAction<ScopeResponse[]>>;
    errDialogOpen: boolean;
    setErrDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isMain: boolean;
}

const SlackConfigPage = (props: SlackConfigProps) => {
    const api = useApi();

    const { toast } = useToast();

    const { searchBarFilters, loading } = useSearch();

    const closeDialog = () => props.setErrDialogOpen(false);

    const [modalOpen, setModalOpen] = useState(false);

    const [dataSources, setDataSources] = useState<string[]>([]);

    useEffect(() => {
        const srcs: string[] = [];
        if (loading) {
            searchBarFilters.forEach((value: FilterMetadata, key: string) => {
                srcs.push(key);
            });
            setDataSources(srcs);
        }
    }, [searchBarFilters, loading]);

    const handleIconUrlChange = (event: { target: { value: string } }) => {
        props.setIconUrl(event.target.value);
    };

    function saveSettings() {
        const requestData: BotMetadata = {
            bot_type: props.isMain ? "Slack" : "CommunitySlack",
            auto_escalate: props.autoEscalate,
            preview_message: props.previewMessage,
            auto_respond: props.autoRespond,
            channels_selected: props.selectedChannels,
            confidence_enabled: props.confidenceScore,
            icon_url: props.iconUrl,
            responding_enabled: true, // will always be true if system is enabled
            index_code: props.indexCode,
        };
        setModalOpen(false);
        api.post(URLS.serverUrl + API.saveBotSettings, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                } else {
                    toast({
                        title: "Updated Settings!",
                        description:
                            "Your settings have been updated successfully.",
                    });
                }
            })
            .catch((res) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            });
    }

    const navigate = useNavigate();
    const closeDialogAndRedirect = async () => {
        navigate("/admin/manage_integrations");
    };

    return (
        <div>
            <DialogComponentHandler
                isDialogOpen={props.errDialogOpen}
                closeDialog={closeDialog}
                isButton={true}
                description={
                    "Seems like you need to first set up a bot to access this page! If you already have one, please contact us at support@askassembly.app."
                }
                title={"Oops!"}
                buttonText="Let's go!"
                onButtonClick={closeDialogAndRedirect}
                clickOutOf={false}
            />

            <Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger>
                    <DialogTriggerButton>
                        <MixerHorizontalIcon className="w-4 h-4 mr-2" />
                        Edit
                    </DialogTriggerButton>
                </DialogTrigger>
                <DialogContent className="min-w-[750px] w-fit">
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col">
                            <h3 className="text-base font-semibold  text-[#333333]">
                                Slackbot Configurations
                            </h3>
                            <p className="mb-2 text-sm">
                                To use the Slackbot, just tag{" "}
                                <strong className="text-[#6E56CF]">
                                    @Assembly
                                </strong>{" "}
                                in any channel!
                            </p>
                        </div>

                        <Card className="flex-1 p-5 shadow-none rounded-lg">
                            <div className="flex flex-col">
                                <div className="flex items-center justify-between py-2">
                                    <div className="min-w-[300px]">
                                        <span className="font-semibold text-[#0000009b]">
                                            Component
                                        </span>
                                    </div>
                                    <div>
                                        <Badge
                                            variant="secondary"
                                            className="rounded-full bg-green-100 text-green-800"
                                        >
                                            Slackbot
                                        </Badge>
                                    </div>
                                </div>

                                <Separator className="w-full my-2" />

                                <div className="flex items-start justify-between pt-4">
                                    <div className="min-w-[200px]">
                                        <div className="flex flex-col gap-1">
                                            <span className="text-sm font-semibold  text-[#0000009b]">
                                                Auto Escalate
                                            </span>
                                            <span className="text-xs font-light text-gray-500">
                                                Loop in a human if Assembly has
                                                to escalate an incoming
                                                question.
                                            </span>
                                        </div>
                                    </div>
                                    <Switch
                                        checked={props.autoEscalate}
                                        onCheckedChange={props.setAutoEscalate}
                                        className="data-[state=checked]:bg-[#6E56CF] w-[35px] h-5"
                                        thumbClassName="w-[18px] h-[18px] data-[state=checked]:translate-x-[16px]"
                                    />
                                </div>

                                <div className="flex items-start justify-between pt-4">
                                    <div className="min-w-[400px]">
                                        <div className="flex flex-col gap-1">
                                            <span className="font-semibold  text-[#0000009b]">
                                                Send as Preview Message
                                            </span>
                                            <span className="text-xs font-light text-gray-500 max-w-[500px]">
                                                Enable this if you'd like to
                                                send this as a preview message
                                                without sending it to users just
                                                yet. Only the admin of the
                                                channel will be able to view the
                                                message if enabled.
                                            </span>
                                        </div>
                                    </div>
                                    <Switch
                                        checked={props.previewMessage}
                                        onCheckedChange={
                                            props.setPreviewMessage
                                        }
                                        className="data-[state=checked]:bg-[#6E56CF] w-[35px] h-5"
                                        thumbClassName="w-[18px] h-[18px] data-[state=checked]:translate-x-[16px]"
                                    />
                                </div>

                                <div className="flex items-start justify-between pt-4">
                                    <div className="min-w-[400px]">
                                        <div className="flex flex-col gap-1">
                                            <span className="font-semibold  text-[#0000009b]">
                                                Auto Respond
                                            </span>
                                            <span className="text-xs font-light text-gray-500">
                                                Have Assembly respond to every
                                                question in a selected channel
                                                without having to tag the bot!
                                            </span>
                                        </div>
                                    </div>
                                    <Switch
                                        checked={props.autoRespond}
                                        onCheckedChange={props.setAutoRespond}
                                        className="data-[state=checked]:bg-[#6E56CF] w-[35px] h-5"
                                        thumbClassName="w-[18px] h-[18px] data-[state=checked]:translate-x-[16px]"
                                    />
                                </div>

                                {props.autoRespond && (
                                    <div className="flex items-start justify-between pt-4">
                                        <div className="min-w-[400px]">
                                            <div className="flex flex-col gap-2">
                                                <div className="flex items-center gap-1">
                                                    <span>
                                                        Which channel should we
                                                        auto-respond in?
                                                    </span>
                                                    <Tooltip>
                                                        <TooltipTrigger>
                                                            <InfoCircledIcon className="w-4 h-4" />
                                                        </TooltipTrigger>
                                                        <TooltipContent>
                                                            This list is sourced
                                                            from the Customer
                                                            Channels Selected
                                                            section. Add it
                                                            there first!
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </div>
                                                <FancyMultiSelect
                                                    selectedChannels={
                                                        props.selectedChannels
                                                    }
                                                    setSelectedChannels={
                                                        props.setSelectedChannels
                                                    }
                                                    options={props.channels}
                                                    placeholder="Select Channels..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="flex items-start justify-between pt-4">
                                    <div className="min-w-[400px]">
                                        <div className="flex flex-col gap-1">
                                            <span className="font-semibold text-[#0000009b]">
                                                Show Confidence Score
                                            </span>
                                            <span className="text-xs font-light text-gray-500">
                                                Assembly will send how confident
                                                it is as a hidden message when
                                                responding to questions.
                                            </span>
                                        </div>
                                    </div>
                                    <Switch
                                        checked={props.confidenceScore}
                                        onCheckedChange={
                                            props.setConfidenceScore
                                        }
                                        className="data-[state=checked]:bg-[#6E56CF] w-[35px] h-5"
                                        thumbClassName="w-[18px] h-[18px] data-[state=checked]:translate-x-[16px]"
                                    />
                                </div>

                                <div className="flex items-start justify-between pt-4">
                                    <div className="min-w-[400px]">
                                        <div className="flex flex-col gap-1">
                                            <span className="font-semibold text-[#0000009b]">
                                                Slack Bot Icon
                                            </span>
                                            <span className="text-xs font-light text-gray-500 max-w-[500px]">
                                                Input the public url of an image
                                                to change the icon of Assembly.
                                                If left empty, the default
                                                Assembly logo will be used.
                                            </span>
                                            <div className="min-w-[450px]">
                                                <Input
                                                    className="bg-[#0112DF0F] h-6"
                                                    value={props.iconUrl}
                                                    onChange={
                                                        handleIconUrlChange
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-start justify-between pt-4">
                                    <div className="min-w-[400px]">
                                        <div className="flex flex-col gap-4">
                                            <span className="font-semibold text-[#0000009b]">
                                                Sources Connected
                                            </span>
                                            {loading && (
                                                <div className="flex flex-row gap-3 flex-wrap">
                                                    {dataSources.map((item) => (
                                                        <Badge
                                                            key={item}
                                                            variant="secondary"
                                                            className="text-[#6E56CF]"
                                                        >
                                                            {item}
                                                        </Badge>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <Button
                                    onClick={saveSettings}
                                    className="w-auto self-end mt-[60px]"
                                >
                                    Save Settings
                                </Button>
                            </div>
                        </Card>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SlackConfigPage;
