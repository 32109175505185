import { Textarea } from "@/component/shadcn/ui/textarea";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Insight, InsightData } from "@/interfaces/serverData";
import { type SetStateAction, useEffect, useRef, useState } from "react";

interface EditableDescriptionInputProps<TData> {
    insight: Insight;
    updateInsightState: (newState: Partial<Insight>) => void;
    userID: string;
}

export function EditableDescriptionInput<TData>({
    insight,
    updateInsightState,
    userID,
}: EditableDescriptionInputProps<TData>) {
    const api = useApi();
    const [value, setValue] = useState<string>(insight.description);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();
        }
    }, [isEditing]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target as Node)
            ) {
                if (isEditing) {
                    handleSave();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isEditing]);

    const handleClick = () => {
        setIsEditing(true);
    };

    const handleChange = (e: {
        target: { value: SetStateAction<string> };
    }) => {
        setValue(e.target.value);
    };

    const handleKeyDown = (e: { key: string }) => {
        if (e.key === "Enter") {
            handleSave();
        }
    };

    const handleSave = () => {
        const requestData: InsightData = {
            existing_insight_id: insight.id,
            description: value,
            user_id: userID,
        };
        api.post(URLS.serverUrl + API.saveInsight, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                updateInsightState({ description: value });
                setIsEditing(false);
                console.log(
                    `Updated insight description to ${value} successfully`,
                );
            } else {
                console.log("Call to update insight description failed");
            }
        });
    };

    const handleBlur = () => {
        // Save on blur as well
        if (isEditing) {
            handleSave();
        }
    };
    return (
        <div className="w-full" ref={containerRef}>
            {isEditing ? (
                <Textarea
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    rows={4}
                    className="w-full text-sm p-1 focus:outline-none focus:ring-0 focus:border-gray-300"
                />
            ) : (
                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                <div onClick={handleClick} className="cursor-pointer p-1">
                    {insight.description || "Click to edit..."}
                </div>
            )}
        </div>
    );
}
