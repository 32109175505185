import { Button } from "@/component/shadcn/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    HistoryResponse,
    Query,
    Ticket,
    TicketTopics,
} from "@/interfaces/serverData";
import type { GetTopicsResponse } from "@/interfaces/serverData";
import { cn } from "@/lib/utils";
import { DotFilledIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { Badge, Callout, Skeleton, Text } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { CheckIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { TicketTagDropdown } from "./TicketTagDropdown";

interface TicketTopicsDropdownProps<TData> {
    ticket: Ticket;
    userID: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    teamID?: string;
}

export function TicketTopicsDropdown<TData extends Query>({
    ticket,
    userID,
    refetchThreadData,
    teamID,
}: TicketTopicsDropdownProps<TData>) {
    const api = useApi();
    const [topicsSelected, setTopicsSelected] = useState<string[]>(
        ticket.topic,
    );
    const [topics, setTopics] = useState<GetTopicsResponse[]>([]);
    const [topicsMap, setTopicsMap] = useState(new Map());
    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error
    const [searchQuery, setSearchQuery] = useState("");
    const [visibleItems, setVisibleItems] = useState(10); // Show first 10 items initially
    const [filteredTopics, setFilteredTopics] = useState<GetTopicsResponse[]>(
        [],
    );

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

    // Grab the list of topics for the org
    useEffect(() => {
        let endpoint = URLS.serverUrl + API.getTopics;
        if (teamID) {
            endpoint = `${URLS.serverUrl}${API.getTopics}/team/${teamID}`;
        }
        api.get(endpoint, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setTopics(topics);
                    setLoadingState(1);
                } else {
                    setLoadingState(2);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
                setLoadingState(2);
            });
    }, [api]);

    function saveTopics(newTopicsSelected: string[]) {
        const requestData: TicketTopics = {
            ids: [ticket.id],
            added_topics: newTopicsSelected.filter(
                (t) => !topicsSelected.includes(t),
            ),
            deleted_topics: topicsSelected.filter(
                (t) => !newTopicsSelected.includes(t),
            ),
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                refetchThreadData();
                console.log(`Updated topics ${newTopicsSelected} successfully`);
            } else {
                console.log("Call to update topics failed");
            }
        });
    }

    const toggleTopicSelection = (topic: GetTopicsResponse) => {
        const isSelected = topicsSelected.includes(topic.topic_name);
        const newTopicsSelected = isSelected
            ? topicsSelected.filter((t) => t !== topic.topic_name)
            : [...topicsSelected, topic.topic_name];

        setTopicsSelected(newTopicsSelected);
        saveTopics(newTopicsSelected);
    };

    useEffect(() => {
        setFilteredTopics(
            topics.filter((topic) =>
                topic.topic_name.toLowerCase().includes(searchQuery),
            ),
        );
    }, [topics, searchQuery]);

    return (
        <div className="text-sm flex items-center">
            {loadingState === 0 && (
                <div>
                    <Skeleton>
                        <Text>
                            {[...Array(6)].map((_, index) => (
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                <Text key={index}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                </div>
            )}
            {loadingState === 1 && topics && (
                <div className="w-full inherit">
                    <div className="flex flex-wrap justify-start data-[state=open]:bg-muted">
                        <TicketTagDropdown
                            ticket={ticket}
                            userID={userID}
                            teamID={teamID}
                        />
                        {topicsSelected.map((topic) => (
                            <Badge
                                size="2"
                                radius="full"
                                variant="outline"
                                className="m-1 ring-[0.8px] text-gray-700 ring-[#E0E1E6]"
                                key={topic}
                                style={
                                    {
                                        "--badge-border-color": "#E0E1E6",
                                    } as React.CSSProperties
                                }
                            >
                                <div className="flex flex-row items-center">
                                    <DotFilledIcon
                                        color={
                                            topicsMap.get(topic)?.color &&
                                            topicsMap.get(topic)?.color !== ""
                                                ? topicsMap.get(topic)?.color
                                                : "#9B9EF0"
                                        }
                                        style={{ transform: "scale(1.8)" }}
                                    />
                                    <p className="pl-0.3">{topic}</p>
                                </div>
                            </Badge>
                        ))}
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="justify-between h-8 text-xs px-3"
                                >
                                    <Pencil2Icon className="h-3 w-3" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-[200px] p-0">
                                <Command shouldFilter={false}>
                                    <CommandInput
                                        placeholder="Search topics..."
                                        className="h-7 text-xs"
                                        value={searchQuery}
                                        onValueChange={(value) =>
                                            setSearchQuery(value.toLowerCase())
                                        }
                                    />
                                    <CommandList>
                                        <CommandEmpty className="text-xs pl-4 pt-4 pr-2 pb-2">
                                            No topics found.
                                        </CommandEmpty>
                                        <CommandGroup>
                                            {filteredTopics
                                                .sort((a, b) => {
                                                    const aSelected =
                                                        topicsSelected.includes(
                                                            a.topic_name,
                                                        );
                                                    const bSelected =
                                                        topicsSelected.includes(
                                                            b.topic_name,
                                                        );

                                                    if (aSelected && !bSelected)
                                                        return -1;
                                                    if (!aSelected && bSelected)
                                                        return 1;

                                                    return a.topic_name.localeCompare(
                                                        b.topic_name,
                                                    );
                                                })
                                                .slice(0, visibleItems)
                                                .map((topic) => {
                                                    const isSelected =
                                                        topicsSelected.includes(
                                                            topic.topic_name,
                                                        );
                                                    return (
                                                        <CommandItem
                                                            key={topic.id}
                                                            onSelect={() =>
                                                                toggleTopicSelection(
                                                                    topic,
                                                                )
                                                            }
                                                            className="text-xs"
                                                        >
                                                            <div className="flex items-center gap-2">
                                                                <div
                                                                    className={cn(
                                                                        "flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                                                                        isSelected
                                                                            ? "bg-primary text-primary-foreground"
                                                                            : "opacity-50",
                                                                    )}
                                                                >
                                                                    {isSelected && (
                                                                        <CheckIcon className="h-4 w-4" />
                                                                    )}
                                                                </div>
                                                                <span>
                                                                    {
                                                                        topic.topic_name
                                                                    }
                                                                </span>
                                                            </div>
                                                        </CommandItem>
                                                    );
                                                })}
                                        </CommandGroup>
                                        {filteredTopics.length >
                                            visibleItems && (
                                            <CommandGroup>
                                                <CommandItem
                                                    className="text-xs"
                                                    onSelect={() =>
                                                        setVisibleItems(
                                                            (prev) => prev + 10,
                                                        )
                                                    }
                                                >
                                                    Show More...
                                                </CommandItem>
                                            </CommandGroup>
                                        )}
                                    </CommandList>
                                </Command>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>
            )}
            {loadingState === 2 && (
                <Callout.Root size="1" variant="outline" color="red">
                    <Callout.Text>
                        Sorry, something's wrong with loading the topic
                        selections! Please notify us at support@askassembly.app.
                    </Callout.Text>
                </Callout.Root>
            )}
        </div>
    );
}
