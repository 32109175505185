import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";
import { API, URLS } from "@/constant";
import type {
    AnnouncementsWithPaginationResponse,
    GetUserResponse,
} from "@/interfaces/serverData";
import { PlusIcon } from "@radix-ui/react-icons";
import { Box, Flex, Heading, Skeleton, Text } from "@radix-ui/themes";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../interfaces/api";
import { AnnouncementsTable } from "../Announcements/AnnouncementsTable";
import { AnnouncementsState } from "../Announcements/constants";

// https://codesandbox.io/p/sandbox/markdown-to-slack-converter-iphgn?file=%2Fsrc%2Findex.js%3A9%2C49

const AnnouncementsPage = () => {
    const api = useApi();

    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [users, setUsers] = useState<GetUserResponse[]>([]);

    useEffect(() => {
        api.post(URLS.serverUrl + API.getAllUsers, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setUsers(res.data.data);
            } else {
                console.log("Call to grab users failed");
            }
        });
    }, [api]);

    const navigate = useNavigate();

    function handleSubmit() {
        const requestData = {
            status: AnnouncementsState.draft,
            id: "",
        };
        api.post(URLS.serverUrl + API.announcement, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                const id = res.data.data.id;
                navigate(`/announcements/new/${id}`, {
                    state: { announcement: res.data.data },
                });
            } else {
                console.log("could not create a draft announcement");
            }
        });
    }

    const fetchAnnouncements =
        async (): Promise<AnnouncementsWithPaginationResponse> => {
            const response = await api.get(
                URLS.serverUrl + API.getAnnouncements,
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return {
                data: [],
                has_next_page: false,
                next_cursor: 0,
            };
        };

    const queryClient = useQueryClient();

    const { data } = useInfiniteQuery({
        queryKey: ["announcements"],
        queryFn: fetchAnnouncements,
        getNextPageParam: (lastPage) => {
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 10000,
        refetchOnWindowFocus: true,
    });

    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];

    const updateData = async () => {
        queryClient.refetchQueries({
            queryKey: ["announcements"],
            exact: true,
        });
    };
    useEffect(() => {
        if (data) {
            setLoadingState(1);
        }
    }, [data]);
    return (
        <div className="text-xs">
            <Flex direction="column" align="center" justify="center">
                <Box
                    height="100%" // Ensure it takes full height of its container
                    width="98%"
                    mt="6"
                >
                    <Flex
                        align="start"
                        direction="column"
                        justify={"start"}
                        className="px-3"
                    >
                        <Heading
                            size="5"
                            align="left"
                            className="flex items-center gap-1"
                        >
                            Announcements
                        </Heading>
                        <Text mb="10px" size="2">
                            Send messages to any customer channel.
                        </Text>
                        <div className="flex items-center absolute top-4 right-4 gap-1.5 my-4">
                            <DialogTriggerButton onClick={handleSubmit}>
                                Draft Announcement
                                <PlusIcon />
                            </DialogTriggerButton>
                        </div>
                        {loadingState === 0 && (
                            <div>
                                <Skeleton>
                                    <Text>
                                        {[...Array(6)].map((_, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            <Text key={index}>
                                                {loremIpsum}
                                            </Text>
                                        ))}
                                    </Text>
                                </Skeleton>
                            </div>
                        )}
                        {loadingState === 1 && combinedData.length !== 0 && (
                            <AnnouncementsTable
                                announcements={combinedData}
                                userID=""
                                users={users}
                                forceUpdate={updateData}
                            />
                        )}
                    </Flex>
                </Box>
            </Flex>
        </div>
    );
};

export default AnnouncementsPage;
