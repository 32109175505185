import { Button } from "@/component/shadcn/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import { ChevronDownIcon } from "@radix-ui/react-icons";

export interface GmailMetadata {
    to?: string;
    bcc?: string;
    cc?: string;
    from?: string;
    snippet?: string;
    "reply-to"?: string;
    subject?: string;
}

const extractNameFromEmail = (email: string): string => {
    // First try to extract email from angle brackets format: "Name <email@domain.com>"
    const angleMatch = email.match(/<([^>]+)>/);
    const emailToProcess = angleMatch ? angleMatch[1] : email;

    // Then extract the part before @ from the email
    const match = emailToProcess.match(/([^@]+)@/);
    if (match?.[1]) {
        match[1] = match[1].charAt(0).toUpperCase() + match[1].slice(1);
    }
    return match ? match[1] : emailToProcess;
};

export const getMetadataGmailHeaders = (
    metadata: string | undefined,
    key = "",
) => {
    if (metadata === "" || metadata === null || metadata === undefined) {
        return null;
    }
    let metadataMap: GmailMetadata | null = null;
    try {
        metadataMap = JSON.parse(metadata) as GmailMetadata;
        if (!metadataMap) return null;
    } catch {
        return null;
    }
    if (key !== "") {
        const val = metadataMap[key as keyof GmailMetadata];
        if (val) return `${key.charAt(0).toUpperCase() + key.slice(1)}: ${val}`;
    }
    if (metadataMap.to) {
        const toEmails = metadataMap.to.split(",").map((e) => e.trim());
        const fromEmails =
            metadataMap.from?.split(",").map((e) => e.trim()) || [];
        const remainingTo = toEmails.slice(2);
        const remainingFrom = fromEmails.slice(2);
        const toEmailsRendered = toEmails
            .slice(0, 2)
            .map(extractNameFromEmail)
            .join(", ");
        const fromEmailsRendered = fromEmails
            .slice(0, 2)
            .map(extractNameFromEmail)
            .join(", ");
        const hasMore =
            remainingTo.length > 0 ||
            remainingFrom.length > 0 ||
            metadataMap.bcc ||
            metadataMap.cc;
        const remainingCount =
            remainingTo.length +
            remainingFrom.length +
            (metadataMap.bcc ? metadataMap.bcc.split(",").length : 0) +
            (metadataMap.cc ? metadataMap.cc.split(",").length : 0);
        return (
            <div className="flex flex-row gap-1 items-center justify-center">
                <p className="text-sm"> {toEmailsRendered},</p>
                <p className="text-sm text-gray-500">{fromEmailsRendered}</p>

                <Popover>
                    <PopoverTrigger
                        asChild
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Button
                            variant="outline"
                            className="bg-[#E6F6EB] hover:bg-[#E6F6EB] rounded-full text-slate-700 text-xs h-5 px-2"
                        >
                            {hasMore && `+${remainingCount} more`}
                            {!hasMore && (
                                <ChevronDownIcon className="w-2.5 h-2.5 text-green9" />
                            )}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-80">
                        <div className="flex flex-col gap-1 text-xs">
                            {toEmails.length > 0 && (
                                <div className="flex flex-row gap-1">
                                    <h4 className="font-semibold">To:</h4>
                                    <p className="font-medium">
                                        {toEmails.join(", ")}
                                    </p>
                                </div>
                            )}
                            {fromEmails.length > 0 && (
                                <div className="flex flex-row gap-1">
                                    <h4 className="font-semibold">From:</h4>
                                    <p className="font-medium">
                                        {fromEmails.join(", ")}
                                    </p>
                                </div>
                            )}
                            {metadataMap.bcc && (
                                <div className="flex flex-row gap-1">
                                    <h4 className="font-semibold">BCC:</h4>
                                    <p className="font-medium">
                                        {metadataMap.bcc}
                                    </p>
                                </div>
                            )}
                            {metadataMap.cc && (
                                <div className="flex flex-row gap-1">
                                    <h4 className="font-semibold">CC:</h4>
                                    <p className="font-medium">
                                        {metadataMap.cc}
                                    </p>
                                </div>
                            )}
                        </div>
                    </PopoverContent>
                </Popover>
            </div>
        );
    }
    return null;
};
