import { Button } from "@/component/shadcn/ui/button";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { URLS } from "@/constant";
import { ContactsAPI } from "@/constant";
import useUser from "@/hooks/use-users";
import { useApi } from "@/interfaces/api";
import {
    type GetOnboardingResponse,
    type Onboarding,
    type OnboardingChecklist,
    type OnboardingChecklistPayload,
    OnboardingChecklistStatus,
    OnboardingChecklistType,
} from "@/interfaces/onboardingServerData";
import type { Account, GetTopicsResponse } from "@/interfaces/serverData";
import { PlusIcon } from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import { type RefetchOptions, useQuery } from "@tanstack/react-query";
// import { GoogleSpreadsheetConnection } from "./GoogleSpreadsheetConnection";
import type { QueryObserverResult } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

import {
    MilestoneCard,
    NewMilestoneCard,
    getMilestoneProgressValue,
} from "./MilestoneCard";
import {
    deleteItem,
    saveAll,
    saveAssignee,
    saveEnabled,
    saveStatus,
} from "./methods";

export interface OnboardingProps {
    orgID: string;
    userID: string;
    accountProp: Account;
    accountType: "Company" | "Customer";
}

export const OnboardingPage = ({
    orgID,
    userID,
    accountProp,
    accountType,
}: OnboardingProps) => {
    const api = useApi();
    const { toast } = useToast();

    const fetchMilestones = async (): Promise<GetOnboardingResponse> => {
        try {
            const { url } = ContactsAPI.getOnboardingChecklist;
            const response = await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                params: {
                    [accountType === "Company" ? "company_id" : "customer_id"]:
                        accountProp.id,
                    onboarding_type: OnboardingChecklistType.Milestone,
                },
            });
            if (response.data === null) {
                return {
                    onboarding: undefined,
                    onboarding_checklist: [],
                };
            }
            if (response.status === 200) {
                return response.data.data;
            }
            return {
                onboarding: undefined,
                onboarding_checklist: [],
            };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return {
                onboarding: undefined,
                onboarding_checklist: [],
            };
        }
    };

    const {
        data: onboardingData,
        isLoading: loadingOnboardingData,
        isError: errorOnboardingData,
        refetch: refetchOnboardingData,
    } = useQuery({
        queryKey: ["milestones", accountProp.id],
        queryFn: fetchMilestones,
    });

    const [onboardingItems, setOnboardingItems] = useState<
        Map<string, OnboardingChecklist>
    >(
        new Map(
            onboardingData?.onboarding_checklist?.map((item) => [
                item.id,
                item,
            ]),
        ),
    );

    useEffect(() => {
        setOnboardingItems(
            new Map(
                onboardingData?.onboarding_checklist?.map((item) => [
                    item.id,
                    item,
                ]),
            ),
        );
    }, [onboardingData]);

    const [onboardingMainItem, setOnboardingMainItem] = useState<
        Onboarding | undefined
    >(onboardingData?.onboarding);

    useEffect(() => {
        if (!loadingOnboardingData && onboardingData) {
            setOnboardingMainItem(onboardingData?.onboarding);
        }
    }, [loadingOnboardingData, onboardingData]);

    const addOnboardingItem = useCallback(
        async (
            onboardingItem: OnboardingChecklistPayload,
            parentId: string,
        ): Promise<string> => {
            onboardingItem.onboarding_type = OnboardingChecklistType.Task;
            onboardingItem.parent_id = parentId;
            if (accountType === "Company") {
                onboardingItem.company_id = accountProp.id;
            } else {
                onboardingItem.customer_id = accountProp.id;
            }

            try {
                const res = await api.post(
                    `${URLS.serverUrl}${ContactsAPI.addOnboardingChecklistItem.url}`,
                    onboardingItem,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                if (res.status !== 200) {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                    return "";
                }

                return res.data.data.id;
            } catch (err) {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
                return "";
            }
        },
        [accountType, accountProp.id, api, toast],
    );

    const addNewMilestone = useCallback(
        (
            milestone: OnboardingChecklistPayload,
            onboardingItems: OnboardingChecklist[],
        ) => {
            if (accountType === "Company") {
                milestone.company_id = accountProp.id;
                for (const item of onboardingItems) {
                    item.company_id = accountProp.id;
                    item.onboarding_type = OnboardingChecklistType.Task;
                }
            } else {
                milestone.customer_id = accountProp.id;
                for (const item of onboardingItems) {
                    item.customer_id = accountProp.id;
                    item.onboarding_type = OnboardingChecklistType.Task;
                }
            }
            milestone.onboarding_type = OnboardingChecklistType.Milestone;
            const request = {
                milestone: milestone,
                tasks: onboardingItems,
            };
            api.post(
                `${URLS.serverUrl}${ContactsAPI.addMilestone.url}`,
                request,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Added milestone!",
                            description:
                                "Your milestone has been added successfully.",
                        });
                        if (refetchOnboardingData) {
                            refetchOnboardingData();
                        }
                        setShowAddItem(false);
                    }
                })
                .catch((err) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                });
        },
        [accountType, accountProp.id, api, refetchOnboardingData],
    );

    const [selectedItems, setSelectedItems] = useState<OnboardingChecklist[]>(
        [],
    );

    const setOnboardingStateHelper = useCallback(
        (
            newState: Partial<OnboardingChecklist>,
            updatedMap: Map<string, OnboardingChecklist>,
            val: OnboardingChecklist,
        ) => {
            if (!val.id) {
                return updatedMap;
            }
            const currentIssue = updatedMap?.get(val.id);

            if (!currentIssue) {
                return updatedMap;
            }

            const updatedIssue = {
                ...currentIssue,
                ...newState,
                assignee_user_id:
                    newState.assignee_user_id || currentIssue.assignee_user_id,
            };

            updatedMap?.set(val.id, updatedIssue);
        },
        [],
    );

    const setOnboardingState = useCallback(
        (
            newState: Partial<OnboardingChecklist>,
            oldItem: OnboardingChecklist,
        ) => {
            setOnboardingItems((prev) => {
                const updatedMap = new Map(prev);
                if (selectedItems.length === 0) {
                    // single issue
                    setOnboardingStateHelper(newState, updatedMap, oldItem);
                } else {
                    // selected issues with selector
                    for (const val of selectedItems) {
                        setOnboardingStateHelper(newState, updatedMap, val);
                    }
                }
                return updatedMap;
            });
            setSelectedItems([]);
        },
        [selectedItems, setOnboardingStateHelper],
    );

    const deleteOnboardingItem = useCallback(
        (ids: string[]) => {
            setOnboardingItems((prev) => {
                const updatedMap = new Map(prev);
                if (selectedItems.length === 0) {
                    // single issue
                    updatedMap.delete(ids[0]);
                } else {
                    // selected issues with selector
                    for (const val of selectedItems) {
                        updatedMap.delete(val.id);
                    }
                }
                return updatedMap;
            });
            setSelectedItems([]);
        },
        [selectedItems],
    );

    const handleDeleteIssue = useCallback(
        (
            userID: string,
            issueIds: string[],
            deleteOnUI?: (ids: string[]) => void,
        ) => {
            let selectedIssueIds: string[];
            if (selectedItems.length === 0) {
                selectedIssueIds = issueIds;
            } else {
                selectedIssueIds = selectedItems.map((issue) => issue.id);
            }
            if (deleteOnUI === undefined) {
                deleteOnUI = deleteOnboardingItem;
            }

            deleteItem(
                api,
                selectedIssueIds,
                deleteOnUI,
                userID,
                onboardingMainItem?.id ?? "",
            );
        },
        [selectedItems, api, deleteOnboardingItem, onboardingMainItem?.id],
    );

    const saveIssue = useCallback(
        (
            type: string,
            payload: Partial<OnboardingChecklistPayload>,
            userID: string,
            updateIssueState: (
                newState: Partial<OnboardingChecklist>,
                oldItem: OnboardingChecklist,
            ) => void,
            refetch: (
                options?: RefetchOptions,
            ) => Promise<QueryObserverResult<GetOnboardingResponse, Error>>,
            issueId: string,
            item: OnboardingChecklist,
            onboardingMainItemId: string,
            milestoneId: string,
            topicsMap?: Map<string, GetTopicsResponse>,
        ) => {
            let selectedIssueIds: string[];
            if (selectedItems.length === 0) {
                selectedIssueIds = [issueId];
            } else {
                selectedIssueIds = selectedItems.map((issue) => issue.id);
            }

            switch (type) {
                case "task_status":
                    return saveStatus(
                        payload.task_status ||
                        OnboardingChecklistStatus.NotStarted,
                        api,
                        selectedIssueIds,
                        userID,
                        onboardingMainItemId,
                        item,
                        updateIssueState,
                    );
                case "Assignee":
                    return saveAssignee(
                        payload.assignee_user_id || "",
                        api,
                        selectedIssueIds,
                        userID,
                        onboardingMainItemId,
                        item,
                        updateIssueState,
                    );
                case "enabled":
                    return saveEnabled(
                        payload.enabled ?? true,
                        api,
                        selectedIssueIds,
                        userID,
                        onboardingMainItemId,
                        item,
                        updateIssueState,
                    );
                case "ALL":
                    return saveAll(
                        payload,
                        api,
                        selectedIssueIds,
                        userID,
                        onboardingMainItemId,
                        item,
                        updateIssueState,
                    );
                default:
                    return null;
            }
        },
        [selectedItems, api],
    );

    const saveOnboardingItem = useCallback(
        (
            issue: Partial<OnboardingChecklist>,
            column: string,
            value: string,
            onboardingMainItemId: string,
        ) => {
            console.log(selectedItems);
            switch (column) {
                default:
                    return saveAll(
                        issue,
                        api,
                        [issue.id ?? ""],
                        userID,
                        onboardingMainItemId,
                    );
            }
        },
        [selectedItems, api, userID],
    );

    const updateGoogleLink = useCallback(
        (onboarding_id: string, google_id: string) => {
            api.post(
                `${URLS.serverUrl}${ContactsAPI.updateOnboardingGoogleLink.url}`,
                { onboarding_id, google_link: google_id },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Updated Google Sheet!",
                            description:
                                "Your Google Sheet has been updated successfully.",
                        });

                        if (refetchOnboardingData) {
                            refetchOnboardingData();
                        }
                    }
                })
                .catch((err) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                });
        },
        [api, refetchOnboardingData, toast],
    );

    const handleSuccess = (fileIds: string[]) => {
        console.log("fileIds", fileIds);
        updateGoogleLink(onboardingMainItem?.id ?? "", fileIds[0]);
    };

    const [showAddItem, setShowAddItem] = useState<boolean>(false);

    const { data: users } = useUser();

    return (
        <Flex direction="column" align="center" justify="center" gap="2">
            <div className="flex flex-row gap-2 justify-end items-end w-full">
                <div className="ml-auto flex gap-2 mb-2">
                    <Button
                        className="shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                        size="sm"
                        variant="outline"
                        onClick={() => setShowAddItem(true)}
                    >
                        Add Milestone
                        <PlusIcon />
                    </Button>

                    {/* <GoogleSpreadsheetConnection
                        onCancel={() => {}}
                        onSuccess={handleSuccess}
                        spreadsheetID={onboardingMainItem?.google_link}
                    /> */}
                </div>
            </div>
            {loadingOnboardingData ? (
                <div>Loading...</div>
            ) : (
                <>
                    {showAddItem && (
                        <NewMilestoneCard
                            removeCard={() => setShowAddItem(false)}
                            handleSave={addNewMilestone}
                            users={users ?? []}
                            userID={userID}
                        />
                    )}
                    {Array.from(onboardingItems.values())
                        .filter(
                            (item) =>
                                item.onboarding_type ===
                                OnboardingChecklistType.Milestone,
                        )
                        .map((item) => {
                            const progress = getMilestoneProgressValue(
                                item.total_tasks ?? 0,
                                item.completed_count ?? 0,
                            );
                            const incompleteCount = Array.from(
                                onboardingItems.values(),
                            )
                                .filter(
                                    (i) =>
                                        i.onboarding_type ===
                                        OnboardingChecklistType.Milestone &&
                                        getMilestoneProgressValue(
                                            i.total_tasks ?? 0,
                                            i.completed_count ?? 0,
                                        ) !== 1,
                                )
                                .indexOf(item);

                            return (
                                <MilestoneCard
                                    key={item.id}
                                    accountType={accountType}
                                    accountProp={accountProp}
                                    item={item}
                                    users={users ?? []}
                                    saveIssue={saveIssue}
                                    userID={userID}
                                    isSelected={false}
                                    onSelect={() => { }}
                                    refetch={refetchOnboardingData}
                                    updateIssueState={setOnboardingState}
                                    deleteIssues={handleDeleteIssue}
                                    setSheetOpen={() => { }}
                                    addIssue={addOnboardingItem}
                                    setTopLevelEditing={() => { }}
                                    isOpen={
                                        progress !== 1 && incompleteCount < 3
                                    }
                                    saveIssueNew={saveOnboardingItem}
                                    accountOnboardingParentId={
                                        onboardingMainItem?.id ?? ""
                                    }
                                />
                            );
                        })}
                </>
            )}
        </Flex>
    );
};
