import { Card, CardContent } from "@/component/shadcn/ui/card";
import type {
    AccountMonetaryRecord,
    GetTopicsResponse,
    IconEntry,
    Insight,
} from "@/interfaces/serverData";
import {
    getExternalIssueIcon,
    getTrendFullIcon,
    handleAccountBadgeClick,
    insightAccountBadge,
    insightAdditionalAccountsBadge,
    insightMonetaryValueBadge,
    insightRelatedInteractionsBadge,
    processInsightMetadata,
} from "@/utilities/methods";
import {
    BookmarkFilledIcon,
    BookmarkIcon,
    DoubleArrowLeftIcon,
    OpenInNewWindowIcon,
    ReloadIcon,
} from "@radix-ui/react-icons";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";
import { Box } from "@radix-ui/themes";

// biome-ignore lint/complexity/noBannedTypes: <explanation>
const useDebounce = (fn: Function, delay: number) => {
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    const debouncedFn = useCallback(
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (...args: any[]) => {
            if (timer) clearTimeout(timer);
            const newTimer = setTimeout(() => {
                fn(...args);
            }, delay);
            setTimer(newTimer);
        },
        [fn, delay, timer],
    );

    return debouncedFn;
};

const areEqual = (
    prevProps: InsightsListCardProps,
    nextProps: InsightsListCardProps,
) => {
    return (
        prevProps.insight === nextProps.insight &&
        prevProps.userID === nextProps.userID &&
        prevProps.handleSaveAIGeneratedInsight ===
            nextProps.handleSaveAIGeneratedInsight &&
        prevProps.saved === nextProps.saved &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.teamID === nextProps.teamID
    );
};

interface InsightsListCardProps {
    insight: Insight;
    userID: string;
    handleSaveAIGeneratedInsight: (
        insight: Insight,
        teamID?: string,
    ) => Promise<void>;
    saved: boolean;
    topicsMap: Map<string, GetTopicsResponse>;
    teamID?: string;
}

function InsightsListCard({
    insight,
    handleSaveAIGeneratedInsight,
    saved,
    topicsMap,
    teamID,
}: InsightsListCardProps) {
    const navigate = useNavigate();
    const [insightState, setInsightState] = useState<Insight>(insight);

    const [isAIGenerated, topic, category] = processInsightMetadata(insight);
    const [titleMaxWidth, setTitleMaxWidth] = useState(window.innerWidth * 0.5);

    const TrendIcon = useMemo(() => {
        return getTrendFullIcon(insight.trend ?? "", 2.5);
    }, [insightState.trend]);

    const handleRowClick = (id: string) => {
        if (saved) {
            if (teamID) {
                navigate(`/teams/${teamID}/insight/${id}`);
            } else {
                navigate(`/insight/${id}`);
            }
        }
    };

    const externalIssuesIcons = useMemo(() => {
        const icons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        insightState.external_issues?.forEach((issue) => {
            icons.add({
                Component: getExternalIssueIcon(issue.url),
                props: {
                    width: 20,
                    height: 20,
                    style: { marginLeft: "2", marginRight: "2" },
                },
            });
        });
        return icons;
    }, [insightState.external_issues]);

    const created_date: string = useMemo(() => {
        try {
            const userLocale = navigator.language || "en-US";
            return new Date(insightState.created_at).toLocaleDateString(
                userLocale,
                {
                    month: "short",
                    day: "numeric",
                },
            );
        } catch (err) {
            console.log(
                `Could not convert ticket's created at timestamp ${insightState.created_at} to a valid date, so using the original timestamp format. Error: ${err}`,
            );
            return insightState.created_at;
        }
    }, [insightState.created_at]);

    const handleOpenInNewTab = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        id: string,
    ) => {
        event.stopPropagation();
        if (teamID) {
            window.open(`/teams/${teamID}/insight/${id}`, "_blank");
        } else {
            window.open(`/insight/${id}`, "_blank");
        }
    };

    const handleReload = () => {
        window.location.reload();
    };

    const [visibleAccounts, setVisibleAccounts] = useState<
        AccountMonetaryRecord[]
    >([]);
    const [overflowAccounts, setOverflowAccounts] = useState<
        AccountMonetaryRecord[]
    >([]);
    const getMaxVisibleCustomers = (width: number): number => {
        if (width > 1280) {
            return 2;
        }
        if (width > 1024) {
            return 1;
        }
        return 0;
    };
    const updateDimensions = useCallback(() => {
        const accountsToShow: AccountMonetaryRecord[] = [];
        const overflow: AccountMonetaryRecord[] = [];
        const windowWidth = window.innerWidth;
        const MAX_VISIBLE_ACCOUNTS = getMaxVisibleCustomers(windowWidth);
        insightState.accounts =
            insightState.accounts?.sort(
                (a, b) => b.value_per_month - a.value_per_month,
            ) ?? [];
        for (let i = 0; i < insightState.accounts.length; i++) {
            const account = insightState.accounts[i];
            if (i < MAX_VISIBLE_ACCOUNTS) {
                accountsToShow.push(account);
            } else {
                overflow.push(account);
            }
        }
        setVisibleAccounts(accountsToShow);
        setOverflowAccounts(overflow);
    }, [insightState.accounts]);

    const debouncedUpdateDimensions = useDebounce(updateDimensions, 200);
    useEffect(() => {
        window.addEventListener("resize", debouncedUpdateDimensions);
        return () => {
            window.removeEventListener("resize", debouncedUpdateDimensions);
        };
    }, [debouncedUpdateDimensions]);

    useEffect(() => {
        // Ensure that the dimensions are updated on first render
        updateDimensions();
    }, [updateDimensions]);

    return (
        <Card
            className="py-2.5 px-5 border-[#fafafa] border-l-transparent border-r-transparent border-b-transparent hover:bg-muted rounded w-full h-full"
            onClick={() => handleRowClick(insightState.id)}
        >
            <CardContent className="p-0">
                <ContextMenu>
                    <ContextMenuTrigger>
                        <button
                            className="text-xs bg-transparent border-none p-0 cursor-pointer w-full"
                            type="button"
                        >
                            <div className="flex items-center justify-between w-full max-h-16 overflow-hidden">
                                <div className="flex gap-2 items-start">
                                    {saved ? (
                                        <BookmarkFilledIcon className="h-4 w-4 text-muted-foreground" />
                                    ) : (
                                        <button
                                            type="button"
                                            className="p-1 hover:bg-secondary hover:text-gray-950 rounded-md"
                                            onClick={() =>
                                                teamID
                                                    ? handleSaveAIGeneratedInsight(
                                                          insight,
                                                          teamID,
                                                      )
                                                    : handleSaveAIGeneratedInsight(
                                                          insight,
                                                      )
                                            }
                                        >
                                            <BookmarkIcon className="h-4 w-4 text-muted-foreground" />
                                        </button>
                                    )}
                                    <div className="flex flex-col gap-0.5 max-w-60 2xl:max-w-[47rem] xl:max-w-[42rem] lg:max-w-[24rem] md:max-w-[20rem]">
                                        <div className="flex flex items-center gap-1">
                                            {insight.trend &&
                                                insight.trend !== "Unknown" &&
                                                TrendIcon && (
                                                    <div className="mr-1">
                                                        <TrendIcon />
                                                    </div>
                                                )}
                                            <div className="font-semibold text-[13px] overflow-hidden whitespace-nowrap text-ellipsis">
                                                {insightState.title.trim()}
                                            </div>

                                            {!saved && (
                                                <img
                                                    src={SparklesIcon}
                                                    alt=""
                                                    className="h-6 w-6 rounded p-1"
                                                />
                                            )}
                                        </div>
                                        <div className="text-xs text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                            {insightState.description}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center justify-end gap-1.5 overflow-hidden pl-1">
                                    {insightMonetaryValueBadge(
                                        insightState.monetary_value ?? 0,
                                    )}
                                    {(visibleAccounts.length ||
                                        overflowAccounts.length > 0) && (
                                        <div className="relative flex-none">
                                            <div className="flex items-center gap-1.5 mx-1">
                                                {visibleAccounts.map(
                                                    (account) =>
                                                        insightAccountBadge(
                                                            account,
                                                            handleAccountBadgeClick,
                                                        ),
                                                )}
                                                {overflowAccounts.length > 0 &&
                                                    insightAdditionalAccountsBadge(
                                                        overflowAccounts.length,
                                                    )}
                                            </div>
                                        </div>
                                    )}
                                    {saved && (
                                        <Box className="lg:block hidden">
                                            <div className="text-[12px] max-w-11 flex text-center justify-center items-center ml-1 -mr-1">
                                                {created_date}
                                            </div>
                                        </Box>
                                    )}
                                    <Box className="lg:block hidden">
                                        <div className="flex flex-row items-center">
                                            {Array.from(
                                                externalIssuesIcons,
                                            ).map((icon) =>
                                                React.createElement(
                                                    icon.Component,
                                                    icon.props,
                                                ),
                                            )}
                                        </div>
                                    </Box>
                                    <Box className="md:block hidden">
                                        {insightRelatedInteractionsBadge(
                                            insightState.count ?? 0,
                                        )}
                                    </Box>
                                </div>
                            </div>
                        </button>
                    </ContextMenuTrigger>
                    <ContextMenuContent className="w-60">
                        <ContextMenuItem
                            inset
                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                            onClick={(event) =>
                                handleOpenInNewTab(event, insightState.id)
                            }
                        >
                            <OpenInNewWindowIcon className="w-3.5 h-3.5" />
                            Open in New Tab
                        </ContextMenuItem>
                        <ContextMenuItem
                            inset
                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                        >
                            <DoubleArrowLeftIcon className="w-3.5 h-3.5" />
                            Back
                        </ContextMenuItem>
                        <ContextMenuItem
                            inset
                            onClick={handleReload}
                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                        >
                            <ReloadIcon className="w-3 h-3" />
                            Reload
                        </ContextMenuItem>
                    </ContextMenuContent>
                </ContextMenu>
            </CardContent>
        </Card>
    );
}

export default memo(InsightsListCard, areEqual);
