import { Card } from "@/component/shadcn/ui/card";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import type { Ticket } from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    cleanText,
    formatEmojis,
    getExternalIssueIcon,
    getExternalIssueText,
    getStatusIcon,
} from "@/utilities/methods";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { PencilRulerIcon, SquareArrowUpIcon } from "lucide-react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkEmoji from "remark-emoji";
import remarkGfm from "remark-gfm";

function extractParts(url: string) {
    try {
        const urlObj = new URL(url);
        const pathParts = urlObj.pathname.split("/").filter((part) => part);
        if (url.toLowerCase().includes("linear")) {
            return pathParts.length > 2 ? pathParts[2] : null;
        } else if (url.toLowerCase().includes("github")) {
            return pathParts.length > 3
                ? `${pathParts[1]} ${pathParts[3]}`
                : null;
        } else if (url.toLowerCase().includes("atlassian")) {
            return pathParts.length > 1 ? pathParts[1] : null;
        }
    } catch (error) {
        console.error("Invalid URL or error parsing URL:", error);
        return null;
    }
}

interface LinkedIssuesSectionProps {
    ticket: Ticket;
    refetchTicketData?: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    userID: string;
}

export const LinkedIssuesSection: React.FC<LinkedIssuesSectionProps> = ({
    ticket,
    refetchTicketData,
    userID,
}) => {
    return (
        <div className="pb-2 flex flex-col gap-0">
            {ticket.child_tickets_info?.map((li) => {
                const Icon = getStatusIcon(li.status);
                const SourceSvgImage: React.ElementType | undefined =
                    integrationBackEndDataMappingToSvg.get(
                        li.source ?? "Unknown",
                    );
                const handleClick = () => {
                    window.open(
                        `/issue/${li.ticket_identifier}-${li.number}`,
                        "_blank",
                        "noopener noreferrer",
                    );
                };
                return (
                    <Card
                        className="py-3 px-1 my-1 rounded flex flex-col gap-1 shadow-none"
                        key={li.id}
                    >
                        <div className="prose max-w-none text-xs flex items-start justify-between gap-1 px-1.5 py-0 w-full">
                            <div className="prose max-w-none text-xs flex flex-col gap-0 w-full">
                                <div className="flex items-center justify-between">
                                    <span className="px-1 flex items-center gap-1">
                                        {SourceSvgImage && (
                                            <SourceSvgImage className="w-4 h-4 flex-shrink-0" />
                                        )}
                                        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                                        <div
                                            onClick={handleClick}
                                            className="text-iris9 hover:text-iris11 hover:underline cursor-pointer"
                                        >
                                            {`${li.ticket_identifier}-${li.number}`}
                                        </div>
                                    </span>
                                    <div className="flex items-center gap-1">
                                        <Icon
                                            style={{
                                                height: "15px",
                                            }}
                                            className="flex-shrink-0"
                                        />
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <SquareArrowUpIcon
                                                        strokeWidth={1.5}
                                                        className="h-4 w-4 text-red-700 flex-shrink-0"
                                                    />
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6] py-1.5 px-3 flex flex-col z-50">
                                                    Escalation
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </div>
                                </div>

                                <div className="px-1 overflow-hidden whitespace-nowrap text-ellipsis flex-1 font-semibold w-full">
                                    {li.title}
                                </div>
                                {/* TODO make sure query does NOT OVERFLOW */}
                                <div className="px-1 overflow-hidden whitespace-nowrap text-ellipsis flex-1 text-[11px] text-muted-foreground w-full">
                                    <ReactMarkdown
                                        remarkPlugins={[remarkGfm, remarkEmoji]}
                                        rehypePlugins={[rehypeRaw]}
                                        className="w-full"
                                        components={{
                                            a: ({ node, ...props }) => (
                                                <a
                                                    {...props}
                                                    style={{
                                                        textDecoration:
                                                            "underline",
                                                        color: "#5B5BD6",
                                                    }}
                                                />
                                            ),
                                        }}
                                    >
                                        {cleanText(
                                            formatEmojis(li.query ?? ""),
                                        )}
                                    </ReactMarkdown>
                                </div>
                            </div>
                        </div>
                    </Card>
                );
            })}
            {ticket?.external_issues?.map((issue) => {
                const IssueSvgImage: React.ElementType = getExternalIssueIcon(
                    issue.url,
                );
                const issueName = extractParts(issue.url);
                return (
                    <Card className="p-2 my-1 rounded shadow-none" key={issue.id}>
                        <div className="flex items-center justify-between">
                            <div className="prose max-w-none text-xs flex items-center gap-1 px-2 py-0">
                                <IssueSvgImage
                                    style={{
                                        width: "16px",
                                        height: "16px",
                                    }}
                                    justify="start"
                                />
                                <span>
                                    <a
                                        href={issue.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-iris9 hover:text-iris11 hover:underline"
                                    >
                                        {issueName ??
                                            getExternalIssueText(issue.url)}
                                    </a>
                                </span>
                            </div>
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <PencilRulerIcon
                                            strokeWidth={1.5}
                                            className="h-4 w-4 text-[#5e6ad2] flex-shrink-0"
                                        />
                                    </TooltipTrigger>
                                    <TooltipContent className="bg-[#5B5BD6] py-1.5 px-3 flex flex-col z-50">
                                        External Issue
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </div>
                    </Card>
                );
            })}
        </div>
    );
};
