import { Input } from "@/component/shadcn/ui/input";
import { Flex } from "@radix-ui/themes";
import { SearchIcon } from "lucide-react";

interface DataTableToolbarProps {
    placeholder: string;
    column: string;
    input: string;
    setInput: (input: string) => void;
}

export function DataTableToolbarDebounce({
    placeholder,
    column,
    input,
    setInput,
}: DataTableToolbarProps) {
    return (
        <div className="relative">
                <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
                <Input
                    placeholder={placeholder}
                    value={input}
                    onChange={(event) => setInput(event.target.value)}
                    className="h-8 w-[500px] border border-[#F2EFFA] bg-[#545A92] bg-opacity-5 rounded-xl pl-10 text-sm"
                />
        </div>
    );
}
