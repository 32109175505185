import { Skeleton, Text, Box, Card, Flex } from "@radix-ui/themes";

const CardLoading = () => {
    return (
        <Box p={"4"} style={{ width: "900px", margin: "auto" }}>
            <Card size="3">
                <Flex direction="column" gap="3">
                    <Text>
                        <Skeleton>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Pellentesque felis tellus, efficitur id
                            convallis a, viverra eget libero. Nam magna erat,
                            fringilla sed commodo sed, aliquet nec magna. Lorem
                            ipsum dolor sit amet, consectetur adipiscing elit.
                            Pellentesque felis tellus, efficitur id convallis a,
                            viverra eget libero. Nam magna erat, fringilla sed
                            commodo sed, aliquet nec magna.
                        </Skeleton>
                    </Text>

                    <Skeleton>
                        <Text>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Pellentesque felis tellus, efficitur id
                            convallis a, viverra eget libero. Nam magna erat,
                            fringilla sed commodo sed, aliquet nec magna. Lorem
                            ipsum dolor sit amet, consectetur adipiscing elit.
                            Pellentesque felis tellus, efficitur id convallis a,
                            viverra eget libero. Nam magna erat, fringilla sed
                            commodo sed, aliquet nec magna. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. Pellentesque
                            felis tellus, efficitur id convallis a, viverra eget
                            libero. Nam magna erat, fringilla sed commodo sed,
                            aliquet nec magna. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Pellentesque felis
                            tellus, efficitur id convallis a, viverra eget
                            libero. Nam magna erat, fringilla sed commodo sed,
                            aliquet nec magna.
                        </Text>
                    </Skeleton>
                </Flex>
            </Card>
        </Box>
    );
};

export default CardLoading;
