import { Card, Flex, Skeleton, Text } from "@radix-ui/themes";
import { format, parse } from "date-fns";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import type { GroupedDataItem } from "../AdminAnalyticsPage";

interface InteractionsProps {
    interactionsChartData: GroupedDataItem[];
    interactionsQueryStatus: string;
    className?: string;
}

const InteractionsComponent = ({
    interactionsChartData,
    interactionsQueryStatus,
    className,
}: InteractionsProps) => {
    return (
        <Card className={className}>
            <Flex direction="column">
                <Text size="3" weight="bold">
                    Interactions
                </Text>
                <Text size="3" color="gray">
                    View interactions submitted over time.
                </Text>
                {interactionsQueryStatus === "loading" && <SkeletonText />}
                {interactionsQueryStatus === "success" && (
                    <ResponsiveContainer height={400}>
                        <LineChart data={interactionsChartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={(dateStr) => {
                                    const date = parse(
                                        dateStr,
                                        "yyyy-MM-dd",
                                        new Date(),
                                    );
                                    return format(date, "dd MMM yyyy");
                                }}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="count"
                                stroke="#5B5BD6"
                                activeDot={{ r: 8 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </Flex>
        </Card>
    );
};

const SkeletonText = () => (
    <Skeleton>
        <Text size="5" color="gray">
            Loading data...
        </Text>
    </Skeleton>
);

export default InteractionsComponent;
