import { Box, Tabs } from "@radix-ui/themes";
import { BuildingIcon, MailboxIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import type { Teams } from "@/interfaces/serverData";
import { TeamsAPI, URLS } from "@/constant";
import { useAuthInfo } from "@propelauth/react";
import { workspace } from "./DataTable/constants";
import { AccountsLabels } from "./AccountsLabels";
import { InteractionLabels } from "./InteractionLabels";

const LabelsPage = () => {
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    const [teams, setTeams] = useState<Teams[]>([]);
    useEffect(() => {
        // Check if teamsQuery.data is an array and filter accordingly
        const teamsData = teamsQuery.data || [];
        setTeams([
            workspace,
            ...teamsData.filter((team) => team.team_name !== "General"),
        ]);
    }, [teamsQuery.data, workspace]);

    return (
        <div>
            <Box mt={"5%"} ml={"15%"} mr={"15%"}>
                <div className="flex flex-col">
                    <h2 className="text-2xl font-semibold">Labels</h2>
                    <p className="text-sm text-gray11 mb-6">
                        Manage your labels
                    </p>

                    <Tabs.Root defaultValue="Interactions">
                        <Tabs.List className="flex flex-row items-start gap-4">
                            <Tabs.Trigger value="Interactions">
                                <div className="flex flex-row items-center gap-0 space-x-2">
                                    <MailboxIcon className="h-4 w-4" />
                                    <p>Interactions</p>
                                </div>
                            </Tabs.Trigger>
                            <Tabs.Trigger value="Accounts">
                                <div className="flex flex-row items-center gap-0 space-x-2">
                                    <BuildingIcon className="h-4 w-4" />
                                    <p>Accounts</p>
                                </div>
                            </Tabs.Trigger>
                        </Tabs.List>
                        <Tabs.Content value="Interactions">
                            <InteractionLabels teams={teams} />
                        </Tabs.Content>
                        <Tabs.Content value="Accounts">
                            <AccountsLabels teams={teams} />
                        </Tabs.Content>
                    </Tabs.Root>
                </div>
            </Box>
        </div>
    );
};

export default LabelsPage;
