export interface Onboarding {
    id: string;
    created_at: string;
    updated_at: string;
    customer_id?: string;
    company_id?: string;
    org_id: string;
    google_link: string;
}

export interface GetOnboardingResponse {
    onboarding?: Onboarding;
    onboarding_checklist?: OnboardingChecklist[];
}

export enum OnboardingChecklistType {
    Milestone = "Milestone",
    Task = "Task",
}

export enum OnboardingChecklistStatus {
    NotStarted = "Not Started",
    InProgress = "In Progress",
    Completed = "Completed",
    Paused = "Paused",
    NA = "NA",
    Cancelled = "Cancelled",
}

export const onboardingStatuses = [
    OnboardingChecklistStatus.NotStarted,
    OnboardingChecklistStatus.InProgress,
    OnboardingChecklistStatus.Completed,
    OnboardingChecklistStatus.Paused,
    OnboardingChecklistStatus.NA,
    OnboardingChecklistStatus.Cancelled,
];

export interface OnboardingChecklistPayload {
    name: string;
    description: string;
    deadline: Date | undefined;
    start_date: Date | undefined;
    parent_id?: string;
    task_status: OnboardingChecklistStatus;
    enabled: boolean;
    customer_id?: string;
    company_id?: string;
    assignee_user_id?: string;
    user_id: string;
    onboarding_type: OnboardingChecklistType;
}

export interface OnboardingChecklist extends OnboardingChecklistPayload {
    id: string;
    created_at: string;
    order_position: number;
    updated_at: string;
    org_id: string;
    total_tasks?: number;
    completed_count?: number;
}

export interface OnboardingChecklistHistory {
    id: string;
    created_at: string;
    content: string;
    metadata: string;
    type: string;
}
