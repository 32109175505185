import { Card, CardContent } from "@/component/shadcn/ui/card";
import { Skeleton } from "@/component/shadcn/ui/skeleton";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse, ScopeResponse } from "@/interfaces/serverData";
import { Box, Flex } from "@radix-ui/themes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Mail, Plus } from "lucide-react";
import { ReactComponent as GmailSvg } from "../../../images/integrations/gmail.svg";
import IntegrationHeader from "./IntegrationHeader";
import { readableIntegrationMapping } from "../constant";
import { ErrorChip } from "@/design/Chip";
import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";

interface GoogleIntegrationState {
    accounts: Map<string, IntegrationMetadataResponse>;
}

interface IntegrationMetadataResponse {
    unique_name: string;
    scopes: ScopeResponse[];
    date: string;
    slack_team_id?: string;
    token_scopes?: string[];
}

export default function GoogleIntegration() {
    const api = useApi();

    const { data, isLoading, isError } = useQuery<GoogleIntegrationState>({
        queryKey: ["integrations", "google"],
        queryFn: async () => {
            const requestData = {
                types: ["Google"],
            };
            const response = await api.post(
                URLS.serverUrl + API.getItemsByOrgID,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            const data: OrgInfoResponse = response.data.data;
            const accounts: Map<string, IntegrationMetadataResponse> =
                new Map();
            if (data.Google) {
                for (const account of data.Google) {
                    accounts.set(account.unique_name, account);
                }
            }
            return {
                accounts: accounts,
            };
        },
    });

    const queryClient = useQueryClient();
    const { mutate: deleteMutation } = useMutation({
        mutationFn: async (account: string) => {
            const res = await api.delete(
                URLS.serverUrl + API.deleteIntegration,
                {
                    data: {
                        integration_name: "Google",
                        unique_name: account,
                    },
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            if (res.status === 200) {
                console.log("Successfully deleted connection");
            } else {
                console.log("Failed to delete connection");
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["integrations", "google"],
            });
        },
        onError: (err) => {
            throw err;
        },
    });

    const googleContinue = () => {
        const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/google`;
        const google_url = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.settings.basic&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${redirect_url}&client_id=${client_id}&approval_prompt=force`;
        window.open(google_url, "_self");
    };
    // not ready to use this just yet.
    // const googleContinue = () => {
    //     const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    //     const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/manage_integrations/google`;
    //     const google_url = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${redirect_url}&client_id=${client_id}&approval_prompt=force`;

    //     const width = 500;
    //     const height = 600;
    //     const left = (window.innerWidth - width) / 2;
    //     const top = (window.innerHeight - height) / 2;

    //     const popup = window.open(
    //         google_url,
    //         "googleAuthPopup",
    //         `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes`
    //     );

    //     if (popup) {
    //         popup.focus();
    //     }
    // };

    const connectedAccounts = Array.from(data?.accounts.values() || []);

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex
                    direction={"column"}
                    align={"start"}
                    gap="6"
                    className="w-full"
                >
                    <IntegrationHeader
                        integrationType={
                            readableIntegrationMapping.get("google") ?? "Google"
                        }
                        description="Sync Gmail requests into Assembly. You can add multiple Gmail accounts."
                        SvgIcon={GmailSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        <DialogTriggerButton onClick={googleContinue}>
                            Add Account
                            <Plus className="h-4 w-4" />
                        </DialogTriggerButton>
                    </Flex>
                    {isLoading && <Skeleton className="h-24 w-full" />}
                    {isError && (
                        <div className="w-full flex justify-center">
                            <ErrorChip />
                        </div>
                    )}
                    {!isLoading &&
                        !isError &&
                        connectedAccounts.length === 0 && (
                            <Card className="w-full shadow-none rounded-md">
                                <CardContent className="p-6 shadow-none">
                                    <p className="text-sm">
                                        No Google accounts connected. Click the
                                        "Connect Account" button to get started.
                                    </p>
                                </CardContent>
                            </Card>
                        )}
                    {!isLoading && !isError && connectedAccounts.length > 0 && (
                        <>
                            <div className="grid gap-4 w-full">
                                {connectedAccounts.map((account) => (
                                    <Card
                                        key={account.unique_name}
                                        className="shadow-none rounded-md"
                                    >
                                        <CardContent className="flex flex-col items-start justify-between p-4">
                                            <div className="flex flex-row items-center justify-between w-full">
                                                <div className="flex items-center gap-4">
                                                    <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-iris3 border border-iris4">
                                                        <Mail className="h-4 w-4 text-iris9" />
                                                    </div>
                                                    <div>
                                                        <h3 className="text-sm">
                                                            {
                                                                account.unique_name
                                                            }
                                                        </h3>
                                                        <p className="text-xs text-muted-foreground">
                                                            Enabled
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="flex items-center gap-1 text-sm">
                                                    <span className="h-2 w-2 rounded-full bg-green-500" />
                                                    Connected
                                                </div>
                                            </div>
                                            {/* <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <Button variant="ghost">
                                                        <ChevronDown className="h-4 w-4" />
                                                    </Button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent align="end">
                                                    <DropdownMenuItem
                                                        className="text-destructive"
                                                        onClick={() =>
                                                            deleteMutation(
                                                                account.unique_name,
                                                            )
                                                        }
                                                    >
                                                        Disconnect
                                                    </DropdownMenuItem>
                                                </DropdownMenuContent>
                                            </DropdownMenu> */}
                                        </CardContent>
                                    </Card>
                                ))}
                            </div>
                        </>
                    )}
                </Flex>
            </Box>
        </div>
    );
}
