import { Button } from "@/component/shadcn/ui/button";
import { Card, CardDescription } from "@/component/shadcn/ui/card";
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import { Label } from "@/component/shadcn/ui/label";
import { Textarea } from "@/component/shadcn/ui/textarea";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    InsightData,
    IssueScore,
    Query,
    Ticket,
} from "@/interfaces/serverData";
import { CheckIcon, PlusIcon } from "@radix-ui/react-icons";
import * as Toggle from "@radix-ui/react-toggle";
import { Badge } from "@radix-ui/themes";
import type {
    QueryClient,
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";

interface CreateInsightProps {
    userID: string;
    queryClient: QueryClient;
    issues: Query[];
    startingTitle?: string;
    startingDescription?: string;
    startingRelatedIssues?: Query[];
    teamID?: string;
    fromSidebarCard?: boolean;
    refetchTicketData?: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
}

export function CreateInsightPopup({
    userID,
    queryClient,
    issues,
    startingTitle,
    startingDescription,
    startingRelatedIssues,
    teamID,
    fromSidebarCard,
    refetchTicketData,
}: CreateInsightProps) {
    const api = useApi();
    const ticket_identifier = issues[0]?.ticket_identifier ?? "ASM";
    const [title, setTitle] = useState(startingTitle ?? "");
    const [description, setDescription] = useState(startingDescription ?? "");
    const [relatedIssues, setRelatedIssues] = useState<Map<string, Query>>(
        () => {
            return new Map(
                startingRelatedIssues?.map((issue) => [issue.id, issue]) ?? [],
            );
        },
    );
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredIssues, setFilteredIssues] = useState(issues);
    const [isGenerating, setIsGenerating] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        setTitle(startingTitle ?? "");
    }, [startingTitle]);
    useEffect(() => {
        setDescription(startingDescription ?? "");
    }, [startingDescription]);

    useEffect(() => {
        if (searchQuery.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredIssues(issues);
        } else {
            // Filter the queries based on the search value
            setFilteredIssues(
                issues.filter(
                    (issue) =>
                        (issue.title ?? issue.query ?? "").toLowerCase() ||
                        `${issue.ticket_identifier}-${issue.ticket_number ?? issue.number}`
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    }, [issues]);

    function clearForm() {
        setTitle("");
        setDescription("");
        setRelatedIssues(new Map());
        setFeedbackMessage("");
    }

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault(); // Prevent the default form submission behavior
        setFeedbackMessage("Loading");

        if (title.trim() === "" || description.trim() === "") {
            setFeedbackMessage("Missing Required Fields");
            return;
        }

        const requestData: InsightData = {
            title: title,
            description: description,
            user_id: userID,
            related_issues_added: Array.from(relatedIssues.keys()),
        };
        if (teamID && teamID !== "") {
            requestData.team_id = teamID;
        }

        api.post(URLS.serverUrl + API.saveInsight, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                console.log(`Created insight ${res.data.data.id} successfully`);
                queryClient.refetchQueries({
                    queryKey: ["insights"],
                    exact: true,
                });
                if (refetchTicketData) {
                    refetchTicketData();
                }
                clearForm();
                setFeedbackMessage("Insight created successfully!");
            } else {
                console.log("Call to update status failed");
                setFeedbackMessage(
                    "Failed to create insight. Please try again.",
                );
            }
        });
    };

    const toggleIssueSelection = (issue: Query) => {
        const newRelatedIssues = new Map<string, Query>(
            Array.from(relatedIssues.entries()),
        );
        if (newRelatedIssues.get(issue.id)) {
            // Delete the issue if it's already in related issues
            newRelatedIssues.delete(issue.id);
        } else {
            // Add the issue if it's not in related issues yet
            newRelatedIssues.set(issue.id, issue);
        }
        setRelatedIssues(newRelatedIssues);
    };

    const handleClick = (id: string) => {
        return () => {
            const url = `/issue/${id}`;
            window.open(url, "_blank");
        };
    };

    const handleSearchChange = (event: { target: { value: string } }) => {
        const searchVal = event.target.value.toLowerCase();
        setSearchQuery(searchVal);

        if (searchVal.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredIssues(issues);
        } else {
            // Filter the queries based on the search value
            setFilteredIssues(
                issues.filter(
                    (issue) =>
                        (issue.title ?? issue.query ?? "")
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                        `${issue.ticket_identifier}-${issue.ticket_number ?? issue.number}`
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    };

    const fetchSimilarIssues = async () => {
        setIsGenerating(() => true);
        setErrorMsg(() => "");
        try {
            const res = await api.post(
                URLS.serverUrl + API.similarIssuesForInsight,
                {
                    title: title,
                    description: description,
                },
            );
            if (res.status === 200) {
                const response: IssueScore[] = res.data.data;
                if (response) {
                    const newRelatedIssues = new Map<string, Query>(
                        Array.from(relatedIssues.entries()),
                    );
                    // biome-ignore lint/complexity/noForEach: <explanation>
                    response.forEach((issueScore) =>
                        newRelatedIssues.set(
                            issueScore.issue.id,
                            issueScore.issue,
                        ),
                    );
                    setRelatedIssues(newRelatedIssues);
                } else {
                    setErrorMsg(
                        "No issues found, please enter a more specific title and/or description then try again",
                    );
                }
            } else {
                console.log("Failed to fetch similar issues");
                setErrorMsg(
                    `Please try again, call failed with status ${res.status}`,
                );
            }
        } catch (error) {
            console.log("System is down.");
            setErrorMsg("Please try again, call failed");
        } finally {
            setIsGenerating(false);
        }
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                {fromSidebarCard ? (
                    <Button className="mb-1" size="sm" variant="secondary">
                        Create Insight from Related Interactions
                    </Button>
                ) : (
                    <DialogTriggerButton>
                        Create Insight
                        <PlusIcon />
                    </DialogTriggerButton>
                )}
            </DialogTrigger>
            <DialogContent className="max-w-[850px] w-full">
                <DialogHeader className="flex flex-col items-start w-full">
                    <DialogTitle>Create Insight</DialogTitle>
                    <DialogDescription>
                        Create your own insight and assign relevant
                        interactions.
                    </DialogDescription>
                </DialogHeader>
                <form
                    onSubmit={handleSubmit}
                    className="space-y-4 py-4 flex flex-col"
                >
                    <div className="flex flex-col items-start gap-1">
                        <Label
                            htmlFor="title"
                            className="text-right font-semibold flex"
                        >
                            Title
                            <div className="text-destructive pl-0.5">*</div>
                        </Label>
                        <Input
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="w-full"
                        />
                    </div>
                    <div className="flex flex-col items-start gap-1">
                        <Label
                            htmlFor="description"
                            className="text-right font-semibold flex"
                        >
                            Description
                            <div className="text-destructive pl-0.5">*</div>
                        </Label>
                        <Textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-full p-2 border rounded-md resize-none"
                            rows={3}
                        />
                    </div>
                    <div className="flex flex-col items-start gap-1">
                        <div className="flex items-center justify-between">
                            <Label
                                htmlFor="related_issue"
                                className="text-right font-semibold pr-1"
                            >
                                Related Interactions
                            </Label>

                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Toggle.Root
                                            aria-label="Toggle italic"
                                            className={`px-1 py-1 hover:bg-iris4 flex items-center justify-center rounded bg-white text-base leading-4 ${
                                                isGenerating ? "bg-iris4" : ""
                                            }`}
                                            onClick={fetchSimilarIssues}
                                        >
                                            <img
                                                src={SparklesIcon}
                                                alt=""
                                                className="h-6 w-6"
                                            />
                                        </Toggle.Root>
                                    </TooltipTrigger>
                                    <TooltipContent className="bg-[#5B5BD6]">
                                        <p>Add Related Issues</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </div>
                        {isGenerating && (
                            <div className="flex items-center gap-2 px-0.5 pb-1 text-xs flex items-center rounded-lg w-full">
                                Adding issues related to the title and
                                description...
                                <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                            </div>
                        )}
                        {!isGenerating && errorMsg !== "" && (
                            <div className="flex items-center gap-2 px-0.5 pb-1 text-xs flex items-center rounded-lg w-full">
                                {errorMsg}
                            </div>
                        )}
                        <div className="w-full">
                            <DropdownMenu>
                                <DropdownMenuTrigger
                                    asChild
                                    className="hover:bg-muted max-w-full w-auto"
                                    type="button"
                                >
                                    <div className="flex flex-wrap w-full">
                                        <Button
                                            variant="ghost"
                                            className="flex flex-wrap justify-start items-start p-0 min-w-0 max-w-full data-[state=open]:bg-muted !h-auto"
                                            type="button"
                                        >
                                            {relatedIssues.size > 0 ? (
                                                Array.from(
                                                    relatedIssues.keys(),
                                                ).map((id) => {
                                                    const ri =
                                                        relatedIssues.get(id);
                                                    const org_specific_id = `${ri?.ticket_identifier ?? ticket_identifier}-${ri?.ticket_number ?? ri?.number}`;
                                                    return (
                                                        <div
                                                            key={id}
                                                            className="p-1"
                                                        >
                                                            <Card
                                                                className="rounded hover:bg-secondary max-w-full overflow-hidden"
                                                                onClick={handleClick(
                                                                    org_specific_id,
                                                                )}
                                                            >
                                                                <CardDescription className="p-1 text-xs w-full overflow-hidden whitespace-nowrap text-ellipsis flex items-center gap-1">
                                                                    <Badge
                                                                        variant="outline"
                                                                        color="gray"
                                                                        className="text-[10px] px-1 py-0 rounded-sm"
                                                                    >
                                                                        {/* TODO: also grab ticket identifier when different ones other than ASM are allowed */}
                                                                        {`ASM-${ri?.ticket_number ?? ri?.number}`}
                                                                    </Badge>
                                                                    {ri?.title ??
                                                                        ri?.query}
                                                                </CardDescription>
                                                            </Card>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className="p-1.5 border border-sidebarBorder rounded-md text-xs bg-secondary">
                                                    Search by title to find
                                                    related interactions
                                                </div>
                                            )}
                                        </Button>
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="start"
                                    className="fixed w-[450px] max-h-60 p-0 bg-muted rounded-md shadow-lg"
                                >
                                    <div className="p-2">
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            className="w-full p-1.5 border rounded-md text-xs"
                                        />
                                    </div>
                                    <div className="max-h-52">
                                        <Command className="bg-muted">
                                            <CommandList>
                                                <CommandGroup>
                                                    {filteredIssues.length >
                                                    0 ? (
                                                        filteredIssues.map(
                                                            (query) => {
                                                                const isSelected =
                                                                    relatedIssues.has(
                                                                        query.id,
                                                                    );
                                                                return (
                                                                    <CommandItem
                                                                        key={
                                                                            query.id
                                                                        }
                                                                        onSelect={() =>
                                                                            toggleIssueSelection(
                                                                                query,
                                                                            )
                                                                        }
                                                                        className="py-1 hover:bg-secondary cursor-pointer text-xs"
                                                                    >
                                                                        <div
                                                                            className={`mr-2 flex h-3 w-3 items-center justify-center rounded-sm border border-primary ${
                                                                                isSelected
                                                                                    ? "bg-primary text-primary-foreground"
                                                                                    : "opacity-50"
                                                                            }`}
                                                                        >
                                                                            {isSelected && (
                                                                                <CheckIcon className="h-3 w-3" />
                                                                            )}
                                                                        </div>
                                                                        <div className="flex items-center gap-1">
                                                                            <Badge
                                                                                variant="outline"
                                                                                color="gray"
                                                                                className="text-[10px] px-1 py-0 rounded-sm"
                                                                            >
                                                                                {`${query.ticket_identifier}-${query.ticket_number ?? query.number}`}
                                                                            </Badge>
                                                                            {query.title
                                                                                ? query.title
                                                                                : query.query}
                                                                        </div>
                                                                    </CommandItem>
                                                                );
                                                            },
                                                        )
                                                    ) : (
                                                        <div className="p-2 text-xs text-gray-500">
                                                            No results found
                                                        </div>
                                                    )}
                                                </CommandGroup>
                                            </CommandList>
                                        </Command>
                                    </div>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    </div>

                    <DialogFooter className="flex justify-end items-end text-end">
                        <div className="flex flex-col gap-1 mx-1">
                            <Button
                                type="submit"
                                className="w-12 bg-[#5B5BD6] self-end"
                            >
                                Submit
                            </Button>
                            {feedbackMessage && (
                                <p
                                    className={`mt-1 text-right italic text-xs ${
                                        feedbackMessage.includes("Failed") ||
                                        feedbackMessage.includes("Missing")
                                            ? "destructive"
                                            : "text-primary"
                                    }`}
                                >
                                    {feedbackMessage}
                                </p>
                            )}
                        </div>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
}
