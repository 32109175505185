import { Button } from "@/component/shadcn/ui/button";
import { Input } from "@/component/shadcn/ui/input";
import type { ScopeResponse } from "@/interfaces/serverData";
import { Card } from "@radix-ui/themes";
import { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import { Check, Plus, X, XIcon } from "lucide-react";
import { Avatar } from "radix-ui";
import { useEffect, useRef, useState } from "react";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../shadcn/ui/select";

export type BadgeColor = (typeof badgePropDefs.color.values)[number];
export interface EmailRecipient {
    email: string;
    name?: string;
    integrationID?: string;
}

interface ReplyFromPluginProps {
    properties: Map<string, EmailRecipient[]>;
    setProperties: (properties: Map<string, EmailRecipient[]>) => void;
    propertiesOptions: Map<string, ScopeResponse[]>;
    className?: string;
    showSubject?: boolean;
    setIntegrationID?: React.Dispatch<React.SetStateAction<string | undefined>>
}

interface RecipientRowProps {
    label: string;
    recipients: EmailRecipient[];
    isAdding: boolean;
    newRecipient: string;
    onAdd: () => void;
    onRemove: (email: string) => void;
    setIsAdding: (isAdding: boolean) => void;
    setNewRecipient: (value: string) => void;
}

export function getColor(id: number): BadgeColor {
    const colors = badgePropDefs.color.values.slice(3);
    return colors[id % colors.length];
}

function RecipientRow({
    label,
    recipients,
    isAdding,
    newRecipient,
    onAdd,
    onRemove,
    setIsAdding,
    setNewRecipient,
    isExpanded,
}: RecipientRowProps & { isExpanded: boolean }) {
    const [showAll, setShowAll] = useState(false);
    const [showFrom, setShowFrom] = useState(false);
    useEffect(() => {
        if (!isExpanded) {
            setShowAll(false);
            setShowFrom(false);
        }
    }, [isExpanded]);

    const visibleRecipients =
        isExpanded || showAll ? recipients : recipients.slice(0, 2);
    const hiddenCount = recipients.length - 2;

    return (
        <div className="flex items-center">
            <span className="w-10 text-xs text-muted-foreground">{label}:</span>
            <div className="flex-1 flex flex-wrap gap-2 items-center">
                {visibleRecipients.map((recipient, index) => (
                    <Card
                        key={recipient.email}
                        className="rounded-lg shadow-none flex flex-row gap-1 px-2 py-1 items-center text-xs"
                    >
                        <Avatar.Root className="inline-flex size-[20px] select-none items-center justify-center overflow-hidden rounded-full bg-blackA1 align-middle">
                            <Avatar.Fallback
                                className={`leading-1 flex size-full items-center justify-center text-[11px] font-medium text-${getColor(index)}11`}
                            >
                                {recipient.name?.charAt(0) ||
                                    recipient.email?.charAt(0) ||
                                    "A"}
                            </Avatar.Fallback>
                        </Avatar.Root>

                        {recipient.name && `${recipient.name} `}
                        <span className="text-muted-foreground">
                            &lt;{recipient.email}&gt;
                        </span>
                        <Button
                            variant="ghost"
                            size="sm"
                            className="h-3 w-3 p-0 ml-1.5 hover:bg-transparent"
                            onClick={() => onRemove(recipient.email)}
                        >
                            <X className="h-2.5 w-2.5" />
                        </Button>
                    </Card>
                ))}

                {!isExpanded && hiddenCount > 0 && (
                    <Button
                        variant="ghost"
                        size="sm"
                        className="h-6 px-2 text-xs"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowAll(!showAll);
                        }}
                    >
                        {showAll ? "Show less" : `+${hiddenCount} more`}
                    </Button>
                )}

                {isAdding ? (
                    <div className="flex items-center">
                        <Input
                            className="h-6 w-[180px] text-xs mr-1"
                            value={newRecipient}
                            onChange={(e) => setNewRecipient(e.target.value)}
                            onKeyDown={(e) => e.key === "Enter" && onAdd()}
                            placeholder="Enter email address"
                            autoFocus
                        />
                        <Button
                            variant="ghost"
                            size="sm"
                            className="h-6 px-1.5 rounded-l-none border-l-0"
                            onClick={() => {
                                setIsAdding(false);
                                setNewRecipient("");
                            }}
                        >
                            <XIcon className="h-3 w-3" color="red" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="sm"
                            className="h-6 px-1.5 rounded-l-none border-l-0"
                            onClick={onAdd}
                        >
                            <Check className="h-3 w-3" color="green" />
                        </Button>
                    </div>
                ) : (
                    <Button
                        variant="ghost"
                        size="sm"
                        className="h-6 px-2"
                        onClick={() => setIsAdding(true)}
                    >
                        <Plus className="h-3 w-3" />
                    </Button>
                )}
            </div>
        </div>
    );
}

interface RecipientDropdownProps {
    label: string;
    recipient: EmailRecipient;
    setRecipient: (recipient: EmailRecipient) => void;
    propertiesOptions: ScopeResponse[];
}

function RecipientDropdown({
    label,
    recipient,
    setRecipient,
    propertiesOptions,
    isExpanded,
}: RecipientDropdownProps & { isExpanded: boolean }) {
    return (
        <div className="flex items-center">
            <span className="w-10 text-xs text-muted-foreground">{label}:</span>
            {propertiesOptions.length === 1 ? (
                <Card className="rounded-lg shadow-none flex flex-row gap-1 px-2 py-1 items-center text-xs">
                    <Avatar.Root className="inline-flex size-[20px] select-none items-center justify-center overflow-hidden rounded-full bg-blackA1 align-middle">
                        <Avatar.Fallback className="leading-1 flex size-full items-center justify-center text-xs">
                            {recipient.name?.charAt(0) ||
                                recipient.email?.charAt(0) ||
                                "A"}
                        </Avatar.Fallback>
                    </Avatar.Root>
                    {recipient.name}
                </Card>
            ) : (
                <Select
                    defaultValue={recipient.name}
                    onValueChange={(value) => {
                        const selectedScope = propertiesOptions.find(scope => scope.name === value);
                        if (selectedScope) {
                            setRecipient({
                                email: selectedScope.name,
                                name: selectedScope.name,
                                integrationID: selectedScope.key
                            });
                        }
                    }}
                >
                    <SelectTrigger className="ml-2 rounded-lg shadow-none flex flex-row gap-1 px-2 py-1 items-center text-xs">
                        <SelectValue defaultValue={recipient.name} >
                            <Avatar.Root className="inline-flex size-[20px] select-none items-center justify-center overflow-hidden rounded-full bg-blackA1 align-middle">
                                <Avatar.Fallback className="leading-1 flex size-full items-center justify-center text-xs">
                                    {recipient.name?.charAt(0) ||
                                        recipient.email?.charAt(0) ||
                                        "A"}
                                </Avatar.Fallback>
                            </Avatar.Root>
                            {recipient.name}
                        </SelectValue>
                    </SelectTrigger>
                    <SelectContent className="w-[300px]">
                        {propertiesOptions.map((scope) => (
                            <SelectItem key={scope.key} value={scope.name}>
                                <div className="flex items-center gap-2 text-xs">
                                    <Avatar.Root className="inline-flex size-[20px] select-none items-center justify-center overflow-hidden rounded-full bg-blackA1 align-middle">
                                        <Avatar.Fallback className="leading-1 flex size-full items-center justify-center text-xs">
                                            {scope.name?.charAt(0) || "A"}
                                        </Avatar.Fallback>
                                    </Avatar.Root>
                                    {scope.name}
                                </div>
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            )}
        </div>
    );
}

export function ReplyFromPlugin({
    properties,
    setProperties,
    propertiesOptions,
    className,
    showSubject,
    setIntegrationID
}: ReplyFromPluginProps) {
    const [newRecipients, setNewRecipients] = useState<Map<string, string>>(
        new Map(),
    );
    const [isAdding, setIsAdding] = useState<Map<string, boolean>>(new Map());
    const [isExpanded, setIsExpanded] = useState(false);
    const pluginRef = useRef<HTMLDivElement>(null);

    const [showFields, setShowFields] = useState<Map<string, boolean>>(
        new Map([
            ["to", true],
            ["cc", properties.has("cc")],
            ["bcc", properties.has("bcc")],
            ["subject", showSubject ?? false],
        ]),
    );

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                pluginRef.current &&
                !pluginRef.current.contains(event.target as Node)
            ) {
                setIsExpanded(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleAddRecipient = (type: string) => {
        const recipient = newRecipients.get(type);
        if (!recipient) return;

        const newRecipient: EmailRecipient = {
            email: recipient,
            name: recipient.split("@")[0],
        };

        const updatedProperties = new Map(properties);
        updatedProperties.set(type, [
            ...(properties.get(type) || []),
            newRecipient,
        ]);
        setProperties(updatedProperties);

        // Clear input and hide add field
        setNewRecipients(new Map(newRecipients.set(type, "")));
        setIsAdding(new Map(isAdding.set(type, false)));
    };

    const handleRemoveRecipient = (type: string, email: string) => {
        const updatedProperties = new Map(properties);
        updatedProperties.set(
            type,
            (properties.get(type) || []).filter((r) => r.email !== email),
        );
        setProperties(updatedProperties);
    };

    const toggleField = (field: string) => {
        setShowFields(new Map(showFields.set(field, !showFields.get(field))));
    };

    return (
        <div
            ref={pluginRef}
            className={`rounded-lg p-4 ${className}`}
            onClick={() => setIsExpanded(true)}
            onKeyDown={(e) => e.key === "Enter" && setIsExpanded(true)}
        >
            <div className="flex flex-row justify-between">
                <div className="space-y-3">
                    <RecipientDropdown
                        label="From"
                        recipient={
                            properties.get("from")?.[0] || {
                                email: "",
                                name: "",
                            }
                        }
                        propertiesOptions={propertiesOptions?.get("from") ?? []}
                        isExpanded={isExpanded}
                        setRecipient={(recipient) => {
                            if (setIntegrationID) {
                                setIntegrationID(recipient.integrationID)
                            }
                            setProperties(
                                new Map(properties.set("from", [recipient])),
                            )
                        }
                        }
                    />
                    <RecipientRow
                        label="To"
                        recipients={properties.get("to") || []}
                        isAdding={isAdding.get("to") || false}
                        newRecipient={newRecipients.get("to") || ""}
                        onAdd={() => handleAddRecipient("to")}
                        onRemove={(email) => handleRemoveRecipient("to", email)}
                        setIsAdding={(value) =>
                            setIsAdding(new Map(isAdding.set("to", value)))
                        }
                        setNewRecipient={(value) =>
                            setNewRecipients(
                                new Map(newRecipients.set("to", value)),
                            )
                        }
                        isExpanded={isExpanded}
                    />

                    {showFields.get("cc") && (
                        <RecipientRow
                            label="Cc"
                            recipients={properties.get("cc") || []}
                            isAdding={isAdding.get("cc") || false}
                            newRecipient={newRecipients.get("cc") || ""}
                            onAdd={() => handleAddRecipient("cc")}
                            onRemove={(email) =>
                                handleRemoveRecipient("cc", email)
                            }
                            setIsAdding={(value) =>
                                setIsAdding(new Map(isAdding.set("cc", value)))
                            }
                            setNewRecipient={(value) =>
                                setNewRecipients(
                                    new Map(newRecipients.set("cc", value)),
                                )
                            }
                            isExpanded={isExpanded}
                        />
                    )}

                    {showFields.get("bcc") && (
                        <RecipientRow
                            label="Bcc"
                            recipients={properties.get("bcc") || []}
                            isAdding={isAdding.get("bcc") || false}
                            newRecipient={newRecipients.get("bcc") || ""}
                            onAdd={() => handleAddRecipient("bcc")}
                            onRemove={(email) =>
                                handleRemoveRecipient("bcc", email)
                            }
                            setIsAdding={(value) =>
                                setIsAdding(new Map(isAdding.set("bcc", value)))
                            }
                            setNewRecipient={(value) =>
                                setNewRecipients(
                                    new Map(newRecipients.set("bcc", value)),
                                )
                            }
                            isExpanded={isExpanded}
                        />
                    )}

                    {showFields.get("subject") && (
                        <div className="flex items-center">
                            <span className="w-14 text-xs text-muted-foreground">
                                Subject:
                            </span>
                            <Input
                                className="ml-2 h-7 min-w-[200px] text-xs max-w-[600px] grow"
                                value={
                                    properties.get("subject")?.[0]?.name || ""
                                }
                                onChange={(e) =>
                                    setProperties(
                                        new Map(
                                            properties.set("subject", [
                                                {
                                                    email: e.target.value,
                                                    name: e.target.value,
                                                },
                                            ]),
                                        ),
                                    )
                                }
                                placeholder="Enter subject"
                            />
                        </div>
                    )}
                </div>
                <div className="flex justify-end gap-1 text-muted-foreground">
                    <Button
                        variant="ghost"
                        size="sm"
                        className={`h-6 px-2 ${showFields.get("cc") ? "bg-secondary" : ""}`}
                        onClick={() => toggleField("cc")}
                    >
                        Cc
                    </Button>
                    <Button
                        variant="ghost"
                        size="sm"
                        className={`h-6 px-2 ${showFields.get("bcc") ? "bg-secondary" : ""}`}
                        onClick={() => toggleField("bcc")}
                    >
                        Bcc
                    </Button>
                    <Button
                        variant="ghost"
                        size="sm"
                        className={`h-6 px-2 ${showFields.get("subject") ? "bg-secondary" : ""}`}
                        onClick={() => toggleField("subject")}
                    >
                        Subject
                    </Button>
                </div>
            </div>
        </div>
    );
}
