import { ComponentBooleanIcon } from "@radix-ui/react-icons";
import { PlayIcon, TargetIcon, TimerIcon } from "lucide-react";

export const TriggerIcon = () => {
    return (
        <div className="flex h-6 w-6 p-1 items-center justify-center rounded-lg bg-iris3 border border-iris4">
            <TargetIcon className="h-4 w-4 text-iris9" />
        </div>
    );
};

export const ActionsIcon = () => {
    return (
        <div className="flex h-6 w-6 p-1 items-center justify-center rounded-lg bg-iris3 border border-iris4">
            <PlayIcon className="h-4 w-4 text-iris9" />
        </div>
    );
};

export const ConditionIcon = () => {
    return (
        <div className="flex h-6 w-6 p-1 items-center justify-center rounded-lg bg-iris3 border border-iris4">
            <ComponentBooleanIcon className="h-4 w-4 text-iris9" />
        </div>
    );
};

export const WaitIcon = () => {
    return (
        <div className="flex h-6 w-6 p-1 items-center justify-center rounded-lg bg-iris3 border border-iris4">
            <TimerIcon className="h-4 w-4 text-iris9" />
        </div>
    );
};

export const handleStyle =
    "w-6 h-6 bg-white border border-gray-200 rounded-full flex items-center justify-center cursor-pointer text-lg font-normal text-gray-400 after:content-['+'] after:leading-none after:-mt-[2px]";
