import { Card } from "@/component/shadcn/ui/card";
import type {
    AccountMonetaryRecord,
    GetTopicsResponse,
    Insight,
    Query,
} from "@/interfaces/serverData";
import {
    arraysAreEqual,
    getTrendFullIcon,
    handleAccountBadgeClick,
    insightAccountBadge,
    insightAdditionalAccountsBadge,
    insightMonetaryValueBadge,
    insightRelatedInteractionsBadge,
} from "@/utilities/methods";
import { memo } from "react";

const areEqual = (
    prevProps: TopInsightCardProps,
    nextProps: TopInsightCardProps,
) => {
    return (
        prevProps.insight === nextProps.insight &&
        prevProps.handleSaveAIGeneratedInsight ===
            nextProps.handleSaveAIGeneratedInsight &&
        arraysAreEqual(prevProps.issues, nextProps.issues) &&
        prevProps.userID === nextProps.userID &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.popUpHeight === nextProps.popUpHeight &&
        prevProps.teamID === nextProps.teamID
    );
};

interface TopInsightCardProps {
    insight: Insight;
    handleSaveAIGeneratedInsight: (
        insight: Insight,
        teamID?: string,
    ) => Promise<void>;
    issues: Query[];
    userID: string;
    topicsMap: Map<string, GetTopicsResponse>;
    popUpHeight: number;
    teamID?: string;
}

const TopInsightCard = (props: TopInsightCardProps) => {
    const Icon = getTrendFullIcon(props.insight.trend ?? "", 2.5);
    const sortedAccounts: AccountMonetaryRecord[] =
        props.insight.accounts?.sort(
            (a, b) => b.value_per_month - a.value_per_month,
        );
    const topCustomer: AccountMonetaryRecord | undefined =
        sortedAccounts && sortedAccounts.length > 0
            ? sortedAccounts[0]
            : undefined;
    const numRemainingCustomers: number = sortedAccounts
        ? sortedAccounts.length - 1
        : 0;

    return (
        <Card className="p-4 mb-3 bg-background bg-mutedhover:bg-muted rounded-sm">
            <div className="flex flex-col gap-1.5 text-sm">
                <div className="flex items-center gap-2 font-semibold text-[13px]">
                    {props.insight.trend &&
                        props.insight.trend !== "Unknown" &&
                        Icon && <Icon />}
                    {props.insight.title}
                </div>
                <div className="flex items-center gap-2.5 px-1 overflow-x-hidden overflow-y-auto">
                    {insightMonetaryValueBadge(
                        props.insight.monetary_value ?? 0,
                    )}
                    {topCustomer &&
                        insightAccountBadge(
                            topCustomer,
                            handleAccountBadgeClick,
                        )}
                    {numRemainingCustomers >= 1 &&
                        insightAdditionalAccountsBadge(numRemainingCustomers)}
                    {insightRelatedInteractionsBadge(props.insight.count ?? 0)}
                </div>
                <div className="text-xs text-muted-foreground line-clamp-2">
                    {props.insight.description}
                </div>
            </div>
        </Card>
    );
};

export default memo(TopInsightCard, areEqual);
