import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import { Input } from "@/component/shadcn/ui/input";
import CalendarPopup from "@/design/CalendarPopup";
import {
    type GetOnboardingResponse,
    type OnboardingChecklist,
    type OnboardingChecklistPayload,
    OnboardingChecklistStatus,
    OnboardingChecklistType,
    onboardingStatuses,
} from "@/interfaces/onboardingServerData";
import type {
    Account,
    GetTopicsResponse,
    GetUserResponse,
} from "@/interfaces/serverData";
import { AvatarIcon, CheckIcon } from "@radix-ui/react-icons";
import type { RefetchOptions } from "@tanstack/react-query";
import { ArrowRightIcon, ChevronDownIcon } from "lucide-react";

import { Badge } from "@radix-ui/themes";
import { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { OnboardingContextMenu } from "./OnboardingContextMenu";

import { ContactsAPI } from "@/constant";
import { URLS } from "@/constant";
import CalendarBadge from "@/design/CalendarBadge";
import { useApi } from "@/interfaces/api";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@heroui/progress";
import { useQuery } from "@tanstack/react-query";
import type { QueryObserverResult } from "@tanstack/react-query";
import type React from "react";
import { OnboardingAssigneeDropdown } from "./AssigneeDropdown";
import {
    OnboardingTable,
    getEmptyRow,
} from "./OnboardingTable/OnboardingTable";
import { StatusDropdown } from "./StatusDropdown";
interface MilestoneCardProps {
    item: OnboardingChecklist;
    users: GetUserResponse[];
    saveIssue: (
        type: string,
        payload: Partial<OnboardingChecklistPayload>,
        userID: string,
        updateIssueState: (
            newState: Partial<OnboardingChecklist>,
            oldItem: OnboardingChecklist,
        ) => void,
        refetch: (
            options?: RefetchOptions,
        ) => Promise<QueryObserverResult<GetOnboardingResponse, Error>>,
        issueId: string,
        item: OnboardingChecklist,
        accountOnboardingParentId: string,
        milestoneId: string,
        topicsMap?: Map<string, GetTopicsResponse>,
    ) => void;
    saveIssueNew: (
        issue: Partial<OnboardingChecklist>,
        columnId: string,
        value: string,
        onboardingMainItemId: string,
    ) => void;
    userID: string;
    isSelected: boolean;
    onSelect: (
        checked: boolean,
        issueId: string,
        event?: React.MouseEvent,
    ) => void;
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<GetOnboardingResponse, Error>>;
    updateIssueState: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void;
    deleteIssues: (
        userID: string,
        issueIds: string[],
        deleteOnUI?: (ids: string[]) => void,
    ) => void;
    setSheetOpen: (open: boolean) => void;
    addIssue: (issue: OnboardingChecklist, parentId: string) => Promise<string>;
    setTopLevelEditing: (editing: { edit: boolean; parentId: string }) => void;
    accountOnboardingParentId: string;
    isOpen: boolean;
    accountProp: Account;
    accountType: "Company" | "Customer";
}

export const getMilestoneProgressValue = (
    totalTasks: number,
    completedTasks: number,
) => {
    if (totalTasks === 0) return 0;
    const progress = (completedTasks ?? 0) / (totalTasks ?? 1);
    return progress;
};

export const getProgressColor = (
    item: OnboardingChecklist,
    completedTasks: number,
    totalTasks: number,
) => {
    if (item.task_status === OnboardingChecklistStatus.Completed)
        return "stroke-green8";
    const progress = completedTasks / (totalTasks || 1);
    if (progress === 1) return "stroke-green8";
    if (progress >= 0.5) return "stroke-yellow8";
    return "stroke-gray8";
};

export const MilestoneCard = ({
    item,
    users,
    saveIssue,
    saveIssueNew,
    userID,
    isSelected,
    onSelect,
    refetch,
    updateIssueState,
    deleteIssues,
    setSheetOpen,
    addIssue,
    setTopLevelEditing,
    accountOnboardingParentId,
    isOpen,
    accountType,
    accountProp,
}: MilestoneCardProps) => {
    const [editing, setEditing] = useState(false);
    const [cardOpen, setCardOpen] = useState(isOpen);
    const foundUser: GetUserResponse | undefined = useMemo(
        () => users.find((user) => user.id === item.assignee_user_id),
        [item.assignee_user_id, users],
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;

    const dropdownOptions = ["Assignee"]; // todo: introduce tag, topic, status

    const handleSave = useCallback(
        (type: string, payload: Partial<OnboardingChecklistPayload>) => {
            saveIssue(
                type,
                payload,
                userID,
                updateIssueState,
                refetch,
                item.id,
                item,
                accountOnboardingParentId,
                item.id,
                new Map(),
            );
        },
        [userID, refetch, saveIssue, item, updateIssueState],
    );

    const handleDelete = useCallback(() => {
        deleteIssues(userID, [item.id]);
    }, [userID, deleteIssues, item.id]);

    const [startDate, setStartDate] = useState<Date | undefined>(
        item.start_date,
    );
    const [deadline, setDeadline] = useState<Date | undefined>(item.deadline);
    const [name, setName] = useState<string>(item.name);
    const [description, setDescription] = useState<string>(item.description);

    const [assignee, setAssignee] = useState<GetUserResponse>({
        id: item.assignee_user_id,
        email: "",
        username: userName,
        first_name: "",
        last_name: "",
        picture_url: pictureURL,
        user_role: "",
    } as GetUserResponse);

    const saveAssignee = (assignee: GetUserResponse) => {
        setAssignee(assignee);
    };

    const saveEdit = () => {
        handleSave("ALL", {
            name: name,
            description: description,
            deadline: deadline,
            start_date: startDate,
            assignee_user_id: assignee.id,
        });
        setEditing(false);
    };

    useEffect(() => {
        setTopLevelEditing({ edit: editing, parentId: item.parent_id ?? "" });
    }, [editing, setTopLevelEditing, item.parent_id]);

    const api = useApi();

    const fetchOnboardingChecklist =
        async (): Promise<GetOnboardingResponse> => {
            try {
                const { url } = ContactsAPI.getOnboardingChecklist;
                const response = await api.get(`${URLS.serverUrl}${url}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        [accountType === "Company"
                            ? "company_id"
                            : "customer_id"]: accountProp.id,
                        onboarding_type: OnboardingChecklistType.Task,
                        parent_id: item.id,
                    },
                });
                if (response.data === null) {
                    return {
                        onboarding: undefined,
                        onboarding_checklist: [],
                    };
                }
                if (response.status === 200) {
                    return response.data.data;
                }
                return {
                    onboarding: undefined,
                    onboarding_checklist: [],
                };
            } catch (error) {
                console.error("Error fetching queries:", error);
                return {
                    onboarding: undefined,
                    onboarding_checklist: [],
                };
            }
        };

    const {
        data: onboardingData,
        isLoading: loadingOnboardingData,
        isError: errorOnboardingData,
        refetch: refetchOnboardingData,
    } = useQuery({
        queryKey: ["onboardingItems", accountProp.id, item.id],
        queryFn: fetchOnboardingChecklist,
        enabled: cardOpen,
    });

    const [incomingData, setIncomingData] = useState<OnboardingChecklist[]>(
        onboardingData?.onboarding_checklist ?? [],
    );
    useEffect(() => {
        setIncomingData(onboardingData?.onboarding_checklist ?? []);
    }, [onboardingData]);

    const totalTasks = cardOpen ? incomingData.length : (item.total_tasks ?? 0);
    const completedTasks = cardOpen
        ? incomingData.filter(
            (task) =>
                task.task_status === OnboardingChecklistStatus.Completed,
        ).length
        : (item.completed_count ?? 0);

    return (
        <Card
            onClick={(e) => {
                e.stopPropagation();
                if (!editing) {
                    setSheetOpen(true);
                } else {
                    setSheetOpen(false);
                }
            }}
            className={`w-full rounded-lg hover:outline-0.5 hover:outline-offset-0 flex flex-col px-1 shadow-none border relative bg-white ${!item.enabled ? "opacity-50" : ""} ${item.task_status === OnboardingChecklistStatus.Completed ? "bg-[#fbfbfb]" : ""}`}
        >
            <CardContent className="p-3">
                <OnboardingContextMenu
                    issueId={item.id}
                    dropdownOptions={dropdownOptions}
                    onSave={handleSave}
                    users={users}
                    teams={[]}
                    filters={new Map()}
                    enabled={item.enabled}
                    completed={
                        item.task_status === OnboardingChecklistStatus.Completed
                    }
                    editing={editing}
                    setEdit={setEditing}
                    onDelete={handleDelete}
                >
                    {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                    <div
                        className="flex items-center gap-3"
                        onClick={() => {
                            setCardOpen(!cardOpen);
                        }}
                    >
                        <ChevronDownIcon
                            className={`w-3 h-3 ${cardOpen ? "rotate-180" : ""}`}
                        />

                        <div className="flex items-center gap-2">
                            <Badge
                                color={"gray"}
                                size="2"
                                variant="outline"
                                className="ring-[0.8px] text-gray-400 ring-[#E0E1E6] rounded-md"
                            >
                                {completedTasks} / {totalTasks}
                                <CircularProgress
                                    aria-label="Loading..."
                                    showValueLabel={
                                        getMilestoneProgressValue(
                                            totalTasks,
                                            completedTasks,
                                        ) === 1
                                    }
                                    classNames={{
                                        svg: "w-5 h-5",
                                        track: "stroke-gray-200",
                                        indicator: getProgressColor(
                                            item,
                                            completedTasks,
                                            totalTasks,
                                        ),
                                    }}
                                    strokeWidth={4}
                                    size="sm"
                                    valueLabel={
                                        <CheckIcon className="w-3 h-3" />
                                    }
                                    value={
                                        getMilestoneProgressValue(
                                            totalTasks,
                                            completedTasks,
                                        ) * 100
                                    }
                                />
                            </Badge>
                            {editing ? (
                                <Input
                                    defaultValue={item.name}
                                    className="text-xs font-medium"
                                    placeholder="Milestone name"
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        setName(e.target.value);
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            ) : (
                                <span className="text-sm font-medium">
                                    {item.name}
                                </span>
                            )}
                        </div>
                        <div className="flex items-center gap-2 ml-auto">
                            {editing ? (
                                <CalendarPopup
                                    date={startDate}
                                    buttonText="Start date"
                                    onSelect={setStartDate}
                                />
                            ) : (
                                item.start_date && (
                                    <CalendarBadge date={item.start_date} />
                                )
                            )}
                            {editing || (item.start_date && item.deadline) ? (
                                <ArrowRightIcon className="h-3 w-3" />
                            ) : (
                                <></>
                            )}
                            {editing ? (
                                <CalendarPopup
                                    date={deadline}
                                    onSelect={setDeadline}
                                    buttonText="Deadline"
                                    iconColor="text-red-500"
                                    startDate={item.start_date}
                                />
                            ) : (
                                item.deadline && (
                                    <CalendarBadge
                                        date={item.deadline}
                                        iconColor="text-red-500"
                                    />
                                )
                            )}

                            {editing ? (
                                <OnboardingAssigneeDropdown
                                    assigneeUserID={assignee.id}
                                    users={users}
                                    userID={userID}
                                    saveAssignee={saveAssignee}
                                />
                            ) : item.assignee_user_id &&
                                item.assignee_user_id !== "noAssignee" ? (
                                <div className="lb-avatar rounded-lg w-6 h-6 mx-1">
                                    {pictureURL && (
                                        <img
                                            className="lb-avatar-image"
                                            src={pictureURL}
                                            alt={userName}
                                        />
                                    )}
                                    <span>{userName ?? ""}</span>
                                </div>
                            ) : (
                                <AvatarIcon className="w-6 h-6 mx-1" />
                            )}
                        </div>
                        {editing && (
                            <div className="flex flex-row gap-2">
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setEditing(false);
                                    }}
                                    variant="outline"
                                    className="h-6 shadow-none outline outline-1 outline-slate-200"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        saveEdit();
                                    }}
                                    variant="default"
                                    className="bg-[#6d78d5] hover:bg-[#6d78d5]/90 h-6 shadow-none "
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </div>
                </OnboardingContextMenu>
                {cardOpen && (
                    <OnboardingTable
                        userID={userID}
                        users={users ?? []}
                        data={incomingData}
                        setData={setIncomingData}
                        saveToDB={true}
                        addIssue={addIssue}
                        saveIssue={saveIssueNew}
                        milestoneID={item.id}
                        onboardingMainItemId={accountOnboardingParentId}
                        deleteIssues={deleteIssues}
                    />
                )}
            </CardContent>
        </Card>
    );
};

interface NewMilestoneCardProps {
    removeCard: () => void;
    handleSave: (
        milestone: OnboardingChecklistPayload,
        data: OnboardingChecklist[],
    ) => void;
    parentId?: string;
    users: GetUserResponse[];
    userID: string;
}
export const NewMilestoneCard = ({
    removeCard,
    handleSave,
    parentId,
    users,
    userID,
}: NewMilestoneCardProps) => {
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [deadline, setDeadline] = useState<Date | undefined>(undefined);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [assignee, setAssignee] = useState<GetUserResponse>({
        id: "",
        email: "",
        username: "",
        first_name: "",
        last_name: "",
        picture_url: "",
        user_role: "",
    } as GetUserResponse);

    const saveAssignee = (assignee: GetUserResponse) => {
        setAssignee(assignee);
    };

    const [status, setStatus] = useState<OnboardingChecklistStatus>(
        OnboardingChecklistStatus.NotStarted,
    );
    const [incomingData, setIncomingData] = useState<OnboardingChecklist[]>([
        getEmptyRow(userID, ""),
    ]);

    return (
        <div className="flex flex-col items-start w-full">
            <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 hover-none -mb-1 ml-[1px] pb-[4px] relative">
                <div className="flex flex-row items-center justify-center gap-1">
                    <FontAwesomeIcon icon={faFlag} className="text-[#5e6ad2]" />
                    <p className="text-xs">Milestone</p>
                </div>
            </Badge>
            <Card
                className={
                    "w-full shadow-none rounded-tr-lg rounded-bl-lg rounded-br-lg z-10 hover:outline-0.5 hover:outline-offset-0 z-10"
                }
            >
                <CardContent className="p-4">
                    <div className="flex flex-col items-start gap-3">
                        <div className="flex flex-row gap-2 w-full">
                            <Input
                                className="h-6 text-xs"
                                placeholder="Milestone name"
                                onChange={(e) => setName(e.target.value)}
                            />
                            <Input
                                className="h-6 text-xs text-gray-500"
                                placeholder="Milestone description"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-row gap-3 w-full justify-between">
                            <div className="flex flex-row gap-4">
                                <StatusDropdown
                                    incomingStatus={status}
                                    userID={userID}
                                    saveStatus={setStatus}
                                    statuses={onboardingStatuses}
                                    className="h-6 outline outline-1 outline-slate-200"
                                />
                                <OnboardingAssigneeDropdown
                                    users={users}
                                    userID={userID}
                                    saveAssignee={saveAssignee}
                                    className="h-6 outline outline-1 outline-slate-200"
                                />
                                <CalendarPopup
                                    date={startDate}
                                    onSelect={setStartDate}
                                    buttonText="Start date"
                                    className="w-200"
                                />
                                <CalendarPopup
                                    date={deadline}
                                    onSelect={setDeadline}
                                    buttonText="Deadline"
                                    iconColor="text-red-500"
                                    className="w-200"
                                    startDate={startDate}
                                />
                            </div>
                            <div className="flex flex-row gap-3 ml-auto">
                                <Button
                                    onClick={() => removeCard()}
                                    variant="outline"
                                    className="h-6 shadow-none outline outline-1 outline-slate-200"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() =>
                                        handleSave(
                                            {
                                                name,
                                                description,
                                                deadline: deadline,
                                                start_date: startDate,
                                                task_status: status,
                                                assignee_user_id: assignee.id,
                                                enabled: true,
                                                parent_id: parentId ?? "",
                                                user_id: userID,
                                                onboarding_type:
                                                    OnboardingChecklistType.Milestone,
                                            },
                                            incomingData,
                                        )
                                    }
                                    variant="default"
                                    className="bg-[#6d78d5] hover:bg-[#6d78d5]/90 h-6 shadow-none "
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                        <OnboardingTable
                            userID={userID}
                            users={users ?? []}
                            data={incomingData}
                            setData={setIncomingData}
                            saveToDB={false}
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};
