import type {
    Category,
    CustomerGroup,
    GetUserResponse,
    ScopeResponse,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import { getFilterOption } from "@/utilities/methods";
import { useCallback, useState } from "react";
import { Filter } from "./Filter";
import FilterDropdown from "./FilterDropdown";
import {
    type FilterOption,
    FilterType,
    menuThenMenuSources,
    sources,
    sourcesFilterOptions,
} from "./constants";

interface FilterItemsProps {
    filters: Map<string, Set<string>>;
    setFilters: (filters: Map<string, Set<string>>) => void;
    topics: Topic[];
    categories: Category[];
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
    teams: Teams[];
    savedViewFilters: Map<string, Set<string>>;
    isGeneralTeam: boolean;
    channels: Map<string, ScopeResponse[]>;
}

export const FilterItems: React.FC<FilterItemsProps> = ({
    filters,
    setFilters,
    topics,
    categories,
    users,
    customerGroups,
    teams,
    savedViewFilters,
    isGeneralTeam,
    channels,
}) => {
    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const filterOptions: FilterOption[] = [
        getFilterOption("Assembly Bot", FilterType.DirectSelect, true),
        getFilterOption("Assignee", FilterType.MenuThenSelect, true),
        getFilterOption("Tag", FilterType.MenuThenSelect, true),
        getFilterOption("Customer Group", FilterType.MenuThenSelect, true),
        getFilterOption("Source", FilterType.MenuThenSelect, true),
        getFilterOption("Status", FilterType.MenuThenSelect, true),
        getFilterOption("Topic", FilterType.MenuThenSelect, true),
    ];
    if (isGeneralTeam) {
        filterOptions.push(
            getFilterOption("Team", FilterType.MenuThenSelect, true),
        );
    }
    filterOptions.push(...sourcesFilterOptions);

    const handleFilterSelect = (
        type: string,
        option: { label: string; value: string; key: string; color: string },
    ) => {
        return () => {
            const newFilters = new Map(filters); // Create a shallow copy of the filters Map
            let typeToStore = type;
            // Open up another context menu for these sources
            if (menuThenMenuSources.includes(option.value)) {
                setActiveMenu(option.value);
                return;
            }
            // Handle source type channels/integrations separately
            if (sources.map((source) => source.value).includes(type)) {
                typeToStore = "Source";
                const [source, channelName] = option.value.split(" -- ");
                const sourcesStored = newFilters.get(typeToStore) ?? new Set();
                if (
                    channelName === "All Channels" ||
                    channelName === "All Emails" ||
                    channelName === "All API Keys"
                ) {
                    if (sourcesStored?.has(option.value)) {
                        // Remove the selected value
                        sourcesStored.delete(option.value);
                        if (sourcesStored.size === 0) {
                            newFilters.delete(typeToStore); // Remove filter if no values remain
                        } else {
                            newFilters.set(typeToStore, sourcesStored); // Update the type with new values
                        }
                    } else {
                        // Remove other existing filters for that source and then add All Channels
                        const filteredSources = Array.from(
                            sourcesStored,
                        ).filter((value) => {
                            const [elemSource] = value.split(" -- ");
                            return elemSource !== source;
                        });
                        filteredSources.push(option.value);
                        newFilters.set(typeToStore, new Set(filteredSources));
                    }
                    setFilters(newFilters);
                    return;
                }
                if (
                    sourcesStored.size === 1 &&
                    Array.from(sourcesStored).some(
                        (item) =>
                            item.includes("-- All Channels") ||
                            item.includes("-- All Emails") ||
                            item.includes("-- All API Keys"),
                    )
                ) {
                    newFilters.set(typeToStore, new Set([option.value]));
                    setFilters(newFilters);
                    return;
                }
            }

            if (newFilters.has(typeToStore)) {
                const currValues = new Set(newFilters.get(typeToStore)); // Shallow copy of the Set
                if (currValues.has(option.value)) {
                    currValues.delete(option.value); // Remove the selected value
                    if (currValues.size === 0) {
                        newFilters.delete(typeToStore); // Remove filter if no values remain
                    } else {
                        newFilters.set(typeToStore, currValues); // Update the type with new values
                    }
                } else {
                    currValues.add(option.value); // Add new value to the filter
                    newFilters.set(typeToStore, currValues); // Update the type with the new value set
                }
            } else {
                newFilters.set(typeToStore, new Set([option.value])); // Add new filter type if it doesn't exist
            }
            setFilters(newFilters);
        };
    };

    const handleMenuClick = useCallback(
        (menu: string) => () => {
            // Reset activeMenu when dropdown is closed
            setActiveMenu(menu);
        },
        [],
    );

    return (
        <>
            {Array.from(filters.entries()).map(([type, values]) => {
                return (
                    <div key={type}>
                        <Filter
                            type={type}
                            values={values} // Convert Set to array
                            filters={filters}
                            setFilters={setFilters}
                            categories={categories}
                            topics={topics}
                            users={users}
                            customerGroups={customerGroups}
                            teams={teams}
                            channels={channels}
                            isSavedViewFilter={savedViewFilters.has(type)}
                            filterOptions={filterOptions}
                        />
                    </div>
                );
            })}
            <FilterDropdown
                filters={filters}
                filterOptions={filterOptions}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                handleItemSelect={handleFilterSelect}
                handleMenuClick={handleMenuClick}
                categories={categories}
                topics={topics}
                users={users}
                teams={teams}
                customerGroups={customerGroups}
                channels={channels}
            />
        </>
    );
};
