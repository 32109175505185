import { Badge } from "@/component/shadcn/ui/badge";
import { Card } from "@/component/shadcn/ui/card";
import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import {
    type GetTopicsPrefResponse,
    type GetTopicsResponse,
    type SaveTopicPrefsPayload,
    type Teams,
    type TopicPrefSettings,
    teamSchema,
} from "@/interfaces/serverData";
import { CaretRightIcon, PlusIcon } from "@radix-ui/react-icons";
import {
    Box,
    Button as RButton,
    Callout,
    Skeleton,
    Switch,
    Text,
} from "@radix-ui/themes";
import {
    type QueryObserverResult,
    type RefetchOptions,
    useQuery,
} from "@tanstack/react-query";
import { HotelIcon, UsersIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { generateTagsColumns } from "./DataTable/columns";
import type { TagsRowState } from "./DataTable/constants";
import { TagsDataTable } from "./DataTable/tags-data-table";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { useApi } from "@/interfaces/api";

const formSchema = z.object({
    tagName: z
        .string()
        .min(2, { message: "The tag name must be at least 2 characters." })
        .max(70, { message: "The tag name must be at most 70 characters." }),
    tagDescription: z.string().max(200, {
        message: "The tag name must be at most 200 characters.",
    }),
    color: z.string(),
    tagTeams: z.array(teamSchema).optional(),
});

interface TopicsProps {
    data: GetTopicsResponse[];
    teams: Teams[];
    rowState: Map<string, TagsRowState>;
    updateRowState: (id: string, newState: Partial<TagsRowState>) => void;
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<GetTopicsResponse[], Error>>;
    saveTag: (id: string, rowStateInfo: Partial<TagsRowState>) => void;
    loadingState: number;
    setLoadingState: React.Dispatch<React.SetStateAction<number>>;
    currentTeam?: Teams;
}

export const Topics: React.FC<TopicsProps> = ({
    data,
    teams,
    rowState,
    updateRowState,
    refetch,
    saveTag,
    loadingState,
    setLoadingState,
    currentTeam,
}) => {
    const { id } = useParams(); // team ID
    const api = useApi();

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [aiTagging, setAITagging] = useState<boolean>(true);
    const [addNewAITopics, setAddNewAITopics] = useState<boolean>(true);
    const [inheritedFromOrg, setInheritedFromOrg] = useState<boolean>(true);
    const { toast } = useToast();

    const { data: topicPrefs, refetch: refetch_prefs } = useQuery({
        queryKey: ["topicsPreferences"],
        queryFn: () => fetchTopicsPref(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    useEffect(() => {
        refetch_prefs();
    }, [id]);

    useEffect(() => {
        setAITagging(topicPrefs?.ai_tagging ?? true);
        setAddNewAITopics(topicPrefs?.add_new_ai_topics ?? true);
        setInheritedFromOrg(topicPrefs?.inherited_from_org ?? true);
    }, [topicPrefs]);

    const fetchTopicsPref = async (): Promise<TopicPrefSettings> => {
        let endpoint = URLS.serverUrl + API.getTopicsPref;
        if (id) {
            endpoint = `${URLS.serverUrl}${API.getTopicsPref}/team/${id}`;
        }
        const response = await api.get(endpoint, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            const topicsPref: GetTopicsPrefResponse = response.data.data;
            return {
                ai_tagging: topicsPref.ai_tagging,
                add_new_ai_topics: topicsPref.add_new_ai_topics,
                inherited_from_org:
                    (id !== undefined && topicsPref?.type === "Org") ?? true,
            };
        }
        // Default is true
        return {
            ai_tagging: true,
            add_new_ai_topics: true,
            inherited_from_org: true,
        };
    };

    const saveTopicsPref = (
        ai_tagging: boolean,
        add_new_ai_topics: boolean,
        team_id: string | undefined,
    ) => {
        const requestData: SaveTopicPrefsPayload = {
            ai_tagging: ai_tagging,
            add_new_ai_topics: add_new_ai_topics,
        };
        // Add new ai topics can only be on if ai tagging is on
        if (!ai_tagging) {
            requestData.add_new_ai_topics = false;
        }

        // Include the team id if it's not undefined
        if (team_id) {
            requestData.team_id = team_id;
        }

        api.post(URLS.serverUrl + API.saveTopicsPref, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    refetch_prefs();
                    console.log("updated topics preferences successfully");
                    toast({
                        title: "Saved Topics Preferences!",
                        description:
                            "The topics preferences have been saved successfully.",
                    });
                } else {
                    console.log("failed to get repository");
                    toast({
                        title: "Failed to save topics preferences",
                        description:
                            "Oops! Something's wrong. Please try again at a later time.",
                        variant: "destructive",
                    });
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                toast({
                    title: "Failed to save topics preferences",
                    description:
                        "Oops! Something's wrong. Please try again at a later time.",
                    variant: "destructive",
                });
            });
    };

    return (
        <div>
            <Box className="flex flex-col gap-4">
                <Card
                    className={`p-5 flex flex-col gap-2.5 shadow-none ${inheritedFromOrg && "bg-muted"}`}
                >
                    {inheritedFromOrg ? (
                        <div className="flex items-center gap-2 -my-0.5 text-[#5B5BD6] text-[9px]">
                            <Badge
                                variant="outline"
                                className="gap-1 py-1 px-1.5 font-normal"
                            >
                                <div className="flex items-center justify-center rounded-lg p-0.5 bg-blue3 border border-blue4 shadow-sm">
                                    <HotelIcon
                                        className="text-blue9"
                                        strokeWidth={1.5}
                                        size={9}
                                    />
                                </div>
                                Workspace
                            </Badge>
                            <div className="text-xs flex items-center gap-2 italic">
                                Edit Workspace's Preferences or
                                <RButton
                                    className="text-xs h-6 italic flex items-center gap-0.5 px-2 hover:cursor-pointer"
                                    variant="outline"
                                    onClick={() =>
                                        saveTopicsPref(false, false, id)
                                    }
                                >
                                    <PlusIcon className="w-3 h-3" />
                                    Create Team Specific Preferences
                                </RButton>
                            </div>
                        </div>
                    ) : (
                        <div className="-my-0.5 text-[9px]">
                            {id ? (
                                <Badge
                                    variant="outline"
                                    className="gap-1 py-1 px-1.5 font-normal"
                                >
                                    <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                        <UsersIcon
                                            className="text-red9"
                                            strokeWidth={1.5}
                                            size={9}
                                        />
                                    </div>
                                    Team
                                </Badge>
                            ) : (
                                <Badge
                                    variant="outline"
                                    className="gap-1 py-1 px-1.5 font-normal"
                                >
                                    <div className="flex items-center justify-center rounded-lg p-0.5 bg-blue3 border border-blue4 shadow-sm">
                                        <HotelIcon
                                            className="text-blue9"
                                            strokeWidth={1.5}
                                            size={9}
                                        />
                                    </div>
                                    Workspace
                                </Badge>
                            )}
                        </div>
                    )}
                    <div className="flex items-center gap-3 justify-between">
                        <div className="flex flex-col gap-0.5">
                            <div className="text-[14px] font-semibold">
                                AI Auto-Tagging
                            </div>
                            <div className="text-xs text-gray-500">
                                Automatically categorize and label incoming
                                issues with related tags.
                            </div>
                        </div>
                        {inheritedFromOrg ? (
                            <Switch checked={aiTagging} />
                        ) : (
                            <Switch
                                checked={aiTagging}
                                onCheckedChange={(checked) =>
                                    saveTopicsPref(checked, addNewAITopics, id)
                                }
                            />
                        )}
                    </div>
                    {aiTagging && (
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <CaretRightIcon className="w-6 h-6" />
                                <div className="flex flex-col gap-0.5">
                                    <div className="text-[14px] font-semibold">
                                        Add New AI Tags
                                    </div>
                                    <div className="text-xs text-gray-500">
                                        If the incoming issue does not fit any
                                        existing tags, allow the AI to create
                                        new tags to categorize the issue into.
                                    </div>
                                </div>
                            </div>
                            {inheritedFromOrg ? (
                                <Switch checked={addNewAITopics} />
                            ) : (
                                <Switch
                                    checked={addNewAITopics}
                                    onCheckedChange={(checked) => {
                                        saveTopicsPref(aiTagging, checked, id);
                                    }}
                                />
                            )}
                        </div>
                    )}
                </Card>

                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && (
                    <div className="flex flex-col items-end gap-2">
                        <TagsDataTable
                            data={data}
                            columns={generateTagsColumns(
                                rowState,
                                saveTag,
                                teams,
                                refetch,
                                setLoadingState,
                                currentTeam,
                                id,
                            )}
                        />
                    </div>
                )}
            </Box>
        </div>
    );
};
