import { cn } from "@/lib/utils";
import { Card } from "./shadcn/ui/card";


const AttributesBadge = ({
    children,
    onClick,
    className,
}: {
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void | Promise<void>;
    asChild?: boolean;
    className?: string;
}) => {
    return (
        <Card
            className={cn(
                "rounded-md shadow-sm shadow-[#f3f4f6] font-medium flex flex-row gap-1 px-1.5 py-1 items-center",
                className,
            )}
            onClick={onClick}>
            {children}
        </Card>
    );
};

export default AttributesBadge;
