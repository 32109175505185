import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import {
    Badge,
    Box,
    Button,
    Callout,
    Card,
    Flex,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as WebSvg } from "../../../images/integrations/web.svg";
import type { metadata } from "../IntegrationsManagementPage";
import IntegrationHeader from "./IntegrationHeader";
import { WebCardPopup } from "./WebCardComponent";

const WebIntegration = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [refreshDisabled, setRefreshDisabled] = useState<boolean>(true);

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    function handleReindex(integrationType: string, integrationUrl?: string) {
        if (integrationType === undefined || integrationType === "") {
            return;
        }

        const requestData = {
            type: integrationType,
            is_reindex: true,
        };

        api.post(URLS.serverUrl + API.indexIntegration, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (res) => {
            if (res.status === 200) {
            } else {
                // todo - need to handle this case.
            }
        });
        navigate("/admin/integrations/reindex");
    }

    useEffect(() => {
        const requestData = {
            types: ["Web"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;

                        if (orgInfo.Web?.[0]?.scopes) {
                            // enable for Web happens after scope is created (because on default we create web integration to create issues manually on our system)
                            setIntegrationEnabled(true);
                            for (const repo of orgInfo.Web[0].scopes) {
                                temp.scopes.push(repo.name);
                            }

                            if (orgInfo.Web?.[0]?.date) {
                                const date: Date = parseISO(
                                    orgInfo.Web?.[0]?.date ?? "",
                                );
                                const humanReadableDate = format(
                                    date,
                                    "MMMM dd, yyyy hh:mm a",
                                );
                                temp.indexedOn = humanReadableDate;

                                const currentTime: Date = new Date();

                                const difference: number =
                                    currentTime.getTime() - date.getTime();
                                const differenceInHours: number =
                                    difference / (1000 * 60 * 60);

                                const hasNotBeen24Hours: boolean =
                                    differenceInHours <= 24;
                                setRefreshDisabled(hasNotBeen24Hours);
                            }
                        }

                        setAdditionalMetadata(temp);
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const navigate = useNavigate();

    const webContinue = (
        integrationType: string,
        URL: string,
        isNew: boolean,
    ) => {
        navigate(
            `/admin/integrations/web?integration=${integrationType}&add=${isNew}&url=${URL}`,
        );
    };

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="Web"
                        description="Automatically search through and answer questions about your web docs"
                        SvgIcon={WebSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded && (
                            <WebCardPopup
                                disabled={integrationEnabled}
                                buttonName={
                                    integrationEnabled ? "Enabled" : "Enable"
                                }
                                index={webContinue}
                                isNew={false}
                            />
                        )}
                    </Flex>
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && integrationEnabled && (
                    <Flex direction={"column"} gap="2" mb="2">
                        <Text size={"2"}>Organizations Selected: </Text>
                        <Card style={{ padding: "20px" }}>
                            <Flex
                                direction="row"
                                gap="3"
                                align="center"
                                wrap="wrap"
                            >
                                {additionalMetadata.scopes.map((item) => (
                                    <Badge key={item}>{item}</Badge>
                                ))}{" "}
                                <WebCardPopup
                                    disabled={false}
                                    buttonName={"Add More Docs"}
                                    index={webContinue}
                                    buttonVariant={true}
                                    isNew={true}
                                />
                            </Flex>
                        </Card>
                        <Flex
                            direction="column"
                            style={{
                                justifyContent: "flex-end",
                                alignItems: "end",
                            }}
                            gap="3"
                        >
                            <Flex direction={"row"}>
                                <Text size={"1"}>
                                    Last Updated: {
                                        additionalMetadata.indexedOn
                                    }{" "}
                                </Text>
                            </Flex>

                            <Button
                                disabled={refreshDisabled}
                                onClick={() => {
                                    handleReindex("Web");
                                }}
                            >
                                Refresh
                            </Button>
                        </Flex>
                    </Flex>
                )}
            </Box>
        </div>
    );
};

export default WebIntegration;
