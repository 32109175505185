import { ElementDisplay } from "@/IssuesTable/FilterDropdownElement";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuSeparator,
    ContextMenuSub,
    ContextMenuSubContent,
    ContextMenuSubTrigger,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";
import type {
    Category,
    GetUserResponse,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import {
    OnboardingChecklistPayload,
    OnboardingChecklistStatus,
} from "@/interfaces/onboardingServerData";
import { getIconForType, getOptions } from "@/utilities/methods";
import {
    CheckCircledIcon,
    Cross2Icon,
    CrossCircledIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { PenIcon, PenOffIcon } from "lucide-react";

interface OnboardingContextMenuProps {
    children: React.ReactNode;
    issueId: string;
    dropdownOptions: string[];
    onSave: (
        type: string,
        payload: Partial<OnboardingChecklistPayload>,
    ) => void;
    users: GetUserResponse[];
    teams: Teams[];
    filters: Map<string, Set<string>>;
    enabled: boolean;
    completed: boolean;
    editing: boolean;
    setEdit: (edit: boolean) => void;
    onDelete: () => void;
}

export function OnboardingContextMenu({
    children,
    issueId,
    dropdownOptions,
    onSave,
    users,
    teams,
    filters,
    enabled,
    completed,
    editing,
    setEdit,
    onDelete,
}: OnboardingContextMenuProps) {
    return (
        <ContextMenu modal={false}>
            <ContextMenuTrigger>{children}</ContextMenuTrigger>
            <ContextMenuContent className="w-60">
                <ContextMenuItem
                    inset
                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                    onClick={(event) => {
                        event.stopPropagation();
                        onSave("task_status", {
                            task_status: !completed
                                ? OnboardingChecklistStatus.Completed
                                : OnboardingChecklistStatus.NotStarted,
                        });
                    }}
                >
                    {completed ? (
                        <CrossCircledIcon className="w-3.5 h-3.5 text-red9" />
                    ) : (
                        <CheckCircledIcon className="w-3.5 h-3.5 text-green9" />
                    )}
                    {completed ? (
                        <p className="text-xs text-red9">Mark as not started</p>
                    ) : (
                        <p className="text-xs text-green9">Mark as completed</p>
                    )}
                </ContextMenuItem>

                <ContextMenuItem
                    inset
                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                    onClick={(event) => {
                        event.stopPropagation();
                        setEdit(!editing);
                    }}
                >
                    {editing ? (
                        <PenOffIcon strokeWidth={1.5} className="w-3.5 h-3.5" />
                    ) : (
                        <PenIcon className="w-3.5 h-3.5" strokeWidth={1.5} />
                    )}
                    {editing ? <p>Cancel Edit</p> : <p>Edit</p>}
                </ContextMenuItem>

                <ContextMenuSeparator />
                <AlertDialog>
                    <AlertDialogTrigger asChild>
                        <ContextMenuItem
                            inset
                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                            onSelect={(e) => e.preventDefault()}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <TrashIcon className="w-3.5 h-3.5" />
                            Delete
                        </ContextMenuItem>
                    </AlertDialogTrigger>
                    <AlertDialogContent onClick={(e) => e.stopPropagation()}>
                        <div>
                            <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                <AlertDialogTitle>
                                    Are you absolutely sure?
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                    {
                                        "This action cannot be undone. This will permanently delete this item."
                                    }
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                        </div>
                        <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                            <AlertDialogCancel
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                Cancel
                            </AlertDialogCancel>
                            <AlertDialogAction
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDelete();
                                }}
                            >
                                Delete
                            </AlertDialogAction>
                            <AlertDialogCancel
                                className="shadow-none absolute top-2 right-2 px-2 py-0 "
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <Cross2Icon />
                            </AlertDialogCancel>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </ContextMenuContent>
        </ContextMenu>
    );
}
