import type { ComposerFormProps } from "@liveblocks/react-ui/primitives";
import "./styles.css";
import { Card } from "@/component/shadcn/ui/card";
import {
    type Account,
    EditorActionType,
    EditorType,
    type HistoryResponse,
    type PublishMessage,
    type ScopeResponse,
    type SendMessageResponse,
    type Ticket,
    type UploadedFile,
    type UploadedFileWithMetadata,
    type UserResponse,
} from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";

import { useToast } from "@/component/shadcn/ui/use-toast";
import {
    type EmailRecipient,
    FromPluginTop,
} from "@/component/textEditor/FromPluginTop";
import { API, URLS } from "@/constant";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import slackifyMarkdown from "slackify-markdown";

import { Editor } from "@/component/textEditor/Editor";
import { ReplyFromPlugin } from "@/component/textEditor/ReplyFromPlugin";
import { useApiFormData } from "@/interfaces/api";

interface ComposerProps extends ComposerFormProps {
    originalTicket: Ticket;
    placeholder?: string;
    submit?: string;
    source_specific_id: string;
    source: string;
    update: boolean;
    setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    refetchTicketData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    customerUserInfo?: UserResponse;
    account?: Account;
    gmailScopes?: ScopeResponse[];
    signature?: string;
}

export function MyComposer({
    originalTicket,
    className,
    placeholder = "Write a comment…",
    submit = "Send",
    source,
    source_specific_id,
    title,
    update,
    setUpdate,
    refetchThreadData,
    refetchTicketData,
    customerUserInfo,
    account,
    gmailScopes,
    signature,
    ...props
}: ComposerProps) {
    const queryClient = useQueryClient();
    const apiFormData = useApiFormData();
    const [loading, setLoading] = useState(false);

    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    useEffect(() => {
        authInfoRef.current = authInfo;
    }, [authInfo]);
    const { toast } = useToast();

    const [isSendDisabled, setIsSendDisabled] = useState(false);

    const [uploadedFiles, setUploadedFiles] = useState<
        (UploadedFile | UploadedFileWithMetadata)[]
    >([]);
    const handleFileUpload = (
        file_name: string,
        file_type: string,
        file_size: number,
        file: File,
    ) => {
        setUploadedFiles((prevFiles) => [
            ...prevFiles,
            {
                file_name: file_name,
                file_type: file_type,
                file_size: file_size,
                file: file,
                type: "file",
            },
        ]);
    };

    const handleImageUpload = (src: string, altText: string) => {
        setUploadedFiles((prevFiles) => [
            ...prevFiles,
            { src: src, alt: altText, type: "image" },
        ]);
    };

    const handleDeleteFile = (
        fileToDelete: UploadedFile | UploadedFileWithMetadata,
    ) => {
        const newUploadedFiles = uploadedFiles.filter((file) => {
            if (file.type === "image") {
                return (
                    (file as UploadedFile).alt !==
                    (fileToDelete as UploadedFile).alt
                );
            } else if (file.type === "file") {
                return (
                    (file as UploadedFileWithMetadata).file_name !==
                    (fileToDelete as UploadedFileWithMetadata).file_name
                );
            }
            return true;
        });
        setUploadedFiles(newUploadedFiles);
        if (newUploadedFiles.length === 0) {
            setIsSendDisabled(false);
        }
    };

    const [isToggled, setIsToggled] = useState(false);

    const [properties, setProperties] = useState<Map<string, EmailRecipient[]>>(
        new Map([
            ["to", originalTicket.gmail_integration_fields?.to ?? []],
            ["cc", originalTicket.gmail_integration_fields?.cc ?? []],
            ["subject", originalTicket.gmail_integration_fields?.subject ?? []],
        ]),
    );

    const [propertiesOptions, setPropertiesOptions] = useState<
        Map<string, ScopeResponse[]>
    >(new Map());

    useEffect(() => {
        setPropertiesOptions(
            new Map([
                [
                    "from",
                    [
                        ...(gmailScopes?.filter((scope) =>
                            scope.key.includes(
                                originalTicket?.integration_id ?? "",
                            ),
                        ) ?? []),
                    ],
                ],
            ]),
        );

        setProperties((prev) =>
            prev.set("from", [
                {
                    email:
                        gmailScopes?.find(
                            (scope) =>
                                scope.key === originalTicket?.integration_id,
                        )?.name ?? "",
                    name:
                        gmailScopes?.find(
                            (scope) =>
                                scope.key === originalTicket?.integration_id,
                        )?.name ?? "",
                },
            ]),
        );
    }, [gmailScopes, originalTicket?.integration_id]);

    async function sendMessage({
        type,
        content,
        files,
        recipient,
        integration_id,
        subject,
        new_source_specific_id, // used to track source specific id of a new ticket created from "send new email"
    }: {
        type: EditorActionType;
        content: string;
        files: (UploadedFile | UploadedFileWithMetadata)[];
        recipient?: string;
        integration_id?: string;
        subject?: string;
        new_source_specific_id?: string;
    }): Promise<SendMessageResponse> {
        if (!authInfoRef.current.isLoggedIn) {
            throw new Error("User not logged in");
        }

        const userId = authInfoRef.current.user?.userId;
        if (userId === undefined) {
            throw new Error("User ID not found");
        }

        if (
            type === EditorActionType.Reply &&
            (source === "Slack" || source === "CommunitySlack")
        ) {
            content = slackifyMarkdown(content);
        }

        const images: UploadedFile[] = files?.filter(
            (file) => file.type === "image",
        ) as UploadedFile[];

        const filesToUpload = files?.filter((file) => {
            return file.type === "file";
        });
        const formData = new FormData();

        // Append non-file fields
        for (const fileData of filesToUpload) {
            formData.append(
                "files",
                (fileData as UploadedFileWithMetadata).file,
                (fileData as UploadedFileWithMetadata).file_name,
            );
        }

        let msg_source = undefined;
        let msg_source_specific_id = undefined;
        let msg_integration_id = originalTicket.integration_id;
        let metadata = undefined;
        switch (type) {
            case EditorActionType.Reply:
                msg_source = source;
                msg_source_specific_id = source_specific_id;
                break;
            case EditorActionType.InternalReply:
                msg_source = "InternalWeb";
                msg_source_specific_id = originalTicket.id;
                metadata = JSON.stringify({
                    new_source_specific_id: new_source_specific_id,
                });
                break;
            case EditorActionType.NewEmail:
                msg_source = "Gmail";
                msg_integration_id =
                    integration_id ?? originalTicket.integration_id;
                break;
            default:
                console.log("not a supported Editor Action Type");
                setLoading(false);
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
                return Promise.reject(
                    new Error("Unsupported editor action type"),
                );
        }
        const requestData: PublishMessage = {
            message: content,
            source: msg_source,
            user: userId,
            source_specific_id: msg_source_specific_id,
            integration_id: msg_integration_id,
            message_title: title,
            images: images,
            gmail_integration_fields: {
                to: properties.get("to") ?? [],
                cc: properties.get("cc") ?? [],
                bcc: properties.get("bcc") ?? [],
                subject: properties.get("subject") ?? [],
                from: properties.get("from") ?? [],
            },
            metadata: metadata,
        };
        if (type === EditorActionType.NewEmail) {
            requestData.recipient = recipient;
            if (subject) {
                requestData.subject = subject;
            }
        }
        console.log("request data is", requestData);
        formData.append("data", JSON.stringify(requestData));

        const response = await apiFormData.post(
            URLS.serverUrl + API.publishMessage,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        );
        const id = response.data?.data?.id ?? "";
        return { id, url: "" };
    }

    const publishMessageMutation = useMutation({
        mutationFn: sendMessage,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["threadData"] });
            setTimeout(() => {
                setUpdate(!update);
                setLoading(false);
                refetchThreadData();
                refetchTicketData();
            }, 2000);
            toast({
                title: "Message sent",
                description: "Message sent successfully",
            });
        },
        onError: (error) => {
            setLoading(false);
            console.error("Error publishing message:", error);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
        onSettled: () => {
            setLoading(false);
            setIsSendDisabled(false);
            setUploadedFiles([]);
        },
    });

    const handleSend = (
        mrkdwn: string,
        files: (UploadedFile | UploadedFileWithMetadata)[],
        type: EditorActionType,
        source?: string,
        scope?: ScopeResponse,
        recipient?: string,
        integration_id?: string,
        subject?: string,
        new_source_specific_id?: string,
    ): Promise<SendMessageResponse> => {
        if (mrkdwn === undefined || !isSendDisabled) {
            return Promise.reject(new Error("Invalid message"));
        }
        setLoading(true);

        return new Promise((resolve, reject) => {
            publishMessageMutation.mutate(
                {
                    type,
                    content: mrkdwn,
                    files,
                    recipient,
                    integration_id,
                    subject,
                    new_source_specific_id,
                },
                {
                    onSuccess: (data) => resolve(data),
                    onError: (error) => reject(error),
                },
            );
        });
    };

    const [isReplyFromPluginOpen, setIsReplyFromPluginOpen] = useState(false);

    // Add a ref to measure the ReplyFromPlugin height
    const replyPluginRef = useRef<HTMLDivElement>(null);
    const [pluginHeight, setPluginHeight] = useState(0);

    // Add useEffect to measure and update height when the plugin opens
    useEffect(() => {
        if (isReplyFromPluginOpen && replyPluginRef.current) {
            setPluginHeight(replyPluginRef.current.offsetHeight);
        } else {
            setPluginHeight(0);
        }
    }, [isReplyFromPluginOpen]);

    return (
        <>
            <div className="flex flex-col mt-3 py-1">
                <div className="relative">
                    {source === "Gmail" && (
                        <div className="absolute top-[-22px] left-0">
                            <FromPluginTop
                                initialFrom={{
                                    email:
                                        originalTicket.source_unique_name ??
                                        gmailScopes?.find(
                                            (scope) =>
                                                scope.key ===
                                                originalTicket?.integration_id,
                                        )?.name ??
                                        "",
                                    name: "",
                                }}
                                setIsReplyFromPluginOpen={
                                    setIsReplyFromPluginOpen
                                }
                                isReplyFromPluginOpen={isReplyFromPluginOpen}
                                gmailEnabled={source === "Gmail"}
                            />
                        </div>
                    )}
                    <Card className="rounded-tr-lg rounded-bl-lg rounded-br-lg focus-within:shadow-md focus-within:outline-0.5 focus-within:outline-offset-0 flex flex-col px-2 mb-2 shadow-sm shadow-[#f3f4f6] color-[#f3f4f6] border relative">
                        {source === "Gmail" && isReplyFromPluginOpen && (
                            <div
                                ref={replyPluginRef}
                                className="w-full bg-white rounded-t-lg"
                            >
                                <ReplyFromPlugin
                                    properties={properties}
                                    setProperties={setProperties}
                                    propertiesOptions={propertiesOptions}
                                />
                            </div>
                        )}
                        <Editor
                            className="max-h-80 overflow-scroll scrollbar-white"
                            enableAIResponse={true}
                            handleSubmit={handleSend}
                            isToggled={isToggled}
                            setIsSendDisabled={setIsSendDisabled}
                            isSendDisabled={isSendDisabled}
                            setIsToggled={setIsToggled}
                            loading={loading}
                            handleFileUpload={handleFileUpload}
                            handleImageUpload={handleImageUpload}
                            handleDeleteFile={handleDeleteFile}
                            uploadedFiles={uploadedFiles}
                            source={source}
                            editorType={EditorType.TicketReply}
                            customerUserInfo={customerUserInfo}
                            customerAccount={account}
                            gmailScopes={gmailScopes}
                            originalTicket={originalTicket}
                            signature={signature}
                        />
                    </Card>
                </div>
            </div>
        </>
    );
}
