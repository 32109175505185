import { Card } from "@/component/shadcn/ui/card";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    AccountsLabel,
    GetUserResponse,
    IconEntry,
    ScopeResponse,
} from "@/interfaces/serverData";
import { TeamBadges } from "@/pages/WorkspacePreferences/TeamBadges";
import { getBadgeForAccountsLabel } from "@/utilities/methods";
import { AvatarIcon, CalendarIcon } from "@radix-ui/react-icons";
import { Badge, DataList } from "@radix-ui/themes";
import type { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import React from "react";
import { integrationBackEndDataMappingToSvg } from "../Integrations/constant";
import { PrefCommDropdown } from "./PrefCommDropdown";

export interface AttributesProps {
    orgID: string;
    userID: string;
    account: Account;
    accountType: "Company" | "Customer";
    contacts: Account[];
    updateData: (
        company: boolean,
        contacts: boolean,
        tickets: boolean,
    ) => Promise<void>;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    channelsQuery: UseQueryResult<Map<string, ScopeResponse[]>, Error>;
}

export const AttributesPage = ({
    orgID,
    userID,
    account,
    accountType,
    contacts,
    updateData,
    usersQuery,
    channelsQuery,
}: AttributesProps) => {
    const api = useApi();
    const foundUser: GetUserResponse | undefined = (usersQuery?.data ?? []).find(
        (user) => user.id === account?.assignee_user_id,
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;

    const renewalDate = account?.renewal_date
        ? new Date(account?.renewal_date)
        : new Date();
    const formattedRenewalDate = renewalDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        timeZone: "UTC"
    });

    const [serviceIcons, setServiceIcons] = useState<Set<IconEntry>>(
        new Set<IconEntry>(),
    );
    useEffect(() => {
        const serviceNames = Object.keys(account?.metadata ?? {});
        const serviceIcons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        serviceNames.forEach((serviceName) => {
            const IconComponent =
                integrationBackEndDataMappingToSvg.get(serviceName);
            if (IconComponent) {
                serviceIcons.add({
                    Component: IconComponent,
                    props: {
                        width: 18,
                        height: 18,
                        style: {
                            marginLeft: "-2px",
                            marginRight: "-2px",
                        },
                    },
                });
            }
        });
        setServiceIcons(serviceIcons);
    }, [account?.metadata]);


    return (
        <div className="flex items-start gap-5 p-3">
            <Card className="w-full rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none py-5 px-7 flex flex-col gap-3">
                <div className="text-md font-semibold">Default Attributes</div>
                <DataList.Root size="3" trim={"both"} className="my-2 mb-4">
                    <DataList.Item
                        align="center"
                        className="w-full flex flex-wrap border border-gray-300 m-0 -my-2.5"
                    >
                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                            Assignee
                        </DataList.Label>
                        <DataList.Value>
                            {account.assignee_user_id && account.assignee_user_id !== "" && pictureURL ? (
                                <div className="flex items-center gap-2 text-sm">
                                    <div className="lb-avatar rounded-lg w-5 h-5 mr-1">
                                        <img
                                            className="lb-avatar-image"
                                            src={pictureURL}
                                            alt={userName}
                                        />
                                        <span>{userName ?? ""}</span>
                                    </div>
                                    {userName}
                                </div>
                            ) : (
                                <div className="flex items-center gap-2 text-sm">
                                    <div className="lb-avatar rounded-lg w-5 h-5 mr-1">
                                        <AvatarIcon className="w-5 h-5" />
                                    </div>
                                    No Assignee
                                </div>
                            )}
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item
                        align="center"
                        className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                    >
                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                            Preferred
                            <br />
                            Communication:
                        </DataList.Label>
                        <DataList.Value>
                            <PrefCommDropdown
                                company={account}
                                contactsCombinedData={contacts}
                                channelsQuery={channelsQuery}
                                updateData={updateData}
                            />
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item
                        align="center"
                        className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                    >
                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                            Contract Value
                        </DataList.Label>
                        <DataList.Value className="text-sm">
                            {(account?.contract_value || account?.contract_type) && (
                                <Badge
                                    color="mint"
                                    size="2"
                                    radius="medium"
                                    variant="soft"
                                    className="text-xs items-center"
                                >
                                    <p>
                                        {account.contract_type && account.contract_value
                                            ? `${account.contract_type} | $${account.contract_value}`
                                            : account.contract_type
                                                ? account.contract_type
                                                : account.contract_value
                                                    ? `$${account.contract_value}`
                                                    : null}
                                    </p>
                                </Badge>

                            )}
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item
                        align="center"
                        className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                    >
                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                            Renewal Date
                        </DataList.Label>
                        <DataList.Value className="text-sm">
                            {account?.renewal_date && (
                                <div className="flex items-center gap-2">
                                    <CalendarIcon />
                                    {formattedRenewalDate}
                                </div>
                            )}
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item
                        align="center"
                        className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                    >
                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                            Labels
                        </DataList.Label>
                        <DataList.Value>
                            {account?.labels?.map((label: AccountsLabel) =>
                                getBadgeForAccountsLabel(label),
                            )}
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item
                        align="center"
                        className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                    >
                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                            Sources
                        </DataList.Label>
                        <DataList.Value>
                            {account?.metadata && (
                                <div className="flex flex-row mx-1">
                                    {Array.from(serviceIcons).map((icon) =>
                                        React.createElement(
                                            icon.Component,
                                            icon.props,
                                        ),
                                    )}
                                </div>
                            )}
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item
                        align="center"
                        className="w-full flex flex-wrap border-b border-l border-r border-gray-300 m-0 -my-2.5"
                    >
                        <DataList.Label className="text-sm w-[170px] border-r border-gray-300 p-2.5 py-3.5">
                            Teams
                        </DataList.Label>
                        <DataList.Value>
                            <TeamBadges
                                teams={account?.teams ?? []}
                                defaultIsWorkspace={false}
                            />
                        </DataList.Value>
                    </DataList.Item>
                </DataList.Root>
            </Card>
            <Card className="w-full rounded-lg hover:outline-0.5 hover:outline-offset-0 shadow-none py-5 px-7 flex flex-col gap-3">
                <div className="text-md font-semibold">Custom Attributes</div>
                {account?.additional_properties ? (
                    <DataList.Root size="3" trim={"both"} className="my-2 mb-4">
                        {Object.entries(account?.additional_properties).map(
                            ([key, value], index) => (
                                <DataList.Item
                                    align="center"
                                    className={`w-full flex border-b border-l border-r border-gray-300 m-0 -my-2.5 ${index === 0 ? "border-t border-gray-300" : ""}`}
                                    key={key}
                                >
                                    <DataList.Label className="text-sm w-[250px] border-r border-gray-300 p-2.5">
                                        {key}
                                    </DataList.Label>
                                    <DataList.Value className="text-sm py-2.5 px-3 flex-1 min-w-0 break-words whitespace-normal overflow-hidden">
                                        {String(value)}
                                    </DataList.Value>
                                </DataList.Item>
                            ),
                        )}
                    </DataList.Root>
                ) : (
                    <div className="text-sm">
                        No additional properties.
                    </div>
                )}
            </Card>
        </div>
    );
};
