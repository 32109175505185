import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import type { GetTopicsResponse, Insight } from "@/interfaces/serverData";
import { arraysAreEqual } from "@/utilities/methods";
import { CaretDownIcon, CaretUpIcon } from "@radix-ui/react-icons";
import { memo, useEffect, useState } from "react";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import { InsightDisplay } from "./InsightDisplay";
import TopInsightCard from "./TopInsightCard";
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";

const areEqual = (prevProps: TopInsightsProps, nextProps: TopInsightsProps) => {
    return (
        arraysAreEqual(
            prevProps.suggestedInsights,
            nextProps.suggestedInsights,
        ) &&
        prevProps.issuesCount === nextProps.issuesCount &&
        prevProps.userID === nextProps.userID &&
        prevProps.handleSaveAIGeneratedInsight ===
            nextProps.handleSaveAIGeneratedInsight &&
        prevProps.updateInsightState === nextProps.updateInsightState &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.setOnDashboard === nextProps.setOnDashboard &&
        prevProps.generatedIsOpen === nextProps.generatedIsOpen &&
        prevProps.setGeneratedIsOpen === nextProps.setGeneratedIsOpen &&
        prevProps.teamID === nextProps.teamID
    );
};

interface TopInsightsProps {
    suggestedInsights: Insight[];
    issuesCount: number;
    userID: string;
    handleSaveAIGeneratedInsight: (
        insight: Insight,
        teamID?: string,
    ) => Promise<void>;
    updateInsightState: (id: string, newState: Partial<Insight>) => void;
    topicsMap: Map<string, GetTopicsResponse>;
    setOnDashboard: React.Dispatch<React.SetStateAction<boolean>>;
    generatedIsOpen: boolean;
    setGeneratedIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    teamID?: string;
}

function TopInsights({
    suggestedInsights,
    issuesCount,
    userID,
    handleSaveAIGeneratedInsight,
    updateInsightState,
    topicsMap,
    setOnDashboard,
    generatedIsOpen,
    setGeneratedIsOpen,
    teamID,
}: TopInsightsProps) {
    const [popUpHeight, setPopUpHeight] = useState<number>(
        window.innerHeight * 0.9,
    );
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedInsight, setSelectedInsight] = useState<Insight | null>(
        null,
    );

    const handleInsightClick = (insight: Insight) => {
        setSelectedInsight(insight);
    };

    // Update the pop upheight when the window resizes
    useEffect(() => {
        const handleResize = () => setPopUpHeight(window.innerHeight * 0.9);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <Card className="m-2 py-1 rounded-sm w-full flex flex-col relative">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1 px-7 py-2 text-[15px] font-semibold">
                        Suggested AI Insights{" "}
                        <img
                            src={SparklesIcon}
                            alt=""
                            className="h-7 w-7 rounded p-1"
                        />
                    </div>
                    {generatedIsOpen ? (
                        <Button
                            variant="ghost"
                            className="text-xs px-1 absolute top-2 right-2"
                            onClick={() => setGeneratedIsOpen(false)}
                        >
                            <CaretUpIcon className="w-5 h-5" />
                        </Button>
                    ) : (
                        <Button
                            variant="ghost"
                            className="text-xs px-1 absolute top-2 right-2"
                            onClick={() => setGeneratedIsOpen(true)}
                        >
                            <CaretDownIcon className="w-5 h-5" />
                        </Button>
                    )}
                </div>
                {generatedIsOpen &&
                    suggestedInsights.length === 0 &&
                    (issuesCount === 0 ? (
                        <div className="flex items-center gap-2 px-7 pb-1 text-xs flex items-center rounded-lg w-full">
                            No recent data available, please create issues or
                            index past issues.
                        </div>
                    ) : (
                        <div className="flex items-center gap-2 px-7 pb-1 text-xs flex items-center rounded-lg w-full">
                            Generating AI Insights{" "}
                            <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                        </div>
                    ))}
                {generatedIsOpen && (
                    <div className="flex flex-col gap-1">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mx-6">
                            {suggestedInsights.length > 0 &&
                                suggestedInsights
                                    .slice(0, 3) // Show top 3 AI generated insights
                                    .map((insight) => (
                                        <DialogTrigger
                                            key={insight.id}
                                            onClick={() =>
                                                handleInsightClick(insight)
                                            }
                                        >
                                            <div>
                                                <TopInsightCard
                                                    insight={insight}
                                                    handleSaveAIGeneratedInsight={
                                                        handleSaveAIGeneratedInsight
                                                    }
                                                    issues={[]}
                                                    userID={userID}
                                                    topicsMap={topicsMap}
                                                    teamID={teamID}
                                                    popUpHeight={popUpHeight}
                                                />
                                            </div>
                                        </DialogTrigger>
                                    ))}
                            <DialogContent className="max-w-[1200px] max-h-[750px] w-full h-full">
                                {selectedInsight && (
                                    <InsightDisplay
                                        insight={selectedInsight}
                                        userID={userID}
                                        insightSelectedIsSaved={false}
                                        topicsMap={topicsMap}
                                        handleSaveAIGeneratedInsight={
                                            handleSaveAIGeneratedInsight
                                        }
                                        issueCounts={
                                            selectedInsight.issue_counts
                                        }
                                        trend={selectedInsight.trend}
                                        teamID={teamID}
                                        setDialogClose={() =>
                                            setDialogOpen(false)
                                        }
                                    />
                                )}
                            </DialogContent>
                        </div>
                        <div className="flex justify-end">
                            <Button
                                onClick={() => setOnDashboard(false)}
                                variant="ghost"
                                className="mx-2 -mt-2 pt-0 hover:bg-background text-gray-600 hover:text-gray-900 text-[12px]"
                            >
                                Generate More AI Insights...
                            </Button>
                        </div>
                    </div>
                )}
            </Card>
        </Dialog>
    );
}

export default memo(TopInsights, areEqual);
