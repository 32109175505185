import { PlusIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import FernIcon from "../../../images/integrations/fern.svg";
import WebIcon from "../../../images/integrations/web.svg";
import MintlifyIcon from "../../../images/mintlify.png";

import DialogTriggerButton from "@/component/buttons/DialogTriggerButton";
import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogTitle,
} from "@/component/shadcn/ui/dialog";
import { DialogTrigger } from "@/component/shadcn/ui/dialog";
import { Input } from "@/component/shadcn/ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import { Text } from "@radix-ui/themes";
interface IntegrationProps {
    index: (integrationType: string, jiraUrl: string, isNew: boolean) => void; // separate function for jira, due to addition of URL
    disabled: boolean;
    buttonName: string;
    buttonVariant?: boolean;
    isNew: boolean; // true if we're adding secondary or more links.
}

export const WebCardPopup = (props: IntegrationProps) => {
    const [open, setOpen] = useState(false);

    const [inputValue, setInputValue] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isValidClick, setIsValidClick] = useState(true);

    const [selectedOption, setSelectedOption] = useState("mintlify");

    const validateInput = (value: string): boolean => {
        if (selectedOption === "other") {
            if (value.includes("grammarly")) {
                return true; // forcing it to just auto include the link here.
            } else if (value.includes("supertokens")) {
                return true;
            } else if (value.includes("easypost")) {
                return true;
            } else if (value.includes("docs")) {
                return true;
            } else if (value.includes("latchel")) {
                return true;
            } else if (value.includes("snaptrade")) {
                return true;
            } else if (value.includes("hellobrigit")) {
                return true;
            } else if (value.includes("pando")) {
                return true;
            } else {
                return false;
            }
        } else {
            if (
                value.includes("docs") ||
                value.includes("reference") ||
                value.includes("mintlify")
            ) {
                return true;
            } else {
                return false;
            }
        }
    };

    const handleInputChange = (value: string) => {
        setInputValue(value);
        if (validateInput(value)) {
            setIsValid(true);
            setIsValidClick(true);
        } else {
            setIsValid(false);
        }
    };

    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
    };

    const handleOnClick = () => {
        if (validateInput(inputValue)) {
            setIsValidClick(true);
            props.index(
                selectedOption.charAt(0).toUpperCase() +
                    selectedOption.slice(1),
                inputValue,
                props.isNew,
            );
            setOpen(false);
        } else {
            setIsValidClick(false);
        }
    };

    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger>
                    {props.buttonVariant ? (
                        <Button
                            style={{ width: "100%" }}
                            variant={"outline"}
                            size="sm"
                        >
                            <PlusIcon width="15" height="15" />
                            {props.buttonName}
                        </Button>
                    ) : (
                        <DialogTriggerButton disabled={props.disabled}>
                            {props.buttonName}
                        </DialogTriggerButton>
                    )}
                </DialogTrigger>

                <DialogContent>
                    <DialogTitle>Add Documentation</DialogTitle>
                    <DialogDescription className="mb-4">
                        Choose one of the options below to let Assembly crawl
                        through your docs.
                    </DialogDescription>

                    <div className="flex flex-col gap-6">
                        <div className="flex flex-row gap-1 items-center">
                            <Select
                                defaultValue="mintlify"
                                onValueChange={handleOptionChange}
                                value={selectedOption}
                            >
                                <SelectTrigger>
                                    <SelectValue />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="mintlify">
                                        <div className="flex flex-row gap-3 items-center">
                                            <img
                                                src={MintlifyIcon}
                                                alt="Mintlify"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                }}
                                            />
                                            Mintlify
                                        </div>
                                    </SelectItem>
                                    <SelectItem value="fern">
                                        <div className="flex flex-row gap-3 items-center">
                                            <img
                                                src={FernIcon}
                                                alt="Fern"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                }}
                                            />
                                            Fern
                                        </div>
                                    </SelectItem>
                                    <SelectItem value="other">
                                        <div className="flex flex-row gap-3 items-center">
                                            <img
                                                src={WebIcon}
                                                alt="Other"
                                                style={{
                                                    width: "20px",
                                                    height: "20px",
                                                }}
                                            />
                                            Other
                                        </div>
                                    </SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <label htmlFor="documentation-link">
                            <Text as="div" size="2" mb="1" weight="bold">
                                Documentation Link
                            </Text>
                            <Input
                                defaultValue=""
                                placeholder="https://docs.askassembly.app/"
                                value={inputValue}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) =>
                                    handleInputChange(
                                        (
                                            event.currentTarget as HTMLInputElement
                                        ).value,
                                    )
                                }
                                color={isValid ? "iris" : "red"}
                            />
                        </label>
                    </div>

                    <div className="flex flex-row gap-3 mt-4 justify-end">
                        <DialogClose>
                            <Button variant="outline" color="gray">
                                Cancel
                            </Button>
                        </DialogClose>
                        <Button
                            onClick={handleOnClick}
                            color={isValidClick ? "iris" : "red"}
                        >
                            Save
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};
