import { Checkbox } from "@/component/shadcn/ui/checkbox";

import { Input } from "@/component/shadcn/ui/input";

import type { GetUserResponse } from "@/interfaces/serverData";
import type { ColumnDef } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { OnboardingAssigneeDropdown } from "../AssigneeDropdown";
import {
    OnboardingChecklistStatus,
    onboardingStatuses,
    OnboardingChecklist,
} from "@/interfaces/onboardingServerData";
import CalendarPopup from "@/design/CalendarPopup";
import { StatusDropdown } from "../StatusDropdown";

export type OnboardingItem = {
    id: string;
    name: string;
    description: string;
    status: OnboardingChecklistStatus;
    assignee: string;
    start_date: string;
    end_date: string;
};

const inputFocusStyle = "focus:ring-2 focus:ring-blue-500 focus:ring-offset-0";

export const dataSample: OnboardingItem[] = [
    {
        id: "task-001",
        name: "Initial Setup",
        description: "Set up development environment and tools",
        status: OnboardingChecklistStatus.Completed,
        assignee: "js",
        start_date: "2024-01-01",
        end_date: "2024-01-03",
    },
    {
        id: "task-002",
        name: "Requirements Gathering",
        description: "Collect and document project requirements",
        status: OnboardingChecklistStatus.InProgress,
        assignee: "sw",
        start_date: "2024-01-04",
        end_date: "2024-01-10",
    },
    {
        id: "task-003",
        name: "Database Design",
        description: "Design database schema and relationships",
        status: OnboardingChecklistStatus.NotStarted,
        assignee: "mj",
        start_date: "2024-01-11",
        end_date: "2024-01-15",
    },
    {
        id: "task-004",
        name: "UI Mockups",
        description: "Create user interface mockups",
        status: OnboardingChecklistStatus.Paused,
        assignee: "eb",
        start_date: "2024-01-05",
        end_date: "2024-01-12",
    },
    {
        id: "task-005",
        name: "Testing Plan",
        description: "Develop testing strategy and test cases",
        status: OnboardingChecklistStatus.NA,
        assignee: "js",
        start_date: "2024-01-16",
        end_date: "2024-01-20",
    },
];

const TableInputCalendarCell = ({
    getValue,
    row,
    column,
    table,
    deadline = false,
}: {
    getValue: () => any;
    row: { index: number; original: OnboardingChecklist };
    column: { id: string };
    table: any;
    deadline?: boolean;
}) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    // Get the start date if this is a deadline cell
    let minDate = deadline ? row.original.start_date : undefined;
    if (typeof minDate === "string") {
        minDate = new Date(minDate);
    }
    return (
        <div
            className={`w-full h-full flex items-center px-0 mx-0 ${inputFocusStyle}`}
        >
            <CalendarPopup
                date={value}
                onSelect={(date) => {
                    setValue(date);
                    table.options.meta?.updateData(row.index, column.id, date);
                }}
                buttonText="Select date"
                className={`w-full h-full text-xs border-none rounded-none bg-transparent !px-2 !justify-start hover:bg-transparent`}
                iconColor={deadline ? "text-red-500" : "slate-200"}
                startDate={minDate}
            />
        </div>
    );
};

const TableInputCell = ({
    getValue,
    row,
    column,
    table,
}: {
    getValue: () => any;
    row: { index: number };
    column: { id: string };
    table: any;
}) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);
    const onBlur = () => {
        table.options.meta?.updateData(row.index, column.id, value);
    };
    return (
        <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
            className={`text-xs w-full h-10 px-2 bg-transparent border-none rounded-none ${inputFocusStyle}`}
        />
    );
};

export function generateOnboardingColumns(
    users: GetUserResponse[],
    userID: string,
): ColumnDef<OnboardingChecklist>[] {
    const AssigneeDropdownCell = ({
        getValue,
        row,
        column,
        table,
    }: {
        getValue: () => any;
        row: { index: number };
        column: { id: string };
        table: any;
    }) => {
        const [assignee, setAssignee] = useState(getValue());

        const onBlur = (assignee: GetUserResponse) => {
            setAssignee(assignee);
            table.options.meta?.updateData(row.index, column.id, assignee.id);
        };
        return (
            <OnboardingAssigneeDropdown
                assigneeUserID={assignee}
                users={users}
                userID={userID}
                saveAssignee={onBlur}
                className="h-full outline-none border-none"
            />
        );
    };

    const StatusDropdownCell = ({
        getValue,
        row,
        column,
        table,
    }: {
        getValue: () => any;
        row: { index: number };
        column: { id: string };
        table: any;
    }) => {
        const [status, setStatus] = useState(getValue());

        const onBlur = (status: OnboardingChecklistStatus) => {
            setStatus(status);
            table.options.meta?.updateData(row.index, column.id, status);
        };
        return (
            <StatusDropdown
                incomingStatus={status}
                userID={userID}
                saveStatus={onBlur}
                statuses={onboardingStatuses}
                className="h-full outline-none"
            />
        );
    };

    const columns: ColumnDef<OnboardingChecklist>[] = [
        {
            id: "select",
            header: ({ table }) => (
                <div className="w-full h-full flex items-center justify-center">
                    <Checkbox
                        checked={
                            table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() &&
                                "indeterminate")
                        }
                        onCheckedChange={(value) =>
                            table.toggleAllPageRowsSelected(!!value)
                        }
                        aria-label="Select all"
                        className="shadow-none outline outline-1 outline-slate-200 mr-2"
                    />
                </div>
            ),
            cell: ({ row }) => (
                <div className="w-full h-full flex items-center justify-center">
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={(value) => row.toggleSelected(!!value)}
                        aria-label="Select row"
                        className="shadow-none outline outline-1 outline-slate-200"
                    />
                </div>
            ),
            enableSorting: false,
            enableHiding: false,
            size: 10,
        },
        {
            accessorKey: "name",
            header: "Task Name",
            size: 150,
            cell: TableInputCell,
        },
        {
            accessorKey: "description",
            header: "Task Description",
            size: 300,
            cell: TableInputCell,
        },
        {
            accessorKey: "task_status",
            header: "Status",
            size: 150,
            cell: ({ row, getValue, column, table }) => {
                return (
                    <div className={`w-full h-full ${inputFocusStyle}`}>
                        <StatusDropdownCell
                            getValue={getValue}
                            row={row}
                            column={column}
                            table={table}
                        />
                    </div>
                );
            },
        },
        {
            accessorKey: "assignee_user_id",
            header: "Assignee",
            size: 150,
            cell: ({ row, getValue, column, table }) => (
                <div className={`w-full h-full ${inputFocusStyle}`}>
                    <AssigneeDropdownCell
                        getValue={getValue}
                        row={row}
                        column={column}
                        table={table}
                    />
                </div>
            ),
        },
        {
            accessorKey: "start_date",
            header: "Start Date",
            size: 150,
            cell: TableInputCalendarCell,
        },
        {
            accessorKey: "deadline",
            header: "Deadline",
            size: 150,
            cell: (props) => (
                <TableInputCalendarCell {...props} deadline={true} />
            ),
        },
    ];
    return columns;
}
