import { Button } from "@/component/shadcn/ui/button";
import { TeamsAPI, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetTeamResponse,
    GetUserResponse,
    TeamSources,
} from "@/interfaces/serverData";
import { AssemblyErrorMessage } from "@/reusable_components/loadingStates/ErrorMessage";
import { PlusIcon } from "@radix-ui/react-icons";
import { Box, Skeleton, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import type { MembersRowState } from "./DataTable/constants";
import NewTeamCard from "./NewTeamCard";
import TeamCard from "./TeamCard";
import { useToast } from "@/component/shadcn/ui/use-toast";

export type TeamCardUI = {
    id: string;
    card: TeamSources;
    isEditing: boolean;
};

const TeamSettings = () => {
    const { id } = useParams<{ id: string }>(); // team id

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();
    const { toast } = useToast();

    const [rowState, setRowState] = useState<Map<string, MembersRowState>>(
        new Map(),
    );

    const [teamData, setTeamData] = useState<GetTeamResponse | null>(null);

    const [teamSources, setTeamSources] = useState<TeamSources[]>([]);

    const [teamCards, setTeamCards] = useState<TeamCardUI[]>([]);

    const [loadingTeamSources, setLoadingTeamSources] = useState<number>(
        loadingTypes.loading,
    );

    useEffect(() => {
        const [url, method] = TeamsAPI.getTeamSources;
        api.get(`${URLS.serverUrl}${url}/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    setTeamSources(res.data.data ?? []);
                    setLoadingTeamSources(loadingTypes.loaded);
                } else {
                    setLoadingTeamSources(loadingTypes.error);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingTeamSources(loadingTypes.error);
            });
    }, [id]);

    useEffect(() => {
        setTeamCards(
            teamSources.map((teamSource) => ({
                id: teamSource.id,
                card: teamSource,
                isEditing: false,
            })),
        );
    }, [teamSources]);

    useEffect(() => {
        const [url, method] = TeamsAPI.getTeam;
        api.get(`${URLS.serverUrl}${url}/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const teamInfo: GetTeamResponse = res.data.data;
                    setTeamData(teamInfo);
                    setSelectedMembersData(teamInfo.members);

                    const newRowState = new Map<string, MembersRowState>();
                    for (let i = 0; i < teamInfo.members.length; i++) {
                        const user: GetUserResponse = teamInfo.members[i];
                        newRowState.set(String(i), {
                            id: user.id,
                            name: `${user.first_name} ${user.last_name}`,
                            email: user.email,
                            username: user.username,
                            picture_url: user.picture_url,
                            role: user.user_role,
                        });
                    }
                    setRowState(newRowState);
                    setLoadingState(loadingTypes.loaded);
                } else {
                    setLoadingState(loadingTypes.error);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingState(loadingTypes.error);
            });
    }, [id, api]);

    const [selectedMembersData, setSelectedMembersData] = useState<
        GetUserResponse[]
    >([]);

    useEffect(() => {
        console.log("selectedMembersData", selectedMembersData);
    }, [selectedMembersData]);

    useEffect(() => {
        console.log("rowState", rowState);
    }, [rowState]);

    const removeTeamCardNew = (teamCardId: string) => {
        console.log(teamCardId);
        setTeamCards((prevCards) =>
            prevCards.filter((card) => card.id !== teamCardId),
        );
    };

    const deleteTeamCard = (teamCardId: string, source: string) => {
        const [url, method] = TeamsAPI.deleteTeamSource;
        const requestData = {
            source: source,
        };
        api.post(`${URLS.serverUrl}${url}/${id}`, requestData) // delete by source and TEAM id
            .then((res) => {
                if (res.status === 200) {
                    setTeamCards(
                        (prevCards) =>
                            prevCards.filter((card) => card.id !== teamCardId), // delete team card from UI
                    );
                }
                if (res.status !== 200) {
                    toast({
                        title: "Error deleting source",
                        description:
                            "Oops! Something's wrong. Please try again at a later time.",
                        variant: "destructive",
                    });
                } else {
                    toast({
                        title: "Source deleted successfully",
                    });
                }
            })
            .catch((err) => {
                console.error("Error fetching scope data:", err);
                toast({
                    title: "Error deleting source",
                    description:
                        "Oops! Something's wrong. Please try again at a later time.",
                    variant: "destructive",
                });
            });
    };

    return (
        <div>
            {loadingState === loadingTypes.loading ? (
                <Skeleton>
                    <Text>
                        {[...Array(6)].map((_, index) => (
                            <p
                                key={
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    index
                                }
                            >
                                {loremIpsum}
                            </p>
                        ))}
                    </Text>
                </Skeleton>
            ) : loadingState === loadingTypes.loaded ? (
                <Box mt={"5%"} ml={"18%"} mr={"18%"}>
                    <div className="flex flex-row justify-between">
                        <h2 className="text-2xl font-semibold">Settings</h2>
                        <div className="flex flex-col items-end gap-2">
                            <Button
                                className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                size="sm"
                                variant="outline"
                                onClick={() => {
                                    // Add a new TeamCard in editing state
                                    // You might need to manage this in state, e.g.:
                                    setTeamCards((prevCards) => [
                                        {
                                            id: String(prevCards.length + 1),
                                            isEditing: true,
                                            card: {
                                                id: "",
                                                source: "",
                                                scopes: [],
                                            },
                                        },
                                        ...prevCards,
                                    ]);
                                }}
                            >
                                Add Accounts
                                <PlusIcon />
                            </Button>
                        </div>
                    </div>
                    <p className="text-sm text-gray11 mb-5">
                        Manage tickets that this team will respond to
                    </p>

                    <div className="flex flex-col gap-2">
                        {teamCards.map((card) =>
                            card.isEditing ? (
                                <NewTeamCard
                                    key={card.id}
                                    isEditing={card.isEditing}
                                    teamId={id ?? ""}
                                    teamCardId={card.id}
                                    removeTeamCard={removeTeamCardNew}
                                    setTeamCards={setTeamCards}
                                />
                            ) : (
                                <TeamCard
                                    key={card.id}
                                    teamId={id ?? ""}
                                    teamSource={card.card}
                                    removeTeamCard={deleteTeamCard}
                                    removeTeamCardFromUI={removeTeamCardNew}
                                    setTeamCards={setTeamCards}
                                />
                            ),
                        )}
                    </div>
                </Box>
            ) : (
                <AssemblyErrorMessage className="mt-5 mx-20" />
            )}
        </div>
    );
};

export default TeamSettings;
